import React from 'react';

import { THColumn } from './THColumn';

import type { IColumnTable } from '~/@types/table';

import { TH, THead, TRHeader } from '../../design';

interface IHeaderProps {
  columns: IColumnTable[];
  sortProps?: {
    sortBy?: string;
    handleSortBy: (col: string | undefined) => void;
  };
  isMenuVisible?: boolean;
  visibleColumn?: string;
  showSortBy?: boolean;
  thColumnPadding?: string;
}
const Header = ({
  columns,
  sortProps,
  isMenuVisible,
  visibleColumn,
  showSortBy,
  thColumnPadding,
}: IHeaderProps) => {
  return (
    <THead>
      <TRHeader>
        {columns.map((column, key) => (
          <THColumn
            key={key}
            column={column}
            sortProps={sortProps}
            visibleColumn={
              visibleColumn || (columns.length - 1 === key ? column.accessor : undefined)
            }
            showSortBy={showSortBy}
            thColumnPadding={thColumnPadding}
          />
        ))}
        {isMenuVisible && <TH key={'th-menu'} minWidth="40px" />}
      </TRHeader>
    </THead>
  );
};

export { Header };
