import React, { Component, Fragment } from 'react';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import IconMenu from '~/components/IconMenu';
import SvgIcon from '~/components/SvgIcon';
import { TableRow, TableCol } from '~/components/UI/TableCard';
import { Header3 } from '~/components/UI/Typographics/headers';

import TeamOpenIcon from '~/assets/baseline-keyboard_arrow_down-24px.svg';
import TeamCloseIcon from '~/assets/baseline-keyboard_arrow_right-24px.svg';

import routes from '~/constants/routes';
import './styles.scss';
import { COLOR_PALETTE } from '~/styles';

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ColText = styled.div`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const PADDING = 45;

class TeamItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTeamOpen: true,
    };
  }

  static propTypes = {
    team: PropTypes.object,
    menuItems: PropTypes.array,
    teamLevel: PropTypes.number,
  };

  static defaultProps = {
    teamLevel: 0,
  };

  goToTeam = (teamId) => {
    const { history } = this.props;
    history.push(routes.TEAM.build({}, { teamId }));
  };

  toggleTeam = (e) => {
    const { isTeamOpen } = this.state;
    e.stopPropagation();
    this.setState({ isTeamOpen: !isTeamOpen });
  };

  render() {
    const { team, menuItems, teamLevel, integrationSettings, inactiveUsers } = this.props;

    const { isTeamOpen } = this.state;

    const coachesLength = !isEmpty(team.coaches) ? team.coaches.length : 0;
    const membersLength = !isEmpty(team.members)
      ? team.members.filter((member) => !inactiveUsers.includes(member)).length
      : 0;

    const integrationValues = Object.values(integrationSettings);
    const integrationSoftware =
      team.externalSource && integrationValues.length
        ? integrationValues.find((x) => x.id === team.externalSource)
        : null;

    return (
      <Fragment>
        <TableRow key={team.id} height={30}>
          <TableCol>
            <NameWrapper style={{ marginLeft: `${teamLevel * PADDING}px` }}>
              {this.props.children && (
                <button onClick={this.toggleTeam} style={{ padding: 0 }}>
                  <SvgIcon
                    width="24px"
                    height="24px"
                    url={isTeamOpen ? TeamOpenIcon : TeamCloseIcon}
                  />
                </button>
              )}
              <div
                className="team-item"
                style={{ cursor: 'pointer' }}
                onClick={() => this.goToTeam(team.id)}
                onKeyUp={() => this.goToTeam(team.id)}
                role="button"
                tabIndex={0}
              >
                <Header3>
                  {team.externalId ? `${team.name} (${team.externalId})` : `${team.name}`}
                </Header3>
              </div>
            </NameWrapper>
          </TableCol>
          <TableCol>
            <ColText>
              {integrationSoftware ? integrationSoftware.name : <Trans>Manual</Trans>}
            </ColText>
          </TableCol>
          <TableCol>
            <ColText>{coachesLength}</ColText>
          </TableCol>
          <TableCol>
            <ColText>{membersLength}</ColText>
          </TableCol>
          <TableCol>
            <IconMenu items={menuItems} />
          </TableCol>
        </TableRow>
        {isTeamOpen ? this.props.children : null}
      </Fragment>
    );
  }
}

export default withI18n()(withRouter(TeamItem));
