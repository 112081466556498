import React from 'react';

import { useLingui } from '@lingui/react';

import { Container, SearchBox, SearchBoxText } from './design';

const InvitePeers = () => {
  const { i18n } = useLingui();

  return (
    <Container>
      <SearchBox>
        <SearchBoxText>{i18n._('Search peers')}</SearchBoxText>
      </SearchBox>
    </Container>
  );
};
export { InvitePeers };
