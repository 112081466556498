import React from 'react';

import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';

import { COLORS } from '~/styles';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

const Label = styled.span`
  letter-spacing: -0.13px;
  color: ${COLORS.ICONS_PRIMARY};
  font-size: 12px;
`;

interface IProps {
  isChecked?: boolean;
  onChange: (value: boolean) => void;
}

export const NAOption = ({ isChecked = false, onChange }: IProps) => {
  return (
    <Container>
      <CheckBox onChange={(_e, value) => onChange(value)} size={'20px'} checked={isChecked} />
      <Label>N/A</Label>
    </Container>
  );
};
