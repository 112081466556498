import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import LearnMore from '~/components/LearnMore';
import SearchSelectButton from '~/components/SearchSelectButton';
import Switch from '~/components/Switch';
import Tooltip from '~/components/Tooltip';
import Divider from '~/components/UI/Divider';

import removeIcon from '~/assets/ic-close-primary-18-px.svg';

import { JOB_PROFILE_STATUSES } from '~/constants';
import { INSTRUCTIONS } from '~/constants/instructions';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUsers } from '~/selectors/baseGetters';
import { getCompanyActiveConnections } from '~/services/companies';
import { sendInvites } from '~/services/invites';
import { getJobProfiles } from '~/services/jobProfiles';
import { COLORS } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';
import isValidEmail from '~/utils/isValidEmail';

import Button from '../Button';
import ShowSpinnerIfLoading from '../ShowSpinnerIfLoading';
import SvgIcon from '../SvgIcon';

const Wrapper = styled.div`
  margin-top: 12px;
`;

const CustomUserHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 310px 175px 175px 220px 50px;
  align-content: center;
`;

const CustomUserHeaderItem = styled.div`
  font-size: 12px;
  text-transform: uppercase;
`;
const CustomUserItem = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0 10px 0 0;
`;
const CustomUserItemEmail = styled.div`
  margin: 0 5px 0 16px;
  width: 270px;
  padding: 4px 4px 4px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CustomUserHeaderItemEmail = styled.div`
  font-size: 12px;
  margin-left: 16px;
  text-transform: uppercase;
`;
const CustomUserElementsStacked = styled.div``;

const ErrorText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #f26c5d;
  margin-top: 8px;
`;

const RowWrap = styled.div`
  width: 100%;
  margin-bottom: 26px;
  margin-right: 25px;
`;

const RemoveButton = styled(SvgIcon)`
  cursor: pointer;
  :hover {
    background-color: var(--company-color);
  }
`;
const CustomUserWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => (props.selected ? '#e6e8ec' : '#f6f8fc')};
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
  min-height: 64px;
  height: auto;
  display: grid;
  grid-template-columns: 4fr 2.5fr 2.5fr 2.5fr 3fr 0.7fr;
  align-items: center;
`;

const EmailInputField = styled.input`
  width: 248px;
  height: 26px;
  border-radius: 4px;
  border: solid 1px #e4e8ef;
  margin-left: 16px;
  margin-right: 16px;
  padding: 4px;
  padding-left: 16px;
  align-self: center;
  font-size: 14px;
`;

const RoleProfileSelect = styled(SearchSelectButton)`
  font-size: 12px;
  height: 34px;
  align-self: center;
`;

const MemberTeamSelect = styled(SearchSelectButton)`
  font-size: 12px;
  align-self: center;
  height: 34px;
`;

const SelectWrapper = styled.div`
  width: 160px;
  height: 36px;
  padding: 8px;
`;

const CoachTeamSelect = styled(SearchSelectButton)`
  font-size: 12px;
  align-self: center;
`;

const AdminSwitchWrapper = styled.div`
  align-self: center;
  justify-self: center;
`;

const AdminSwitch = styled(Switch)`
  align-self: center;
  justify-self: center;
`;

const ButtonSection = styled.div`
  margin: 106px 0 19px 0;
  display: flex;
  padding: 8px;
  padding-right: 24px;
  justify-content: flex-end;
`;

const AddButton = styled(Button)`
  margin-top: 4px;
  margin-right: 16px;
`;

const UsersToInviteWrap = styled.div`
  margin-top: 24px;
`;

function renderNames(options, userToInvite, field) {
  const namesList = options
    .filter((obj) => userToInvite[field].includes(obj.value))
    .map((obj) => obj.name);
  return namesList.map((item, index) => (
    <React.Fragment key={index}>
      <CustomUserElementsStacked>{item}</CustomUserElementsStacked>
    </React.Fragment>
  ));
}

function renderUserRow(userToInvite, teamOptions, roleOptions, usersToInvite, setUsersToInvite) {
  const email = userToInvite.email;
  const roleNames = renderNames(roleOptions, userToInvite, 'jobProfiles');
  const coachTeamNames = renderNames(teamOptions, userToInvite, 'coachTeams');
  const memberTeamNames = renderNames(teamOptions, userToInvite, 'memberTeams');
  const isAdmin = userToInvite.isAdmin;

  return (
    <CustomUserWrapper key={userToInvite.email}>
      <Tooltip tooltip={email} maxWidth="100%">
        <CustomUserItemEmail>{email}</CustomUserItemEmail>
      </Tooltip>
      <CustomUserItem>{roleNames}</CustomUserItem>
      <CustomUserItem>{memberTeamNames}</CustomUserItem>
      <CustomUserItem>{coachTeamNames}</CustomUserItem>
      <CustomUserItem style={{ margin: 'auto' }}>
        <AdminSwitchWrapper>
          <AdminSwitch disabled={true} checked={isAdmin} />
        </AdminSwitchWrapper>
      </CustomUserItem>
      <CustomUserItem style={{ width: '44px' }}>
        <RemoveButton
          url={removeIcon}
          onClick={() =>
            setUsersToInvite(usersToInvite.filter((x) => x.email !== userToInvite.email))
          }
        />
      </CustomUserItem>
    </CustomUserWrapper>
  );
}

function CustomUsersTab({ i18n, onModalClose, teams, invites }) {
  const [loading, setLoading] = useState(true);
  const [usersToInvite, setUsersToInvite] = useState([]);
  const [availableRoles, setAvailableRoles] = useState();

  const [companyActiveConnections, setCompanyActiveConnections] = useState({});
  const users = useSelector(getUsers);
  const [error, setError] = useState(false);
  const getMultiLangString = useMultiLangString();

  useEffect(() => {
    getCompanyActiveConnections().then(setCompanyActiveConnections);
    getJobProfiles({ status: JOB_PROFILE_STATUSES.ACTIVE.key }).then((roles = {}) => {
      // Override correct multi lang string for name
      Object.values(roles).forEach((jp) => {
        jp.name = getMultiLangString(jp.name);
      });

      setAvailableRoles(sortBy(roles, (role) => role.name.toLowerCase()));
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const teamOptions = [...map(teams, (team) => ({ name: team.name, value: team.id }))];

  const roleOptions = [...map(availableRoles, (role) => ({ name: role.name, value: role.id }))];

  const emptyUser = { email: '', jobProfiles: [], memberTeams: [], coachTeams: [], isAdmin: false };
  const [currentUser, setCurrentUser] = useState(emptyUser);

  const addUser = () => {
    const lowerCaseEmail = currentUser.email.toLowerCase();
    const predicate = (u) => u.email === lowerCaseEmail;
    const existUser = find(users, predicate);
    const userAlreadyInvited = find(invites, predicate);
    const userAlreadyAddedToList = find(usersToInvite, predicate);

    const activeUserConnection = find(
      companyActiveConnections,
      (c) => existUser && c.user === existUser.id, // userConnection has no email, so we need to check by userId
    );

    if (existUser && activeUserConnection) {
      setError(i18n._(t`This user is a company member`));
    } else if (userAlreadyInvited) {
      setError(i18n._(t`The user has already been invited`));
    } else if (userAlreadyAddedToList) {
      setError(i18n._(t`The user has already been added to the list`));
    } else if (isValidEmail(lowerCaseEmail)) {
      setUsersToInvite([...usersToInvite, { ...currentUser, email: lowerCaseEmail }]);
      setCurrentUser(emptyUser);
    }
  };

  return (
    <Wrapper>
      <LearnMore
        label={i18n._(t`Manually invited members cannot be synchronised or delete via en API.`)}
        LearnMoreLink={getInstructionUrl(INSTRUCTIONS.INVITING_MANAGING_MEMBERS)}
      />

      <ShowSpinnerIfLoading loading={loading}>
        <CustomUserHeader>
          <CustomUserHeaderItemEmail>
            <Trans>Email</Trans>
          </CustomUserHeaderItemEmail>
          <CustomUserHeaderItem>
            <Trans>Role profile</Trans>
          </CustomUserHeaderItem>
          <CustomUserHeaderItem>
            <Trans>Member of team</Trans>
          </CustomUserHeaderItem>
          <CustomUserHeaderItem>
            <Trans>Coach of team</Trans>
          </CustomUserHeaderItem>
          <CustomUserHeaderItem>
            <Trans>Admin</Trans>
          </CustomUserHeaderItem>
        </CustomUserHeader>
        <RowWrap>
          <CustomUserWrapper>
            <EmailInputField
              placeholder="example@email.com"
              value={currentUser.email}
              onChange={(e) => {
                if (error) {
                  setError(false);
                }
                setCurrentUser({ ...currentUser, email: e.target.value });
              }}
            />
            <SelectWrapper>
              <RoleProfileSelect
                checkedList={currentUser.jobProfiles}
                options={map(availableRoles, (t) => ({ id: t.id, label: t.name }))}
                title={i18n._(t`Roles`)}
                handleChange={(e) => setCurrentUser({ ...currentUser, jobProfiles: e })}
                height="36px"
                fontSize="12px"
              />
            </SelectWrapper>
            <SelectWrapper>
              <MemberTeamSelect
                checkedList={currentUser.memberTeams}
                options={map(teamOptions, (t) => ({ id: t.value, label: t.name })).filter(
                  (team) => !currentUser.coachTeams.includes(team.id),
                )}
                title={i18n._(t`Teams`)}
                handleChange={(e) => setCurrentUser({ ...currentUser, memberTeams: e })}
                height="36px"
                fontSize="12px"
              />
            </SelectWrapper>
            <SelectWrapper>
              <CoachTeamSelect
                checkedList={currentUser.coachTeams}
                options={map(teamOptions, (t) => ({ id: t.value, label: t.name })).filter(
                  (team) => !currentUser.memberTeams.includes(team.id),
                )}
                title={i18n._(t`Coach teams`)}
                handleChange={(e) => setCurrentUser({ ...currentUser, coachTeams: e })}
                height="34px"
                fontSize="12px"
              />
            </SelectWrapper>
            <AdminSwitchWrapper>
              <AdminSwitch
                onChange={(e) => setCurrentUser({ ...currentUser, isAdmin: e })}
                checked={currentUser.isAdmin}
              />
            </AdminSwitchWrapper>
            <AddButton
              label="Add"
              type="primary-border"
              disabled={!isValidEmail(currentUser.email.toLowerCase())}
              onClick={addUser}
            />
          </CustomUserWrapper>
          {error && <ErrorText>{error}</ErrorText>}
        </RowWrap>
        {!isEmpty(usersToInvite) && <Divider $color={COLORS.BORDER_HARD} />}
        <UsersToInviteWrap>
          {usersToInvite.map((userToInvite) =>
            renderUserRow(userToInvite, teamOptions, roleOptions, usersToInvite, setUsersToInvite),
          )}
        </UsersToInviteWrap>
        <ButtonSection>
          <Button
            label={i18n._(t`Invite`)}
            disabled={isEmpty(usersToInvite) || error}
            onClick={async () => {
              setLoading(true);

              const invites = Object.values(usersToInvite).map((userToInvite) => ({
                email: userToInvite.email,
                ...(userToInvite.memberTeams !== [] && {
                  teamsMember: userToInvite.memberTeams,
                }),
                ...(userToInvite.coachTeams !== [] && {
                  teamsCoach: userToInvite.coachTeams,
                }),
                ...(userToInvite.jobProfiles !== [] && {
                  jobProfiles: userToInvite.jobProfiles,
                }),
                isAdmin: userToInvite.isAdmin,
              }));
              const response = await sendInvites(invites);
              onModalClose(response !== 'OK');
            }}
            loading={loading}
            type="primary"
          />
        </ButtonSection>
      </ShowSpinnerIfLoading>
    </Wrapper>
  );
}

export default withI18n()(CustomUsersTab);
