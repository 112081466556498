import React, { useEffect } from 'react';

import { Circle } from '~/components/TableOfContents/components/Circle';

import { useScrollEffect } from '~/hooks/useScrollEffect';
import { COLORS } from '~/styles';

import { Animated, AnimatedTitle, Card, SectionNumber, Title, Wrapper } from '../design';
import { SECTION_STATUS, SECTION_TYPE } from '../types';

import type { ISectionProps } from '../types';

const Section = ({
  section,
  index,
  currentSection,
  setCurrentSection,
  parentHeight,
  isCollapsed,
  hideErrorState,
}: ISectionProps) => {
  const { ref, scrollToRef } = useScrollEffect<HTMLDivElement>({ behavior: 'smooth' });
  let status = currentSection === index ? SECTION_STATUS.CURRENT : section.status;
  if (hideErrorState === true && status === SECTION_STATUS.ERROR) {
    status = undefined;
  }

  useEffect(() => {
    if (currentSection === index) {
      scrollToRef();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection]);

  const isParentSection = section.type === SECTION_TYPE.PARENT;
  return (
    <Wrapper parentHeight={parentHeight} ref={ref}>
      <Card
        isCollapsed={isCollapsed}
        status={status}
        onClick={() => void (!isParentSection && setCurrentSection(index))}
        noHover={isParentSection}
      >
        <Circle
          index={index}
          status={status}
          sectionType={section.type}
          currentSection={currentSection}
        />
        <Animated>
          <AnimatedTitle shouldAnimate={isCollapsed}>
            {currentSection === index ? (
              <Title bold={section.type === SECTION_TYPE.PARENT} color={COLORS.COMPANY}>
                {section.sectionNumber}
              </Title>
            ) : (
              <Title bold={section.type === SECTION_TYPE.PARENT}>{section.sectionNumber}</Title>
            )}
          </AnimatedTitle>
          <AnimatedTitle shouldAnimate={!isCollapsed}>
            {currentSection === index ? (
              <Title
                color={isParentSection ? COLORS.ICONS_PRIMARY : COLORS.COMPANY}
                bold={isParentSection}
              >
                <SectionNumber>{section.sectionNumber}</SectionNumber>
                <span>{section.title}</span>
              </Title>
            ) : (
              <Title bold={isParentSection}>
                <SectionNumber>{section.sectionNumber}</SectionNumber>
                <span>{section.title}</span>
              </Title>
            )}
          </AnimatedTitle>
        </Animated>
      </Card>
    </Wrapper>
  );
};

export { Section };
