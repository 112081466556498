import React from 'react';

import styled from 'styled-components';

import { CircularProgressCard } from '~/components/CircularProgressCard';

import { COLORS } from '~/styles';

const Wrapper = styled.div<{ $size: number }>`
  margin-top: 24px;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: ${(props) => `repeat(${props.$size}, minmax(312px, 484px))`};
`;

const TotalSection = () => {
  // TODO delete mock data
  const data = [
    {
      progress: 27,
      title: 'Participation',
      color: COLORS.CAREER,
    },
    {
      progress: 100,
      title: 'Participation',
      color: COLORS.SECONDARY,
    },
    {
      progress: 60,
      title: 'Participation',
      color: COLORS.ACCENT_SUCCESS,
    },
  ];

  return (
    <Wrapper $size={data.length}>
      {data.map((item, key) => (
        <CircularProgressCard key={key} {...item} />
      ))}
    </Wrapper>
  );
};

export { TotalSection };
