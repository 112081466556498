import { t } from '@lingui/macro';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

import type { I18n } from '@lingui/core';

export const superAdminMenuItems = [
  {
    title: (i18n: I18n) => i18n._(t`Companies`),
    url: routes.SUPERADMIN_COMPANY_DASHBOARD.url,
    childUrls: [routes.SUPERADMIN_COMPANY_MEMBERS.url],
    superAdminRoute: true,
    role: ROLES.USER,
  },
  {
    title: (i18n: I18n) => i18n._(t`Templates`),
    children: [
      {
        title: (i18n: I18n) => i18n._(t`Reviews`),
        url: routes.SUPERADMIN_TEMPLATES_REVIEW.url,
        superAdminRoute: true,
        role: ROLES.USER,
      },
    ],
  },
  {
    title: (i18n: I18n) => i18n._(t`Super admins`),
    url: routes.SUPERADMIN_OVERVIEW.url,
    superAdminRoute: true,
    role: ROLES.USER,
  },
  {
    title: (i18n: I18n) => i18n._(t`Delete company requests`),
    url: routes.SUPERADMIN_REQUESTS.url,
    superAdminRoute: true,
    role: ROLES.USER,
  },
  {
    title: (i18n: I18n) => i18n._(t`Industries`),
    url: routes.SUPERADMIN_INDUSTRIES.url,
    superAdminRoute: true,
    role: ROLES.USER,
  },
  {
    title: (i18n: I18n) => i18n._(t`Skill matrix`),
    url: routes.SUPERADMIN_SKILL_MATRIX.url,
    superAdminRoute: true,
    role: ROLES.USER,
  },
  {
    title: (i18n: I18n) => i18n._(t`Job Library`),
    url: routes.SUPERADMIN_JOB_LIBRARY.url,
    superAdminRoute: true,
    role: ROLES.USER,
  },
];
