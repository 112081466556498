import React from 'react';

import { StickyContainer } from 'react-sticky';
import styled from 'styled-components';

import Footer from '~/components/Footer';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const Main = styled(StickyContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  background: ${COLORS.BG_PAGE};
  position: relative;
`;

const FooterContainer = styled.div`
  width: 100%;
  height: 88px;
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
`;

const PageContent = styled.div`
  flex: 1;
`;

const FocusLayoutWithAbsoluteFooter = ({ children }) => (
  <Wrapper>
    <Main id="main-content">
      <PageContent>{children}</PageContent>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </Main>
  </Wrapper>
);

export default FocusLayoutWithAbsoluteFooter;
