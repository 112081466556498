import React from 'react';

import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import Editor from '~/components/Editor';

import { NAOption } from './Components/NAOption';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';

interface IQuestionViewTextProps {
  languageState: ILanguageStateReturn;
  onChange?: (data: { answer?: string; comment?: string; isNotApplicable?: boolean }) => void;
  defaultValues: IQuestionTextForm;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  & .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const QuestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.16px;
  margin-bottom: 10px;
  color: ${COLORS.ICONS_PRIMARY};
`;

const DescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  flex-direction: column;
`;

type IQuestionTextForm = {
  answer: string;
  isNotApplicable: boolean;
};

const TextAreaQuestion = ({ defaultValues, onChange }: IQuestionViewTextProps) => {
  const { control } = useForm<IQuestionTextForm>({ defaultValues });

  return (
    <Wrapper>
      <DescriptionWrapper>
        <QuestionHeader>
          <Label>
            <Trans>Your answer</Trans>
          </Label>
          <Controller
            name="isNotApplicable"
            control={control}
            render={({ field }) => (
              <NAOption
                isChecked={field.value}
                onChange={(value: boolean) => {
                  field.onChange(value);
                  onChange?.({ isNotApplicable: value });
                }}
              />
            )}
          />
        </QuestionHeader>
        <Controller
          name="answer"
          control={control}
          render={({ field }) => (
            <Editor
              value={field.value}
              compact
              onChange={(value: string) => {
                field.onChange(value);
                onChange?.({ answer: value });
              }}
              placeholder={i18n._(t`Type here...`)}
              minHeight="150px"
              big
            />
          )}
        />
      </DescriptionWrapper>
    </Wrapper>
  );
};

export { TextAreaQuestion };
