import React from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { difference } from 'lodash';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';
import { SearchSelect } from '~/components/SearchSelect';
import ToolTip from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';
import type { IEmployee } from '~/pages/Reviews/EditCycle/ReviewCycleForm/types';

import { Actions, Content, Footer, Header, Label, Title } from './design';
import { useEditEmployeeModal } from './useEditEmployeeModal';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import getUserFullName from '~/utils/getUserFullName';

import { confirm } from '../ConfirmationModal/confirm';

import type { IUser } from '@learned/types';

interface EditEmployeeModalProps {
  onClose: () => void;
  onSave: (employee: IEmployee) => void;
  employee: IEmployee;
  options: {
    showCoaches: boolean;
    showJobs: boolean;
  };
}

const EditEmployeeModal = ({ onClose, onSave, employee, options }: EditEmployeeModalProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const { formMethods } = useEditEmployeeModal({ employee });

  const { watch, setValue } = formMethods;

  const isPublishedUserReview = !!employee.userReview;

  const onCoachesChange = async (selected: IUser[]) => {
    // @ts-ignore
    const removedItems = difference(watch('coaches.selected'), selected);

    if (removedItems.length > 0 && isPublishedUserReview) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Delete coach?`),
        description: i18n._(
          t`Are you sure you want to delete this coach from the review? Provided input will be lost. This cannot be undone.`,
        ),
      });
      if (!isConfirmed) {
        return;
      }
    }

    setValue('coaches.selected', selected);
  };

  return (
    <Modal
      width={500}
      contentStyles={{ padding: '24px 32px', overflow: 'visible' }}
      hideFooter
      isHideHeader
      centerModal
      showDivider={false}
      borderRadius={6}
    >
      <Header>
        <Title>
          <Trans>Edit employee</Trans>
        </Title>
        <Button size={ButtonSize.BIG} onClick={onClose} variant={ButtonVariant.CLOSE} />
      </Header>
      <Content>
        <UserAvatar userId={employee.id} />
        {options.showJobs && (
          <ToolTip
            tooltip={i18n._(
              t`After a review cycle has been published, the selected job(s) cannot be changed. Remove user from and add to cycle again to select jobs.`,
            )}
            disabled={!isPublishedUserReview}
          >
            <div className="inputWrapper">
              <Label>
                <Trans>Select job(s) to review</Trans>
              </Label>
              <SearchSelect
                selectedItems={watch('jobProfiles.selected')}
                onChange={(selected) => setValue('jobProfiles.selected', selected)}
                onSearchChange={(search) => setValue('jobProfiles.search', search)}
                items={watch('jobProfiles.filtered')}
                stringifyItem={(job) => getMultiLangString(job.name)}
                placeholder={i18n._(t`Search job(s)`)}
                isExpandable
                disabled={isPublishedUserReview}
              />
            </div>
          </ToolTip>
        )}
        {options.showCoaches && (
          <div className="inputWrapper">
            <Label>
              <Trans>Select coach(es) who will provide</Trans>
            </Label>
            <SearchSelect
              selectedItems={watch('coaches.selected')}
              onChange={onCoachesChange}
              onSearchChange={(search) => setValue('coaches.search', search)}
              items={watch('coaches.filtered')}
              stringifyItem={(user) => getUserFullName(user)}
              placeholder={i18n._(t`Search coach(es)`)}
              isExpandable
              showAvatar
            />
          </div>
        )}
        <div className="inputWrapper">
          <Label>
            <Trans>Select guests invited to the review</Trans>
          </Label>
          <SearchSelect
            selectedItems={watch('guests.selected')}
            onChange={(selected) => setValue('guests.selected', selected)}
            onSearchChange={(search) => setValue('guests.search', search)}
            items={watch('guests.filtered')}
            stringifyItem={(user) => getUserFullName(user)}
            placeholder={i18n._(t`Search guests`)}
            minCharsToShowDropdown={3}
            isExpandable
            showAvatar
          />
        </div>
      </Content>
      <Footer>
        <Actions>
          <Button
            label={i18n._(t`Cancel`)}
            type="button"
            variant={ButtonVariant.TEXT_PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
          <Button
            label={i18n._(t`Save`)}
            type="button"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={() => {
              onSave({
                ...employee,
                jobProfiles: watch('jobProfiles.selected'),
                coaches: watch('coaches.selected').map(({ id }) => id),
                guests: watch('guests.selected').map(({ id }) => id),
              });
              onClose();
            }}
          />
        </Actions>
      </Footer>
    </Modal>
  );
};

export { EditEmployeeModal };
