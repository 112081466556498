// The Horizontal bar chart
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import { ENGAGEMENT_REPORT_CHART_DIMENSIONS, REPORT_CHART_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Loader } from '~/components/Buttons/components/Loader';
import { ICONS, Icon } from '~/components/Icon';
import ChevronIcon from '~/components/Icons/Chevron';
import PaginationBar from '~/components/PaginationBar';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import {
  MainCtr,
  TableCtr,
  LeftCtr,
  Line,
  DataRaw,
  DataCtr,
  Numbers,
  NumberLineBorder,
  ItemNr,
  DataCtrBackground,
  RowTitle,
  RightCtr,
  SearchFieldWrapper,
  SearchCtr,
  FilterBtnCtr,
  FilterCtr,
  LoaderCtr,
  PrimaryDimension,
  PrimaryDimensionHolder,
  SecondaryDimension,
  SortIcons,
  SortCtr,
  SortRow,
  DataMultiCtr,
  DataMultiCtrBackground,
  DataMultiOuterCtr,
  PaginationCtr,
  NoDataTextContainer,
} from './TotalStyle';

import useDebounce from '~/hooks/useDebounce';
import { useOutsideClick } from '~/hooks/useOutsideClick';
import { usePagination } from '~/hooks/usePagination';
import {
  TLucaModalData,
  TSortingOrder,
  TTotalGraphData,
  getEngagementCharts,
} from '~/services/reports';
import { COLORS } from '~/styles';
import { toFixed } from '~/utils/math';
import { getDateForTimeFrame, getSelectedKeys, sanitizeDimensions } from '~/utils/reports';

import ReFetchBtn from '../../Components/RefetchBtn';
import { PAGINATION_PRIMARY_OPTIONS } from '../../options';
import { EOptions, TOptions } from '../../types';
import { EngagementReportContext } from '../EngagementContext';
import { HeaderIncluded } from '../Header';
import { InformationModal } from '../InformationModal';
import { Luca } from '../Luca';

const BAR_HEIGHT = 30;
const BAR_MAX_HEIGHT = 40;

type TProps = { optionChangeHandler: (key: EOptions, value?: string | TOptions[] | null) => void };

const getMultiComponent = (item: TTotalGraphData, isActive: boolean) => {
  if (item.benchmark || item.team) {
    return (
      <DataMultiOuterCtr mHeight={`${BAR_MAX_HEIGHT}px`}>
        {!!item?.value && (
          <DataMultiCtr
            barWidth={`${toFixed(item.value || 0, 2)}%`}
            deviation={toFixed(item.deviation || 0, 2)}
            isActive={isActive}
            value={`${toFixed(item.value || 0, 2)}%`}
          >
            <DataMultiCtrBackground isActive={isActive} bgClr={COLORS.CONFIRMATION_MODAL_INFO} />
          </DataMultiCtr>
        )}
        {!!item?.team && (
          <DataMultiCtr
            barWidth={`${toFixed(item.team || 0, 2)}%`}
            deviation={toFixed(item.deviation || 0, 2)}
            isActive={isActive}
            value={`${toFixed(item.team || 0, 2)}%`}
          >
            <DataMultiCtrBackground isActive={isActive} bgClr={COLORS.ACCENT_WARNING} />
          </DataMultiCtr>
        )}
        {!!item?.benchmark && (
          <DataMultiCtr
            barWidth={`${toFixed(item.benchmark || 0, 2)}%`}
            deviation={toFixed(item.deviation || 0, 2)}
            isActive={isActive}
            value={`${toFixed(item.benchmark || 0, 2)}%`}
          >
            <DataMultiCtrBackground isActive={isActive} bgClr={COLORS.COMPANY} />
          </DataMultiCtr>
        )}
      </DataMultiOuterCtr>
    );
  }
  return (
    <DataCtr
      barWidth={`${toFixed(item.value, 2)}%`}
      mHeight={`${BAR_HEIGHT}px`}
      isActive={isActive}
      deviation={toFixed(item.deviation || 0, 2)}
    >
      <DataCtrBackground isActive={isActive} />
      <ItemNr isActive={isActive}>{toFixed(item.value, 2)}%</ItemNr>
    </DataCtr>
  );
};

function Total({ optionChangeHandler }: TProps): JSX.Element {
  const { i18n } = useLingui();
  const [activeRow, setActiveRow] = useState<null | number>(null);
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [informationModalData, setInformationModalData] = useState<TLucaModalData | null>(null);

  const [data, setData] = useState<TTotalGraphData[]>([]);

  const { shouldShowLuca, options, viewAs, filters, reportType, dimensions, isWaiting } =
    useContext(EngagementReportContext);

  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [sortedBy, setSortedBy] = useState('');
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 1000);

  const { pagination, changePagination } = usePagination(PAGINATION_PRIMARY_OPTIONS[0].id);

  enum ESort {
    primary = 'primary',
    secondary = 'secondary',
  }
  const [showSortedMenu, setShowSortedMenu] = useState<ESort | null>(null);
  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    const newPagination = {
      ...pagination,
      skip,
      index,
    };
    changePagination(newPagination);
  };

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    const newPagination = {
      skip: 0,
      index: 1,
      limit,
    };
    changePagination(newPagination);
  };

  const handleDataRowClick = (item: TLucaModalData) => {
    // TODO: lets start handling rest of the dimensions here
    if (item.filters.themeId) {
      // perhaps should do a luca modal data validation here?
      setInformationModalData(item);
      setShowInformationModal(true);
    }
  };

  const sortBy = {
    primary: {
      asc: {
        key: 'primary__asc',
        label: `${i18n._(t`A-Z Alphabetic`)}`,
      },
      desc: {
        key: 'primary__desc',
        label: `${i18n._(t`Z-A Alphabetic`)}`,
      },
    },
    secondary: {
      asc: {
        key: 'secondary__asc',
        label: `${i18n._(t`Low to high`)}`,
      },
      desc: {
        key: 'secondary__desc',
        label: `${i18n._(t`High to low`)}`,
      },
    },
  };

  const primaryRef = useOutsideClick<HTMLDivElement>(() => {
    setShowSortedMenu(null);
  });

  const barSize = 20;
  const gaps = Array.from({ length: 100 / barSize + 1 }, (_, i) => i * barSize);
  const barLines = () => {
    return gaps.map((i) => <Line key={`line-${i + 1}`} leftAlign={`${i}%`} />);
  };

  const getData = async () => {
    try {
      if (dimensions.primary === 'primary_none' || !dimensions.primary) {
        return;
      }
      setIsLoading(true);
      const sortedArray = sortedBy.split('__') || [];
      const payload = {
        viewAs,
        reportType,
        chartType: REPORT_CHART_TYPES.BY_ONE_DIMENSION,
        primaryDimension: sanitizeDimensions(
          dimensions.primary || 'theme',
        ) as ENGAGEMENT_REPORT_CHART_DIMENSIONS,
        sorting: {
          orderBy: sortedArray.length === 2 ? sortedArray[0] : '',
          order:
            sortedArray.length === 2 ? (sortedArray[1] as TSortingOrder) : ('' as TSortingOrder),
        },
        filters: {
          themes: getSelectedKeys(filters.themesOptionSelected),
          teams: getSelectedKeys(filters.teamsOptionSelected),
          surveys: getSelectedKeys(filters.surveysOptionSelected),
          jobs: getSelectedKeys(filters.jobsSelected),
          jobGroups: getSelectedKeys(filters.jobsGroupsSelected),
          genders: getSelectedKeys(filters.gendersSelected),
          ageGroups: getSelectedKeys(filters.ageGroupSelected),
          educationLevels: getSelectedKeys(filters.educationLevelsSelected),
          ...(debouncedSearch &&
            debouncedSearch.trim().length > 0 && { search: debouncedSearch || '' }),
        },
        options: {
          includeCompanyAverage: options.includeCompanyAverage,
          includeBenchmark: options.includeBenchmark,
          includeTeamAverage: options.includeTeamAverage,
        },
        dateRange: getDateForTimeFrame(filters.monthSelected),
        pagination,
      };
      const chartData = await getEngagementCharts(payload);
      setData(chartData?.data?.dimensionAverage || []);
      setTotalCount(chartData?.data?.total || 0);
    } finally {
      setIsLoading(false);
    }
  };

  const baseRef = useOutsideClick<HTMLDivElement>(() => {
    setShowInformationModal(false);
  });

  const numberLine = () => {
    return gaps.map((i) => (
      <Numbers key={`line-${i + 1}`} leftAlign={`${i}%`}>
        {i}
      </Numbers>
    ));
  };

  const getSortMenu = () => {
    return (
      <SortCtr ref={primaryRef}>
        {showSortedMenu &&
          Object.values(sortBy[showSortedMenu]).map((item, i) => (
            <SortRow
              key={`sort-${i + 1}`}
              onClick={(e) => {
                e.stopPropagation();
                setShowSortedMenu(null);
                setSortedBy(item.key);
              }}
            >
              {item.key.includes('asc') ? (
                <ChevronIcon
                  size={12}
                  className="icon"
                  width="12px"
                  height="15px"
                  color={
                    sortedBy?.includes(`${showSortedMenu}__asc`)
                      ? COLORS.COMPANY
                      : COLORS.PLACEHOLDERS
                  }
                />
              ) : (
                <ChevronIcon
                  size={12}
                  className="icon rotate"
                  width="12px"
                  height="15px"
                  color={
                    sortedBy?.includes(`${showSortedMenu}__desc`)
                      ? COLORS.COMPANY
                      : COLORS.PLACEHOLDERS
                  }
                />
              )}
              {item.label}
            </SortRow>
          ))}
      </SortCtr>
    );
  };

  useEffect(() => {
    optionChangeHandler(EOptions.reset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isWaiting) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isWaiting,
    options.includeBenchmark,
    options.includeCompanyAverage,
    options.includeTeamAverage,
    filters.ageGroupSelected,
    filters.educationLevelsSelected,
    filters.gendersSelected,
    filters.jobsGroupsSelected,
    filters.jobsSelected,
    filters.monthSelected,
    filters.surveysOptionSelected,
    filters.teamsOptionSelected,
    filters.themesOptionSelected,
    dimensions.measure,
    dimensions.primary,
    dimensions.secondary,
    pagination.index,
    debouncedSearch,
    sortedBy,
  ]);

  const isLoadingState = isWaiting || isLoading;

  if (!isLoadingState && data.length === 0) {
    return (
      <HeaderIncluded isLoading={isLoading}>
        <MainCtr>
          <LoaderCtr>
            <NoDataTextContainer>
              {i18n._(t`There is no data available yet. Please try again later.`)}
            </NoDataTextContainer>
            <ReFetchBtn optionChangeHandler={() => getData()} />
          </LoaderCtr>
        </MainCtr>
      </HeaderIncluded>
    );
  }

  return (
    <HeaderIncluded isLoading={isLoading}>
      <MainCtr>
        {isLoadingState ? (
          <LoaderCtr>
            <Loader />
          </LoaderCtr>
        ) : (
          <>
            <FilterCtr>
              <FilterBtnCtr>
                <SearchCtr>
                  <SearchFieldWrapper
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setSearch(e.currentTarget.value);
                    }}
                    value={search}
                    placeholder={i18n._(t`Search...`)}
                  />
                </SearchCtr>
              </FilterBtnCtr>
            </FilterCtr>
            <PrimaryDimensionHolder />
            <TableCtr>
              <LeftCtr>
                <PrimaryDimension
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowSortedMenu(ESort.primary);
                  }}
                >
                  {i18n._(t`Primary dimension`)}{' '}
                  <SortIcons>
                    <Icon
                      icon={ICONS.SORT_UP}
                      width={9}
                      height={7}
                      color={
                        sortedBy?.includes('primary__asc') ? COLORS.COMPANY : COLORS.PLACEHOLDERS
                      }
                    />
                    <Icon
                      icon={ICONS.SORT_DOWN}
                      width={9}
                      height={7}
                      color={
                        sortedBy?.includes('primary__desc') ? COLORS.COMPANY : COLORS.PLACEHOLDERS
                      }
                    />
                  </SortIcons>
                  {showSortedMenu === ESort.primary && getSortMenu()}
                </PrimaryDimension>
                {data.map((item, i) => (
                  <RowTitle
                    key={`data-${i + 1}`}
                    isActive={activeRow === i}
                    onMouseEnter={() => setActiveRow(i)}
                    onMouseLeave={() => setActiveRow(null)}
                    isClickable={!!item.id}
                    onClick={() => handleDataRowClick({ ...item, filters: { themeId: item.id } })}
                    mHeight={Array.isArray(item.value) ? `${BAR_MAX_HEIGHT}px` : `${BAR_HEIGHT}px`}
                  >
                    <Tooltip tooltip={i18n._(t`${item.name}`)} size={TOOLTIP_SIZES.BIG}>
                      <div>{i18n._(t`${item.name}`)}</div>
                    </Tooltip>
                  </RowTitle>
                ))}
              </LeftCtr>
              <RightCtr>
                <SecondaryDimension
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowSortedMenu(ESort.secondary);
                  }}
                >
                  {i18n._(t`Average`)}
                  <SortIcons>
                    <Icon
                      icon={ICONS.SORT_UP}
                      width={9}
                      height={7}
                      color={
                        sortedBy?.includes('secondary__asc') ? COLORS.COMPANY : COLORS.PLACEHOLDERS
                      }
                    />
                    <Icon
                      icon={ICONS.SORT_DOWN}
                      width={9}
                      height={7}
                      color={
                        sortedBy?.includes('secondary__desc') ? COLORS.COMPANY : COLORS.PLACEHOLDERS
                      }
                    />
                  </SortIcons>
                  {showSortedMenu === ESort.secondary && getSortMenu()}
                </SecondaryDimension>
                {data.map((item, i) => (
                  <DataRaw
                    key={`data-${i + 1}`}
                    onMouseEnter={() => setActiveRow(i)}
                    onMouseLeave={() => setActiveRow(null)}
                    isClickable={!!item.id}
                    isActive={activeRow === i}
                    mHeight={Array.isArray(item.value) ? `${BAR_MAX_HEIGHT}px` : `${BAR_HEIGHT}px`}
                    onClick={() => handleDataRowClick({ ...item, filters: { themeId: item.id } })}
                  >
                    {getMultiComponent(item, activeRow === i)}
                  </DataRaw>
                ))}
                {numberLine()}
                {barLines()}
                <NumberLineBorder />
              </RightCtr>
            </TableCtr>
            <PaginationCtr>
              <PaginationBar
                pagination={pagination}
                changePagination={onPageChangeClick}
                changePageSize={handleChangeItemsPerPage}
                count={totalCount}
                noShadow
                noBorder
                noTopBorder
                showCount
              />
            </PaginationCtr>
            {shouldShowLuca && <Luca />}
          </>
        )}

        {showInformationModal && informationModalData && (
          <InformationModal
            data={informationModalData}
            onClose={setShowInformationModal}
            baseRef={baseRef}
          />
        )}
      </MainCtr>
    </HeaderIncluded>
  );
}

export default Total;
