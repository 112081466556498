import React from 'react';

import { REVIEW_QUESTION_TYPES } from '@learned/constants';
import styled from 'styled-components';

import { AnswerReviewForm, IQuestionData } from '~/pages/ReviewGiveFeedback/types';

import { TextAreaQuestion } from './Questions/TextAreaQuestion';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';

interface IQuestionView {
  questionData: IQuestionData;
  languageState: ILanguageStateReturn;
  className?: string;
  ratings: AnswerReviewForm['ratings'];
  onChange?: (data: { answer?: string; comment?: string; isNotApplicable?: boolean }) => void;
}

const Container = styled.div`
  margin-top: 66px;
  min-width: 750px;
  height: 350px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-content: center;
`;

const AnswerView = styled.div`
  padding: 23px 33px;
  background: ${COLORS.WHITE};
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 1.18;
  letter-spacing: -0.24px;
  color: ${COLORS.ICONS_PRIMARY};
  margin-bottom: 10px;
`;

const ThemeTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 2.17;
  letter-spacing: -0.13px;
  color: ${COLORS.SUBTEXT};
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const QuestionView = ({
  languageState,
  className,
  questionData,
  ratings,
  onChange,
}: IQuestionView) => {
  const prepareQuestion = (questionData: IQuestionData) => {
    switch (questionData.type) {
      case REVIEW_QUESTION_TYPES.TEXT: {
        const relevantRating = ratings.find(
          (rating) => rating.question === questionData.question.id,
        );
        return (
          <TextAreaQuestion
            key={questionData.question.id}
            onChange={onChange}
            languageState={languageState}
            defaultValues={{
              answer: (relevantRating?.answer as string | null) ?? '',
              isNotApplicable: relevantRating?.isNotApplicable ?? false,
            }}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <Container className={className}>
      <HeaderWrapper>
        {/* TODO: Add theme name here  */}
        <ThemeTitle>{`[Theme name] | Question: ${questionData.questionNumber}`}</ThemeTitle>
        <Title>{questionData.questionTitle}</Title>
      </HeaderWrapper>
      <AnswerView>{prepareQuestion(questionData)}</AnswerView>
    </Container>
  );
};

export { QuestionView };
