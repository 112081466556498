import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Editor from '~/components/Editor';
import { ICONS } from '~/components/Icon';
import RickTextView from '~/components/RickTextView';

import {
  ActionContainer,
  AreaTitle,
  MessageBox,
  MessageContainer,
  MessageInnerContainer,
  MessageTriangle,
} from './design';

import { COLORS } from '~/styles';

type InvitationMessageProps = {
  value?: string;
  onSave?(text: string): void;
};

const InvitationMessage = ({ value = '', onSave }: InvitationMessageProps) => {
  const { i18n } = useLingui();

  const [editMode, setEditMode] = useState(false);
  const [content, setContent] = useState(value);
  return (
    <>
      <AreaTitle>{i18n._(t`Invitation message`)}</AreaTitle>

      {!editMode && (
        <MessageContainer>
          <MessageInnerContainer>
            <MessageTriangle />
            <MessageBox>
              <RickTextView
                html={content}
                color={COLORS.TEXT_MAIN}
                isHtmlWithoutStyles={undefined}
                className={undefined}
              />
            </MessageBox>
          </MessageInnerContainer>
        </MessageContainer>
      )}
      {editMode && (
        <Editor
          value={content}
          onChange={(args: string) => {
            setContent(args);
          }}
          small
          compact
        />
      )}

      {editMode && (
        <ActionContainer>
          <Button
            label={i18n._(t`Cancel`)}
            type="button"
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            onClick={() => setEditMode(false)}
          />

          <Button
            label={i18n._(t`Save`)}
            type="button"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={() => {
              setEditMode(false);
              onSave?.(content);
            }}
          />
        </ActionContainer>
      )}

      {!editMode && (
        <ActionContainer>
          <Button
            label={i18n._(t`Edit invite`)}
            type="button"
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            onClick={() => setEditMode(true)}
            icon={ICONS.EDIT_PENCIL}
          />
        </ActionContainer>
      )}
    </>
  );
};
export { InvitationMessage };
