import styled from 'styled-components';

import { Button } from '~/components/Buttons';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  border-radius: 10px;
  width: 750px;
  margin: auto;
  margin-top: 96px;
  background-color: ${COLORS.WHITE};
  padding: 31.5px 41px 61px 36px;
  box-sizing: border-box;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24.5px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0px;
  border-top: solid 1px ${COLORS.BORDERS};
  margin-top: 37px;
  margin-bottom: 27px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6px;
`;

export const Title = styled.div`
  font-size: 26px;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

export const SmallTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.86;
  color: ${COLORS.TEXT_HOVER};
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledButton = styled(Button)`
  margin-right: 20px;
`;

export const SkillsWrapper = styled.div`
  margin-bottom: 23px;
`;

export const IconsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
`;

export const NameRow = styled.div<{
  maxWidth?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${COLORS.BG_LIST};
  padding: 12px 22px;
  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
  border-radius: 6px;
  height: 100%;
  min-height: 64px;
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
`;

export const Image = styled.div<{ height?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height || '177px'};
  border-radius: 6px;
  overflow: hidden;
  background-color: ${COLORS.BG_ELEMENTS};

  & img {
    width: 100%;
    visibility: hidden;
  }
  & img[alt] {
    text-indent: 50%;
    font-size: 32px;
  }
`;

export const ExpectedResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 4px;
`;

export const ExpectedResult = styled.div`
  min-height: 48px;
  padding: 13px 19px 15px 17px;
  display: flex;
  align-items: center;
  background-color: ${COLORS.BG_LIST};
  border-radius: 6px;
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
  box-sizing: border-box;
`;
