/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, SKILL_TEMPLATE_SORT_OPTIONS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import flatten from 'lodash/flatten';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { ImageWithAlt } from '~/components/ImageWithAlt';
import RickTextView from '~/components/RickTextView';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import { TableList } from '~/components/TableList';

import { JOB_KPIS_COLUMNS } from './columns/JobKPIs.columns';
import { IconInfo } from './components/IconInfo';
import { SkillTemplateTable } from './components/SkillTemplatesTable';
import {
  Card,
  Container,
  Description,
  Divider,
  Footer,
  Form,
  Image,
  JobDetails,
  SectionHeader,
  SectionTitle,
  Subtitle,
  SummarySection,
  TableWrapper,
  Thead,
  Title,
} from './design';
import { useJobFamilies } from './hooks/useJobFamilies';

import type { IBaseLanguageStateReturn } from '~/hooks/useLanguageState';
import { listSkillTemplatesSuperAdmin } from '~/services/skillTemplates';
import { COLORS } from '~/styles';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { convertMarkdownStarsToStrong } from '~/utils/markdown';
import { getSuperAdminMultiLangString } from '~/utils/superAdmin';
import { isNotNil } from '~/utils/typePredicates';

import type { IGeneralForm } from './types';
import type { ISkillCategory, ISkillTemplate } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface StepSummaryProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IGeneralForm>;
  onPublish: (data: IGeneralForm) => void;
  onSave: (data: IGeneralForm) => void;
  onFail: () => void;
  languageState: IBaseLanguageStateReturn;
  skillCategories: ISkillCategory[];
}

function StepSummary({
  sectionState,
  formMethods,
  languageState,
  onPublish,
  onSave,
  onFail,
  skillCategories,
}: StepSummaryProps) {
  const { i18n } = useLingui();
  const { jobFamilies } = useJobFamilies();
  const { handleSubmit, getValues, watch } = formMethods;
  const [skillTemplates, setSkillTemplates] = useState<ISkillTemplate[]>([]);

  const watchSkills = watch('skills');

  useEffect(() => {
    const fetchData = async () => {
      const skillTemplateIds = flatten(
        Object.values(watchSkills).map((skillCategory) =>
          skillCategory.skills.map((skill) => skill.skillTemplate),
        ),
      ).filter(isNotNil);

      const result: { data: { skillTemplates: ISkillTemplate[] } } =
        await listSkillTemplatesSuperAdmin(
          {
            skillTemplateIds,
          },
          { skip: 0, limit: skillTemplateIds.length, sortBy: SKILL_TEMPLATE_SORT_OPTIONS.NAME_A_Z },
        );

      setSkillTemplates(result.data[API_RETURN_FIELDS.SKILL_TEMPLATES]);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watchSkills)]);

  const jobFamily = jobFamilies.filter((jobFamily) => {
    if (jobFamily.id === watch('jobFamily')) {
      return jobFamily;
    }
  })?.[0];

  const name = getSuperAdminMultiLangString(convertLanguageValue(getValues('name')), languageState);

  const description = getSuperAdminMultiLangString(
    convertLanguageValue(getValues('description')),
    languageState,
  );

  return (
    <Container>
      <Form>
        <Title>
          <Trans>Final check</Trans>
        </Title>
        <Subtitle>
          <Trans>
            Congratulations! You have successfully created a new job. Take a moment to review the
            job details below. Once you are satisfied, you can proceed to publish the job and start
            attracting qualified candidates.
          </Trans>
        </Subtitle>
        <Footer>
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.SECONDARY}
            label={<Trans>Save as draft</Trans>}
            onClick={handleSubmit(onSave, onFail)}
          />
          <Button
            type="button"
            size={ButtonSize.BIG}
            variant={ButtonVariant.PRIMARY}
            label={<Trans>Publish job</Trans>}
            onClick={handleSubmit(onPublish, onFail)}
          />
        </Footer>
      </Form>
      <Card>
        <SummarySection>
          <SectionHeader>
            <SectionTitle>
              <Icon icon={ICONS.VALID} size={ICON_SIZES.MEDIUM} fill={COLORS.COMPANY} />
              <Trans>1. Job details</Trans>
            </SectionTitle>
            <Button
              onClick={() => sectionState.setCurrentSection(0)}
              icon={ICONS.EDIT_PENCIL}
              label={<Trans>Edit</Trans>}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              color={COLORS.ICONS_PRIMARY}
            />
          </SectionHeader>
          <JobDetails>
            {getValues('coverImage') && (
              <Image height="327px" altFontSize="32px">
                <ImageWithAlt src={getValues('coverImage')} alt={name.substring(0, 2)} />
              </Image>
            )}
            <Title>{name}</Title>
            <Description>
              <h1>
                <Trans>Description</Trans>
              </h1>
              {/* @ts-ignore */}
              <RickTextView
                color={COLORS.TEXT_MAIN}
                html={convertMarkdownStarsToStrong(description)}
              />
            </Description>
          </JobDetails>
        </SummarySection>
        <Divider />
        <SummarySection>
          <div>
            <Description>
              <h1>
                <Trans>Job matrix</Trans>
              </h1>
            </Description>
            <div className="iconsSection">
              <IconInfo
                title={i18n._(t`Job Group`)}
                subtitle={jobFamily?.name?.en_GB || i18n._(t`No job family selected`)}
                icon={ICONS.JOB_FAMILY}
              />
            </div>
          </div>
        </SummarySection>
        <SummarySection $marginBottom="24px">
          <SectionHeader>
            <SectionTitle>
              <Icon icon={ICONS.VALID} size={ICON_SIZES.MEDIUM} fill={COLORS.COMPANY} />
              <Trans>2. Expected results</Trans>
            </SectionTitle>
            <Button
              onClick={() => sectionState.setCurrentSection(1)}
              icon={ICONS.EDIT_PENCIL}
              label={<Trans>Edit</Trans>}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              color={COLORS.ICONS_PRIMARY}
            />
          </SectionHeader>
          <TableWrapper>
            <Thead>KPI</Thead>
            <TableList className="tableList" data={getValues('kpis')} columns={JOB_KPIS_COLUMNS} />
          </TableWrapper>
        </SummarySection>
        {Object.keys(watchSkills).map((skillCategoryId, index) => (
          <SummarySection key={skillCategoryId}>
            <SectionHeader>
              <SectionTitle>
                <Icon icon={ICONS.VALID} size={ICON_SIZES.MEDIUM} fill={COLORS.COMPANY} />
                <Trans>
                  {index + 3}. {sectionState.sections[index + 2].title}
                </Trans>
              </SectionTitle>
              <Button
                onClick={() => sectionState.setCurrentSection(index + 2)}
                icon={ICONS.EDIT_PENCIL}
                label={<Trans>Edit</Trans>}
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.MEDIUM}
                color={COLORS.ICONS_PRIMARY}
              />
            </SectionHeader>
            <SkillTemplateTable
              skillCategoryId={skillCategoryId}
              formMethods={formMethods}
              skillTemplates={skillTemplates}
              skillCategories={skillCategories}
            />
          </SummarySection>
        ))}
      </Card>
      <Form>
        <Title>
          <Trans>Final check</Trans>
        </Title>
        <Subtitle>
          <Trans>
            Congratulations! You have successfully created a new job. Take a moment to review the
            job details below. Once you are satisfied, you can proceed to publish the job and start
            attracting qualified candidates.
          </Trans>
        </Subtitle>
        <Footer>
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.SECONDARY}
            label={<Trans>Save as draft</Trans>}
            onClick={handleSubmit(onSave, onFail)}
          />
          <Button
            type="button"
            size={ButtonSize.BIG}
            variant={ButtonVariant.PRIMARY}
            label={<Trans>Publish job</Trans>}
            onClick={handleSubmit(onPublish, onFail)}
          />
        </Footer>
      </Form>
    </Container>
  );
}

export { StepSummary };
