import React, { useMemo, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';
import {
  ConnectModalContent,
  Footer,
  Header,
  StyledButton,
  Title,
  TitleWrapper,
} from '~/pages/SuperAdminDashboard/SuperAdminSkillMatrix/components/design';

import { CustomOptionComponent } from './CustomOptionComponent';
import { StyledSearchSelect, ModalDescription, SearchTitle } from './design';

import routes from '~/constants/routes';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import { useSkillTemplates } from '../hooks';
import { useSkillCategories } from '../SkillForm/hooks';

import type { PopulatedSkillTemplate } from './types';

function SkillCreateModal({ onClose }: { onClose: () => void }) {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const { skillTemplates, search, setSearch } = useSkillTemplates();
  const { skillCategories } = useSkillCategories();

  const [selectedSkillTemplate, setSelectedSkillTemplate] = useState<PopulatedSkillTemplate>();

  const populatedSkillTemplates = useMemo(() => {
    return skillTemplates.map((skillTemplate) => ({
      ...skillTemplate,
      skillCategory: skillCategories.find(
        (skillCategory) => skillCategory.skillCategoryTemplate === skillTemplate.skillCategory,
      ),
    }));
  }, [skillTemplates, skillCategories]);

  const goToSkillCreatePage = (templateId?: string) => {
    routes.SKILLS_CREATE.go(
      {},
      { query: { createFromSkillTemplateId: templateId }, isBackPath: true },
    );
  };

  return (
    <Modal
      width={500}
      onClose={onClose}
      showDivider={false}
      isHideHeader
      footerStyles={{ paddingBottom: 32, paddingTop: 0, height: 'unset', minHeight: 'unset' }}
      contentStyles={{ padding: '9px 24px 32px 25.5px', overflowY: 'unset' }}
      footerRight={
        <Footer>
          <Button
            onClick={onClose}
            label={<Trans>Cancel</Trans>}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />

          <Button
            onClick={() => goToSkillCreatePage(selectedSkillTemplate?.id)}
            label={<Trans>Start</Trans>}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
          />
        </Footer>
      }
    >
      <Header>
        <TitleWrapper>
          <Title>
            <Trans>Create a skill</Trans>
          </Title>
          <StyledButton onClick={onClose} variant={ButtonVariant.CLOSE} size={ButtonSize.MEDIUM} />
        </TitleWrapper>
      </Header>
      <ConnectModalContent>
        <ModalDescription>
          <Trans>
            Search for the skill you want to add. Use a template from the database and customise
            this skill template.
          </Trans>
        </ModalDescription>
        <div>
          <SearchTitle>
            <Trans>Search for skill template</Trans>
          </SearchTitle>
          <StyledSearchSelect
            placeholder={i18n._(t`Communication`)}
            noResultsPlaceholder={i18n._(t`No skills were found`)}
            items={populatedSkillTemplates}
            hashItem={(item) => item.id}
            selectedItem={selectedSkillTemplate}
            onChange={setSelectedSkillTemplate}
            onSearchChange={setSearch}
            stringifyItem={(item) => getMultiLangString(item.name)}
            CustomOptionComponent={CustomOptionComponent}
            actions={[
              {
                handler: () => goToSkillCreatePage(),
                name: i18n._(t`Create new skill for '${search}''`),
                icon: ICONS.ADD_PLUS,
              },
            ]}
            isSingleSelect
          />
        </div>
      </ConnectModalContent>
    </Modal>
  );
}

export { SkillCreateModal };
