import React from 'react';

import { t } from '@lingui/macro';
import styled from 'styled-components';

import { ICONS } from '~/components/Icon';

import DefaultAvatar from '~/assets/user-default.svg';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import getUserFullName from '~/utils/getUserFullName';

import type { IUser } from '@learned/types';
import type { I18n } from '@lingui/core';

const BadgeAvatar = styled.div<{
  avatarUrl: string;
}>`
  width: 32px;
  height: 32px;
  outline-color: transparent;
  border-radius: 24px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(props) => `url("${props.avatarUrl}")`};

  &:focus {
    outline-color: transparent;
  }
`;

const accountMenuStructure = ({
  user,
  isUserHasAdminRole,
  isModuleIntegrationsEnabled,
  logout,
  onOpenNotebook,
  numberOfActiveInvites,
}: {
  user: IUser;
  isUserHasAdminRole: boolean;
  isModuleIntegrationsEnabled: boolean;
  logout: () => void;
  onOpenNotebook: () => void;
  numberOfActiveInvites: number;
}) => {
  return [
    {
      title: (i18n: I18n) => i18n._(t`Profile & settings`),
      // @ts-ignore
      iconNode: <BadgeAvatar avatarUrl={user.avatarUrl || DefaultAvatar} />,
      key: 'account',
      userFullName: getUserFullName(user),
      userEmail: user?.email || '',
      _children: [
        {
          title: (i18n: I18n) => i18n._(t`Profile settings`),
          // @ts-ignore
          url: routes.PROFILE_SETTINGS.build({ role: ROLES.USER }),
          role: ROLES.USER,
          icon: ICONS.SETTINGS,
        },
        {
          title: (i18n: I18n) => i18n._(t`Invites`),
          // @ts-ignore
          url: routes.INVITES.build({ role: ROLES.USER }),
          role: ROLES.USER,
          icon: ICONS.INVITES,
          numberOfNotifications: numberOfActiveInvites || 0,
        },
        {
          title: (i18n: I18n) => i18n._(t`Notebook`),
          onClick: onOpenNotebook,
          role: ROLES.USER,
          isDivider: true,
          icon: ICONS.NOTEBOOK,
        },
        ...(isUserHasAdminRole
          ? [
              {
                title: (i18n: I18n) => i18n._(t`Members`),
                // @ts-ignore
                url: routes.MEMBERS.build({ role: ROLES.ADMIN }),
                role: ROLES.ADMIN,
                icon: ICONS.MEMBERS,
              },
              {
                title: (i18n: I18n) => i18n._(t`Teams`),
                // @ts-ignore
                url: routes.TEAMS.build({ role: ROLES.ADMIN }),
                role: ROLES.ADMIN,
                icon: ICONS.TEAM,
              },
              {
                title: (i18n: I18n) => i18n._(t`Company settings`),
                // @ts-ignore
                url: routes.SETTINGS.build({ role: ROLES.ADMIN }),
                role: ROLES.ADMIN,
                icon: ICONS.COMPANY_SETTINGS,
              },
              {
                title: (i18n: I18n) => i18n._(t`Log`),
                // @ts-ignore
                url: routes.LOGS.build({ role: ROLES.ADMIN }),
                role: ROLES.ADMIN,
                icon: ICONS.LOG,
              },
              isModuleIntegrationsEnabled && {
                title: (i18n: I18n) => i18n._(t`Integrations`),
                // @ts-ignore
                url: routes.SETTINGS_INTEGRATIONS.build({ role: ROLES.ADMIN }),
                role: ROLES.ADMIN,
                icon: ICONS.INTEGRATION,
              },
              {
                title: (i18n: I18n) => i18n._(t`Plan`),
                // @ts-ignore
                url: routes.SETTINGS_MODULES.build({ role: ROLES.ADMIN }),
                role: ROLES.ADMIN,
                isDivider: true,
                icon: ICONS.PLAN,
              },
            ].filter((c) => c)
          : []),
        // @ts-ignore
        user.isSuperAdmin && {
          title: (i18n: I18n) => i18n._(t`Super Admin`),
          url: routes.SUPERADMIN_COMPANY_DASHBOARD.url,
          role: ROLES.ADMIN,
          icon: ICONS.SUPERADMIN,
        },
        {
          title: (i18n: I18n) => i18n._(t`Help center`),
          href: 'https://intercom.help/learned',
          role: ROLES.USER,
          icon: ICONS.HELP,
        },
        {
          title: (i18n: I18n) => i18n._(t`Product roadmap`),
          // @ts-ignore
          url: routes.ROADMAP.build({ role: ROLES.USER }),
          role: ROLES.USER,
          icon: ICONS.ROADMAP,
        },
        {
          title: (i18n: I18n) => i18n._(t`Logout`),
          onClick: () => logout(),
          role: ROLES.USER,
          icon: ICONS.LOGOUT,
        },
      ].filter((c) => c),
      get children() {
        return this._children;
      },
      set children(value) {
        this._children = value;
      },
    },
  ].filter((c) => c);
};

export default accountMenuStructure;
