import React, { useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import nanoid from 'nanoid';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import { TableList } from '~/components/TableList';

import { JOB_KPIS_COLUMNS } from './columns/JobKPIs.columns';
import { EditKPIsModal } from './components/EditKPIsModal';
import { StepFooter } from './components/StepFooter';
import { Form, Title, Header, Description, TableWrapper, Thead } from './design';

import { generateTextTranslation } from '~/services/completions';
import { generateJobKPIs } from '~/services/jobTemplates';
import { isNotNil } from '~/utils/typePredicates';

import type { IGeneralForm } from './types';
import type { IMultiLangString } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';
import type { IBaseLanguageStateReturn } from 'src/hooks/useLanguageState';

type StepExpectedResultsProps = {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IGeneralForm>;
  languageState: IBaseLanguageStateReturn;
};

function StepExpectedResults({
  sectionState,
  formMethods,
  languageState,
}: StepExpectedResultsProps) {
  const { handleSubmit, trigger, getValues, setValue, watch } = formMethods;

  const { i18n } = useLingui();
  const [editKPI, setEditKPI] = useState('');
  const editKPIsForm = getValues('kpis')?.find((kpi) => kpi.id === editKPI);
  const jobName = getValues('name')?.find(
    (name) => name.locale === languageState.primaryLanguage.locale,
  );
  const [isEditKPIsModalVisible, setIsEditKPIsModalVisible] = useState(false);
  const [isGenerationLoading, setIsGenerationLoading] = useState(false);
  const [isTranslationLoading, setIsTranslationLoading] = useState(false);
  const jobNameEnglish = watch('name').find((value) => value.locale === 'en_GB');
  const jobKPIsEnglish = watch('kpis')
    .map((value) => value.en_GB)
    .filter(isNotNil);

  const updateKPIs = (newValues: IMultiLangString & { id?: string }) => {
    const kpiArray = getValues('kpis') || [];
    if (newValues.id) {
      const kpiIndex = kpiArray.findIndex((kpi) => kpi.id === newValues.id);
      // @ts-ignore
      kpiArray[kpiIndex] = newValues;
    } else {
      kpiArray.push({
        ...newValues,
        id: nanoid(),
      });
    }
    setValue('kpis', kpiArray);
    setEditKPI('');
  };

  const deleteKPIs = (id: string) => {
    const kpiArray = getValues('kpis') || [];
    const kpiIndex = kpiArray.findIndex((kpi) => kpi.id === id);
    kpiArray.splice(kpiIndex, 1);
    setValue('kpis', kpiArray);
    setEditKPI('');
    trigger('kpis');
  };

  const onSubmit = () => sectionState.setCurrentSection(1);

  const createMenuItems = (item: { id: string }) => {
    return [
      {
        label: i18n._(t`Edit`),
        action: () => {
          setIsEditKPIsModalVisible(true);
          setEditKPI(item.id);
        },
        icon: ICONS.EDIT_PENCIL,
      },
    ].filter((i) => i);
  };
  const generateKPIs = async () => {
    try {
      if (!jobNameEnglish?.value) {
        return;
      }

      setIsGenerationLoading(true);
      const response = await generateJobKPIs(jobNameEnglish.value);
      if (response.code !== 200) {
        return;
      }

      const { kpis } = response.data;
      setValue(
        'kpis',
        kpis?.map((kpi: string) => ({
          id: nanoid(),
          en_GB: kpi,
        })),
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsGenerationLoading(false);
    }
  };

  const translateKPIs = async () => {
    const locales = languageState.languages
      .map(({ locale }) => locale)
      .filter((locale) => locale !== 'en_GB');

    try {
      if (isEmpty(jobKPIsEnglish)) {
        return;
      }

      setIsTranslationLoading(true);
      const response = await generateTextTranslation(jobKPIsEnglish, locales);
      if (response.code !== 200) {
        return;
      }

      const translatedTexts = response.data[API_RETURN_FIELDS.TRANSLATED_TEXTS];

      setValue(
        'kpis',
        translatedTexts.map((texts: Record<string, string>, index: number) => {
          return {
            id: nanoid(),
            ...texts,
            en_GB: jobKPIsEnglish[index],
          };
        }),
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsTranslationLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmit)}>
      <Header>
        <Title>
          <Trans>Expected results</Trans>
        </Title>
        <div className="buttons">
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.SECONDARY}
            label={i18n._(t`Generate results`)}
            disabled={!isEmpty(watch('kpis'))}
            onClick={generateKPIs}
            isLoading={isGenerationLoading}
          />
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.SECONDARY}
            label={i18n._(t`Translate results`)}
            disabled={isEmpty(watch('kpis').filter((value) => value.en_GB !== ''))}
            onClick={translateKPIs}
            isLoading={isTranslationLoading}
          />
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.PRIMARY}
            icon={ICONS.ADD_PLUS}
            iconSize={ICON_SIZES.SMALL}
            label={i18n._(t`Add result`)}
            onClick={() => {
              setIsEditKPIsModalVisible(true);
            }}
          />
        </div>
      </Header>
      <Description>
        <Trans>
          Here, you specify which KPIs are associated with this job. These KPIs are provided to
          users as suggestions when they create a new goal for this job.
        </Trans>
      </Description>
      {isEditKPIsModalVisible && (
        <EditKPIsModal
          jobName={jobName?.value}
          kpi={editKPIsForm}
          languageState={languageState}
          onSave={(newValues: IMultiLangString) => updateKPIs({ ...newValues, id: editKPI })}
          onDelete={(id: string) => deleteKPIs(id)}
          onClose={() => {
            setIsEditKPIsModalVisible(false);
            setEditKPI('');
          }}
        />
      )}
      <TableWrapper marginTop="24px">
        <Thead>KPI</Thead>
        <TableList
          className="tableList"
          data={getValues('kpis')}
          columns={JOB_KPIS_COLUMNS}
          menuProps={{
            createMenuItems,
            isMenuVisible: true,
          }}
        />
      </TableWrapper>
      <StepFooter
        onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
        onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
      />
    </Form>
  );
}

export { StepExpectedResults };
