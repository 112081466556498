import styled from 'styled-components';

import { Dropdown } from '~/components/Dropdown';
import { TableList } from '~/components/TableList';

import { StatusDropDownItem } from './types';

import { COLORS } from '~/styles';

const StatusDropDown = styled(Dropdown<StatusDropDownItem>)`
  position: absolute;
  width: 150px;
  height: 32px;
  right: 30px;
`;

const TableWrapper = styled.div`
  tr {
    height: 90px;
    background-color: ${COLORS.BG_LIST};
    margin-bottom: 5px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  color: ${COLORS.BLACK};
  margin-left: 32px;
  margin-top: 21px;
`;

const StyledTableList = styled(TableList)`
  padding: 0px 15px 15px 15px;
`;

export { StatusDropDown, TableWrapper, Title, StyledTableList };
