import { REVIEW_QUESTION_TYPES } from '@learned/constants';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

import { type ISection, SECTION_STATUS, SECTION_TYPE } from '~/components/TableOfContents/types';

import type {
  IPartialReviewRating,
  IPopulatedReviewTask,
  IPopulatedUserReview,
  IQuestionCustomSkillData,
  IQuestionData,
  IQuestionSkillCategoryData,
  IWithRatings,
  TransformedQuestion,
} from './types';
import type {
  IMultiLangString,
  IUserReviewQuestion,
  IUserReviewQuestionCustomSkill,
  IUserReviewQuestionSkillCategory,
} from '@learned/types';

function checkMissingAnswerAndComment(
  question: IUserReviewQuestion,
  rating?: IPartialReviewRating,
) {
  let missingAnswer = false;
  let missingComment = false;

  switch (question?.type) {
    case REVIEW_QUESTION_TYPES.TEXT:
      missingAnswer = isEmpty(rating?.answer);
      break;
    case REVIEW_QUESTION_TYPES.SKILL_CATEGORY:
    case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
    case REVIEW_QUESTION_TYPES.RATING:
      if (!isNumber(rating?.answer)) {
        missingAnswer = true;
      }

      if (question?.settings?.isCommentsObligated === true && isEmpty(rating?.comment)) {
        missingComment = true;
      }
      break;
  }
  return missingComment || missingAnswer;
}

export const createInitialSections = (
  questions: TransformedQuestion[],
  getMultiLangString: (multiLangString: Record<string, string>) => string,
  userTo: {
    id?: string | undefined;
    email?: string | undefined;
  },
): ISection<IQuestionData>[] => {
  const sections: ISection<IQuestionData>[] = [];
  let sectionNumber = 1;
  let totalQuestionIndex = 0;
  let hasSeenMissingAnswerOrComment = false;
  let hasSeenMissingAnswerOrCommentParent = false;
  let questionNumber = 0;
  questions.forEach((question) => {
    switch (question?.type) {
      case REVIEW_QUESTION_TYPES.SKILL_CATEGORY: {
        let hasMissingInfoChildren = false;
        const parentIndex = totalQuestionIndex;
        const skillQuestions = question.skills.map((skillQuestion, i) => {
          const subQuestions = skillQuestion.questions.map((subQuestion) => {
            const relevantRating = find(
              subQuestion.ratings,
              (rating) =>
                rating?.createdBy.id === userTo.id || rating?.createdBy.email === userTo.email,
            );

            return {
              question: subQuestion,
              yourRating: relevantRating ?? {
                // we have to use undefined to start with because null means user refused to answer
                answer: undefined,
                comment: null,
                createdBy: userTo,
                company: subQuestion.company,
                createdFor: subQuestion.createdFor,
                userReview: subQuestion.userReview,
                userReviewQuestion: subQuestion.id,
              },
              otherPeerRatings: undefined,
              otherCoachRatings: undefined,
            };
          });
          const hasMissingInfo = subQuestions
            .map((subQuestion) =>
              checkMissingAnswerAndComment(subQuestion.question, subQuestion.yourRating),
            )
            .some((item) => item);
          if (hasMissingInfo) {
            hasMissingInfoChildren = true;
          }
          let newStatus: SECTION_STATUS | undefined;
          if (hasMissingInfo && !hasSeenMissingAnswerOrComment) {
            newStatus = SECTION_STATUS.CURRENT;
            hasSeenMissingAnswerOrComment = true;
          } else if (!hasMissingInfo) {
            newStatus = SECTION_STATUS.DONE;
          }

          totalQuestionIndex++;
          return {
            type: SECTION_TYPE.CHILD,
            title: getMultiLangString(skillQuestion.skillName),
            sectionNumber: `${sectionNumber}.${i + 1}`,
            status: newStatus,
            parentSection: parentIndex,
            data: {
              type: REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
              subQuestions,
              questionNumber: questionNumber++,
              questionTitle: getMultiLangString(skillQuestion.skillName),
            } as IQuestionSkillCategoryData,
          };
        });

        let newStatus: SECTION_STATUS | undefined;
        if (hasMissingInfoChildren && !hasSeenMissingAnswerOrCommentParent) {
          newStatus = SECTION_STATUS.CURRENT;
          hasSeenMissingAnswerOrCommentParent = true;
        } else if (!hasMissingInfoChildren) {
          newStatus = SECTION_STATUS.DONE;
        }
        totalQuestionIndex++;
        sections.push({
          type: SECTION_TYPE.PARENT,
          title: getMultiLangString(question.name),
          sectionNumber: `${sectionNumber}`,
          status: newStatus,
          parentSection: parentIndex,
        });
        sections.push(...skillQuestions);
        break;
      }
      case REVIEW_QUESTION_TYPES.CUSTOM_SKILL: {
        const subQuestions = question.questions.map((subQuestion) => {
          const relevantRating = find(
            subQuestion.ratings,
            (rating) =>
              rating?.createdBy.id === userTo.id || rating?.createdBy.email === userTo.email,
          );

          totalQuestionIndex++;
          return {
            question: subQuestion,
            yourRating: relevantRating,
            otherPeerRatings: undefined,
            otherCoachRatings: undefined,
          };
        });
        const hasMissingInfo = subQuestions
          .map((subQuestion) =>
            checkMissingAnswerAndComment(subQuestion.question, subQuestion.yourRating),
          )
          .some((item) => item);

        let newStatus: SECTION_STATUS | undefined;
        if (hasMissingInfo && !hasSeenMissingAnswerOrComment) {
          newStatus = SECTION_STATUS.CURRENT;
          hasSeenMissingAnswerOrComment = true;
          hasSeenMissingAnswerOrCommentParent = true;
        } else if (!hasMissingInfo) {
          newStatus = SECTION_STATUS.DONE;
        }

        totalQuestionIndex++;
        sections.push({
          type: SECTION_TYPE.BASIC,
          title: getMultiLangString(question.name),
          sectionNumber: `${sectionNumber}`,
          status: newStatus,
          data: {
            type: REVIEW_QUESTION_TYPES.CUSTOM_SKILL,
            subQuestions,
            questionNumber: ++questionNumber,
            questionTitle: getMultiLangString(question.name),
          } as IQuestionCustomSkillData,
        });
        break;
      }
      case REVIEW_QUESTION_TYPES.GOAL_PLAN: {
        totalQuestionIndex++;
        sections.push({
          type: SECTION_TYPE.BASIC,
          title: getMultiLangString(question.name),
          sectionNumber: `${sectionNumber}`,
          status: SECTION_STATUS.DONE,
          data: {
            type: REVIEW_QUESTION_TYPES.GOAL_PLAN,
            question,
            questionNumber: ++questionNumber,
            questionTitle: getMultiLangString(question.name),
          },
        });
        break;
      }
      case REVIEW_QUESTION_TYPES.TEXT:
      case REVIEW_QUESTION_TYPES.RATING: {
        const relevantRating = find(
          question.ratings,
          (rating) =>
            rating?.createdBy.id === userTo.id || rating?.createdBy.email === userTo.email,
        );
        const hasMissingInfo = checkMissingAnswerAndComment(question, relevantRating);

        let newStatus: SECTION_STATUS | undefined;
        if (hasMissingInfo && !hasSeenMissingAnswerOrComment) {
          newStatus = SECTION_STATUS.CURRENT;
          hasSeenMissingAnswerOrComment = true;
          hasSeenMissingAnswerOrCommentParent = true;
        } else if (!hasMissingInfo) {
          newStatus = SECTION_STATUS.DONE;
        }
        totalQuestionIndex++;
        sections.push({
          type: SECTION_TYPE.BASIC,
          title: getMultiLangString(question.name),
          sectionNumber: `${sectionNumber}`,
          status: newStatus,
          data: {
            type: question.type,
            question,
            yourRating: relevantRating ?? {
              // we have to use undefined to start with because null means user refused to answer
              answer: undefined,
              comment: null,
              createdBy: userTo,
              company: question.company,
              createdFor: question.createdFor,
              userReview: question.userReview,
              userReviewQuestion: question.id,
            },
            questionNumber: ++questionNumber,
            questionTitle: getMultiLangString(question.name),
          },
        });
        break;
      }
    }

    sectionNumber++;
  });

  return sections;
};

export function findFirstUnansweredQuestion(
  questions: TransformedQuestion[],
  reviewTask: IPopulatedReviewTask,
) {
  let totalQuestions = 0;

  for (const question of questions) {
    if (question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
      totalQuestions++;
      for (const skillQuestion of question.skills) {
        const hasMissingInfo = skillQuestion.questions
          .map((subQuestion) => {
            const relevantRating = find(
              subQuestion.ratings,
              (rating) =>
                rating?.createdBy.id === reviewTask.userTo.id ||
                rating?.createdBy.email === reviewTask.userTo.email,
            );

            return checkMissingAnswerAndComment(subQuestion, relevantRating);
          })
          .some(Boolean);

        if (hasMissingInfo) {
          return totalQuestions;
        }
        totalQuestions++;
      }
    } else if (question.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
      totalQuestions++;
      for (const subQuestion of question.questions) {
        const relevantRating = find(
          subQuestion.ratings,
          (rating) =>
            rating.createdBy.id === reviewTask.userTo.id ||
            rating.createdBy.email === reviewTask.userTo.email,
        );
        if (checkMissingAnswerAndComment(subQuestion, relevantRating)) {
          return totalQuestions;
        }
        totalQuestions++;
      }
    } else {
      const relevantRating = find(
        question.ratings,
        (rating) =>
          rating.createdBy.id === reviewTask.userTo.id ||
          rating.createdBy.email === reviewTask.userTo.email,
      );
      if (checkMissingAnswerAndComment(question, relevantRating)) {
        return totalQuestions;
      }
      totalQuestions++;
    }
  }

  return -1;
}

export function groupQuestionsBasedOnType(userReview: IPopulatedUserReview) {
  const populatedQuestionsMap = userReview.questions.reduce(
    (acc: Record<string, IPopulatedUserReview['questions'][0]>, question) => {
      acc[question.id] = question;
      return acc;
    },
    {},
  );

  const groupedSkillCategoryQuestions = userReview.userReviewQuestions.reduce(
    (
      acc: Record<
        string,
        Record<
          string,
          {
            skillId: string;
            skillName: IMultiLangString;
            questions: IWithRatings<IUserReviewQuestionSkillCategory>[];
          }
        >
      >,
      questionId,
    ) => {
      const question = populatedQuestionsMap[questionId];
      if (question.type !== REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
        return acc;
      }
      const skillCategory = question.settings.skillCategory;
      const skill = question.settings.skill;
      acc[skillCategory] = acc[skillCategory] ?? {};
      acc[skillCategory][skill] = acc[skillCategory][skill] ?? {
        skillId: skill,
        skillName: question.settings.skillName,
        questions: [],
      };
      acc[skillCategory][skill].questions.push(question);
      return acc;
    },
    {},
  );

  const groupedCustomSkillQuestions = userReview.userReviewQuestions.reduce(
    (acc: Record<string, IWithRatings<IUserReviewQuestionCustomSkill>[]>, questionId) => {
      const question = populatedQuestionsMap[questionId];
      if (question.type !== REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
        return acc;
      }
      const skill = question.settings.skill;
      acc[skill] = acc[skill] ?? [];
      acc[skill].push(question);
      return acc;
    },
    {},
  );

  const seenSkillCategories = new Set();
  const seenSkills = new Set();
  return userReview.userReviewQuestions.reduce((acc: TransformedQuestion[], questionId) => {
    const question = populatedQuestionsMap[questionId];
    if (question && question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
      if (seenSkillCategories.has(question.settings.skillCategory)) {
        return acc;
      } else {
        seenSkillCategories.add(question.settings.skillCategory);
        acc.push({
          type: REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
          skillCategory: question.settings.skillCategory,
          skills: Object.values(groupedSkillCategoryQuestions[question.settings.skillCategory]),
          name: question.name,
          description: question.description,
          theme: question.theme,
        });
        return acc;
      }
    } else if (question && question.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
      if (seenSkills.has(question.settings.skill)) {
        return acc;
      } else {
        seenSkills.add(question.settings.skill);
        acc.push({
          type: REVIEW_QUESTION_TYPES.CUSTOM_SKILL,
          skillId: question.settings.skill,
          skillName: question.settings.skillName,
          questions: groupedCustomSkillQuestions[question.settings.skill],
          name: question.name,
          description: question.description,
          theme: question.theme,
        });
        return acc;
      }
    }

    if (question) {
      acc.push(question);
    }
    return acc;
  }, []);
}

export function* findAllRatings(sections: ISection<IQuestionData>[]) {
  for (const section of sections) {
    if (section.type === SECTION_TYPE.PARENT) {
      continue;
    }
    if (
      section.data?.type === REVIEW_QUESTION_TYPES.TEXT ||
      section.data?.type === REVIEW_QUESTION_TYPES.RATING
    ) {
      yield section.data.yourRating;
    }
    if (
      section.data?.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL ||
      section.data?.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY
    ) {
      for (const subQuestion of section.data.subQuestions) {
        yield subQuestion.yourRating;
      }
    }
  }
}
