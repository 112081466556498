import React from 'react';
import type { Dispatch } from 'react';

import { Trans } from '@lingui/macro';
import { DragDropContext } from 'react-beautiful-dnd';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import RickTextView from '~/components/RickTextView';
import { FocusAreas } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/components/FocusAreas';
import { SkillCategory } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/components/SkillCategory';

import {
  Card,
  Container,
  Description,
  Footer,
  Form,
  Levels,
  SectionHeader,
  SectionTitle,
  SkillDetails,
  SkillTitleAndCategory,
  Subtitle,
  SummarySection,
  Title,
} from './design';

import type { IBaseLanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { getSuperAdminMultiLangString } from '~/utils/superAdmin';

import type { IGeneralForm } from './types';
import type { ISkillCategory } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

type StepSummaryProps = {
  setCurrentSection: Dispatch<number>;
  formMethods: UseFormReturn<IGeneralForm>;
  onPublish: (data: IGeneralForm) => void;
  onSave: (data: IGeneralForm) => void;
  onFail: () => void;
  skillCategory?: ISkillCategory;
  languageState: IBaseLanguageStateReturn;
};

function StepSummary({
  formMethods,
  setCurrentSection,
  skillCategory,
  languageState,
  onPublish,
  onSave,
  onFail,
}: StepSummaryProps) {
  const { handleSubmit, getValues, formState } = formMethods;
  const { isSubmitting } = formState;

  const goToStepDetails = () => setCurrentSection(0);
  const goToStepFocusAreas = () => setCurrentSection(1);

  return (
    <Container>
      <Form>
        <Title>
          <Trans>Final check</Trans>
        </Title>
        <Subtitle>
          <Trans>
            Congratulations! You have successfully created a new skill in your company. Take a
            moment to review the details below. Once you are satisfied, you can proceed to publish
            the skill.
          </Trans>
        </Subtitle>
        <Footer>
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.SECONDARY}
            label={<Trans>Save as draft</Trans>}
            onClick={handleSubmit(onSave, onFail)}
            disabled={isSubmitting}
          />
          <Button
            type="button"
            size={ButtonSize.BIG}
            variant={ButtonVariant.PRIMARY}
            label={<Trans>Publish skill</Trans>}
            onClick={handleSubmit(onPublish, onFail)}
            disabled={isSubmitting}
          />
        </Footer>
      </Form>
      <Card>
        <SummarySection>
          <SectionHeader>
            <SectionTitle>
              <Icon icon={ICONS.VALID} size={ICON_SIZES.MEDIUM} fill={COLORS.COMPANY} />
              <Trans>1. Skill details</Trans>
            </SectionTitle>
            <Button
              onClick={goToStepDetails}
              icon={ICONS.EDIT_PENCIL}
              label={<Trans>Edit</Trans>}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              color={COLORS.ICONS_PRIMARY}
            />
          </SectionHeader>
          <SkillDetails>
            <SkillTitleAndCategory>
              <Title>
                {getSuperAdminMultiLangString(
                  convertLanguageValue(getValues('name')),
                  languageState,
                )}
              </Title>

              <SkillCategory>
                {skillCategory ? (
                  getSuperAdminMultiLangString(skillCategory.name, languageState)
                ) : (
                  <Trans>No category selected</Trans>
                )}
              </SkillCategory>
            </SkillTitleAndCategory>
            <Description>
              <h1>
                <Trans>Description</Trans>
              </h1>
              {/* @ts-ignore */}
              <RickTextView
                color={COLORS.TEXT_MAIN}
                html={getSuperAdminMultiLangString(
                  convertLanguageValue(getValues('description')),
                  languageState,
                )}
              />
            </Description>
          </SkillDetails>
        </SummarySection>
        <SummarySection>
          <SectionHeader>
            <SectionTitle>
              <Icon icon={ICONS.VALID} size={ICON_SIZES.MEDIUM} fill={COLORS.COMPANY} />
              <Trans>2. Levels & Focus area&apos;s</Trans>
            </SectionTitle>
            <Button
              onClick={goToStepFocusAreas}
              icon={ICONS.EDIT_PENCIL}
              label={<Trans>Edit</Trans>}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              color={COLORS.ICONS_PRIMARY}
            />
          </SectionHeader>
          <DragDropContext onDragEnd={() => {}}>
            <Levels>
              {skillCategory?.skillLevels.map((levelName, index) => (
                <FocusAreas
                  formMethods={formMethods}
                  levelName={levelName}
                  key={index}
                  index={index}
                  languageState={languageState}
                  readonly
                />
              ))}
            </Levels>
          </DragDropContext>
        </SummarySection>
      </Card>
    </Container>
  );
}

export { StepSummary };
