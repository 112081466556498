import React, { useState, useContext, useEffect } from 'react';

import { ROLES, REPORT_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';

import { Loader } from '~/components/Buttons/components/Loader';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import PaginationBar from '~/components/PaginationBar';

import { LucaSummaryContent } from './LucaSummaryContent';

import useBoolState from '~/hooks/useBoolState';
import { usePagination } from '~/hooks/usePagination';
import * as reportsService from '~/services/reports';
import { TLucaModalData } from '~/services/reports';
import * as reportsUtil from '~/utils/reports';

import { EngagementReportContext } from '../EngagementContext';
import DimensionsStrip from '../Luca/DimensionsStrip';
import {
  CloseIcon,
  DescriptionWrapper,
  Footer,
  Header,
  HeaderInnerWrapper,
  LucaDescriptionContainer,
  LucaModalContainer,
  ModalTitleRow,
  ModalContent,
  PaginationContainer,
  Title,
  ModalTitleRowActions,
} from '../Luca/Luca.design';
import { LucaDescription } from '../Luca/LucaDescription';

// mini-luca Modal
const InformationModal = ({
  baseRef,
  data,
  onClose,
}: {
  baseRef: React.RefObject<HTMLDivElement>;
  data: TLucaModalData;
  onClose(v: boolean): void;
}) => {
  const { i18n } = useLingui();
  const { filters, viewAs, dimensions } = useContext(EngagementReportContext);

  const { primary, secondary, measure } = dimensions;

  const timeFrame = reportsUtil.getDateForTimeFrame(filters.monthSelected, 'DD-MM-YYYY');
  const [ratings, setRatings] =
    useState<{ date: string; comment: string; rating: number; pName: string }[]>();
  const $isLucaOpen = useBoolState(false);
  const $isLoading = useBoolState(false);
  const themeID = data.filters.themeId;
  const [total, setTotal] = useState(0);
  const { pagination, changePagination } = usePagination(10);

  const shouldShowAISection = !!data.value && [ROLES.ADMIN, ROLES.COACH].includes(viewAs);

  const fetchRatings = React.useCallback(
    async (newPagination: { skip: number; limit: number; index: number } | null) => {
      try {
        $isLoading.on();
        const res = await reportsService.getRatings('engagement', {
          pagination: {
            limit: newPagination ? newPagination.limit : pagination.limit,
            skip: newPagination ? newPagination.skip : pagination.skip,
          },
          viewAs,
          reportType: REPORT_TYPES.ENGAGEMENT,
          dateRange: {
            start: moment(timeFrame.start, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            end: moment(timeFrame.end, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          },
          filters: {
            themes: themeID ? [themeID] : [],
            teams: data.filters.teamId ? [data.filters.teamId] : [],
          },
        });
        setTotal(res.data.total);
        setRatings(res.data.rows);
        $isLoading.off();
      } catch (error) {
        setRatings([]);
        $isLoading.off();
      }
    },
    [
      $isLoading,
      pagination.limit,
      pagination.skip,
      viewAs,
      timeFrame.start,
      timeFrame.end,
      themeID,
      data.filters.teamId,
    ],
  );

  useEffect(() => {
    if (!ratings && shouldShowAISection && $isLoading.value === false) {
      // if there is a fetch error, stop refetching,
      fetchRatings(null);
    }
  }, [$isLoading.value, fetchRatings, ratings, shouldShowAISection]);

  return (
    <LucaModalContainer>
      <ModalContent ref={baseRef}>
        <Header>
          <ModalTitleRow>
            <HeaderInnerWrapper>
              <Title>{i18n._(t`Report details`)}</Title>
            </HeaderInnerWrapper>

            <ModalTitleRowActions>
              <CloseIcon
                onClick={() => {
                  setRatings(undefined);
                  return onClose(false);
                }}
              >
                <Icon icon={ICONS.CLOSE} size={ICON_SIZES.MEDIUM} />
              </CloseIcon>
            </ModalTitleRowActions>
          </ModalTitleRow>
          <DimensionsStrip
            firstDimension={primary}
            secondDimension={secondary}
            measure={measure}
            timeFrame={timeFrame}
          />
        </Header>
        <LucaDescriptionContainer>
          <DescriptionWrapper>
            <LucaDescription
              ratings={ratings}
              value={data.value}
              team={data.team}
              benchmark={data.benchmark}
              deviation={data.deviation}
              timeFrame={timeFrame.start}
              themeID={themeID}
              isLoading={$isLoading.value}
            />
            {!ratings && shouldShowAISection && <Loader />}
            <Footer>
              {ratings && ratings.length > 0 && (
                <PaginationContainer>
                  <PaginationBar
                    pagination={pagination}
                    changePagination={(data) => {
                      fetchRatings(data);
                      changePagination(data);
                    }}
                    count={total}
                    showCount
                    noBorder
                    noShadow
                  />
                </PaginationContainer>
              )}
            </Footer>
          </DescriptionWrapper>
          {/* TODO: we need to decide what to show if the luca modal is not about the theme */}
          {shouldShowAISection && themeID && (
            <LucaSummaryContent
              isLucaOpen={$isLucaOpen.value}
              onLucaOpen={(value) => $isLucaOpen.set(value)}
              themeID={themeID}
            />
          )}
        </LucaDescriptionContainer>
      </ModalContent>
    </LucaModalContainer>
  );
};

export { InformationModal };
