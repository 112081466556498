import { useMemo, useReducer } from 'react';

import { ISection, SECTION_TYPE, SECTION_STATUS } from './types';

enum SectionStateActionTypes {
  SET_CURRENT_SECTION = 'SET_CURRENT_SECTION',
  SET_SECTION_ERROR = 'SET_SECTION_ERROR',
  SET_TRIED_TO_SUBMIT = 'SET_TRIED_TO_SUBMIT',
  SET_STATUS_SECTION = 'SET_STATUS_SECTION',
  SET_SECTIONS = 'SET_SECTIONS',
}

interface SetCurrentSection {
  type: SectionStateActionTypes.SET_CURRENT_SECTION;
  payload: number;
}

interface SetErrorSection {
  type: SectionStateActionTypes.SET_SECTION_ERROR;
  payload: {
    index: number;
    hasError: boolean;
  };
}

interface SetTriedToSubmit {
  type: SectionStateActionTypes.SET_TRIED_TO_SUBMIT;
  payload: boolean;
}

interface SetTypeSection {
  type: SectionStateActionTypes.SET_STATUS_SECTION;
  payload: {
    index: number;
    status?: SECTION_STATUS;
  };
}
interface SetSections<T> {
  type: SectionStateActionTypes.SET_SECTIONS;
  payload: {
    sections: ISection<T>[];
  };
}

function reducer<T>(
  state: { sections: ISection<T>[]; currentSection: number; triedToSubmit: boolean },
  action: SetCurrentSection | SetErrorSection | SetTriedToSubmit | SetTypeSection | SetSections<T>,
) {
  switch (action.type) {
    case SectionStateActionTypes.SET_CURRENT_SECTION: {
      const sections = [...state.sections];
      const previousSection = sections[state.currentSection];
      const newSection = sections[action.payload];

      // set new section to touched
      sections[action.payload] = { ...newSection, isTouched: true };

      // Update previous section to done status if no errors
      if (
        previousSection.status !== SECTION_STATUS.ERROR &&
        previousSection.type !== SECTION_TYPE.PARENT
      ) {
        sections[state.currentSection] = {
          ...previousSection,
          status: SECTION_STATUS.DONE,
        };
      }

      // if we moved away from a parent section, we need to update the parent section status
      if (
        previousSection.parentSection &&
        previousSection.parentSection !== newSection.parentSection
      ) {
        const oldStatus = sections[previousSection.parentSection].status;
        let newStatus;
        if (oldStatus === SECTION_STATUS.ERROR) {
          newStatus = SECTION_STATUS.ERROR;
        }

        if (oldStatus !== SECTION_STATUS.ERROR) {
          newStatus = SECTION_STATUS.DONE;
        }

        sections[previousSection.parentSection] = {
          ...sections[previousSection.parentSection],
          status: newStatus,
        };
      }

      // if we go to a child section, make parent active
      if (newSection.parentSection && sections[newSection.parentSection]) {
        sections[newSection.parentSection] = {
          ...sections[newSection.parentSection],
          status: SECTION_STATUS.CURRENT,
        };
      }

      // update previousSection and sections
      return { ...state, sections, currentSection: action.payload };
    }
    case SectionStateActionTypes.SET_SECTION_ERROR: {
      const sections = state.sections;
      let newStatus = sections[action.payload.index].status;
      if (action.payload.hasError) {
        newStatus = SECTION_STATUS.ERROR;
      } else if (sections[action.payload.index].isTouched) {
        newStatus = SECTION_STATUS.DONE;
      }

      sections[action.payload.index] = {
        ...sections[action.payload.index],
        status: newStatus,
      };

      return { ...state };
    }
    case SectionStateActionTypes.SET_TRIED_TO_SUBMIT: {
      return { ...state, triedToSubmit: action.payload };
    }
    case SectionStateActionTypes.SET_STATUS_SECTION: {
      const sections = [...state.sections];

      // set new section to touched
      sections[action.payload.index] = {
        ...sections[action.payload.index],
        status: action.payload.status,
      };

      // update currentSection and sections
      return { ...state, sections };
    }
    case SectionStateActionTypes.SET_SECTIONS: {
      const sections = action.payload.sections;

      // update sections
      return { ...state, sections };
    }
  }
}

interface ISectionState<T> {
  triedToSubmit: boolean;
  currentSection: number;
  sections: ISection<T>[];
  setTriedToSubmit: () => void;
  setErrorSection: (index: number, hasError: boolean) => void;
  setCurrentSection: (index: number) => void;
  goToNextSection: () => boolean;
  goToPreviousSection: () => boolean;
  goToFirstErrorSection: () => void;
  setStatusSection: (index: number, status?: SECTION_STATUS) => void;
  setSections: (section: ISection<T>[]) => void;
}

function useSectionState<T>(
  initialSections: ISection<T>[],
  options: { currentSection?: number } = {},
): ISectionState<T> {
  const mappedInitialSections = useMemo(
    () =>
      initialSections.map((section, index) => ({
        ...section,
        isTouched: (options.currentSection ?? 0) >= index,
      })),
    [initialSections, options.currentSection],
  );
  const [sectionState, dispatch] = useReducer<typeof reducer<T>>(reducer, {
    sections: mappedInitialSections,
    currentSection: options.currentSection ?? findFirstSection(mappedInitialSections),
    triedToSubmit: false,
  });

  function setCurrentSection(index: number) {
    dispatch({ type: SectionStateActionTypes.SET_CURRENT_SECTION, payload: index });
  }

  function goToFirstErrorSection() {
    const index = sectionState.sections.findIndex(
      (section) => section.status === SECTION_STATUS.ERROR,
    );

    if (index >= 0) {
      dispatch({ type: SectionStateActionTypes.SET_CURRENT_SECTION, payload: index });
    }
  }

  function setErrorSection(index: number, hasError: boolean) {
    dispatch({ type: SectionStateActionTypes.SET_SECTION_ERROR, payload: { index, hasError } });
  }

  function setTriedToSubmit() {
    dispatch({ type: SectionStateActionTypes.SET_TRIED_TO_SUBMIT, payload: true });
  }

  function setStatusSection(index: number, status?: SECTION_STATUS) {
    dispatch({ type: SectionStateActionTypes.SET_STATUS_SECTION, payload: { index, status } });
  }

  function setSections(sections: ISection<T>[]) {
    dispatch({ type: SectionStateActionTypes.SET_SECTIONS, payload: { sections } });
  }

  function findFirstSection(sections: ISection<T>[]) {
    for (let i = 0; i < sections.length; i++) {
      const nextSection = sections[i];
      if (nextSection.type === SECTION_TYPE.PARENT) {
        continue;
      }

      return i;
    }
    return 0;
  }

  function goToNextSection() {
    let newIndex;

    // when going to the previous section we need to skip parent sections
    for (let i = sectionState.currentSection + 1; i < sectionState.sections.length; i++) {
      const nextSection = sectionState.sections[i];
      if (nextSection.type === SECTION_TYPE.PARENT) {
        continue;
      }

      newIndex = i;
      break;
    }
    if (newIndex === undefined) {
      return false;
    }

    setCurrentSection(newIndex);
    return true;
  }

  function goToPreviousSection() {
    let newIndex;

    // when going to the next section we need to skip parent sections
    for (let i = sectionState.currentSection - 1; i >= 0; i--) {
      const previousSection = sectionState.sections[i];
      if (previousSection.type === SECTION_TYPE.PARENT) {
        continue;
      }

      newIndex = i;
      break;
    }
    if (newIndex === undefined) {
      return false;
    }
    setCurrentSection(newIndex);
    return true;
  }

  return {
    triedToSubmit: sectionState.triedToSubmit,
    currentSection: sectionState.currentSection,
    sections: sectionState.sections,
    goToNextSection,
    goToPreviousSection,
    setCurrentSection,
    setErrorSection,
    setTriedToSubmit,
    goToFirstErrorSection,
    setStatusSection,
    setSections,
  };
}

export { useSectionState };
export type { ISectionState };
