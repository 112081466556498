import React, { ChangeEvent } from 'react';

import { FocusAreaType } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICONS } from '~/components/Icon';

import {
  StyledSearchField,
  Label,
  SuggestedSkillsWrapper,
  StyledSearchFieldWrapper,
  RecommendedSkillsWrapper,
  Title,
  Header,
  Content,
  Actions,
  Subtitle,
  Footer,
} from './design';

import type { ISelectedSkillTemplate } from '~/@types/job';
import { isNotNil } from '~/utils/typePredicates';

import type { IForm, SearchSkillProps } from './types';
import type { ISkill, ISkillTemplate } from '@learned/types';

function SearchSkill<T extends IForm>({
  onClose,
  skillCategoryName,
  skillCategoryId,
  searchInputValue,
  setSearchInputValue,
  skillNames,
  skills,
  defaultFocusAreaLevel,
  isDefaultFocusAreaLevelEnabled,
  setIsSelectLevelAndFocusAreasVisible,
  setSource,
  formMethods,
  setSkillTemplates,
  setSkills,
}: SearchSkillProps<T>) {
  const { i18n } = useLingui();
  const { setValue, watch } = formMethods;
  // @ts-ignore
  const watchSkills = watch(`skills.${skillCategoryId}.skills`) as ISelectedSkillTemplate['skills'];

  const addSkill = (source: ISkillTemplate | ISkill) => {
    const focusAreas = source.focusAreas;
    if (defaultFocusAreaLevel && isDefaultFocusAreaLevelEnabled) {
      let level = defaultFocusAreaLevel;

      if (focusAreas.length < defaultFocusAreaLevel) {
        level = focusAreas.length;
      }

      if (focusAreas.length) {
        // @ts-ignore
        if (source?.company) {
          setSkills?.((skill) => uniqBy(skill.concat(source as ISkill), ({ id }) => id));
        } else {
          setSkillTemplates?.((prevSkillTemplates) =>
            uniqBy(prevSkillTemplates.concat(source as ISkillTemplate), ({ id }) => id),
          );
        }
        // @ts-ignore
        setValue(`skills.${skillCategoryId}.skills`, [
          ...watchSkills
            .filter(
              // @ts-ignore
              ({ skillTemplate, skill }) => ![skill, skillTemplate].includes(source.id),
            )
            .filter(
              // @ts-ignore
              ({ skillTemplate, skill }) =>
                (skillTemplate && (source as ISkill)?.skillTemplate !== skillTemplate) ||
                (skill && (source as ISkill)?.id !== skill),
            ),
          {
            selectedFocusAreas: [
              {
                type: FocusAreaType.SELECT_LEVEL,
                level: level - 1,
              },
            ],
            // @ts-ignore
            ...(source?.company ? { skill: source.id } : { skillTemplate: source.id }),
          },
        ]);
      }
    }
  };

  return (
    <>
      <Header>
        <Title>
          <Trans>Add skill</Trans>
        </Title>
        <Subtitle>
          <Trans>To skill category: {skillCategoryName}</Trans>
        </Subtitle>
      </Header>
      <Content>
        <StyledSearchFieldWrapper>
          <StyledSearchField
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchInputValue(e.currentTarget.value)
            }
            value={searchInputValue}
            placeholder={i18n._(t`Search skills`)}
            style={{
              width: '100%',
              borderRadius: '10rem',
              fontSize: '14px',
            }}
          />
          {!isNil(skillNames) && (
            <SuggestedSkillsWrapper>
              {!isEmpty(skillNames) ? (
                skillNames.map((skillTemplate) => (
                  <button
                    key={skillTemplate.id}
                    className="item"
                    onClick={() => {
                      if (!isDefaultFocusAreaLevelEnabled) {
                        setIsSelectLevelAndFocusAreasVisible(true);
                        setSource(skillTemplate);
                      } else {
                        addSkill(skillTemplate);
                        onClose();
                      }
                    }}
                  >
                    <span>
                      {skillTemplate.name.en_GB ||
                        skillTemplate.name.nl_NL ||
                        find(skillTemplate.name)}
                    </span>
                    <Icon icon={ICONS.NEXT} className="icon" />
                  </button>
                ))
              ) : (
                <div className="notFound">
                  <Trans>No results...</Trans>
                </div>
              )}
            </SuggestedSkillsWrapper>
          )}
        </StyledSearchFieldWrapper>
        <Label>
          <Trans>Recommended skills for this job</Trans>
        </Label>
        <RecommendedSkillsWrapper>
          {skills
            .filter(
              ({ id }) =>
                !watchSkills
                  .map(({ skillTemplate }) => skillTemplate)
                  .filter(isNotNil)
                  .includes(id),
            )
            .filter(
              ({ id }) =>
                !watchSkills
                  .map(({ skill }) => skill)
                  .filter(isNotNil)
                  .includes(id),
            )
            .map((skill) => (
              <button
                key={skill.id}
                className="item"
                onClick={() => {
                  if (!isDefaultFocusAreaLevelEnabled) {
                    setIsSelectLevelAndFocusAreasVisible(true);
                    setSource(skill);
                  } else {
                    addSkill(skill);
                    onClose();
                  }
                }}
              >
                {skill.name.en_GB || skill.name.nl_NL || find(skill.name)}
              </button>
            ))}
        </RecommendedSkillsWrapper>
      </Content>
      <Footer>
        <Actions>
          <Button
            label={i18n._(t`Cancel`)}
            type="button"
            variant={ButtonVariant.TEXT_PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
          <Button
            label={i18n._(t`Add skill`)}
            type="button"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={() => {
              onClose();
            }}
            disabled={!isDefaultFocusAreaLevelEnabled || !watchSkills?.length}
          />
        </Actions>
      </Footer>
    </>
  );
}

export { SearchSkill };
