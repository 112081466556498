import React from 'react';

import { REVIEW_STATUS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';

import { DateInput } from '~/components/DateInput';
import Switch from '~/components/Switch';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import { InputContainerGeneral, SubTitle, ToggleRow, ToggleText } from '../../../design';
import { useReviewTasks } from '../../../hooks/useReviewTasks';
import { DateRow } from '../design';

import type { IReviewCycleForm } from '../../../types';
import type { UseFormReturn } from 'react-hook-form';

interface StartEndSectionProps {
  formMethods: UseFormReturn<IReviewCycleForm>;
}

const StartEndSection = ({ formMethods }: StartEndSectionProps) => {
  const { watch, setValue, formState, trigger } = formMethods;
  const { i18n } = useLingui();
  const startDate = watch('settings.startDate');
  const endDate = watch('settings.endDate');

  const { resetGeneratedDates, handleReEnableAutoGenerate } = useReviewTasks({ formMethods });

  const onChangeStartDate = (date: Date, isStartDate?: boolean) => {
    const key = isStartDate ? 'settings.startDate' : 'settings.endDate';
    setValue(key, date, { shouldDirty: true });
    formState.errors?.settings?.startDate || formState.errors?.settings?.endDate
      ? trigger('settings')
      : trigger(key);
  };
  const previouslyEnabled = watch('tasks.isPreviouslyAutoGenerateEnabled');

  const getFormattedDateToString = (date: Date | null) => {
    const datePattern = 'DD-MM-YYYY';

    if (date) {
      return moment(date).format(datePattern);
    }
    return '';
  };

  const onToggleChange = async (value: boolean) => {
    setValue('tasks.isAutoGenerate', value, { shouldDirty: true });
    if (!value) {
      resetGeneratedDates();
      return;
    }
    if (previouslyEnabled) {
      await handleReEnableAutoGenerate();
    }
  };

  const savedStartDate = new Date(watch('fetchedCycle').settings.startDate);
  const isPublished = watch('status') === REVIEW_STATUS.PUBLISHED;
  const isActive = watch('status') === REVIEW_STATUS.ACTIVE;

  const today = new Date();
  const isStartDateDisabled = (isPublished && savedStartDate && savedStartDate < today) || isActive;

  return (
    <div>
      <SubTitle>
        <Trans>Start and end date of the review cycle</Trans>
      </SubTitle>
      <DateRow marginTop="8px" marginBottom="16px">
        <ToolTip
          tooltip={i18n._(
            t`It is not possible to change this date after the review cycle has been published`,
          )}
          disabled={!isStartDateDisabled}
        >
          <InputContainerGeneral>
            <DateInput
              value={getFormattedDateToString(startDate)}
              label={i18n._(t`Start date`)}
              onChange={(date) => onChangeStartDate(date as Date, true)}
              error={formState.errors?.settings?.startDate?.message}
              disabled={!!isStartDateDisabled}
            />
          </InputContainerGeneral>
        </ToolTip>
        <DateInput
          value={getFormattedDateToString(endDate)}
          label={i18n._(t`Deadline`)}
          onChange={(date) => onChangeStartDate(date as Date)}
          error={formState.errors?.settings?.endDate?.message}
        />
      </DateRow>
      <ToggleRow>
        <Switch onChange={onToggleChange} checked={watch('tasks.isAutoGenerate')} />
        <ToolTip
          tooltip={i18n._(
            t`Based on the selected start date, we fill the remaining date fields with recommended dates`,
          )}
          placement={TOOLTIP_PLACEMENTS.BOTTOM}
        >
          <ToggleText>
            <Trans>Automatically generate a timeline based on the start date</Trans>
          </ToggleText>
        </ToolTip>
      </ToggleRow>
    </div>
  );
};

export { StartEndSection };
