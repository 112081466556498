import { IServerRoute, IGetServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';

import cloudRequest from '../cloudRequest';

import type { IGetUserReviewsRequest, IUserReview } from '@learned/types';

export function getUserReviews(body: IGetUserReviewsRequest) {
  return cloudRequest(serverRoutes.userReviews.items as IServerRoute, {}, body);
}

export function getUserReview(userReviewId: IUserReview['id']) {
  return cloudRequest((serverRoutes.userReviews.item as IGetServerRoute)(userReviewId), {}, {});
}

export function deleteUserReviewById(userReviewId: IUserReview['id']) {
  return cloudRequest(
    (serverRoutes.userReviews.deleteById as IGetServerRoute)(userReviewId),
    {},
    {},
  );
}

export function archiveUserReviewById(userReviewId: IUserReview['id']) {
  return cloudRequest(
    (serverRoutes.userReviews.archiveById as IGetServerRoute)(userReviewId),
    {},
    {},
  );
}

export function unarchiveUserReviewById(userReviewId: IUserReview['id']) {
  return cloudRequest(
    (serverRoutes.userReviews.unarchiveById as IGetServerRoute)(userReviewId),
    {},
    {},
  );
}
