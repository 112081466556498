import React from 'react';

import { REPORT_CHART_TYPES, REPORT_TYPES, ROLES } from '@learned/constants';

import { ITabConfigurationResponse } from '~/services/reports';

import { MONTH_OPTIONS } from '../options';
import { EExportOptions, EOptions, TOptions, TViewAs } from '../types';

interface IContext {
  themesOptions: TOptions[];
  learnedThemesOptions: TOptions[];
  primaryRowSelected: TOptions | null;
  viewAs: TViewAs;
  tabConfiguration: ITabConfigurationResponse | null;
  reportType: REPORT_TYPES;
  enabledOptions: EOptions[];
  exportOptionArr: EExportOptions[];
  shouldShowLuca: boolean;
  shouldBenchmarkDisabled: boolean;
  viewType: REPORT_CHART_TYPES | null;
  reportId: string | null;
  filters: {
    monthSelected: string;
    themesOptionSelected: TOptions[];
    teamsOptionSelected: TOptions[];
    surveysOptionSelected: TOptions[];
    jobsSelected: TOptions[];
    jobsGroupsSelected: TOptions[];
    gendersSelected: TOptions[];
    ageGroupSelected: TOptions[];
    educationLevelsSelected: TOptions[];
  };
  options: {
    includeCompanyAverage: boolean;
    includeBenchmark: boolean;
    includeTeamAverage: boolean;
    isHeatmapColored: boolean;
  };
  dimensions: {
    primary: string;
    secondary: string;
    measure: string;
  };
  isWaiting: boolean;
  showMenu: boolean;
}
const initContext: IContext = {
  primaryRowSelected: null,
  themesOptions: [],
  learnedThemesOptions: [],
  tabConfiguration: null,
  viewAs: ROLES.USER,
  reportType: REPORT_TYPES.ENGAGEMENT,
  enabledOptions: [],
  exportOptionArr: [],
  shouldShowLuca: false,
  shouldBenchmarkDisabled: false,
  filters: {
    monthSelected: MONTH_OPTIONS[0].key,
    themesOptionSelected: [],
    teamsOptionSelected: [],
    surveysOptionSelected: [],
    jobsSelected: [],
    jobsGroupsSelected: [],
    gendersSelected: [],
    ageGroupSelected: [],
    educationLevelsSelected: [],
  },
  options: {
    includeCompanyAverage: false,
    includeBenchmark: false,
    includeTeamAverage: false,
    isHeatmapColored: true,
  },
  dimensions: {
    primary: '',
    secondary: '',
    measure: '',
  },
  isWaiting: true,
  showMenu: false,
  viewType: null,
  reportId: null,
};
export const EngagementReportContext = React.createContext<IContext>(initContext);
