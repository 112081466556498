import { useEffect, useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { useSelector } from 'react-redux';

import { useLanguageState } from '~/hooks/useLanguageState';
import { getUser } from '~/selectors/baseGetters';
import { getUserReview, deleteUserReviewById } from '~/services/userReviews';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type { IReviewDashboardUserForm } from '../types';
import type { IUserReview } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface UseReviewProps {
  formMethods: UseFormReturn<IReviewDashboardUserForm>;
  userReviewId: IUserReview['id'];
}

export const useUserReview = ({ formMethods, userReviewId }: UseReviewProps) => {
  const { setValue } = formMethods;
  const languageState = useLanguageState(true);
  const user = useSelector(getUser);

  const [item, setItem] = useState<IUserReview>();

  const [isAllowToDelete, setIsAllowToDelete] = useState(false);

  useEffect(() => {
    setIsAllowToDelete(item && (user.isAdmin || item?.createdBy === user.id));
  }, [item, user]);

  const fetchUserReview = async () => {
    const result = await getUserReview(userReviewId);
    const userReview: IUserReview = result.data[API_RETURN_FIELDS.USER_REVIEW];
    setItem(userReview);
    return userReview;
  };

  const setFormValues = async () => {
    const review = await fetchUserReview();
    setValue('name', turnMultiLangIntoArray(review.name, languageState.companyLanguages));
  };

  const deleteUserReview = async () => {
    if (isAllowToDelete && item) {
      await deleteUserReviewById(item.id);
    }
  };

  useEffect(() => {
    setFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReviewId]);

  return {
    deleteUserReview,
    isAllowToDelete,
  };
};
