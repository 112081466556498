import styled from 'styled-components';

import { COLORS } from '~/styles';

const Container = styled.div`
  margin: 16px 0 37px 0;
`;

const SearchBox = styled.div`
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDERS};
  justify-content: center;
  flex-direction: row;
  height: 38px;
`;

const SearchBoxText = styled.div`
  padding: 8px 16px;
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.PLACEHOLDERS};
`;

export { Container, SearchBox, SearchBoxText };
