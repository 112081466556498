import { ENGAGEMENT_REPORT_CHART_DIMENSIONS } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

export type TOptions = {
  key: string;
  title: (i18n: I18n) => string;
};

export const MONTH_OPTIONS = [
  { key: '12', title: (i18n: I18n) => i18n._(t`Last 12 months`) },
  { key: '24', title: (i18n: I18n) => i18n._(t`Last 24 months`) },
  { key: '36', title: (i18n: I18n) => i18n._(t`Last 36 months`) },
];

export const BASIC_OPTIONS = [
  { key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.TEAM, title: (i18n: I18n) => i18n._(t`Team`) },
  { key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.SURVEY, title: (i18n: I18n) => i18n._(t`Survey`) },
  { key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.THEME, title: (i18n: I18n) => i18n._(t`Theme`) },

  {
    key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.AGE_GROUP,
    title: (i18n: I18n) => i18n._(t`Age Group`),
  },
  { key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.GENDER, title: (i18n: I18n) => i18n._(t`Gender`) },
  { key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.JOB, title: (i18n: I18n) => i18n._(t`Job`) },
  {
    key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.JOB_GROUP,
    title: (i18n: I18n) => i18n._(t`Job Group`),
  },
  {
    key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.EDUCATION_LEVEL,
    title: (i18n: I18n) => i18n._(t`Education Level`),
  },
  {
    key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.PERFORMANCE_CATEGORY,
    title: (i18n: I18n) => i18n._(t`Performance Category`),
  },
  {
    key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.REVIEW,
    title: (i18n: I18n) => i18n._(t`Review`),
  },
  {
    key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.MEMBER,
    title: (i18n: I18n) => i18n._(t`Member`),
  },
  {
    key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.SKILL,
    title: (i18n: I18n) => i18n._(t`Skill`),
  },
  {
    key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.FOCUS_AREA,
    title: (i18n: I18n) => i18n._(t`Focus Area`),
  },
];

export const PRIMARY_OPTIONS = [
  { key: 'primary_none', title: () => '-' },
  ...BASIC_OPTIONS,
  { key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.QUESTION, title: (i18n: I18n) => i18n._(t`Question`) },
];

export const SECONDARY_OPTIONS = [
  { key: 'secondary_none', title: () => '-' },
  ...BASIC_OPTIONS,
  { key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.QUESTION, title: (i18n: I18n) => i18n._(t`Question`) },
];

export const MEASURE_OPTIONS = [
  { key: 'measure_none', title: () => '-' },
  ...BASIC_OPTIONS,
  { key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.WEEK, title: (i18n: I18n) => i18n._(t`Week`) },
  { key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.MONTH, title: (i18n: I18n) => i18n._(t`Month`) },
  { key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.QUARTER, title: (i18n: I18n) => i18n._(t`Quarter`) },
  { key: ENGAGEMENT_REPORT_CHART_DIMENSIONS.YEAR, title: (i18n: I18n) => i18n._(t`Year`) },
];

export const PAGINATION_OPTIONS = [
  { id: 50, label: (i18n: I18n) => i18n._(t`50 per page`) },
  { id: 100, label: (i18n: I18n) => i18n._(t`100 per page`) },
  { id: 150, label: (i18n: I18n) => i18n._(t`150 per page`) },
];

// TODO: remove this when we confirm on per page options
export const PAGINATION_PRIMARY_OPTIONS = [
  { id: 10, label: (i18n: I18n) => i18n._(t`10 per page`) },
  { id: 25, label: (i18n: I18n) => i18n._(t`25 per page`) },
  { id: 50, label: (i18n: I18n) => i18n._(t`50 per page`) },
  { id: 100, label: (i18n: I18n) => i18n._(t`100 per page`) },
];

export const MENU_SIZE = {
  RIGHT_FULL_WIDTH: 200,
  LEFT_HALF_WIDTH: 180,
  LEFT_FULL_WIDTH: 280,
  ONLY_AVERAGE: 90,
  ONLY_BENCHMARK: 110,
};

export type TPaginationProp = {
  skip: number;
  limit: number;
  index: number;
};
