import styled from 'styled-components';

import { COLORS } from '~/styles';

const FlexRow = styled.div<{
  $isHidePointer?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: ${({ $isHidePointer }) => (!$isHidePointer ? 'pointer' : 'default')};
`;

const TextRow = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
  }
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const DateRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  width: 170px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const Wrapper = styled.div`
  color: ${COLORS.TEXT_MAIN};
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

const EmployeeRow = styled.div`
  padding: 16px 24px;
`;

export { TextRow, FlexRow, DateRow, Wrapper, EmployeeRow };
