import styled, { css } from 'styled-components';

import { Icon } from '~/components/Icon';
import Label from '~/components/Label';

import { Top } from './components/Top';

import { COLORS } from '~/styles';

export const TH = styled.th<{
  minWidth?: string;
  maxWidth?: string;
  padding?: string;
  isActive?: boolean;
}>`
  min-width: ${({ minWidth }) => minWidth || '120px'};
  width: ${({ maxWidth }) => maxWidth || 'unset'};
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
  .sortIcons {
    opacity: 0;
  }
  .sortIconsActive {
    opacity: 1;
  }
  div {
    &:hover {
      .sortIcons {
        opacity: 1;
      }
    }
  }
  white-space: nowrap;
`;

export const THLabel = styled.div<{
  $isPointer?: boolean;
  minWidth?: string;
  maxWidth?: string;
  padding?: string;
}>`
  cursor: ${({ $isPointer }) => ($isPointer ? 'pointer' : 'default')};
  padding: ${({ padding }) => padding || '4px 10px'};
  border-radius: 6px;
  min-width: ${({ minWidth }) => minWidth || 'unset'};
  width: ${({ maxWidth }) => maxWidth || 'unset'};
  margin: 20px 14px 16px 14px;

  @keyframes fade {
    0% {
      background-color: ${COLORS.HOVER};
    }
    100% {
      background-color: unset;
    }
  }

  &:hover,
  &.hover {
    background-color: ${COLORS.INFO_LIGHT};
  }

  &.isFade {
    animation: fade 0.2s ease-in-out;
  }
`;

export const TableWrapper = styled.table<{
  $isPointer: boolean;
  isScrollbarVisible?: boolean;
  isStriped?: boolean;
  isLeftCornerRounded?: boolean;
  isRightCornerRounded?: boolean;
}>`
  border-collapse: separate;
  border-spacing: 0 3px;
  background-color: ${COLORS.WHITE};
  width: 100%;

  ${({ isScrollbarVisible }) =>
    isScrollbarVisible &&
    css`
      transform: rotateX(180deg);
    `}

  ${({ isStriped }) =>
    isStriped &&
    css`
      & tr:nth-child(odd) .cell {
        background-color: ${COLORS.BG_LIST};
      }
    `}


  & tr {
    cursor: ${(props) => (props.$isPointer ? 'pointer' : 'default')};
    border-radius: 6px;
    overflow: hidden;

    ${({ isLeftCornerRounded = true }) =>
      isLeftCornerRounded &&
      css`
        & td:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
      `}
    ${({ isRightCornerRounded = true }) =>
      isRightCornerRounded &&
      css`
        & td:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      `}
  }

  & tbody {
    & tr {
      &:nth-child(odd) {
        background-color: ${COLORS.BG_LIST};
      }

      &:hover {
        background-color: ${COLORS.HOVER};
      }
    }
  }

  & th,
  td {
    position: relative;
    text-align: left;
    margin: 8px 14px;
  }

  & th {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    & ${THLabel} {
      display: inline-flex;
      align-items: center;
    }
  }

  & td {
    font-size: 14px;
    cursor: ${(props) => (props.$isPointer ? 'pointer' : 'default')};

    & .withPercentage {
      display: inline-flex;
      align-items: center;
      gap: 12px;
      width: 80px;
    }

    &.checkbox {
      width: 45px !important;
      min-width: 45px !important;
    }
  }
`;

export const TableContainer = styled.div<{ padding?: string }>`
  display: flex;
  align-items: start;
  width: 100%;
  padding: ${({ padding }) => padding || '0'};
`;

export const MainTable = styled.div<{ isScrollbarVisible?: boolean }>`
  position: relative;
  width: 100%;

  ${({ isScrollbarVisible }) =>
    isScrollbarVisible
      ? css`
          overflow-x: scroll;
          table-layout: fixed;

          &::-webkit-scrollbar {
            height: 8px;
            position: absolute;
          }

          &::-webkit-scrollbar-track {
            background: ${COLORS.BG_ELEMENTS};
            border-radius: 100px;
          }

          &::-webkit-scrollbar-thumb {
            background: var(--company-color);
            border-radius: 100px;
          }

          &::-webkit-scrollbar:hover {
            cursor: pointer;
          }

          transform: rotateX(180deg);
        `
      : css`
          &::-webkit-scrollbar {
            display: none;
          }
        `}
`;

export const SelectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TD = styled.td<{
  minWidth?: string;
  maxWidth?: string;
  padding?: string;
  isGrayColor?: boolean;
  $isPointer?: boolean;
  isSelected?: boolean;
  isHovered?: boolean;
}>`
  min-width: ${({ minWidth }) => minWidth || '120px'};
  width: ${({ maxWidth }) => maxWidth || 'unset'};
  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
  padding: ${({ padding }) => padding || '0 24px'};
  color: ${({ isGrayColor, isSelected, isHovered }) =>
    isGrayColor
      ? COLORS.SUBTEXT
      : isSelected
      ? COLORS.COMPANY
      : isHovered
      ? COLORS.TEXT_HOVER
      : COLORS.TEXT_MAIN};
  cursor: ${({ $isPointer }) => ($isPointer ? 'pointer' : 'default')};
  height: 64px;
  box-sizing: border-box;
`;

export const THead = styled.thead`
  & .rotate {
    rotate: 180deg;
  }
`;

export const TR = styled.tr<{
  isSelected?: boolean;
}>`
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${COLORS.HOVER};
    `}
`;

export const TRHeader = styled(TR)<{
  isHideHeader?: boolean;
}>`
  ${({ isHideHeader }) =>
    isHideHeader &&
    css`
      height: 0 !important;
    `};

  ${({ isHideHeader }) =>
    isHideHeader
      ? css`
          display: none;
          &:hover {
            background-color: transparent;
          }
        `
      : css`
          &:hover {
            background-color: ${COLORS.WHITE};
          }
        `};
`;

export const SortMenu = styled.div`
  position: absolute;
  top: 40px;
  left: 15px;
  background-color: ${COLORS.WHITE};
  border-radius: 6px;
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
  border: solid 1px #dae4f7;
  width: 150px;
  padding: 4px;
  z-index: 2;

  & .item {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: normal;
    height: 32px;
    border-radius: 6px;
    color: ${COLORS.DARK_GRAY};
    cursor: pointer;

    &:first-child {
      margin-bottom: 4px;
    }

    & .icon {
      width: 32px;
    }

    &.selected {
      background-color: ${COLORS.COMPANY};
      color: ${COLORS.WHITE};
      & .icon {
        color: ${COLORS.WHITE};
      }
    }

    &:not(.selected):hover {
      background-color: #f5f7fd;
    }
  }
`;

export const SortIcons = styled.span`
  margin-left: 4px;
  display: inline-flex;
  flex-direction: column;
`;

export const DraftLabel = styled.div`
  position: absolute;
  right: -85px;
  top: -10px;
`;

export const DraftWrapper = styled.div`
  position: relative;
`;

export const IconMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    color: ${COLORS.COMPANY};
  }
`;

export const IsVisible = styled.div<{ isVisible?: boolean }>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

export const StyledTop = styled(Top)`
  padding: 0;
`;

export const StatusLabel = styled(Label)`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

export const StyledIcon = styled(Icon)`
  box-sizing: border-box;
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  max-width: 100%;
`;
