import FileSaver from 'file-saver';
import isEmpty from 'lodash/isEmpty';

import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

/**
 * @deprecated since Review 3.0 (check reviews)
 */
// isGrouped - works only for COACH role
export function getReviews(body) {
  return cloudRequest(serverRoutes.reviews.items, {}, body);
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getUserReviews(userId, { filters = {}, isJoinDraft = false } = {}) {
  return cloudRequest(serverRoutes.reviews.getUserReviews, { ...filters, userId, isJoinDraft });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getUserReviewForConversations(id) {
  return cloudRequest(serverRoutes.reviews.getUserReviewForConversations, { id });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getReview(
  id,
  {
    populate = [],
    join = [],
    isSubReviews = false,
    isQuestions = false,
    isConversations = false,
    isAvg = false,
    isInterviewee = false,
  } = {},
) {
  return cloudRequest(serverRoutes.reviews.getReview, {
    id,
    populate,
    join,
    isSubReviews,
    isQuestions,
    isConversations,
    isAvg,
    isInterviewee,
  });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getFilteredReview(
  id,
  {
    populate = [],
    join = [],
    isSubReviews = false,
    isSubReviewsTotalCount = false,
    isQuestions = false,
    isConversations = false,
    isAvg = false,
    isWithCalibrateRatings = false,
    isDontGetSkillsJobProfiles = false,
  } = {},
  { filters = {}, sort = {}, pagination = {}, KPIs } = {},
) {
  return cloudRequest(
    serverRoutes.reviews.getFilteredReview,
    {
      id,
      populate,
      join,
      isSubReviews,
      isSubReviewsTotalCount,
      isQuestions,
      isConversations,
      isAvg,
      isWithCalibrateRatings,
      isDontGetSkillsJobProfiles,
    },
    { filters, sort, pagination, KPIs },
  );
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function updateReview(id, body) {
  return cloudRequest(serverRoutes.reviews.updateReview, { id }, body);
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function updatePublishedReview(id, body) {
  return cloudRequest(serverRoutes.reviews.updatePublishedReview, { id }, body);
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function deleteReview(reviewId) {
  return cloudRequest(serverRoutes.reviews.deleteReview, { id: reviewId });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function setSelfReviewStatus(reviewId, isDraft) {
  return cloudRequest(serverRoutes.reviews.setSelfReviewStatus, { id: reviewId }, { isDraft });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function nominatePeopleForReview(reviewId, type, users, emails, description, forUser) {
  return cloudRequest(
    serverRoutes.reviews.nominatePeopleForReview,
    { id: reviewId },
    { type, users, emails, description, ...(forUser && { forUser }) },
  );
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function addUsersToReview(
  reviewId,
  userIds,
  { coaches = {}, conversationCoaches = {}, jobProfilesPerUser = {}, startDates = {} } = {},
) {
  return cloudRequest(
    serverRoutes.reviews.addUsersToReview,
    { id: reviewId },
    { users: userIds, coaches, conversationCoaches, jobProfilesPerUser, startDates },
  );
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function deleteUsersFromReview(reviewId, userIds) {
  return cloudRequest(
    serverRoutes.reviews.deleteUsersFromReview,
    { id: reviewId },
    { users: userIds },
  );
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function lockReviewStage(reviewId, stage, value) {
  return cloudRequest(serverRoutes.reviews.lockReviewStage, { id: reviewId }, { stage, value });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function prepareUserReview(reviewId) {
  return cloudRequest(serverRoutes.reviews.prepareUserReview, { id: reviewId });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function downloadReviewCSV(reviewId, reviewName) {
  return cloudRequest(serverRoutes.reviews.downloadReviewCSV, { id: reviewId }).then((response) => {
    FileSaver.saveAs(response, reviewName ? reviewName + '_review.csv' : 'review.csv');
  });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function downloadUserReviewPDFReport(reviewId, reviewName) {
  return cloudRequest(
    serverRoutes.generatePDF.getReviewPDF(reviewId),
    {},
    {},
    { arrayBuffer: true },
  ).then((response) => {
    if (response) {
      const blob = new Blob([response], { type: 'application/pdf' });
      FileSaver.saveAs(blob, `${reviewName} report.pdf`);
    }
  });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function sendReviewConversationReminder(reviewId) {
  return cloudRequest(serverRoutes.reviews.sendReviewConversationReminder(reviewId));
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function sendDigitalSignReminder(reviewId) {
  return cloudRequest(serverRoutes.reviews.sendDigitalSignReminder, { id: reviewId });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getNineGridReviews() {
  return cloudRequest(serverRoutes.reviews.getNineGridReviews);
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getReviewLogs(reviewId) {
  return cloudRequest(serverRoutes.reviews.getReviewLogs(reviewId));
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getStatistics(reviewId, filters = {}) {
  return cloudRequest(serverRoutes.reviews.getStatistics(reviewId), {}, { filters });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getTeamsStatistics(reviewId, teamsIds = []) {
  return cloudRequest(serverRoutes.reviews.getTeamsStatistics(reviewId), {}, { teams: teamsIds });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
/**
 *
 * @param pagination = { skip: number, limit: number }
 * @param sectionTypes = ['skills', 'potential', 'performance'], by default empty - show all
 * @param reviews = ['reviewId1', 'reviewId2'], by default empty - show all
 * @param members = ['userId1', 'userId2'], by default empty - show all
 * @param skills = ['skillId1', 'skillId2'], by default empty - show all
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getReviewsReport({
  pagination = {},
  sectionTypes,
  reviews = [],
  members = [],
  skills = [],
} = {}) {
  return cloudRequest(serverRoutes.reviews.getReviewsReport, {
    pagination: JSON.stringify(pagination),
    sectionTypes: sectionTypes ? JSON.stringify(sectionTypes) : null,
    ...(!isEmpty(reviews) && { reviews: JSON.stringify(reviews) }),
    ...(!isEmpty(members) && { members: JSON.stringify(members) }),
    ...(!isEmpty(skills) && { skills: JSON.stringify(skills) }),
  });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getReviewsFilter({ search, limit = 0 } = {}) {
  return cloudRequest(serverRoutes.reviews.getReviewsFilter, { search, limit });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getTeamsInReview(reviewId, options) {
  return cloudRequest(serverRoutes.reviews.getTeamsInReview(reviewId), options);
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function downloadNinegridCSV({ reviewIds, KPIs, jobProfiles, users } = {}) {
  return cloudRequest(serverRoutes.reviews.downloadNinegridCSV, {
    reviewIds,
    KPIs,
    jobProfiles,
    users,
  }).then((response) => {
    FileSaver.saveAs(response, 'ninegridreport.csv');
  });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function updateGoalCycleForUserReview(reviewId) {
  return cloudRequest(serverRoutes.reviews.updateGoalCycleForUserReview, { reviewId });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function setDateOfConversation(
  reviewId,
  { startDate, endDate, includeLinkMeeting, returnCalendarEvent },
) {
  return cloudRequest(serverRoutes.reviews.setDateOfConversation(reviewId), null, {
    startDate,
    endDate,
    includeLinkMeeting,
    returnCalendarEvent,
  });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function updateReviewConversationCoaches(id, conversationCoaches) {
  return cloudRequest(
    serverRoutes.reviews.updateReviewConversationCoaches,
    { id },
    { conversationCoaches },
  );
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function addSignature(reviewId, body) {
  return cloudRequest(serverRoutes.reviews.addSignature(reviewId), null, body);
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function deleteSignatures(reviewId) {
  return cloudRequest(serverRoutes.reviews.deleteSignatures(reviewId));
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getReviewsForDevelopmentCycle(filters = {}) {
  return cloudRequest(serverRoutes.reviews.getReviewsForDevelopmentCycle, filters);
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function uploadReviewAttachment(file, reviewId) {
  return cloudRequest(
    serverRoutes.reviews.uploadReviewAttachment(reviewId),
    {},
    { name, file },
    { sendAsFormData: true },
  );
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function deleteReviewAttachment(fileId, reviewId) {
  return cloudRequest(serverRoutes.reviews.deleteReviewAttachment(reviewId), {
    file: fileId,
  });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export const shareCoachesCalibratedReviews = (reviewId) => {
  return cloudRequest(serverRoutes.reviews.shareCoachesCalibratedReviews(reviewId));
};

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export const getNextScheduledConversation = () => {
  return cloudRequest(serverRoutes.reviews.getNextScheduledConversation);
};
