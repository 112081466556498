import React, { useEffect, useMemo, useState } from 'react';

import { REVIEW_RATING_STATUS, TASK_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useFieldArray, useForm } from 'react-hook-form';

import { useSectionState } from '~/components/TableOfContents/hooks';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { useAutoSave } from '~/hooks/useAutoSave';
import { useFromQuery } from '~/hooks/useFromQuery';
import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { createOrUpdateReviewRatings } from '~/services/reviewRatings';

import {
  createInitialSections,
  findAllRatings,
  findFirstUnansweredQuestion,
  groupQuestionsBasedOnType,
} from '../utils';
import { resolver } from '../validation';

import type {
  AnswerReviewForm,
  IPopulatedReviewTask,
  IPopulatedUserReview,
  IQuestionData,
} from '../types';

export function useReviewRatingsState({
  reviewTask,
  userReview,
}: {
  reviewTask: IPopulatedReviewTask;
  userReview: IPopulatedUserReview;
}) {
  const getMultiLangString = useMultiLangString();
  const { addToast } = useToasts();
  const { goBack } = useFromQuery({ includeHash: true });
  const { i18n } = useLingui();
  const languageState = useLanguageState();

  const [showOutro, setShowOutro] = useState(false);

  const groupedQuestions = useMemo(() => groupQuestionsBasedOnType(userReview), [userReview]);
  const firstUnansweredQuestion = findFirstUnansweredQuestion(groupedQuestions, reviewTask);
  const sectionState = useSectionState<IQuestionData>(
    createInitialSections(groupedQuestions, getMultiLangString, reviewTask.userTo),
    { currentSection: firstUnansweredQuestion === -1 ? undefined : firstUnansweredQuestion },
  );
  const autoSaveState = useAutoSave(async () => {
    const data = getValues();
    const transformedData = data.ratings.map((rating) => ({
      id: rating.ratingId,
      company: reviewTask.company,
      createdBy: reviewTask.userTo,
      createdFor: reviewTask.userFrom as string,
      userReview: userReview.id,
      userReviewQuestion: rating.question,
      answer: (rating.isNotApplicable ? null : rating.answer) as string | null,
      comment: rating.comment,
    }));

    await createOrUpdateReviewRatings({
      taskId: reviewTask.id,
      reviewRatings: transformedData,
      isAutoSave: true,
    });
  });

  const formData = useForm<AnswerReviewForm>({
    mode: 'all',
    resolver,
    context: { reviewTask, userReview },
    defaultValues: {
      ratings: Array.from(findAllRatings(sectionState.sections)).map((rating) => ({
        ratingId: rating.id,
        question: rating.userReviewQuestion,
        answer: rating.answer === null ? undefined : rating.answer,
        comment: rating.comment,
        isNotApplicable: rating.answer === null,
      })),
      shouldValidate: true,
    },
  });
  const { getValues, setValue, trigger, control, handleSubmit, formState } = formData;
  const { fields, update } = useFieldArray({
    name: 'ratings',
    control,
  });

  useEffect(() => {
    const ratingErrors = formState.errors?.ratings;

    fields.forEach((_, index) => {
      const fieldError = ratingErrors?.[index];
      if (fieldError?.comment?.type !== undefined || fieldError?.answer?.type !== undefined) {
        sectionState.setErrorSection(index, true);
      } else {
        sectionState.setErrorSection(index, false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors.ratings]);

  useEffect(() => {
    trigger('ratings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = async (
    data: {
      answer?: string | undefined;
      comment?: string | undefined;
      isNotApplicable?: boolean | undefined;
    },
    section: number,
  ) => {
    const newData = {
      ...fields[section],
      ...(data.answer !== undefined && { answer: data.answer }),
      ...(data.comment !== undefined && { comment: data.comment }),
      ...(data.isNotApplicable !== undefined && { isNotApplicable: data.isNotApplicable }),
    };

    update(section, newData);

    await trigger('ratings');
  };

  const onSuccess = async (data: AnswerReviewForm, isPublishing = false) => {
    const transformedData = data.ratings.map((rating) => ({
      id: rating.ratingId,
      company: reviewTask.company,
      createdBy: reviewTask.userTo,
      createdFor: reviewTask.userFrom as string,
      userReview: userReview.id,
      userReviewQuestion: rating.question,
      answer: (rating.isNotApplicable ? null : rating.answer) as string | null,
      comment: rating.comment,
    }));

    const result = await createOrUpdateReviewRatings({
      taskId: reviewTask.id,
      status: isPublishing ? REVIEW_RATING_STATUS.PUBLISHED : REVIEW_RATING_STATUS.TODO,
      reviewRatings: transformedData,
      isAutoSave: false,
    });

    if (isPublishing && result.code === 200 && reviewTask.status === TASK_STATUS.TODO) {
      if (result.code === 200) {
        addToast({ title: 'Answers published', type: TOAST_TYPES.SUCCESS });
        goBack();
        return;
      }
    }

    if (result.code === 200 && reviewTask.status === TASK_STATUS.COMPLETED) {
      addToast({ title: 'Answers saved', type: TOAST_TYPES.INFO });
      goBack();
    }

    if (result.code === 200 && reviewTask.status === TASK_STATUS.TODO) {
      addToast({ title: 'Answers saved as draft', type: TOAST_TYPES.INFO });
      goBack();
    }

    if (result.code === 403) {
      goBack();
    }

    setValue('shouldValidate', true);
  };

  const onFail = () => {
    sectionState.setTriedToSubmit();
    addToast({
      title: i18n._(t`Warning`),
      subtitle: i18n._(t`Please fill in all obligated fields`),
      type: TOAST_TYPES.INFO,
    });
    setShowOutro(false);
    sectionState.goToFirstErrorSection();
    setValue('shouldValidate', true);
  };

  const onPublish = async (e?: React.BaseSyntheticEvent) => {
    const submit = handleSubmit(
      (data) => {
        return onSuccess(data, true);
      },
      () => onFail(),
    );

    return submit(e);
  };

  const onSave = async (e?: React.BaseSyntheticEvent) => {
    setValue('shouldValidate', false);
    const submit = handleSubmit(
      (data) => {
        return onSuccess(data, false);
      },
      () => onFail(),
    );

    return submit(e);
  };

  const nextSection = () => {
    const hasNextSection = sectionState.goToNextSection();
    if (!hasNextSection) {
      setShowOutro(true);
    }
  };

  const previousSection = () => {
    if (!showOutro) {
      sectionState.goToPreviousSection();
    }
    setShowOutro(false);
  };

  const hasError =
    sectionState.triedToSubmit &&
    formState.errors?.ratings?.[sectionState.currentSection]?.answer?.type !== undefined;
  const hasCommentError =
    sectionState.triedToSubmit &&
    formState.errors?.ratings?.[sectionState.currentSection]?.comment?.type !== undefined;

  return {
    showOutro,
    setShowOutro,
    hasError,
    hasCommentError,
    sectionState,
    formData,
    onPublish,
    onSave,
    onChange,
    nextSection,
    previousSection,
    autoSaveState,
    languageState,
  };
}

export type ReturnTypeUseReviewRatingsState = ReturnType<typeof useReviewRatingsState>;
