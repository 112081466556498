import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { Controller, useForm } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Input } from '~/components/Input';
import Modal from '~/components/Modal';

import { Title, Header, Content, Actions, Subtitle, Footer } from './design';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';

import { InputContainer, Label } from '../design';

import type { ILanguageValue, IMultiLangString } from '@learned/types';

function EditKPIsModal({
  onSave,
  onClose,
  onDelete,
  languageState,
  kpi,
  jobName,
}: {
  onSave: (newValues: IMultiLangString) => void;
  onClose: () => void;
  onDelete: (id: string) => void;
  languageState: ILanguageStateReturn;
  kpi?: IMultiLangString & { id: string };
  jobName?: string;
}) {
  const { i18n } = useLingui();
  const formMethods = useForm<{ kpi: ILanguageValue[] }>({
    mode: 'all',
    defaultValues: {
      kpi: languageState.companyLanguages.map(({ locale }) => ({
        locale,
        value: kpi?.[locale] ?? '',
      })),
    },
  });
  const { register, unregister, control, getValues, watch } = formMethods;

  const kpiFieldArray = useMultiLangFieldArray({
    name: 'kpi',
    control,
    unregister,
    languageState,
  });

  return (
    <Modal
      width={486}
      contentStyles={{ padding: '30px' }}
      hideFooter
      isHideHeader
      centerModal
      showDivider={false}
    >
      <Header>
        <Title>{kpi ? <Trans>Edit result</Trans> : <Trans>Create a result</Trans>}</Title>
        <Subtitle>{jobName}</Subtitle>
      </Header>
      <Content>
        <Label>
          <Trans>KPI</Trans>
        </Label>
        <InputContainer>
          {kpiFieldArray.fields.map((field) => (
            <Controller
              key={field.id}
              {...register(`kpi.${field.index}.value`)}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    placeholder={i18n._(t`KPI`)}
                    value={value}
                    onChange={onChange}
                    key={field.id}
                    leftIcon={
                      size(languageState.languages) > 1
                        ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                        : undefined
                    }
                  />
                );
              }}
            />
          ))}
        </InputContainer>
      </Content>
      <Footer>
        <Actions>
          <Button
            label={i18n._(t`Cancel`)}
            type="button"
            variant={ButtonVariant.TEXT_PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
          <Button
            label={i18n._(t`Save`)}
            type="button"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={() => {
              const newValues = getValues();
              const kpiValue = newValues.kpi.reduce((acc, kpi) => {
                return { ...acc, [kpi.locale]: kpi.value };
              }, {} as IMultiLangString & { id: string });

              onSave(kpiValue);
              onClose();
            }}
            disabled={isEmpty(watch('kpi').filter(({ value }) => value))}
          />
        </Actions>
        {kpi && (
          <Button
            label={i18n._(t`Delete`)}
            type="button"
            variant={ButtonVariant.TEXT_DELETE}
            size={ButtonSize.MEDIUM}
            onClick={() => {
              onDelete(kpi.id);
              onClose();
            }}
          />
        )}
      </Footer>
    </Modal>
  );
}

export { EditKPIsModal };
