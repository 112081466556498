import React, { useState, useEffect } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { useDispatch } from 'react-redux';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import HeadingNavigation from '~/components/HeadingNavigation';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';
import TableCard from '~/components/UI/TableCard';
import BaseLayout from '~/layouts/BaseLayout';

import AddIntegrationModal from './AddIntegrationModal';
import RenderIntegrationRow from './components/RenderIntegrationsRow';
import UpdateIntegrationModal from './UpdateIntegrationModal';

import RolesIcon from '~/assets/mdi-ballot.svg';

import { INSTRUCTIONS } from '~/constants/instructions';
import useBoolState from '~/hooks/useBoolState';
import {
  getCompanyIntegrationSettings,
  deleteIntegrationSettings,
} from '~/services/integrationSettings';
import * as userActions from '~/store/users/actions';
import { COLOR_PALETTE } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';

function CompanyIntegrationSettings({ i18n }) {
  const [integrations, setIntegrations] = useState([]);
  const [updateIntegration, setUpdateIntegration] = useState();
  const $showAddIntegrationModal = useBoolState();
  const $loading = useBoolState(true);
  const $showUpdateIntegrationModal = useBoolState();
  const dispatch = useDispatch();

  useEffect(() => {
    getCompanyIntegrationSettings().then(
      (integrations) => setIntegrations(Object.values(integrations)),
      $loading.off(),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCols = [
    {
      title: i18n._(t`Integration`),
    },
    {
      title: i18n._(t`System`),
      width: '200px',
    },
    {
      title: i18n._(t`Created`),
      width: '160px',
    },
    {
      name: 'actions',
      width: '70px',
    },
  ];

  const onDelete = async (integration) => {
    if (
      await confirm(
        i18n,
        i18n._(t`Are you sure you want to completely delete this integration?

    This will orphanate all user accounts that have been created through this integration and synchronization for these users will no longer function.

    If you want to recreate this integration in the future, the user accounts will remain orphanated.
    In this case, consider disabling the integration and re-enabling it later.`),
      )
    ) {
      await deleteIntegrationSettings(integration.id);

      // Refresh user store
      dispatch(userActions.fetchUserData());

      setIntegrations(Object.values(integrations).filter((i) => i.id !== integration.id));
    }
  };

  return (
    <>
      <HeadingNavigation
        label={i18n._(t`Integration settings`)}
        description={i18n._(
          t`Set-up integrations with HR core systems to import and automatically synchronise: members, teams and roles.`,
        )}
        instructions={i18n._(t`How integrations work`)}
        instructionsUrl={getInstructionUrl(INSTRUCTIONS.LEARNED_API_INTEGRATIONS)}
        actions={<Button label={i18n._(t`+ Integration`)} onClick={$showAddIntegrationModal.on} />}
      />
      <BaseLayout>
        <TableCard
          cols={getCols}
          items={integrations}
          loading={$loading.value}
          renderRow={(integrationItem) => {
            return (
              <RenderIntegrationRow
                integration={integrationItem}
                onUpdate={() => {
                  setUpdateIntegration(integrationItem);
                  $showUpdateIntegrationModal.on();
                }}
                onDelete={onDelete}
              />
            );
          }}
          hideHeader
          hideSearch
          firstPlaceholder={
            <Placeholder
              Icon={() => (
                <SvgIcon
                  url={RolesIcon}
                  width={'50px'}
                  height={'50px'}
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
              title={i18n._(t`No integrations have been created`)}
              subTitle={i18n._(
                t`Integrate Learned with your HR systems to easily import your employees.`,
              )}
            />
          }
        />
      </BaseLayout>
      {$showAddIntegrationModal.value && (
        <AddIntegrationModal
          onClose={() => {
            getCompanyIntegrationSettings().then((integrations) =>
              setIntegrations(Object.values(integrations)),
            );
            $showAddIntegrationModal.off();
          }}
        />
      )}
      {$showUpdateIntegrationModal.value && updateIntegration && (
        <UpdateIntegrationModal
          onClose={() => {
            getCompanyIntegrationSettings().then((integrations) =>
              setIntegrations(Object.values(integrations)),
            );
            $showUpdateIntegrationModal.off();
          }}
          integration={updateIntegration}
        />
      )}
    </>
  );
}

export default withI18n()(CompanyIntegrationSettings);
