export const transformToISOString = (date: Date | null) => {
  if (date) {
    // set day from date in UTC
    date.setUTCDate(date.getDate());
    // set 0 UTC hours to keep same Day (not yesterday)
    date.setUTCHours(0, 0, 0, 0);
    return date.toISOString();
  }

  return date || null;
};
