import React from 'react';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './components/App';
import getCurrentPath from './utils/getCurrentPath';

import { Auth0Provider } from '~/auth0';
import authConfig from '~/auth0/config';
import { VERSION } from '~/constants/env';
import store from '~/store';
import history from '~/utils/history';

import './index.css';

function getSentryEnv() {
  const baseUrl = window.location.href.split('/')[2];
  switch (baseUrl) {
    case 'learned-stage.web.app':
    case 'learned-stage-1.web.app':
    case 'learned-stage-2.web.app':
    case 'learned-stage-3.web.app':
    case 'learned-stage-4.web.app':
    case 'learned-stage-5.web.app':
    case 'learned-stage.firebaseapp.com':
    case 'learned-stage-1.firebaseapp.com':
    case 'learned-stage-2.firebaseapp.com':
    case 'learned-stage-3.firebaseapp.com':
    case 'learned-stage-4.firebaseapp.com':
    case 'learned-stage-5.firebaseapp.com':
    case 'stage.learned.io':
    case 'stage-1.learned.io':
    case 'stage-2.learned.io':
    case 'stage-3.learned.io':
    case 'stage-4.learned.io':
    case 'stage-5.learned.io':
      return 'stage';

    case 'learned-qa.web.app':
    case 'learned-qa.firebaseapp.com':
    case 'qa.learned.io':
      return 'live';

    case 'learned-production.web.app':
    case 'learned-production.firebaseapp.com':
    case 'app.learned.io':
      return 'production';

    default:
      return baseUrl;
  }
}
window.version = VERSION;
// eslint-disable-next-line no-console
console.log('VERSION: %s', window.version);
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://e764f98ad98642939abee3b71397d8f3@sentry.io/1773159',
    environment: getSentryEnv(),
    release: 'learned-client@' + VERSION,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.2,
    beforeSend(event, hint) {
      return hint && hint.originalException && hint.originalException.ignoreSentry ? null : event;
    },
    ignoreErrors: [
      'ResizeObserver loop limit exceeded', // seems this only for specific browser versions https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
      'Login required', // auth0 error, when Failed Silent Auth (user just need to log in again)
    ],
  });
}

// A function that routes the user to the right place after login
const onRedirectCallback = (appState) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : getCurrentPath());
};

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider
      domain={authConfig.domain}
      client_id={authConfig.clientId}
      redirect_uri={window.location.origin}
      audience={authConfig.audience}
      onRedirectCallback={onRedirectCallback}
    >
      <App />
    </Auth0Provider>
  </Provider>,
  document.getElementById('root'),
);
// registerServiceWorker();
