import type { IServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function generateTextTranslation(texts: string[], locales: string[]) {
  return cloudRequest(
    serverRoutes.completions.generateTextTranslation as IServerRoute,
    {},
    { texts, locales },
  );
}
