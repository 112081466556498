import React, { useEffect, useState } from 'react';

import { JOB_PROFILE_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CleanUpModal from '~/components/CleanUpModal';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';

import usersIcon from '~/assets/a-users-icn.svg';

import { INTEGRATIONS, INTEGRATIONS_CONN_ERROR_MSG } from '~/constants';
import { INSTRUCTIONS } from '~/constants/instructions';
import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getJobProfiles } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { findRolesToDeleteFromCompanyIntegrations } from '~/services/integrations';
import { COLORS, COLOR_PALETTE } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';

const CleanUpRolesModal = ({ i18n, onClose, onSubmit }) => {
  const [integrations, setIntegrations] = useState([]);
  const [isDeleteAllForIntegration, setIsDeleteAllForIntegration] = useState('');
  const [isIntegrationError, setIsIntegrationError] = useState(false);

  const jobProfiles = useSelector(getJobProfiles);
  const currentCompany = useSelector(getCurrentCompany);

  const $loading = useBoolState(true);
  const getMultiLangString = useMultiLangString();

  const itemsPath = 'result'; // path where to get items

  useEffect(() => {
    const fetchData = async () => {
      let integrationsWithDeletedRoles;
      try {
        integrationsWithDeletedRoles = await findRolesToDeleteFromCompanyIntegrations();
      } catch (e) {
        // Do nothing
      }

      // If it is undefined then the fetching of integration data failed
      if (!integrationsWithDeletedRoles) {
        setIsIntegrationError(true);
        $loading.off();
        return;
      }

      if (!isEmpty(integrationsWithDeletedRoles)) {
        // Sort integrations tabs by external software name
        integrationsWithDeletedRoles.sort((a, b) =>
          a.externalSoftware > b.externalSoftware ? 1 : -1,
        );

        setIntegrations(integrationsWithDeletedRoles);

        // Check if any integration is informing that all their roles should be cleaned up
        const companyExternalRoles = Object.values(jobProfiles).filter(
          ({ externalSource, status }) => externalSource && status === JOB_PROFILE_STATUSES.ACTIVE,
        );

        integrationsWithDeletedRoles.forEach((integration) => {
          const companyIntegrationRolesLength = companyExternalRoles.filter(
            ({ externalSource }) => externalSource === integration.id,
          ).length;

          if (
            integration.result?.length &&
            integration.result.length === companyIntegrationRolesLength
          ) {
            const integrationSystem = Object.values(INTEGRATIONS).find(
              ({ key }) => key === integration.externalSoftware,
            );

            // Set warning field as this is possibly an integration problem
            setIsDeleteAllForIntegration(integrationSystem?.name(i18n));
          }
        });
      }

      $loading.off();
    };

    fetchData();

    // eslint-disable-next-line
  }, []);

  return (
    <CleanUpModal
      title={i18n._(t`Cleanup roles`)}
      integrations={integrations}
      loading={$loading.value}
      onClose={onClose}
      onSubmit={() => {
        if (!isEmpty(integrations)) {
          const jobProfilesToDelete = [];
          integrations.forEach((i) => jobProfilesToDelete.push(...(i.result || [])));
          return onSubmit(jobProfilesToDelete);
        }
      }}
      placeholder={
        <Placeholder
          Icon={() => (
            <SvgIcon
              url={usersIcon}
              width={'50px'}
              height={'50px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
          title={i18n._(t`No API available`)}
          subTitle={i18n._(
            isIntegrationError
              ? INTEGRATIONS_CONN_ERROR_MSG
              : t`Integrate Learned with your HR systems. Contact your customer success manager.`,
          )}
          subTitleStyles={{ ...(isIntegrationError && { color: COLORS.ACCENT_ERROR }) }}
        />
      }
      warningMessage={i18n._(
        t`Warning: Always check if the roles you are about to delete are no longer being used in Learned. Deleting roles cannot be undone.`,
      )}
      confirmMessage={
        isDeleteAllForIntegration
          ? i18n._(
              t`Warning: please note that the integration with ${isDeleteAllForIntegration} has provided 0 results. This is most likely incorrect and a system error in the integration. If you continue with the synchronisation you will DELETE ALL your imported roles. This action CANNOT be undone. Click ‘Cancel’ to return.`,
            )
          : i18n._(
              t`Are you sure you want to clean-up this roles from Learned? Deleting roles cannot be undone!`,
            )
      }
      integrationExplanation={i18n._(
        t`These roles changes have been detected. Click cleanup to apply the changes.`,
      )}
      integrationPlaceholder={
        <Placeholder
          Icon={() => (
            <SvgIcon
              url={usersIcon}
              width={'50px'}
              height={'50px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
          title={i18n._(t`No roles to clean up`)}
        />
      }
      getIntegrationCols={(_integration) => [
        { title: i18n._(t`Role`), width: '150px' },
        { title: i18n._(t`Type`), width: '120px' },
        { title: i18n._(t`Description`) },
      ]}
      getIntegrationRowData={(item) => [
        item.name[currentCompany.primaryLang?.locale] || getMultiLangString(item.name),
        i18n._(t`Status`),
        i18n._(t`Delete role`),
      ]}
      isSubmitDisabled={integrations.every((i) => isEmpty(i.result))}
      infoMessage={i18n._(
        t`Roles can only be cleaned-up if they have been imported via an API and can only be cleaned-up with that specific API`,
      )}
      infoUrl={getInstructionUrl(INSTRUCTIONS.CLEANUP_ROLES)}
      pathToItems={itemsPath}
    />
  );
};

CleanUpRolesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withI18n()(CleanUpRolesModal);
