import React, { useEffect, useState } from 'react';

import { REPORT_CHART_TYPES, REPORT_TYPES, ROLES } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui, withI18n } from '@lingui/react';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Loader } from '~/components/Buttons/components/Loader';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import BaseLayout from '~/layouts/BaseLayout';

import ControlPanel from './ControlPanel';
import { EngagementReportContext } from './EngagementContext';
import { LoaderCtr } from './LayoutStyles';
import Custom from './tabs/Custom';
import Overtime from './tabs/Overtime';
import Total from './tabs/Total';

import routes from '~/constants/routes';
import { usePagination } from '~/hooks/usePagination';
import { getSelectedRole } from '~/selectors/baseGetters';
import { getTabDetails, ITabConfigurationResponse, getFacets } from '~/services/reports';
import history from '~/utils/history';

import {
  MONTH_OPTIONS,
  PAGINATION_PRIMARY_OPTIONS,
  PRIMARY_OPTIONS,
  SECONDARY_OPTIONS,
  MEASURE_OPTIONS,
} from '../options';
import { EOptions, TDropDownValues, TOptions, EExportOptions, TViewAs } from '../types';

type TOptionData = {
  id: string;
  local: {
    name: string;
    description?: string;
  };
}[];

const EngagementReportPage = () => {
  const { i18n } = useLingui();
  const params: Record<string, string | undefined> = useParams();
  const { reportId, reportType } = params;
  const currentReportType = reportType as REPORT_TYPES;
  const currentRole = useSelector(getSelectedRole);
  const location = useLocation();

  const [showMenu, setShowMenu] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState<REPORT_CHART_TYPES | null>(null);
  const [enabledOptions, setEnabledOptions] = useState<EOptions[]>([]);
  const [exportOptionArr, setExportOptionArr] = useState<EExportOptions[]>([]);

  const [tabConfiguration, setTabConfiguration] = useState<ITabConfigurationResponse | null>(null);

  const [primaryOptions, setPrimaryOptions] = useState<TOptions[]>([...PRIMARY_OPTIONS]);
  const [secondaryOptions, setSecondaryOptions] = useState<TOptions[]>([...SECONDARY_OPTIONS]);
  const [measureOptions, setMeasureOptions] = useState<TOptions[]>([...MEASURE_OPTIONS]);

  const [primarySelectionData, setPrimarySelectionData] = useState<TOptions[]>([]);
  const [secondarySelectionData, setSecondarySelectionData] = useState<TOptions[]>([]);
  const [measureSelectionData, setMeasureSelectionData] = useState<TOptions[]>([]);

  const [teamsOptions, setTeamsOptions] = useState<TOptions[]>([]);
  const [themesOptions, setThemesOptions] = useState<TOptions[]>([]);
  const [learnedThemesOptions, setLearnedThemesOptions] = useState<TOptions[]>([]);
  const [surveysOptions, setSurveysOptions] = useState<TOptions[]>([]);
  const [jobsOptions, setJobOptions] = useState<TOptions[]>([]);
  const [jobGroupOptions, setJobGroupOptions] = useState<TOptions[]>([]);
  const [genderOptions, setGenderOptions] = useState<TOptions[]>([]);
  const [ageGroupOptions, setAgeGroupOptions] = useState<TOptions[]>([]);
  const [educationLevelOptions, setEducationLevelOptions] = useState<TOptions[]>([]);

  const [primaryRowSelected, setPrimaryRowSelected] = useState<TOptions | null>(null);
  const [secondaryRowSelected, setSecondaryRowSelected] = useState<TOptions | null>(null);
  const [measureRowSelected, setMeasureRowSelected] = useState<TOptions | null>(null);

  const [isHeatmapSelected, setIsHeatmapSelected] = useState(true);
  const [companyAverageSelected, setCompanyAverageSelected] = useState(false);
  const [teamAverageSelected, setTeamAverageSelected] = useState(false);
  const [industryBenchMarkSelected, setIndustryBenchMarkSelected] = useState(false);

  const [monthSelected, setMonthSelected] = useState<string>(MONTH_OPTIONS[0].key);
  const [themesOptionSelected, setThemesOptionSelected] = useState<TOptions[]>([]);
  const [teamsOptionSelected, setTeamsOptionSelected] = useState<TOptions[]>([]);
  const [surveysOptionSelected, setSurveysOptionSelected] = useState<TOptions[]>([]);
  const [jobsSelected, setJobsOptionSelected] = useState<TOptions[]>([]);
  const [jobsGroupsSelected, setJobGroupsSelected] = useState<TOptions[]>([]);
  const [gendersSelected, setGendersSelected] = useState<TOptions[]>([]);
  const [ageGroupSelected, setAgeGroupSelected] = useState<TOptions[]>([]);
  const [educationLevelsSelected, setEducationLevelsSelected] = useState<TOptions[]>([]);

  const { pagination, changePagination } = usePagination(PAGINATION_PRIMARY_OPTIONS[0].id);

  const optionMenu = {
    primary: primaryRowSelected,
    secondary: secondaryRowSelected,
    measure: measureRowSelected,

    includeBenchmark: industryBenchMarkSelected,
    includeCompanyAverage: companyAverageSelected,
    includeTeamAverage: teamAverageSelected,
    isHeatmapColored: isHeatmapSelected,

    primaryOptions,
    secondaryOptions,
    measureOptions,
    surveysOptions,
    themesOptions,
    teamsOptions,
    monthOptions: MONTH_OPTIONS,
    jobsOptions,
    jobGroupOptions,
    genderOptions,
    ageGroupOptions,
    educationLevelOptions,

    monthSelected,
    themesOptionSelected,
    teamsOptionSelected,
    surveysOptionSelected,
    jobsSelected,
    jobsGroupsSelected,
    gendersSelected,
    ageGroupSelected,
    educationLevelsSelected,
  };

  const { addToast } = useToasts();
  const query = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { team, survey } = query;

  const resetSkip = {
    index: 1,
    skip: 0,
    limit: pagination.limit,
  };

  const dimensionSelected = {
    primary: primaryRowSelected,
    secondary: secondaryRowSelected,
    measure: measureRowSelected,
  };

  const filterChangeHandler = (OptionArr: TOptions[], firstKey: string, secondKey?: string) => {
    const filteredOptions = OptionArr.filter(
      (option) => option.key !== firstKey && option.key !== secondKey,
    );
    return filteredOptions;
  };

  const onPrimaryRowsOptionChange = (value: string) => {
    const selectedOption = primaryOptions.find((option) => option.key === value);
    if (selectedOption) {
      setPrimaryRowSelected(selectedOption);
      setSecondaryRowSelected(null);
      setMeasureRowSelected(null);
      setSecondaryOptions(filterChangeHandler(secondarySelectionData, selectedOption.key));
      setMeasureOptions(filterChangeHandler(measureSelectionData, selectedOption.key));
      changePagination({ ...resetSkip });
    }
  };

  const onSecondaryRowsOptionChange = (value: string) => {
    const selectedOption = secondaryOptions.find((option) => option.key === value);
    if (selectedOption) {
      setSecondaryRowSelected(selectedOption);
      setPrimaryOptions(filterChangeHandler(primarySelectionData, selectedOption.key));
      setMeasureOptions(filterChangeHandler(measureSelectionData, selectedOption.key));
      changePagination({
        ...resetSkip,
      });
    }
  };

  const onMeasureRowsOptionChange = (value: string) => {
    const selectedOption = measureOptions.find((option) => option.key === value);
    if (selectedOption) {
      setMeasureRowSelected(selectedOption);
      setSecondaryRowSelected(null);
      setPrimaryOptions(filterChangeHandler(primarySelectionData, selectedOption.key));
      setSecondaryOptions(
        filterChangeHandler(primarySelectionData, selectedOption.key, primaryRowSelected?.key),
      );
      changePagination({
        ...resetSkip,
      });
    }
  };

  const handleOptionInit = (opArr: TOptionData) => {
    const opTempArr = opArr.map((item) => ({
      key: item.id,
      title: (_i18n: I18n) => item.local.name,
      description: (_i18n: I18n) => item.local.description,
    }));
    return opTempArr;
  };

  const getViewAs = (): TViewAs => {
    let viewAs = currentRole as TViewAs;
    if (
      location.pathname ===
      routes.REPORTS_COACH_CUSTOM_REPORT.build(
        // @ts-ignore
        { role: ROLES.USER },
        { reportId: params.reportId, reportType: params.reportType },
      )
    ) {
      viewAs = ROLES.COACH;
    }
    return viewAs;
  };

  const VIEW_ROLE = getViewAs();

  const optionChangeHandler = (type: EOptions, val?: TDropDownValues) => {
    if (type === EOptions.primaryDimension) {
      if (val === null) {
        setPrimaryRowSelected(null);
        setSecondaryRowSelected(null);
        setMeasureRowSelected(null);
        setPrimaryOptions([...PRIMARY_OPTIONS]);
        setSecondaryOptions([...SECONDARY_OPTIONS]);
        setMeasureOptions([...MEASURE_OPTIONS]);
        return;
      }
      onPrimaryRowsOptionChange(val as string);
    } else if (type === EOptions.secondaryDimension) {
      onSecondaryRowsOptionChange(val as string);
    } else if (type === EOptions.measure) {
      onMeasureRowsOptionChange(val as string);
      // Filters -->
    } else if (type === EOptions.timeFrame) {
      setMonthSelected(val as string);
    } else if (type === EOptions.themes) {
      setThemesOptionSelected(val as TOptions[]);
    } else if (type === EOptions.teams) {
      setTeamsOptionSelected(val as TOptions[]);
    } else if (type === EOptions.surveys) {
      setSurveysOptionSelected(val as TOptions[]);
    } else if (type === EOptions.jobs) {
      setJobsOptionSelected(val as TOptions[]);
    } else if (type === EOptions.jobGroups) {
      setJobGroupsSelected(val as TOptions[]);
    } else if (type === EOptions.genders) {
      setGendersSelected(val as TOptions[]);
    } else if (type === EOptions.ageGroups) {
      setAgeGroupSelected(val as TOptions[]);
    } else if (type === EOptions.educationLevels) {
      setEducationLevelsSelected(val as TOptions[]);
    } else if (type === EOptions.includeCompanyAverage) {
      setCompanyAverageSelected((average) => !average);
    } else if (type === EOptions.includeBenchmark) {
      setIndustryBenchMarkSelected((benchmark) => !benchmark);
    } else if (type === EOptions.isHeatmapColored) {
      setIsHeatmapSelected((heatmap) => !heatmap);
    } else if (type === EOptions.reset) {
      setTeamsOptionSelected([]);
      setThemesOptionSelected([]);
      setSurveysOptionSelected([]);

      setAgeGroupSelected([]);
      setGendersSelected([]);
      setJobsOptionSelected([]);
      setJobGroupsSelected([]);
      setEducationLevelsSelected([]);
    } else if (type === EOptions.includeTeamAverage) {
      setTeamAverageSelected((average) => !average);
    }
  };

  const getSelectedFilters = (filterOptionArray: TOptions[], selectedFilterData?: string[]) => {
    return filterOptionArray.filter((item) => selectedFilterData?.includes(item.key));
  };

  const initData = async () => {
    try {
      setIsLoading(true);
      let tempTabsData = null;
      let tempThemesOptions = [] as TOptions[];
      let tempTeamsOptions = [] as TOptions[];
      let tempSurveysOptions = [] as TOptions[];
      let newJobOptions = [] as TOptions[];
      let newJobGroupOptions = [] as TOptions[];
      let newGenderOptions = [] as TOptions[];
      let newAgeGroupOptions = [] as TOptions[];
      let newEducationLevelOptions = [] as TOptions[];
      let primaryOptions = [] as TOptions[];
      let secondaryOptions = [] as TOptions[];
      let measureOptions = [] as TOptions[];

      const tabApiData = await getTabDetails(
        params.reportId || 'default',
        params.reportType || REPORT_TYPES.ENGAGEMENT,
        getViewAs(),
      );

      primaryOptions = tabApiData?.data?.primaryDimension?.options
        ? PRIMARY_OPTIONS.filter((option) =>
            tabApiData?.data?.primaryDimension?.options?.includes(option.key),
          )
        : PRIMARY_OPTIONS;

      secondaryOptions = tabApiData?.data?.secondaryDimension?.options
        ? SECONDARY_OPTIONS.filter((option) =>
            tabApiData?.data?.secondaryDimension?.options?.includes(option.key),
          )
        : SECONDARY_OPTIONS;

      measureOptions = tabApiData?.data?.measure?.options
        ? MEASURE_OPTIONS.filter((option) =>
            tabApiData?.data?.measure?.options?.includes(option.key),
          )
        : MEASURE_OPTIONS;

      const facets = await getFacets(
        [
          'survey',
          'theme',
          'team',
          '_learned_theme',
          'team',
          'age_group',
          'gender',
          'education_level',
          'job',
          'job_group',
        ],
        getViewAs(),
      );

      tempTeamsOptions = handleOptionInit(facets?.data?.teams || []);
      tempThemesOptions = handleOptionInit(facets?.data?.themes || []);
      tempSurveysOptions = handleOptionInit(facets?.data?.surveys || []);
      newJobOptions = handleOptionInit(facets?.data?.jobs || []);
      newJobGroupOptions = handleOptionInit(facets?.data?.jobGroups || []);
      newGenderOptions = handleOptionInit(facets?.data?.genders || []);
      newAgeGroupOptions = handleOptionInit(facets?.data?.ageGroups || []);
      newEducationLevelOptions = handleOptionInit(facets?.data?.educationLevels || []);
      setLearnedThemesOptions(handleOptionInit(facets?.data?.learnedThemes || []));

      tempTabsData = tabApiData.data;

      const selectedTabData = tempTabsData;
      const selectedFilterData = selectedTabData?.filters || {};
      const selectedOptData = selectedTabData?.options || {};
      const enabledFilterArr = Object.entries(selectedFilterData).filter(
        ([_, value]) => value.isEnabled,
      );
      const enabledOptionArr = Object.entries(selectedOptData).filter(
        ([_, value]) => value?.isEnabled,
      );
      const enabledFilterKeys = enabledFilterArr.map(([key]) => key as EOptions);
      const enabledOptionKeys = enabledOptionArr.map(([key]) => key as EOptions);

      const initiatePrimary = PRIMARY_OPTIONS.filter((option) => option.key === 'theme');
      const initiateMeasure = MEASURE_OPTIONS.filter((option) => option.key === 'month');

      const tempPrimaryRowSelected =
        PRIMARY_OPTIONS.filter(
          (option) => option.key === selectedTabData?.primaryDimension?.value,
        )[0] ||
        initiatePrimary[0] ||
        null;

      const tempSecondaryRowSelected =
        SECONDARY_OPTIONS.filter(
          (option) => option.key === selectedTabData?.secondaryDimension?.value,
        )[0] || null;

      const tempMeasureRowSelected =
        MEASURE_OPTIONS.filter((option) => option.key === selectedTabData?.measure?.value)[0] ||
        initiateMeasure[0] ||
        null;

      const tempCompanyAverageSelected = selectedOptData?.includeCompanyAverage?.value || false;
      const tempIndustryBenchMarkSelected = selectedOptData?.includeBenchmark?.value || false;
      const tempIsHeatmapSelected = selectedOptData?.isHeatmapColored?.value || false;
      const tempTeamAverageSelected = selectedOptData?.includeTeamAverage?.value || false;

      const timeFrameEnabled = enabledFilterArr.filter(([key]) => key === 'timeFrame');
      const tempMonthSelected =
        timeFrameEnabled.length > 0 ? `${timeFrameEnabled[0][1].value}` : MONTH_OPTIONS[0].key;

      const tempThemesOptionSelected =
        tempThemesOptions.filter((item) => selectedFilterData?.themes?.value.includes(item.key)) ||
        [];

      const tempTeamsOptionSelected =
        tempTeamsOptions.filter((item) => {
          if (team) {
            return item.key === team;
          }
          return selectedFilterData?.teams?.value.includes(item.key);
        }) || [];

      const tempSurveysOptionSelected =
        tempSurveysOptions.filter((item) => {
          if (survey) {
            return item.key === survey;
          } else {
            return selectedFilterData?.surveys?.value.includes(item.key);
          }
        }) || [];

      setCurrentTab(selectedTabData?.viewType || null);
      setTabConfiguration(selectedTabData);

      // Set Options of Dimensions and Filters

      setEnabledOptions([
        ...enabledFilterKeys,
        ...enabledOptionKeys,
        ...(selectedTabData?.primaryDimension?.isEnabled ? [EOptions.primaryDimension] : []),
        ...(selectedTabData?.secondaryDimension?.isEnabled ? [EOptions.secondaryDimension] : []),
        ...(selectedTabData?.measure?.isEnabled ? [EOptions.measure] : []),
        ...(selectedOptData?.exportOptions?.isEnabled ? [EOptions.exportOptions] : []),
      ]);

      // Set Initial values for Dimensions and Filters
      setPrimaryRowSelected(tempPrimaryRowSelected);
      setSecondaryRowSelected(tempSecondaryRowSelected);
      setMeasureRowSelected(tempMeasureRowSelected);
      setPrimarySelectionData(primaryOptions);
      setSecondarySelectionData(secondaryOptions);
      setMeasureSelectionData(measureOptions);
      setPrimaryOptions(filterChangeHandler(primaryOptions, tempMeasureRowSelected?.key || ''));
      setMeasureOptions(filterChangeHandler(measureOptions, tempPrimaryRowSelected?.key || ''));
      setSecondaryOptions(
        filterChangeHandler(
          secondaryOptions,
          tempPrimaryRowSelected?.key || '',
          tempMeasureRowSelected?.key || '',
        ),
      );
      setCompanyAverageSelected(tempCompanyAverageSelected);
      setTeamAverageSelected(tempTeamAverageSelected);
      setIndustryBenchMarkSelected(tempIndustryBenchMarkSelected);
      setIsHeatmapSelected(tempIsHeatmapSelected);

      setJobGroupOptions(newJobGroupOptions);
      setJobGroupsSelected(
        getSelectedFilters(newJobGroupOptions, selectedFilterData?.jobGroups?.value || []),
      );

      setJobOptions(newJobOptions);
      setJobsOptionSelected(
        getSelectedFilters(newJobOptions, selectedFilterData?.jobs?.value || []),
      );

      setGendersSelected(
        getSelectedFilters(newGenderOptions, selectedFilterData?.genders?.value || []),
      );
      setGenderOptions(newGenderOptions);

      setAgeGroupOptions(newAgeGroupOptions);
      setAgeGroupSelected(
        getSelectedFilters(newAgeGroupOptions, selectedFilterData?.ageGroups?.value || []),
      );

      setEducationLevelOptions(newEducationLevelOptions);
      setEducationLevelsSelected(
        getSelectedFilters(
          newEducationLevelOptions,
          selectedFilterData?.educationLevels?.value || [],
        ),
      );

      setThemesOptions(tempThemesOptions);
      setSurveysOptions(tempSurveysOptions);
      setTeamsOptions(tempTeamsOptions);
      setMonthSelected(tempMonthSelected);
      setThemesOptionSelected(tempThemesOptionSelected);
      setTeamsOptionSelected(tempTeamsOptionSelected);
      setSurveysOptionSelected(tempSurveysOptionSelected);
      setExportOptionArr((selectedOptData?.exportOptions?.value || []) as EExportOptions[]);
      setIsLoading(false);
    } catch (_error) {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while fetching the data. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (reportId) {
      initData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, team, survey]);

  const shouldShowLuca = [ROLES.ADMIN, ROLES.COACH].includes(VIEW_ROLE);

  const shouldBenchmarkDisabled =
    industryBenchMarkSelected &&
    dimensionSelected?.primary?.key === 'theme' &&
    (dimensionSelected?.secondary?.key === 'secondary_none' ||
      dimensionSelected?.secondary?.key === 'primary_none' ||
      !dimensionSelected?.secondary?.key) &&
    (dimensionSelected?.measure?.key === 'month' ||
      dimensionSelected?.measure?.key === 'quarter' ||
      dimensionSelected?.measure?.key === 'year');

  const getTabBody = () => {
    switch (currentTab) {
      case REPORT_CHART_TYPES.BY_MONTH:
        return <Overtime optionChangeHandler={optionChangeHandler} />;
      case REPORT_CHART_TYPES.BY_TWO_DIMENSION:
        return <Custom optionChangeHandler={optionChangeHandler} />;
      case REPORT_CHART_TYPES.BY_ONE_DIMENSION:
        return <Total optionChangeHandler={optionChangeHandler} />;
      default:
        return (
          <LoaderCtr>
            <Loader />
          </LoaderCtr>
        );
    }
  };

  return (
    <EngagementReportContext.Provider
      value={{
        themesOptions,
        primaryRowSelected,
        viewAs: VIEW_ROLE,
        tabConfiguration,
        learnedThemesOptions,
        reportType: currentReportType,
        exportOptionArr,
        enabledOptions,
        shouldShowLuca,
        shouldBenchmarkDisabled,
        dimensions: {
          primary: primaryRowSelected?.key || '',
          secondary: secondaryRowSelected?.key || '',
          measure: measureRowSelected?.key || '',
        },
        filters: {
          monthSelected,
          themesOptionSelected,
          teamsOptionSelected,
          surveysOptionSelected,
          jobsSelected,
          jobsGroupsSelected,
          gendersSelected,
          ageGroupSelected,
          educationLevelsSelected,
        },
        options: {
          includeCompanyAverage: companyAverageSelected,
          includeBenchmark: industryBenchMarkSelected,
          includeTeamAverage: teamAverageSelected,
          isHeatmapColored: isHeatmapSelected,
        },
        isWaiting: isLoading,
        showMenu,
        viewType: currentTab,
        reportId: reportId || null,
      }}
    >
      <BaseLayout maxWidth={'1270px'} noMarginTop>
        {getTabBody()}
        <ControlPanel
          optionMenu={optionMenu}
          isLoading={isLoading}
          showMenu={showMenu}
          onShow={(val) => setShowMenu(val)}
          optionChangeHandler={optionChangeHandler}
          monthSelected={monthSelected}
          enabledOptions={enabledOptions}
          currentTab={currentTab}
        />
      </BaseLayout>
    </EngagementReportContext.Provider>
  );
};

export default withI18n()(EngagementReportPage);
