import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { UseFormReturn } from 'react-hook-form';

import Switch from '~/components/Switch';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import {
  Separator,
  SubSectionWrapper,
  SubTitle,
  ToggleContainer,
  ToggleRow,
  ToggleText,
} from '../design';

import type { GeneralSettingsType, IReviewSelfForm } from '../types';
import type { I18n } from '@lingui/core';

interface GeneralSettingsProps {
  formMethods: UseFormReturn<IReviewSelfForm>;
}

type ToggleItem = {
  key: GeneralSettingsType;
  text: (i18n: I18n) => string;
  tooltip: (i18n: I18n) => string;
};

const TOGGLE_LIST: Array<ToggleItem> = [
  {
    key: 'isDigitalSign',
    text: (i18n: I18n) => i18n._(t`Digitally sign the review reports`),
    tooltip: (i18n: I18n) =>
      i18n._(
        t`Participants receive a notification 7 days before the end date to sign the review report`,
      ),
  },
];

const GeneralSettings = ({ formMethods }: GeneralSettingsProps) => {
  const { watch, setValue } = formMethods;
  const { i18n } = useLingui();

  const onChangeToggle = (key: GeneralSettingsType) => {
    setValue(`settings.${key}`, !watch(`settings.${key}`), { shouldDirty: true });
  };

  return (
    <SubSectionWrapper>
      <SubTitle>
        <Trans>General</Trans>
      </SubTitle>
      <Separator />
      <ToggleContainer>
        {TOGGLE_LIST.map((item) => (
          <ToggleRow key={item.key} marginTop="-10px" marginBottom="-14px">
            <Switch
              disabled={false}
              onChange={() => onChangeToggle(item.key)}
              checked={watch(`settings.${item.key}`)}
            />
            <ToolTip tooltip={item.tooltip(i18n)} placement={TOOLTIP_PLACEMENTS.BOTTOM}>
              <ToggleText>{item.text(i18n)}</ToggleText>
            </ToolTip>
          </ToggleRow>
        ))}
      </ToggleContainer>
    </SubSectionWrapper>
  );
};

export { GeneralSettings };
