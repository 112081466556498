import React, { ReactNode } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { UserAvatar } from '~/components/UserAvatar';
import {
  Card,
  Content,
  Description,
  Footer,
  Header,
  ReviewIntroWrapper,
  Text,
  ButtonContainer,
  StyledCompanyAvatar,
} from '~/pages/ReviewGiveFeedback/components/ReviewIntro.design';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { IMultiLangString, IUser } from '@learned/types';

function ReviewIntro({
  reviewTitle,
  reviewMessage,
  onClose,
  onStart,
  userFrom,
  onDecline,
  companyLogo,
}: {
  reviewTitle: ReactNode;
  reviewMessage: IMultiLangString;
  onClose: () => void;
  onStart: () => void;
  userFrom?: IUser['id'];
  onDecline?: () => void;
  companyLogo?: string;
}) {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  return (
    <ReviewIntroWrapper>
      {companyLogo !== undefined && <StyledCompanyAvatar logoUrl={companyLogo} />}
      <Card>
        <Header>{reviewTitle}</Header>
        <Content>
          {userFrom && (
            <div>
              <Text>
                <Trans>You are requested to provide input for the review of:</Trans>
              </Text>
              <UserAvatar userId={userFrom} />
            </div>
          )}
          {reviewMessage && <Description>{getMultiLangString(reviewMessage)}</Description>}
        </Content>
        <Footer>
          <div>
            {onDecline && (
              <Button
                onClick={onDecline}
                label={i18n._(t`Decline`)}
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.TEXT_DELETE}
              />
            )}
          </div>
          <ButtonContainer>
            <Button
              label={i18n._(t`Start later`)}
              onClick={onClose}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.TEXT_PRIMARY}
            />
            <Button
              label={i18n._(t`Start now`)}
              onClick={onStart}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.PRIMARY}
            />
          </ButtonContainer>
        </Footer>
      </Card>
    </ReviewIntroWrapper>
  );
}

export { ReviewIntro };
