import React, { useEffect, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import nanoid from 'nanoid';
import qs from 'qs';
import { useForm } from 'react-hook-form';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import { useFromQuery } from '~/hooks/useFromQuery';
import { useLanguageState } from '~/hooks/useLanguageState';
import { createSkill } from '~/services/skills';
import { getSkillTemplate } from '~/services/skillTemplates';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';

import { SkillForm } from '../SkillForm';
import { useSkillCategories } from '../SkillForm/hooks';
import { removeEmptyValues } from '../SkillForm/utils';
import { resolver } from '../SkillForm/validations';

import type { IGeneralForm } from '../SkillForm/types';
import type { IJobProfile, ISkillCategory, ISkillTemplate } from '@learned/types';

function SkillCreate() {
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });
  const { addToast } = useToasts();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { skillCategories } = useSkillCategories();

  const [skillCategory, setSkillCategory] = useState<ISkillCategory>();
  const [skillTemplate, setSkillTemplate] = useState<ISkillTemplate>();
  const languageState = useLanguageState();
  const generalFormMethods = useForm<IGeneralForm>({
    mode: 'all',
    resolver,
    defaultValues: {
      name: languageState.companyLanguages.map(({ locale }) => ({ locale, value: '' })),
      description: languageState.companyLanguages.map(({ locale }) => ({ locale, value: '' })),
      focusAreas: new Array(5).fill(0).map((_, index) => ({
        values: [],
        level: index,
      })),
      jobProfiles: new Array(5)
        .fill(0)
        .reduce((acc: Record<number, IJobProfile[]>, _, index) => ({ ...acc, [index]: [] }), {}),
    },
  });

  const { watch } = generalFormMethods;

  const createFromSkillTemplateId = query?.createFromSkillTemplateId as string | undefined;
  const skillName =
    watch('name').find(({ locale }) => locale === languageState.companyPrimaryLanguage.locale)
      ?.value || watch('name').find(({ value }) => value)?.value;
  const title = i18n._(t`Create a skill: ${skillName}`);

  useEffect(() => {
    let mounted = true;
    const fetchSkillTemplate = async () => {
      if (!createFromSkillTemplateId) {
        return;
      }

      const result = await getSkillTemplate(createFromSkillTemplateId);

      const skillTemplate = result.data.skillTemplate as ISkillTemplate;
      if (mounted && result.code === 200) {
        setSkillTemplate(skillTemplate);
      }
    };

    fetchSkillTemplate();

    return () => void (mounted = false);
  }, [createFromSkillTemplateId]);

  useEffect(() => {
    const preSelectedSkillCategory = skillCategories.find(
      (item) => item.skillCategoryTemplate === skillTemplate?.skillCategory,
    );
    setSkillCategory(preSelectedSkillCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillTemplate, skillCategories]);

  useEffect(() => {
    if (!skillCategory) {
      return;
    }

    const focusAreas: IGeneralForm['focusAreas'] | undefined = skillCategory?.skillLevels.map(
      (_, index) => ({
        level: index,
        values: [],
      }),
    );
    skillTemplate?.focusAreas.forEach((focusAreaFromTemplate) => {
      const focusArea = focusAreas?.find((area) => area.level === focusAreaFromTemplate.level);
      focusAreaFromTemplate.values.forEach((value) => {
        focusArea?.values.push({ ...value, _id: nanoid() });
      });
    });

    const previousName = generalFormMethods.getValues('name');
    const previousDescription = generalFormMethods.getValues('description');

    generalFormMethods.reset({
      name: languageState.companyLanguages.map(({ locale }) => ({
        locale,
        value:
          previousName.find((value) => value.locale === locale)?.value ??
          skillTemplate?.name[locale] ??
          '',
      })),
      description: languageState.companyLanguages.map(({ locale }) => ({
        locale,
        value:
          previousDescription.find((value) => value.locale === locale)?.value ??
          skillTemplate?.description[locale] ??
          '',
      })),
      focusAreas,
      jobProfiles: skillCategory?.skillLevels.reduce(
        (acc: Record<number, IJobProfile[]>, _, index) => ({ ...acc, [index]: [] }),
        {},
      ),
      skillCategory: skillCategory?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillCategory, skillTemplate]);

  const onSubmit = async (data: IGeneralForm & { published: boolean }) => {
    const predicate = ({ value, locale }: { value: string; locale: string }) =>
      value !== '' || locale === '_id';
    const transformedData = {
      ...data,
      skillTemplate: skillTemplate?.id,
      name: convertLanguageValue(data.name.filter(predicate)),
      description: convertLanguageValue(data.description.filter(predicate)),
      jobProfiles: Object.entries(data.jobProfiles).reduce((acc, [key, value]) => {
        return { ...acc, [key]: value.map((jp) => jp.id) };
      }, {}),
      focusAreas: data.focusAreas
        .map(({ values, level }) => ({
          values: values.map(({ name }) => ({
            name: removeEmptyValues(name),
          })),
          level,
        }))
        .slice(0, skillCategory?.skillLevels?.length ?? 5),
    };

    const result = await createSkill(transformedData, transformedData.jobProfiles);

    if (result.code === 200 && result.data.skill.published === true) {
      addToast({
        title: i18n._(t`Success`),
        subtitle: i18n._(t`Skill published`),
        type: TOAST_TYPES.SUCCESS,
      });
      goBack();
    } else if (result.code === 200 && result.data.skill.published === false) {
      addToast({
        title: i18n._(t`Success`),
        subtitle: i18n._(t`Skill saved as draft`),
        type: TOAST_TYPES.SUCCESS,
      });
      goBack();
    }
  };

  return (
    <SkillForm
      title={title}
      formMethods={generalFormMethods}
      languageState={languageState}
      onSubmit={onSubmit}
      goBack={goBack}
      skillCategory={skillCategory}
      skillCategories={skillCategories}
      setSkillCategory={setSkillCategory}
      skillTemplate={skillTemplate}
      onDelete={async () => {
        if (
          await confirm({
            type: CONFIRMATION_MODAL_TYPE.DELETE,
            title: i18n._(t`Delete?`),
            description: i18n._(
              t`Are you sure you want to delete the skill? This cannot be undone.`,
            ),
          })
        ) {
          goBack();
        }
      }}
    />
  );
}

export { SkillCreate };
