import React from 'react';

import { t } from '@lingui/macro';
import find from 'lodash/find';

import { NameRow } from './design';

import type { IColumnTable } from '~/@types/table';

import type { ISkillCategoryColumn } from '../SuperAdminJobTemplateForm/types';
import type { I18n } from '@lingui/core';

export const SKILL_CATEGORY_COLUMNS: IColumnTable<ISkillCategoryColumn>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Skills`),
    accessor: 'name',
    maxWidth: '200px',
    renderCell: ({ name }) => {
      return name ? <NameRow maxWidth="200px">{name.en_GB || find(name)}</NameRow> : <></>;
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Focus Area`),
    accessor: 'focusArea',
    renderCell: ({ focusArea }) => {
      return <NameRow>{focusArea.en_GB || find(focusArea)}</NameRow>;
    },
  },
];
