import React from 'react';

import { CommonReportsDashboard } from '../CommonReportsDashboard.tsx';
import { PAGE_TYPE } from '../CommonReportsDashboard.tsx/types';

const AdminDashboard = () => {
  return <CommonReportsDashboard type={PAGE_TYPE.ADMIN} />;
};

export { AdminDashboard };
