import React, { useState } from 'react';

import { TASK_STATUS } from '@learned/constants';
import { Trans } from '@lingui/macro';

import { Button, ButtonVariant } from '~/components/Buttons';
import { DashboardHeader } from '~/components/DashboardHeader';
import { MultiLangComponent } from '~/components/Dropdown/MultiLangualDropdown';
import { ICONS } from '~/components/Icon';
import { LastSaved } from '~/components/LastSaved';
import { useReviewRatingsState } from '~/pages/ReviewGiveFeedback/components/GiveReview.hooks';
import { ActionItemBlock, ActionsWrap } from '~/pages/ReviewThemeView/design';

import { Container } from './GiveReview.design';
import { QuestionsView } from './QuestionsView';
import { ReviewIntro } from './ReviewIntro';

import { useFromQuery } from '~/hooks/useFromQuery';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { IPopulatedReviewTask, IPopulatedUserReview } from '../types';

function GiveReview({
  reviewTask,
  userReview,
  introTitle,
  companyLogo,
}: {
  reviewTask: IPopulatedReviewTask;
  userReview: IPopulatedUserReview;
  introTitle: string;
  companyLogo?: string;
}) {
  const { goBack } = useFromQuery({ includeHash: true });
  const getMultiLangString = useMultiLangString();

  const [showIntro, setShowIntro] = useState(true);
  const showingIntro = reviewTask && userReview && showIntro;
  const reviewRatingsState = useReviewRatingsState({ reviewTask, userReview });
  const { autoSaveState, languageState, onSave, onPublish } = reviewRatingsState;

  return (
    <>
      <DashboardHeader
        onBack={goBack}
        title={userReview?.name && getMultiLangString(userReview.name)}
        actions={
          !showingIntro && (
            <ActionsWrap>
              <LastSaved
                time={autoSaveState.state.time}
                status={autoSaveState.state.status}
                errorMessage={autoSaveState.state.message}
              />
              <ActionItemBlock>
                <MultiLangComponent languageState={languageState} isSingleSelect />
              </ActionItemBlock>
              {reviewTask.status !== TASK_STATUS.COMPLETED && (
                <ActionItemBlock>
                  <Button onClick={onSave} variant={ButtonVariant.ICON} icon={ICONS.SAVE} />
                </ActionItemBlock>
              )}
              <ActionItemBlock>
                <Button
                  onClick={onPublish}
                  variant={ButtonVariant.PRIMARY}
                  label={
                    reviewTask.status !== TASK_STATUS.COMPLETED ? (
                      <Trans>Publish</Trans>
                    ) : (
                      <Trans>Save</Trans>
                    )
                  }
                />
              </ActionItemBlock>
            </ActionsWrap>
          )
        }
      />
      <Container>
        {showingIntro && (
          <ReviewIntro
            onClose={() => goBack()}
            onStart={() => setShowIntro(false)}
            reviewTitle={introTitle}
            reviewMessage={userReview.description}
            companyLogo={companyLogo}
          />
        )}
        {!showingIntro && <QuestionsView reviewRatingsState={reviewRatingsState} />}
      </Container>
    </>
  );
}

export { GiveReview };
