import { t } from '@lingui/macro';

export default function getIntegrationName(integration, i18n) {
  const lowerCaseExternalSoftware = integration.externalSoftware.toLowerCase();
  switch (lowerCaseExternalSoftware) {
    case 'azure_ad':
    case 'azure-ad':
    case 'azuread':
    case 'azure_active_directory':
    case 'azure-active-directory':
      return i18n._(t`Azure AD`);
    case 'afas':
    case 'afas_online':
    case 'afas-online':
      return i18n._(t`AFAS`);
    case 'nmbrs':
    case 'nmbr':
      return i18n._(t`NMBRS`);
    case 'exact':
    case 'exact-online':
    case 'exact_online':
      return i18n._(t`Exact`);
    case 'sdworx':
      return i18n._(t`SDWORX`);
    default:
      return integration.name;
  }
}
