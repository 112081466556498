import React, { useState, useEffect } from 'react';

import { SKILL_CATEGORIES_ID } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import AddSkillModalNew from '~/components/AddSkillModalNew';
import Button from '~/components/Button';
import Modal from '~/components/Modal';

import Section from './Section';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getCurrentCompanyId } from '~/selectors/baseGetters';
import { getSkillsById } from '~/services/skills';

const ButtonsContainer = styled.div`
  display: flex;
`;

const SectionsWrapper = styled.div`
  max-height: 360px;
  overflow: auto;
`;
function ManageSkillsModal({
  populatedSkills = {}, // all company skills
  onClose,
  onSave,
  loading,
  currentSkillsHidden = [], // user hidden skills from user.skillsHidden
  currentManualAddedSkills = [], // user skills from user.skills
  categories = [], // populdated company categories without skills
  populatedUserSkills = {}, // skillsOrder with populated skills
}) {
  const { i18n } = useLingui();
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const getMultiLangString = useMultiLangString();

  const [userSkills, setUserSkills] = useState(populatedUserSkills);
  const [skillsHidden, setSkillsHidden] = useState(currentSkillsHidden);
  const [manualAddedSkills, setManualAddedSkills] = useState(currentManualAddedSkills);
  const [sections, setSections] = useState({}); // populated ordered categories with populated ordered skills

  // modals
  const $isAddSkillsModal = useBoolState(false);

  useEffect(() => {
    const getData = async () => {
      let manualAddedSkillsData = {};
      if (manualAddedSkills) {
        const response = await getSkillsById(manualAddedSkills);
        manualAddedSkillsData = groupBy(response, (item) => item.skillCategory);
      }
      const newSection = {};
      categories.forEach((category) => {
        const name = getMultiLangString(category.name);
        const categorySkills = userSkills[category.id] || [];
        const categorySkillsManual = manualAddedSkillsData[category.id] || [];
        newSection[name] = {
          id: category.id,
          categoryId: category?.categoryId || null,
          title: name,
          skills: [...categorySkills, ...categorySkillsManual],
        };
      });
      setSections(newSection);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify({
      currentCompanyId,
      categories,
      populatedSkills,
      userSkills,
      getMultiLangString,
      manualAddedSkills,
    }),
  ]);

  const onItemDrag = (result) => {
    const { destination, source } = result;
    if (!userSkills[destination?.droppableId]) {
      return;
    } // category doesn't exist

    const newSkillsOrder = { ...userSkills };
    // update skills order inside a category
    let updatedSkills = [...newSkillsOrder[destination.droppableId]];
    const [value] = updatedSkills.splice(source.index, 1); // delete from old position
    updatedSkills.splice(destination.index, 0, value); // insert into new position
    newSkillsOrder[destination.droppableId] = updatedSkills;

    setUserSkills(newSkillsOrder);
  };

  const onChangeHiddenSkills = (skillId) => {
    if (skillsHidden.includes(skillId)) {
      setSkillsHidden(skillsHidden.filter((s) => s !== skillId));
    } else {
      setSkillsHidden([...skillsHidden, skillId]);
    }
  };

  const onAddSkill = (e) => {
    let newSkillsOrder = { ...userSkills };
    e.selectedSkills.forEach((s) => {
      const populatedSkill = populatedSkills[s.id];
      const skillCategories = populatedSkill?.categories;
      if (!isEmpty(skillCategories)) {
        skillCategories.forEach((c) => {
          if ({}.hasOwnProperty.call(newSkillsOrder, c)) {
            newSkillsOrder[c] = [...newSkillsOrder[c], populatedSkill];
          } else if (c === SKILL_CATEGORIES_ID.INTERPERSONAL_SKILLS) {
            // learned skills
            // TODO this won't work anymore after migration but don't know how to fix it
            const softCategoryId = categories.find(
              (category) => category.categoryId === SKILL_CATEGORIES_ID.INTERPERSONAL_SKILLS,
            )?.id;
            if (!isEmpty(softCategoryId)) {
              if ({}.hasOwnProperty.call(newSkillsOrder, softCategoryId)) {
                newSkillsOrder[softCategoryId] = [
                  ...newSkillsOrder[softCategoryId],
                  populatedSkill,
                ];
              } else {
                newSkillsOrder[softCategoryId] = [populatedSkill];
              }
            }
          } else {
            newSkillsOrder[c] = [populatedSkill];
          }
        });
      }
    });
    setUserSkills(newSkillsOrder);
    const newSkillsIds = e.selectedSkills.map((s) => s.id).filter((s) => s);
    setManualAddedSkills([...manualAddedSkills, ...newSkillsIds]);
  };

  const onDeleteSkill = (skillId) => {
    // user can only delete the manually added skills
    if (!manualAddedSkills.includes(skillId)) {
      return;
    }

    let newSkillsOrder = {};
    for (const category in userSkills) {
      if ({}.hasOwnProperty.call(userSkills, category)) {
        if (userSkills[category].some((s) => s.id === skillId)) {
          newSkillsOrder[category] = userSkills[category].filter((s) => s.id !== skillId);
        } else {
          newSkillsOrder[category] = [...userSkills[category]];
        }
      }
    }
    setUserSkills(newSkillsOrder);
    setManualAddedSkills(manualAddedSkills.filter((skill) => skill !== skillId));
  };

  const onSubmit = () => {
    // prepare skillsOrder
    let newSkillsOrder = {};
    for (const category in userSkills) {
      if ({}.hasOwnProperty.call(userSkills, category)) {
        newSkillsOrder[category] = userSkills[category].map((s) => s.id).filter((s) => s);
      }
    }

    return onSave({
      skills: manualAddedSkills,
      skillsHidden,
      skillsOrder: newSkillsOrder,
    });
  };

  return (
    <Modal
      title={i18n._(t`Edit skills`)}
      onClose={onClose}
      width={750}
      minWidth={750}
      height={556}
      showDivider={false}
      greyDescription={i18n._(t`Adjust the skills that are visible in your profile`)}
      contentStyles={{ padding: '28px 24px 0' }}
      headerStyles={{ padding: '0 24px' }}
      footerStyles={{ padding: '24px', marginTop: '16px' }}
      greyDescriptionStyles={{ padding: '0 24px', marginTop: '-10px' }}
      footerRight={
        <ButtonsContainer>
          <Button
            label={i18n._(t`Add skills`)}
            onClick={$isAddSkillsModal.on}
            marginRight={12}
            type="primary-border"
            loading={loading}
          />
          <Button label={i18n._(t`Save`)} loading={loading} onClick={onSubmit} width={80} />
        </ButtonsContainer>
      }
    >
      <SectionsWrapper>
        {Object.values(sections).map((section) => (
          <Section
            key={section.id}
            section={section}
            onItemDelete={(skillId) => {
              onDeleteSkill(skillId);
            }}
            onItemDrag={onItemDrag}
            skillsHidden={skillsHidden}
            onChangeHiddenSkills={onChangeHiddenSkills}
            manualAddedSkills={manualAddedSkills}
          />
        ))}
      </SectionsWrapper>
      {$isAddSkillsModal.value && (
        <AddSkillModalNew
          withoutLevels={true}
          canSaveEmpty={true}
          onAddSkill={onAddSkill}
          onModalClose={$isAddSkillsModal.off}
          hiddenSkills={uniqBy(Object.values(userSkills).flat(1), 'id')}
          isShowPreloadedSkills
        />
      )}
    </Modal>
  );
}

ManageSkillsModal.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  loading: PropTypes.bool,
  skillsHidden: PropTypes.arrayOf(PropTypes.string),
  manualAddedSkills: PropTypes.arrayOf(PropTypes.string),
};

ManageSkillsModal.defaultProps = {
  skillsHidden: [],
  manualAddedSkills: [],
};

export default ManageSkillsModal;
