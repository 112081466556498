import React, { useEffect } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { type UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

import { SearchSelect } from '~/components/SearchSelect';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import { UserAvatar } from '~/components/UserAvatar';

import { StepFooter } from './components/StepFooter';
import { Form, Title, Header, ParticipantSection, UserAvatarWrapper, Label } from './design';
import { useEditEmployee } from './hooks/useEditEmployee';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import getUserFullName from '~/utils/getUserFullName';

import type { IReviewSelfForm } from './types';

interface StepParticipantsProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewSelfForm>;
}

const Wrapper = styled.div`
  padding-bottom: 2px;
  margin-bottom: -4px;
`;

const StepParticipants = ({ sectionState, formMethods }: StepParticipantsProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const { watch, setValue } = formMethods;
  const watchEmployees = watch('employees');
  const { formMethods: employeeFormMethods } = useEditEmployee({
    employee: watchEmployees[0],
  });

  useEffect(() => {
    const formData = employeeFormMethods.getValues();
    const selectedData = {
      ...watchEmployees[0],
      careerPlans: formData.careerPlans.selected,
      coaches: formData.coaches.selected?.map((item) => item.id),
      guests: formData.guests.selected?.map((item) => item.id),
    };

    setValue('employees', [selectedData], { shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(employeeFormMethods?.getValues())]);

  return (
    <>
      <Form>
        <Header>
          <Title>
            <Trans>Select job and participants</Trans>
          </Title>
        </Header>

        <Wrapper>
          <ParticipantSection>
            <div className="title">
              <Trans>Employee</Trans>
            </div>
            <UserAvatarWrapper>
              <UserAvatar user={watchEmployees[0]} />
            </UserAvatarWrapper>
          </ParticipantSection>
          <ParticipantSection>
            <div className="title">
              <Trans>Jobs</Trans>
            </div>
            <div className="inputWrapper">
              <Label>
                <Trans>Select job(s) to review</Trans>
              </Label>
              <SearchSelect
                selectedItems={employeeFormMethods.watch('careerPlans.selected')}
                onChange={(selected) =>
                  employeeFormMethods.setValue('careerPlans.selected', selected)
                }
                onSearchChange={(search) =>
                  employeeFormMethods.setValue('careerPlans.search', search)
                }
                items={employeeFormMethods.watch('careerPlans.filtered')}
                stringifyItem={(careerPlan) => getMultiLangString(careerPlan.jobProfile.name)}
                placeholder={i18n._(t`Search job(s)`)}
                isExpandable
              />
            </div>
          </ParticipantSection>
          <ParticipantSection>
            <div className="title">
              <Trans>Coaches</Trans>
            </div>
            <div className="inputWrapper">
              <Label>
                <Trans>Select coach(es) who will provide</Trans>
              </Label>
              <SearchSelect
                selectedItems={employeeFormMethods.watch('coaches.selected')}
                onChange={(selected) => employeeFormMethods.setValue('coaches.selected', selected)}
                onSearchChange={(search) => employeeFormMethods.setValue('coaches.search', search)}
                items={employeeFormMethods.watch('coaches.filtered')}
                stringifyItem={(user) => getUserFullName(user)}
                placeholder={i18n._(t`Search coach(es)`)}
                isExpandable
                showAvatar
              />
            </div>
          </ParticipantSection>
          <ParticipantSection>
            <div className="title">
              <Trans>Guests</Trans>
            </div>
            <div className="inputWrapper">
              <Label>
                <Trans>Select guests invited to the review</Trans>
              </Label>
              <SearchSelect
                selectedItems={employeeFormMethods.watch('guests.selected')}
                onChange={(selected) => employeeFormMethods.setValue('guests.selected', selected)}
                onSearchChange={(search) => employeeFormMethods.setValue('guests.search', search)}
                items={employeeFormMethods.watch('guests.filtered')}
                stringifyItem={(user) => getUserFullName(user)}
                placeholder={i18n._(t`Search guests`)}
                minCharsToShowDropdown={3}
                isExpandable
                showAvatar
              />
            </div>
          </ParticipantSection>
        </Wrapper>

        <StepFooter
          onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
          onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
        />
      </Form>
    </>
  );
};

export { StepParticipants };
