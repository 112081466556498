import React from 'react';

import { TableOfContents } from '~/components/TableOfContents';

import { Footer } from './Footer';
import { MainView, QuestionWrapper, TableAndQuestions } from './QuestionsView.design';
import { QuestionView } from './QuestionView';

import { ReturnTypeUseReviewRatingsState } from '../components/GiveReview.hooks';

function QuestionsView({
  reviewRatingsState,
}: {
  reviewRatingsState: ReturnTypeUseReviewRatingsState;
}) {
  const {
    hasError: _hasError,
    hasCommentError: _hasCommentError,
    sectionState,
    showOutro,
    nextSection,
    previousSection,
    setShowOutro,
    languageState,
    formData,
    onChange,
  } = reviewRatingsState;

  return (
    <MainView>
      <TableAndQuestions>
        <TableOfContents
          sections={sectionState.sections}
          currentSection={sectionState.currentSection}
          hideErrorState={!sectionState.triedToSubmit}
          setCurrentSection={(i) => {
            sectionState.setCurrentSection(i);
            setShowOutro(false);
          }}
          noNextSection={showOutro}
          nextSection={nextSection}
          previousSection={previousSection}
        />
        <QuestionWrapper>
          {sectionState.sections.at(sectionState.currentSection)?.data && (
            <QuestionView
              questionData={sectionState.sections.at(sectionState.currentSection)!.data!}
              languageState={languageState}
              ratings={formData.getValues('ratings')}
              onChange={(data) => onChange(data, sectionState.currentSection)}
            />
          )}
        </QuestionWrapper>
      </TableAndQuestions>
      <Footer onNext={nextSection} onBack={previousSection} />
    </MainView>
  );
}

export { QuestionsView };
