/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import { REVIEW_QUESTION_EVALUATORS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';

import { DateInput } from '~/components/DateInput';
import { ICONS, Icon } from '~/components/Icon';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import Switch from '~/components/Switch';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import { NominatePeersSection } from './components/NominatePeersSection';
import { TasksSection } from './components/TasksSection';
import { AdvancedSettingsWrapper, DateRow } from './design';

import { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { SubTitle, ToggleRow, ToggleText, Form, Title } from '../../design';
import { StepFooter } from '../StepFooter';

import type { IReviewSelfForm } from '../../types';
import type { UseFormReturn } from 'react-hook-form';

interface TimelineSectionProps {
  formMethods: UseFormReturn<IReviewSelfForm>;
  sectionState: ISectionState;
  languageState: ILanguageStateReturn;
  onPublish: () => void;
  resetGeneratedDates: () => void;
}

const TimelineSection = ({
  formMethods,
  sectionState,
  languageState,
  onPublish,
  resetGeneratedDates,
}: TimelineSectionProps) => {
  const [isAdvanceSettingsVisible, setIsAdvanceSettingsVisible] = useState(false);
  const { watch, setValue, formState, trigger } = formMethods;
  const { i18n } = useLingui();
  const startDate = watch('settings.startDate');
  const endDate = watch('settings.endDate');

  const onChangeStartDate = (date: Date, isStartDate?: boolean) => {
    const key = isStartDate ? 'settings.startDate' : 'settings.endDate';
    setValue(key, date, { shouldDirty: true });
    formState.errors?.settings?.startDate || formState.errors?.settings?.endDate
      ? trigger('settings')
      : trigger(key);
  };

  const getFormattedDateToString = (date: Date | null) => {
    const datePattern = 'DD-MM-YYYY';

    if (date) {
      return moment(date).format(datePattern);
    }

    return '';
  };

  const onToggleChange = (value: boolean) => {
    setValue('tasks.isAutoGenerate', value, { shouldDirty: true });
    if (!value) {
      resetGeneratedDates();
    }
  };
  const evaluators = watch('evaluators').map(
    (evaluator: { value: REVIEW_QUESTION_EVALUATORS; icon: ICONS; title: string }) =>
      evaluator.value,
  );
  return (
    <Form>
      <Title>
        <Trans>Timeline</Trans>
      </Title>
      <SubTitle>
        <Trans>Start and end date of the review cycle</Trans>
      </SubTitle>
      <DateRow marginTop="8px" marginBottom="16px">
        <DateInput
          value={getFormattedDateToString(startDate)}
          label={i18n._(t`Start date`)}
          onChange={(date) => onChangeStartDate(date as Date, true)}
          error={formState.errors?.settings?.startDate?.message}
        />
        <DateInput
          value={getFormattedDateToString(endDate)}
          label={i18n._(t`Deadline`)}
          onChange={(date) => onChangeStartDate(date as Date)}
          error={formState.errors?.settings?.endDate?.message}
        />
      </DateRow>
      <AdvancedSettingsWrapper isCollapsed={isAdvanceSettingsVisible}>
        <div
          className="head"
          onClick={() => setIsAdvanceSettingsVisible((prevState) => !prevState)}
        >
          <div className="title">
            <Trans>Advanced settings</Trans>
          </div>
          <div className="icon">
            <Icon icon={ICONS.BACK} />
          </div>
        </div>
        <div className="content">
          <ToggleRow marginBottom="-4px" marginTop="16px">
            <Switch onChange={onToggleChange} checked={watch('tasks.isAutoGenerate')} />
            <ToolTip
              tooltip={i18n._(t`Show recommended timeline`)}
              placement={TOOLTIP_PLACEMENTS.BOTTOM}
            >
              <ToggleText>
                <Trans>Show recommended timeline</Trans>
              </ToggleText>
            </ToolTip>
          </ToggleRow>
          {evaluators.includes(REVIEW_QUESTION_EVALUATORS.PEER) && (
            <NominatePeersSection formMethods={formMethods} languageState={languageState} />
          )}
          <TasksSection formMethods={formMethods} evaluators={evaluators} />
          <StepFooter
            isNext={false}
            onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
            isPublish
            // TODO: future tasks
            onPublish={onPublish}
            hideSeparator
          />
        </div>
      </AdvancedSettingsWrapper>
    </Form>
  );
};

export { TimelineSection };
