import { ROLES } from '~/constants';
import { pageOverview } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'settings';

// overview
export const SETTINGS = new LinkConstructor([ROLES.USER, ROLES.ADMIN], () =>
  pageOverview(`company-${routeName}`),
);

// security settings
export const SETTINGS_INTEGRATIONS = new LinkConstructor([ROLES.ADMIN], () =>
  pageOverview('company-integrations'),
);

// module settings
export const SETTINGS_MODULES = new LinkConstructor([ROLES.ADMIN], () => pageOverview('billing'));
