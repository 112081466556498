import { serverRoutes } from '~/server_config';

import cloudRequest from '../cloudRequest';

/**
 *
 * @param [filters]
 * @param [filters.taskStatus] - get tasks according to active tab 'active/completed'
 * @param [filters.limit] - max return items count
 */

export function getTasks(filters = { taskStatus: '', limit: 0 }) {
  return cloudRequest(serverRoutes.tasks.getTasks, filters);
}

export function getReviewTask(reviewTaskId) {
  return cloudRequest(serverRoutes.tasks.getReviewTask(reviewTaskId));
}
