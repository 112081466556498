import styled from 'styled-components';

import { COLORS } from '~/styles';

const AreaTitle = styled.div<{ color?: string; padding?: string }>`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${({ color }) => (color ? color : COLORS.TEXT_HOVER)};
  padding: ${({ padding }) => (padding ? padding : '0 0 4px 0')};
`;

export { AreaTitle };
