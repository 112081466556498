import React, { useEffect, useMemo, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, SKILL_SORTING } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isObject } from 'lodash';
import moment from 'moment';

import { ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TableList } from '~/components/TableList';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import { createSkillColumns } from './AllSkills.columns';
import { Wrapper } from './AllSkills.design';
import { DropDownCtr, StyledDropdown } from './SkillMatrixCommon.design';

import routes from '~/constants/routes';
import useDebounce from '~/hooks/useDebounce';
import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getSkillCategories } from '~/services/skillCategories';
import { deleteSkills, downloadSkillsCSV, getSkills } from '~/services/skills';
import { getUsers } from '~/services/users';

import type {
  IEditors,
  INewSkill,
  ISkills,
  TCategoriesObj,
  TSkillsObj,
  TMultiSelect,
} from '../types';
import type { ISkill, IUser } from '@learned/types';

type IFilter = {
  search: string;
  sortBy: SKILL_SORTING;
  pagination: { skip: number; limit: number; index: number };
  categoryFilters: TMultiSelect[];
};

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };

const initialFilters: IFilter = {
  search: '',
  sortBy: SKILL_SORTING.NAME_A_Z,
  pagination: DEFAULT_PAGINATION,
  categoryFilters: [],
};

const AllSkills = () => {
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const getMultiLangString = useMultiLangString();
  const [totalCount, setTotalCount] = useState<number>();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [skillData, setSkillData] = useState<ISkills[]>([]);
  const [loading, setLoading] = useState(true);
  const languageState = useLanguageState();
  const primaryLanguage = languageState.companyPrimaryLanguage;
  const [options, setOptions] = useState<TMultiSelect[]>([]);
  const [currentFilters, setCurrentFilters] = useState<IFilter>(initialFilters);
  const debCurrentFilters = useDebounce(currentFilters, 500);
  const filters = {
    search: currentFilters.search,
    setSearch: (value: string) => {
      setCurrentFilters({ ...currentFilters, search: value, pagination: DEFAULT_PAGINATION });
    },
  };

  const getSkillCategoryData = (skillCategories: TCategoriesObj, skillId: string) => {
    const filteredCategories = Object.values(skillCategories).filter((item) => item.id === skillId);
    return filteredCategories.length > 0 ? filteredCategories[0] : null;
  };

  const getEditors = (editors: IEditors[], users: IUser) => {
    const tempEditors = editors?.map((editor) => editor.user) || [];
    const tempUser = Object.values(users)
      .filter((a) => tempEditors.includes(a.id))
      .map((user) => user.avatarUrl || null);
    return tempUser;
  };

  const onGetSkills = async () => {
    try {
      setLoading(true);
      const categoryArray = currentFilters.categoryFilters.map((category) => category.key);
      const [skillsResponse, skillCategories, users] = await Promise.all([
        getSkills({
          search: currentFilters.search,
          sortBy: currentFilters.sortBy,
          limit: currentFilters.pagination.limit,
          skip: currentFilters.pagination.skip,
          // @ts-ignore
          categories: categoryArray,
        }) as Promise<{ data: { skills: TSkillsObj; total: number } }>,
        getSkillCategories() as Promise<TCategoriesObj>,
        getUsers() as Promise<IUser>,
      ]);
      const skills = skillsResponse?.data?.skills;

      const skillDataObj = Object.entries(skills).map(([id, skill]: [string, INewSkill]) => {
        return {
          id,
          company: skill?.company || null,
          name: isObject(skill?.name) ? skill?.name : { en_GB: (skill?.name || '') as string },
          category: getSkillCategoryData(skillCategories, skill?.skillCategory as string),
          editors: getEditors(skill?.editors || [], users),
          last_updated: skill?.meta?.lastModifiedDate
            ? moment(skill?.meta?.lastModifiedDate).format('DD-MM-YYYY | HH:mm')
            : '',
        };
      });

      setSkillData(skillDataObj);
      const categoryOptions = Object.values(skillCategories).map((category) => ({
        key: category.id,
        title: category.name,
      }));
      if (options.length === 0) {
        setOptions(categoryOptions);
      }
      setTotalCount(skillsResponse?.data?.total || 0);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const isAllSelected = skillData.every((skill) => {
    return selectedItems.includes(skill.id);
  });

  const onSelectAll = () => {
    const itemsToSelect = isAllSelected ? [] : skillData.map((skill) => skill.id);
    setSelectedItems(itemsToSelect);
  };

  const refreshData = async () => {
    setSkillData([]);
    setSelectedItems([]);
    await onGetSkills();
  };

  const onDeleteItem = async (item: ISkills) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete?`),
      description: i18n._(t`Are you sure you want to delete the skill? This cannot be undone.`),
    });
    if (isConfirmed) {
      try {
        setLoading(true);
        setSkillData([]);
        setSelectedItems([]);
        await deleteSkills([item?.id]);
        addToast({
          title: i18n._(t`Succesfully deleted`),
          type: TOAST_TYPES.INFO,
        });
        await refreshData();
      } catch (error) {
        addToast({
          title: i18n._(t`Oops... Something went wrong`),
          subtitle: i18n._(t`Try again later!`),
          type: TOAST_TYPES.ERROR,
        });
        setLoading(false);
      }
    }
  };

  const createMenuItems = (item: ISkills) => {
    return [
      {
        label: i18n._(t`Edit`),
        action: () => {
          routes.SKILL_EDIT.go({}, { skillId: item.id, isBackPath: true });
        },
        icon: ICONS.EDIT_PENCIL,
      },
      {
        label: i18n._(t`Delete`),
        action: () => onDeleteItem(item),
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ];
  };

  const multiSelect = {
    checkedCount: selectedItems.length,
    onCheckAll: onSelectAll,
    onSelectItem: (skill: INewSkill) => {
      if (selectedItems.includes(skill.id)) {
        setSelectedItems((prevItems) => prevItems.filter((id) => id !== skill.id));
      } else {
        setSelectedItems((prevItems) => [...prevItems, skill.id]);
      }
    },
    isItemChecked: (skill: INewSkill) => selectedItems.includes(skill.id),
    isAllChecked: isAllSelected,
    onDelete: async () => {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Delete?`),
        description: i18n._(
          t`Are you sure you want to delete the selected skills? This cannot be undone.`,
        ),
      });
      if (isConfirmed) {
        try {
          setLoading(true);
          await deleteSkills(selectedItems);
          addToast({
            title: i18n._(t`Succesfully deleted ${selectedItems.length} skills`),
            type: TOAST_TYPES.INFO,
          });
        } catch (error) {
          addToast({
            title: i18n._(t`Oops... Something went wrong`),
            subtitle: i18n._(t`Try again later!`),
            type: TOAST_TYPES.ERROR,
          });
          setLoading(false);
        }
        await refreshData();
      }
    },
  };

  const handleExportCSV = async () => {
    const categoryArray = currentFilters.categoryFilters.map((category) => category.key);
    // @ts-ignore
    await downloadSkillsCSV({ search: currentFilters.search, categories: categoryArray });
  };

  const onRowClick = (skill: ISkill) => {
    routes.SKILL_VIEW.go({}, { skillId: skill.id, isBackPath: true });
  };

  const onResetSearchFilters = (): void => {
    setCurrentFilters(initialFilters);
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debCurrentFilters]);

  const missingLanguageText = i18n._(t`Missing translation`);

  const SKILL_COLUMNS = useMemo(
    () => createSkillColumns(getMultiLangString, primaryLanguage, missingLanguageText),
    [getMultiLangString, primaryLanguage, missingLanguageText],
  );

  return (
    <>
      <Wrapper>
        <TableList
          isLoading={loading}
          data={skillData}
          columns={SKILL_COLUMNS}
          onRowClick={onRowClick}
          filtersProps={{
            isToggleHideFilterVisible: true,
            filters,
            resetFilters: onResetSearchFilters,
            isFiltered: !!currentFilters.search.length,
            filterComponents: (
              <DropDownCtr>
                <StyledDropdown
                  isSingleSelect={false}
                  items={options}
                  selectedItems={currentFilters.categoryFilters}
                  onChange={(selectedItem) => {
                    setCurrentFilters({
                      ...currentFilters,
                      categoryFilters: selectedItem as TMultiSelect[],
                      pagination: DEFAULT_PAGINATION,
                    });
                  }}
                  placeholder={i18n._(t`Skill Categories`)}
                  stringifyItem={(item) => getMultiLangString((item as TMultiSelect).title || '')}
                />
              </DropDownCtr>
            ),
          }}
          actionButton={{
            label: <Trans>Export</Trans>,
            onClick: handleExportCSV,
            variant: ButtonVariant.SECONDARY,
            icon: ICONS.EXPORT_COPY,
          }}
          paginationProps={{
            pagination: currentFilters.pagination,
            changePagination: ({ skip, limit, index }) =>
              setCurrentFilters({
                ...currentFilters,
                pagination: { ...currentFilters.pagination, skip, limit, index },
              }),
            totalCount,
            paginationItemLabel: i18n._(t`Skills`),
          }}
          sortProps={{
            sortBy: currentFilters.sortBy,
            setSortBy: (sortBy: SKILL_SORTING) => setCurrentFilters({ ...currentFilters, sortBy }),
          }}
          multiSelectProps={{
            isMultiSelectVisible: true,
            isSelectedCountVisible: true,
            multiSelect,
            isSelectAllVisible: true,
          }}
          menuProps={{ isMenuVisible: true, createMenuItems }}
          noForceUpdate
        />
      </Wrapper>
    </>
  );
};

export { AllSkills };
