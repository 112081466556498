import React, { Fragment, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import {
  TableCtr,
  DataRaw,
  NumberLineBorder,
  RowTitle,
  Numbers,
  Line,
  Container,
  DataMultiOuterCtr,
  DataMultiCtrBackground,
  DataMultiCtr,
  DataCtr,
  DataCtrBackground,
  ItemNr,
  GraphLineContainer,
} from './HorizontalChartContainer.design';

import { TTotalGraphData } from '~/services/reports';
import { COLORS } from '~/styles';
import { toFixed } from '~/utils/math';

const BAR_HEIGHT = 30;
const BAR_MAX_HEIGHT = 40;
const MAX_CHARACTERS = 16;

interface IProps {
  chartData: TTotalGraphData[];
}

function HorizontalChartContainer({ chartData }: IProps): JSX.Element {
  const { i18n } = useLingui();
  const [activeRow, setActiveRow] = useState<null | number>(null);
  const barSize = 20;
  const gaps = Array.from({ length: 100 / barSize + 1 }, (_, i) => i * barSize);
  const barLines = () => {
    return gaps.map((i) => <Line key={`axis-${i + 1}`} leftAlign={`${i}%`} />);
  };

  const numberLine = () => {
    return gaps.map((i) => (
      <Numbers key={`number-line-${i + 1}`} leftAlign={`${i}%`}>
        {i}
      </Numbers>
    ));
  };
  const getMultiComponent = (item: TTotalGraphData, isActive: boolean) => {
    if (item.benchmark || item.team) {
      return (
        <DataMultiOuterCtr mHeight={`${BAR_MAX_HEIGHT}px`}>
          {!!item?.value && (
            <DataMultiCtr
              barWidth={`${toFixed(item.value || 0, 2)}%`}
              deviation={toFixed(item.deviation || 0, 2)}
              isActive={isActive}
              value={`${toFixed(item.value || 0, 2)}%`}
            >
              <DataMultiCtrBackground isActive={isActive} bgClr={COLORS.CONFIRMATION_MODAL_INFO} />
            </DataMultiCtr>
          )}
          {!!item?.team && (
            <DataMultiCtr
              barWidth={`${toFixed(item.team || 0, 2)}%`}
              deviation={toFixed(item.deviation || 0, 2)}
              isActive={isActive}
              value={`${toFixed(item.team || 0, 2)}%`}
            >
              <DataMultiCtrBackground isActive={isActive} bgClr={COLORS.ACCENT_WARNING} />
            </DataMultiCtr>
          )}
          {!!item?.benchmark && (
            <DataMultiCtr
              barWidth={`${toFixed(item.benchmark || 0, 2)}%`}
              deviation={toFixed(item.deviation || 0, 2)}
              isActive={isActive}
              value={`${toFixed(item.benchmark || 0, 2)}%`}
            >
              <DataMultiCtrBackground isActive={isActive} bgClr={COLORS.COMPANY} />
            </DataMultiCtr>
          )}
        </DataMultiOuterCtr>
      );
    }
    return (
      <DataCtr
        barWidth={`${toFixed(item.value, 2)}%`}
        mHeight={`${BAR_HEIGHT}px`}
        isActive={isActive}
        deviation={toFixed(item.deviation || 0, 2)}
      >
        <DataCtrBackground isActive={isActive} />
        <ItemNr isActive={isActive}>{toFixed(item.value, 2)}%</ItemNr>
      </DataCtr>
    );
  };

  return (
    <Container>
      <TableCtr maxCharacters={MAX_CHARACTERS}>
        <GraphLineContainer maxCharacters={MAX_CHARACTERS}>
          {barLines()}
          {numberLine()}
          <NumberLineBorder> </NumberLineBorder>
        </GraphLineContainer>
        {chartData.map((item, i) => (
          <Fragment key={`data-${i + 1}`}>
            <RowTitle
              isActive={activeRow === i}
              onMouseEnter={() => setActiveRow(i)}
              onMouseLeave={() => setActiveRow(null)}
              isClickable={!!item.id}
              onClick={() => {}}
              mHeight={Array.isArray(item.value) ? `${BAR_MAX_HEIGHT}px` : `${BAR_HEIGHT}px`}
              maxCharacters={MAX_CHARACTERS}
            >
              <Tooltip tooltip={i18n._(t`${item.name}`)} size={TOOLTIP_SIZES.BIG}>
                <span>{i18n._(t`${item.name}`)}</span>
              </Tooltip>
            </RowTitle>
            <DataRaw
              key={`data-${i + 1}`}
              onMouseEnter={() => setActiveRow(i)}
              onMouseLeave={() => setActiveRow(null)}
              isActive={activeRow === i}
              mHeight={Array.isArray(item.value) ? `${BAR_MAX_HEIGHT}px` : `${BAR_HEIGHT}px`}
            >
              {getMultiComponent(item, activeRow === i)}
            </DataRaw>
          </Fragment>
        ))}
      </TableCtr>
    </Container>
  );
}

export { HorizontalChartContainer };
