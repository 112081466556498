import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import { useSelector, connect } from 'react-redux';
import { useLocation } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import { ExportPDFOptionsModal } from '~/components/Modals/ExportPDFOptionsModal';
import OverviewHeading from '~/components/OverviewHeading';
import { TabSlider } from '~/components/TabSlider';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import Shadow from '~/components/UI/Shadow';
import BaseLayout from '~/layouts/BaseLayout';
import { ActivitiesModal } from '~/pages/PassportPage/components/ActivitiesModal';

import { PassportHeader } from './components/PassportHeader';
import { profileTabBuilder } from './utils/profileTabBuilder';

import { ROLES } from '~/constants';
import { INSTRUCTIONS } from '~/constants/instructions';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import {
  getUser,
  getUsers,
  checkModuleGoals,
  checkModuleLearning,
  checkLearningCreateActivityCoachModule,
  checkLearningCreatePathCoachModule,
  getAppTheme,
  getInactiveUsers,
  checkModuleReviews,
  checkModuleConversations,
  getCurrentProducts,
} from '~/selectors/baseGetters';
import getCurrentTeam from '~/selectors/getCurrentTeam';
import { downloadUserProfileCvPDF } from '~/services/users';
import { COLORS } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';
import getUserFullName from '~/utils/getUserFullName';

const Container = styled(Shadow)`
  background: ${COLORS.WHITE};
`;

const NoDataPlaceholder = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

const TabsSliderContainer = styled.div`
  margin-bottom: 16px;

  & > div:first-child {
    padding-top: 20px;
  }
`;

export const TABS_ENUM = {
  CONVERSATIONS: 'conversations',
  MEETINGS: 'meetings',
  GOALS: 'goals',
  LEARNING: 'learning',
  CAREER: 'career',
  PROFILE: 'profile',
  NOTES: 'notes',
  SETTINGS: 'settings',
};

export const PROFILE_TABS_ENUM = {
  DEVELOPMENT: 'development',
  CAREER: 'career',
  SETTINGS: 'settings',
};

const PROFILE_TABS = [
  {
    label: t`Development`,
    key: PROFILE_TABS_ENUM.DEVELOPMENT,
  },
  {
    label: t`Career`,
    key: PROFILE_TABS_ENUM.CAREER,
  },
  {
    label: t`Settings`,
    key: PROFILE_TABS_ENUM.SETTINGS,
  },
];

function PassportPage({
  i18n,
  currentTeam,
  selectedRole,
  isModuleGoalsEnabled,
  isModuleLearningEnabled,
  isLearningCreateActivityCoachModuleEnabled,
  isLearningCreatePathCoachModuleEnabled,
  isModuleReviewsEnabled,
  isModuleMeetingsEnabled,
}) {
  // Global state hooks
  const users = useSelector(getUsers);
  const usersInactive = useSelector(getInactiveUsers);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const viewerType = searchParams.get('view');
  const viewer = useSelector(getUser);
  const match = useRouteMatch();
  const memberId = searchParams.get('memberId');
  const userIdFromUrl = memberId || get(match, 'params.userId');
  const $openActivitiesModal = useBoolState(false);
  const $openExportCVModal = useBoolState(false);
  const { addToast } = useToasts();
  const appTheme = useSelector(getAppTheme);
  const $isFetchAgain = useBoolState(false);
  const products = useSelector(getCurrentProducts);

  const readOnly = !!userIdFromUrl;
  const watchUserProfile =
    viewerType === 'public' ||
    (userIdFromUrl && userIdFromUrl !== viewer.id && ROLES.USER === selectedRole);
  const currentUserId = userIdFromUrl || viewer.id;
  const user = users[currentUserId];
  const inactiveUser = usersInactive[currentUserId];

  const onOpenCreatePathFlow = () => {
    routes.PATH_CREATE.go(
      // @ts-ignore // TODO remove when LinkConstructor rewrited with TS
      {},
      {
        query: { users: [currentUserId] },
        isBackPath: true,
        noHash: true,
        hash: TABS_ENUM.LEARNING,
      },
    );
  };

  const checkVisibilityOfTabs = (profileTabs) => {
    let tabs = profileTabs;

    if (
      products?.performance?.status !== 'active' &&
      products?.performance?.status !== 'trialActive'
    ) {
      tabs = tabs.filter((tab) => tab.key !== PROFILE_TABS_ENUM.DEVELOPMENT);
    }

    if (products?.career?.status !== 'active' && products?.career?.status !== 'trialActive') {
      tabs = tabs.filter((tab) => tab.key !== PROFILE_TABS_ENUM.CAREER);
    }
    return tabs;
  };

  const MEMBER_PROFILE_TABS = profileTabBuilder(
    checkVisibilityOfTabs(PROFILE_TABS),
    PROFILE_TABS_ENUM,
    products,
    user,
    currentUserId,
    readOnly,
    appTheme,
    isModuleReviewsEnabled,
    isModuleMeetingsEnabled,
    isModuleGoalsEnabled,
    $isFetchAgain.value,
    isModuleLearningEnabled,
    isLearningCreateActivityCoachModuleEnabled,
    isLearningCreatePathCoachModuleEnabled,
    $openActivitiesModal.on,
    onOpenCreatePathFlow,
  );

  const downloadCV = () => {
    const userName = user.firstName ? `${user.firstName} ${user?.lastName}` : user.email;
    downloadUserProfileCvPDF(currentUserId, userName);
    addToast({
      title: i18n._(t`Downloading user profile PDF`),
      subtitle: i18n._(t`Generating the user profile in PDF. Please wait a few seconds.`),
      type: TOAST_TYPES.INFO,
    });
  };

  const getPageTitle = () => {
    if (selectedRole === ROLES.ADMIN) {
      return i18n._(t`Members`);
    }
    if (selectedRole === ROLES.COACH) {
      return currentTeam.name || i18n._(t`Team`);
    }
    if (selectedRole === ROLES.USER) {
      let url = new URL(window.location.href);
      const from = url.searchParams.get('from');
      if (from) {
        return i18n._(t`Dashboard`);
      }
    }
    return i18n._(t`Profile`);
  };

  const pageTitle = getPageTitle();

  const getBackPath = () => {
    let url = new URL(window.location.href);
    return url.searchParams.get('from');
  };

  const backPath = getBackPath();

  const onCloseActivityModal = (isSubmit) => {
    if (isSubmit) {
      $isFetchAgain.toggle();
      $openActivitiesModal.off();
    } else {
      $openActivitiesModal.off();
    }
  };

  return (
    <>
      <OverviewHeading
        title={pageTitle}
        instructions={
          selectedRole !== ROLES.USER
            ? i18n._(t`How to coach with Learned`)
            : !watchUserProfile
            ? i18n._(t`How your profile works`)
            : null
        }
        instructionsUrl={
          selectedRole !== ROLES.USER
            ? getInstructionUrl(INSTRUCTIONS.HOW_TO_COACH)
            : !watchUserProfile
            ? getInstructionUrl(INSTRUCTIONS.HOW_TO_PROFILE_WORKS)
            : null
        }
        backPath={backPath}
        smallWidth
      >
        {user && selectedRole === ROLES.USER && !watchUserProfile && (
          <Button label={i18n._(t`Export PDF`)} onClick={() => $openExportCVModal.on()} />
        )}
      </OverviewHeading>
      {user ? (
        <BaseLayout maxWidth={750}>
          <Container>
            <PassportHeader user={user} readOnly={readOnly} companyLogo={appTheme.url}>
              <></>
            </PassportHeader>
          </Container>
          <TabsSliderContainer>
            <TabSlider items={MEMBER_PROFILE_TABS} tabTitleAlignment="left" noPadding />
          </TabsSliderContainer>
        </BaseLayout>
      ) : (
        <NoDataPlaceholder>
          {inactiveUser
            ? i18n._(t`User ${getUserFullName(inactiveUser)} is disabled!`)
            : i18n._(t`User does not exist in the company!`)}
        </NoDataPlaceholder>
      )}

      {$openActivitiesModal.value && (
        <ActivitiesModal userId={currentUserId} onClose={onCloseActivityModal} />
      )}
      {$openExportCVModal.value && (
        <ExportPDFOptionsModal
          onClose={() => $openExportCVModal.off()}
          downloadCV={downloadCV}
          user={user}
        />
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    currentTeam: getCurrentTeam(state),
    selectedRole: state.selected.role,
    isModuleGoalsEnabled: checkModuleGoals(state),
    isModuleLearningEnabled: checkModuleLearning(state),
    isLearningCreateActivityCoachModuleEnabled: checkLearningCreateActivityCoachModule(state),
    isLearningCreatePathCoachModuleEnabled: checkLearningCreatePathCoachModule(state),
    isModuleReviewsEnabled: checkModuleReviews(state),
    isModuleMeetingsEnabled: checkModuleConversations(state),
  };
};

export default withI18n()(connect(mapStateToProps)(PassportPage));
