import React, { useEffect, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, ProductName, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import FirstTimeSetupModalNew from '~/components/FirstTimeSetupModalNew';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import BaseLayout from '~/layouts/BaseLayout';

import { Header } from './components/Header';
import { TasksSection } from './components/TasksSection';
import { UserDashboardSidebar } from './components/UserDashboardSidebar';

import routes from '~/constants/routes';
import {
  checkModuleReviews,
  checkModuleConversations,
  getUser,
  getCurrentCompanyId,
  checkModuleJobMatrix,
} from '~/selectors/baseGetters';
import { updateTrialEndedProductsMessages } from '~/services/users';
import { getCompanyConnection } from '~/utils/getCompanyConnection';
import { getProductName } from '~/utils/products';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

const DashboardWrapper = styled.div`
  display: flex;
  width: 1001px;
`;

const LeftColumn = styled.div`
  width: 100%;
  max-width: 760px;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 218px;
  margin-left: 23px;
`;

const SectionWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

interface TrialEndedProduct {
  dateTime: string;
  read: boolean;
}

const UserDashboard = () => {
  const { i18n } = useLingui();
  const history = useHistory();
  const user = useSelector(getUser);
  const companyId = useSelector(getCurrentCompanyId);
  const companyConnection = useSelector(() => getCompanyConnection(companyId, user));
  const isModuleJobMatrixEnabled = useSelector(checkModuleJobMatrix);
  const isModuleReviewsEnabled = useSelector(checkModuleReviews);
  const isModuleMeetingsEnabled = useSelector(checkModuleConversations);
  const [isTrialEndedModalVisible, setIsTrialEndedModalVisible] = useState(false);
  const [trialEndedProduct, setTrialEndedProduct] = useState<{
    name: ProductName;
    dateTime: string;
  }>();

  useEffect(() => {
    if (!isEmpty(companyConnection?.trialEndedProductsMessages)) {
      const product = Object.entries(
        companyConnection?.trialEndedProductsMessages as Record<ProductName, TrialEndedProduct>,
      )
        ?.map(([name, item]) => ({
          name: name as ProductName,
          ...item,
        }))
        ?.filter((item: TrialEndedProduct) => !item.read)[0];

      if (product) {
        setTrialEndedProduct(product);
        setIsTrialEndedModalVisible(true);
      }
    }
  }, [companyConnection?.trialEndedProductsMessages, i18n]);

  const onCloseTrialEndedModal = async () => {
    setIsTrialEndedModalVisible(false);

    if (trialEndedProduct) {
      await updateTrialEndedProductsMessages({
        [trialEndedProduct.name]: {
          read: true,
        },
      });
    }
  };

  const onSubmitTrialEndedModal = () => {
    // @ts-ignore
    history.push(routes.SETTINGS_MODULES.build({ role: ROLES.ADMIN }));
  };

  return (
    <Wrapper>
      <Header user={user} />
      <BaseLayout maxWidth="1001px">
        <DashboardWrapper>
          <LeftColumn>
            <SectionWrapper>
              <TasksSection />
            </SectionWrapper>
          </LeftColumn>
          <RightColumn>
            <UserDashboardSidebar
              isModuleJobMatrixEnabled={isModuleJobMatrixEnabled}
              isModuleReviewsEnabled={isModuleReviewsEnabled}
              isModuleMeetingsEnabled={isModuleMeetingsEnabled}
            />
          </RightColumn>
          {user && !isEmpty(user) && !user.completedFirstTimeSetup && <FirstTimeSetupModalNew />}
        </DashboardWrapper>
      </BaseLayout>
      {isTrialEndedModalVisible && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.INFO}
          title={i18n._(t`Free trial ended`)}
          description={i18n._(
            t`The ${getProductName(
              trialEndedProduct?.name as ProductName,
            )} product is not visible for you and all members within your company since ${moment(
              trialEndedProduct?.dateTime,
            ).format('ll')}.`,
          )}
          cancelButton={i18n._(t`Close`)}
          submitButton={i18n._(t`View plan`)}
          onClose={onCloseTrialEndedModal}
          onSubmit={onSubmitTrialEndedModal}
        />
      )}
    </Wrapper>
  );
};

export default UserDashboard;
