import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { AreaTitle } from './design';

import { COLORS } from '~/styles';

type QuestionsForPeersProps = {
  questions?: string[];
};

const QuestionsForPeers = ({ questions = [] }: QuestionsForPeersProps) => {
  const { i18n } = useLingui();
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
      <AreaTitle>{i18n._(t`Questions for peers`)}</AreaTitle>
      <AreaTitle color={COLORS.SUBTEXT} padding={'0 0 4px 4px'}>
        {`(${questions.length})`}
      </AreaTitle>
    </div>
  );
};

export { QuestionsForPeers };
