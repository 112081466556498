import React, { useContext, useMemo } from 'react';

import { REPORT_TYPES, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { AreaGraph, ELabelType, ILineGraphPoint } from '~/components/Graphs/custom/AreaGraph';

import getCurrentCompany from '~/selectors/getCurrentCompany';
import { COLORS, PRIMARY_COLOR } from '~/styles';
import { toFixed } from '~/utils/math';

import { EngagementReportContext } from '../Reports/engagement/EngagementContext';

type IProps = {
  chartData: {
    benchmark: number;
    deviation: number;
    month: string;
    team: number;
    value: number;
  }[];
  includeCompanyAverage: boolean;
  includeBenchmark: boolean;
  includeTeamAverage: boolean;
  viewAs: ROLES;
};

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  background: ${COLORS.WHITE};
  color: ${COLORS.TEXT_MAIN};
  border-radius: 6px;
  font-size: 14px;
  gap: 4px;
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
`;

function AreaChartContainer({
  chartData,
  includeCompanyAverage,
  includeBenchmark,
  includeTeamAverage,
  viewAs,
}: IProps): JSX.Element {
  const { i18n } = useLingui();
  const { reportType } = useContext(EngagementReportContext);
  const currentCompany = useSelector(getCurrentCompany);
  const companyColor = currentCompany.color || PRIMARY_COLOR;

  const companyAverage = useMemo(() => {
    return chartData.map((item) => ({
      key: item.month,
      value: toFixed(item.value || 0, 2),
      deviation: item.deviation || 0,
    }));
  }, [chartData]);

  const teamAverage = useMemo(() => {
    return chartData.map((item) => ({
      key: item.month,
      value: toFixed(item.team || 0, 2),
      deviation: item.deviation || 0,
    }));
  }, [chartData]);

  const benchmark = useMemo(() => {
    return chartData.map((item) => ({
      key: item.month,
      value: toFixed(item.benchmark || 0, 2),
      deviation: item.deviation || 0,
    }));
  }, [chartData]);

  const getLabelText = () => {
    if (viewAs === ROLES.ADMIN) {
      return `${i18n._(t`Company Average`)}`;
    } else if (viewAs === ROLES.COACH) {
      return `${i18n._(t`Team Average`)}`;
    }
    return `${i18n._(t`Your engagement`)}`;
  };

  const timeData: ILineGraphPoint[] = [
    {
      data: companyAverage,
      colors: [companyColor, COLORS.WHITE],
      label: getLabelText(),
      type: ELabelType.FILL,
      key: 'primary',
    },
    ...(includeCompanyAverage
      ? [
          {
            data: companyAverage,
            colors: [COLORS.CONFIRMATION_MODAL_SUCCESS],
            label: `${i18n._(t`Company`)}`,
            type: ELabelType.BORDER,
            key: 'company',
          },
        ]
      : []),
    ...(includeTeamAverage
      ? [
          {
            data: teamAverage,
            colors: [COLORS.PERFORMANCE],
            label: `${i18n._(t`Team`)}`,
            type: ELabelType.BORDER,
            key: 'team',
          },
        ]
      : []),

    ...(includeBenchmark
      ? [
          {
            data: benchmark,
            colors: [COLORS.SUBTEXT],
            label: `${i18n._(t`Benchmark`)}`,
            type: ELabelType.BORDER,
            key: 'benchmark',
          },
        ]
      : []),
  ];

  return (
    <Container>
      <AreaGraph
        timeData={timeData}
        stepSize={20}
        colorRatio={1}
        tooltipTitle={
          reportType === REPORT_TYPES.ENGAGEMENT
            ? `${i18n._(t`Average engagement`)}`
            : `${i18n._(t`Average performance`)}`
        }
        bgColor={companyColor}
      />
    </Container>
  );
}

export { AreaChartContainer };
