import React, { useEffect, useState } from 'react';

import { I18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { HeaderTabs } from '~/components/HeaderTabs';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import OverviewHeading from '~/components/OverviewHeading';
import BaseLayout from '~/layouts/BaseLayout';
import {
  HeaderWrapper,
  StyledHeaderButton,
} from '~/pages/SuperAdminDashboard/SuperAdminSkillMatrix/design';
import { SuperAdminSkillTemplates } from '~/pages/SuperAdminDashboard/SuperAdminSkillMatrix/SuperAdminSkillTemplates';
import { useSkillCategories } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/hooks';

import { SkillCategoriesAndLevelsModal } from './components/SkillCategoriesAndLevelsModal';
import { SuperAdminPendingSkills } from './SuperAdminPendingSkills';

import useBoolState from '~/hooks/useBoolState';
import { getSkillTemplatesPendingCountSuperAdmin } from '~/services/skillTemplatesPending';

const TABS_MAP: Record<string, string> = {
  PENDING_SKILLS_TAB: 'pendingSkillsTab',
  CORE_VALUES: 'Core values',
};

function SuperAdminSkillMatrix() {
  const { i18n } = useLingui();
  const [totalPending, setTotalPending] = useState(0);
  const { skillCategories, isLoading } = useSkillCategories();
  const [currentTab, setCurrentTab] = useState(
    TABS_MAP[window.location.hash.substring(1).toUpperCase()],
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const $isCoreValues = useBoolState(false);

  useEffect(() => {
    let isMounted = true;

    async function fetch() {
      const result = await getSkillTemplatesPendingCountSuperAdmin();
      if (isMounted && result?.code === 200) {
        setTotalPending(result?.data?.total ?? 0);
      }
    }

    fetch();

    return () => void (isMounted = false);
  }, []);

  async function reFetchTotalPending() {
    const result = await getSkillTemplatesPendingCountSuperAdmin();
    if (result?.code === 200) {
      setTotalPending(result?.data?.total ?? 0);
    }
  }

  const tabs = [
    ...skillCategories.map((category) => ({
      label: () => category.name.en_GB,
      key: category.id,
    })),
    {
      label: (i18n: I18n) => i18n._(t`Pending (${totalPending})`),
      key: TABS_MAP.PENDING_SKILLS_TAB,
    },
  ];

  useEffect(() => {
    setCurrentTab(
      TABS_MAP[window.location.hash.substring(1).toUpperCase()] || skillCategories[0]?.id,
    );
  }, [skillCategories]);

  function handleChangeTab(key: string) {
    window.location.hash = key;
    setCurrentTab(key);
    tabs.forEach((tab) => {
      if (tab.key === key) {
        $isCoreValues.set(tab.label(i18n) === i18n._(t`${TABS_MAP.CORE_VALUES}`));
      }
    });
  }

  return (
    <>
      <OverviewHeading title={i18n._(t`Skill matrix`)}>
        <StyledHeaderButton
          onClick={() => setIsModalOpen(true)}
          label={<Trans>Manage skill levels</Trans>}
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          icon={ICONS.SETTINGS}
          iconSize={ICON_SIZES.MEDIUM}
        />
      </OverviewHeading>
      <BaseLayout>
        <HeaderWrapper>
          {!isLoading && (
            <HeaderTabs tabs={tabs} selectedTab={currentTab} handleChangeTab={handleChangeTab} />
          )}
        </HeaderWrapper>
        {currentTab !== TABS_MAP.PENDING_SKILLS_TAB && (
          <SuperAdminSkillTemplates categoryId={currentTab} isCoreValues={$isCoreValues.value} />
        )}
        {currentTab === TABS_MAP.PENDING_SKILLS_TAB && (
          <SuperAdminPendingSkills reFetchTotalPending={reFetchTotalPending} />
        )}
      </BaseLayout>
      {isModalOpen && <SkillCategoriesAndLevelsModal onClose={() => setIsModalOpen(false)} />}
    </>
  );
}

export { SuperAdminSkillMatrix };
