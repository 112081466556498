import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';
import styled from 'styled-components';

import LearnMore from '~/components/LearnMore';
import Placeholder from '~/components/Placeholder';
import SearchSelectButton from '~/components/SearchSelectButton';
import SvgIcon from '~/components/SvgIcon';
import Switch from '~/components/Switch';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import Tooltip from '~/components/Tooltip';

import UsersIcon from '~/assets/mdi-account-group.svg';

import { INTEGRATIONS_CONN_ERROR_MSG, JOB_PROFILE_STATUSES } from '~/constants';
import { INSTRUCTIONS } from '~/constants/instructions';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUsersFromIntegration } from '~/services/integrations';
import { getJobProfiles } from '~/services/jobProfiles';
import { createUserFromIntegration } from '~/services/users';
import { COLOR_PALETTE, COLORS } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';
import getUserFullName from '~/utils/getUserFullName';
import isValidEmail from '~/utils/isValidEmail';

import useBoolState from '../../hooks/useBoolState';
import Button from '../Button';
import CheckBox from '../CheckBox';
import ShowSpinnerIfLoading from '../ShowSpinnerIfLoading';

const TOOLTIP_SEVERITIES = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'informational',
};

const Wrapper = styled.div`
  margin-top: 12px;
`;

const UserExternalHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.7fr 4fr 2.5fr 3fr 3fr 3fr 1.5fr;
  align-items: center;
`;

const PlaceholderWrap = styled.div`
  margin: 25px auto 100px auto;
`;

const UserExternalHeaderItem = styled.div``;

const UserExternalWrapper = styled.div`
  width: 99%;
  background-color: ${(props) => (props.selected ? '#e6e8ec' : '#f6f8fc')};
  ${(props) => {
    if (props.severity === TOOLTIP_SEVERITIES.ERROR) {
      return 'border: 1px solid red;';
    }
    if (props.severity === TOOLTIP_SEVERITIES.WARNING) {
      return 'border: 1px solid #FDDA0D;';
    }
    if (props.severity === TOOLTIP_SEVERITIES.INFO) {
      return 'border: 1px solid #A9A9A9;';
    }
  }}
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 64px;
  display: grid;
  grid-template-columns: 0.7fr 4fr 2.5fr 3fr 3fr 3fr 1.5fr;
  align-content: center;
`;

const SelectAllWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SelectedAllCheckMark = styled(CheckBox)`
  align-self: center;
  padding: 8px;
  height: 18px;
  width: 18px;
`;

const SelectedCheckMark = styled(CheckBox)`
  align-self: center;
  padding: 8px;
`;
const EmailField = styled.div`
  align-self: center;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const NameField = styled.div`
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const RoleProfileSelect = styled(SearchSelectButton)`
  height: 32px;
  align-self: center;
`;
const MemberTeamSelect = styled(SearchSelectButton)`
  align-self: center;
`;

const SelectWrapper = styled.div`
  padding: 8px;
`;

const CoachTeamSelect = styled(SearchSelectButton)`
  align-self: center;
`;

const AdminSwitchWrapper = styled.div`
  align-self: center;
  justify-self: center;
`;

const AdminSwitch = styled(Switch)`
  align-self: center;
  justify-self: center;
`;

const ButtonSection = styled.div`
  display: flex;
  padding: 8px 0px 16px 18px;
  justify-content: flex-end;
`;

const messages = {
  membersImported: {
    title: t`Success`,
    content: t`The selected members have been invited`,
  },
};

const validateUserExternalFields = (i18n, user) => {
  if (!user.firstName) {
    return {
      field: 'firstName',
      message: i18n._(
        t`The user doesn't have a first name, set it on your integration system to be able to import this user in Learned`,
      ),
    };
  }
  if (!user.lastName) {
    return {
      field: 'lastName',
      message: i18n._(
        t`The user doesn't have a last name, set it on your integration system to be able to import this user in Learned`,
      ),
    };
  }
  if (!user.email) {
    return {
      field: 'email',
      message: i18n._(
        t`The user doesn't have an email address, set it on your integration system to be able to import this user in Learned`,
      ),
    };
  }
  if (!isValidEmail(user.email)) {
    return {
      field: 'email',
      message: i18n._(
        t`The user has an invalid email address, fix it on your integration system to be able to import this user in Learned`,
      ),
    };
  }
  return null;
};

function renderUserRow(
  i18n,
  userExternal,
  selectedUsersExternal,
  setSelectedUsersExternal,
  teams,
  roles,
) {
  const isUserSelected = selectedUsersExternal
    .map((x) => x.externalId)
    .includes(userExternal.externalId);
  const selectedUserExternal = selectedUsersExternal.find(
    (x) => x.externalId === userExternal.externalId,
  );

  const teamOptions = [...map(teams, (team) => ({ label: team.name, id: team.id }))];

  const selectedUserMemberTeamValues =
    isUserSelected && selectedUserExternal.memberTeams ? selectedUserExternal.memberTeams : [];
  const selectedUserCoachTeamValues =
    isUserSelected && selectedUserExternal.coachTeams ? selectedUserExternal.coachTeams : [];
  const roleOptions = [...map(roles, (role) => ({ id: role.id, label: role.name }))];
  const selectedUserRoleValues =
    isUserSelected && selectedUserExternal.jobProfiles ? selectedUserExternal.jobProfiles : [];
  const selectedUserIsAdmin = isUserSelected && selectedUserExternal.isAdmin;

  const { tooltip } = userExternal;

  return (
    <Tooltip
      placement="top-start"
      disabled={!tooltip}
      tooltip={(tooltip && tooltip.message) || ''}
      key={userExternal.externalId}
    >
      <UserExternalWrapper
        selected={isUserSelected}
        severity={tooltip && tooltip.severity}
        onClick={() => {
          if ((!tooltip || !tooltip.disableItem) && !isUserSelected) {
            setSelectedUsersExternal([...selectedUsersExternal, userExternal]);
          }
        }}
      >
        <SelectedCheckMark
          checked={isUserSelected}
          onChange={() => {
            setSelectedUsersExternal(
              isUserSelected
                ? selectedUsersExternal.filter((x) => x.externalId !== userExternal.externalId)
                : [...selectedUsersExternal, userExternal],
            );
          }}
          disabled={tooltip && tooltip.disableItem}
        />
        <Tooltip tooltip={userExternal.email} maxWidth="100%">
          <EmailField>{userExternal.email}</EmailField>
        </Tooltip>
        <Tooltip tooltip={getUserFullName(userExternal)} maxWidth="100%">
          <NameField>{getUserFullName(userExternal)}</NameField>
        </Tooltip>
        <SelectWrapper>
          <RoleProfileSelect
            disabled={!isUserSelected}
            checkedList={selectedUserRoleValues}
            options={roleOptions}
            title={i18n._(t`Roles`)}
            handleChange={(e) => {
              if (isUserSelected) {
                setSelectedUsersExternal([
                  ...selectedUsersExternal.filter((x) => x.externalId !== userExternal.externalId),
                  { ...selectedUserExternal, jobProfiles: e },
                ]);
              }
            }}
            height="36px"
          />
        </SelectWrapper>
        <SelectWrapper>
          <MemberTeamSelect
            disabled={!isUserSelected}
            checkedList={selectedUserMemberTeamValues}
            options={map(teamOptions, (t) => ({ id: t.id, label: t.label })).filter(
              (team) => !selectedUserCoachTeamValues.includes(team.id),
            )}
            title={i18n._(t`Teams`)}
            handleChange={(e) => {
              if (isUserSelected) {
                setSelectedUsersExternal([
                  ...selectedUsersExternal.filter((x) => x.externalId !== userExternal.externalId),
                  { ...selectedUserExternal, memberTeams: e },
                ]);
              }
            }}
            height="36px"
          />
        </SelectWrapper>
        <SelectWrapper>
          <CoachTeamSelect
            disabled={!isUserSelected}
            checkedList={selectedUserCoachTeamValues}
            options={map(teamOptions, (t) => ({ id: t.id, label: t.label })).filter(
              (team) => !selectedUserMemberTeamValues.includes(team.id),
            )}
            title={i18n._(t`Coach teams`)}
            handleChange={(e) => {
              if (isUserSelected) {
                setSelectedUsersExternal([
                  ...selectedUsersExternal.filter((x) => x.externalId !== userExternal.externalId),
                  { ...selectedUserExternal, coachTeams: e },
                ]);
              }
            }}
            height="36px"
          />
        </SelectWrapper>
        <AdminSwitchWrapper>
          <AdminSwitch
            onChange={(e) => {
              setSelectedUsersExternal([
                ...selectedUsersExternal.filter((x) => x.externalId !== userExternal.externalId),
                { ...selectedUserExternal, isAdmin: e },
              ]);
            }}
            disabled={!isUserSelected}
            checked={selectedUserIsAdmin}
          />
        </AdminSwitchWrapper>
      </UserExternalWrapper>
    </Tooltip>
  );
}

function UsersIntegrationTab({ onModalClose, teams, search, integration, invites }) {
  const [loading, setLoading] = useState(true);
  const [usersExternal, setUsersExternal] = useState([]);
  const [selectedUsersExternal, setSelectedUsersExternal] = useState([]);
  const [availableRoles, setAvailableRoles] = useState();
  const [isIntegrationError, setIsIntegrationError] = useState(false);
  const $isSelectAll = useBoolState(false);
  const { addToast } = useToasts();
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  useEffect(() => {
    setUsersExternal([]);
    setSelectedUsersExternal([]);
    if (!loading) {
      setLoading(true);
    }
    const getData = async () => {
      let usersFromIntegration;
      let roles;
      try {
        [usersFromIntegration, roles] = await Promise.all([
          getUsersFromIntegration(integration.id, true),
          getJobProfiles({ status: JOB_PROFILE_STATUSES.ACTIVE.key }),
        ]);
      } catch (e) {
        // Do nothing
      }

      // Override correct multi lang string for name
      Object.values(roles).forEach((jp) => {
        jp.name = getMultiLangString(jp.name);
      });

      // If it is undefined then the fetching of integration data failed
      if (!usersFromIntegration) {
        setIsIntegrationError(true);
        setLoading(false);
        return;
      }

      const usersExternal = Object.values(usersFromIntegration || []).filter(
        (u) => u.accountEnabled,
      );
      const rolesValues = Object.values(roles);
      const invitedEmails = Object.values(invites).map((inv) => inv.email);

      usersExternal.map((u) => {
        if (u.email) {
          u.email = u.email.toLowerCase();
        }
        // Keep selected any role that is already imported from integrations
        if (u.roles) {
          const userRolesIds = u.roles.map((role) => role.roleId.toString());
          u.jobProfiles = rolesValues
            .filter((role) => userRolesIds.includes(role.externalId))
            .map((role) => role.id);
        }
        // Keep selected any team that is already imported from integrations
        if (u.teams) {
          const userTeamsIds = u.teams.map((team) => team.teamId.toString());
          u.memberTeams = teams
            .filter((team) => userTeamsIds.includes(team.externalId))
            .map((team) => team.id);
        }
        // Keep selected any coach of team, if the team is already imported from integrations
        if (u.coachTeams) {
          const userCoachTeamsIds = u.coachTeams.map((team) => team.teamId.toString());
          u.coachTeams = teams
            .filter((team) => userCoachTeamsIds.includes(team.externalId))
            .map((team) => team.id);
        }

        // Add a warning tooltip if there are other external users with the same email as the user
        const isDuplicated =
          usersExternal.filter(
            (userExt) => userExt.email && userExt.email.toLowerCase() === u.email,
          ).length > 1;
        if (isDuplicated) {
          u.tooltip = {
            disableItem: false,
            severity: TOOLTIP_SEVERITIES.WARNING,
            message: i18n._(
              t`There are multiple users with this email, you can only import one of them`,
            ),
          };
        }

        // Add an informational tooltip to each user that is already on the invited list
        if (invitedEmails.length && invitedEmails.includes(u.email)) {
          u.tooltip = {
            disableItem: true,
            severity: TOOLTIP_SEVERITIES.INFO,
            message: i18n._(t`A user with this email has already been invited`),
          };
        }

        // Add an error tooltip if the user has any invalid field
        const invalidField = validateUserExternalFields(i18n, u);
        if (invalidField) {
          u.tooltip = {
            disableItem: true,
            severity: TOOLTIP_SEVERITIES.ERROR,
            message: invalidField.message,
          };
        }
      });

      setUsersExternal(usersExternal);
      setAvailableRoles(sortBy(roles, (role) => role.name.toLowerCase()));
      setLoading(false);
    };
    getData();

    // eslint-disable-next-line
  }, [integration]);

  // search logic
  let usersExternalFiltered = [...usersExternal];
  if (search) {
    usersExternalFiltered = usersExternal.filter((item) =>
      (item.email || '').includes(search.toLowerCase()),
    );
  }

  const toggleSelectAll = (isChecked) => {
    if (isChecked) {
      // select all
      $isSelectAll.on();
      setSelectedUsersExternal([
        ...usersExternal.filter((user) => !user.tooltip || !user.tooltip.disableItem),
      ]);
    } else {
      // deselect all
      $isSelectAll.off();
      setSelectedUsersExternal([]);
    }
  };

  const showToastMessage = (msg, type) => {
    addToast({ title: i18n._(msg.title), subtitle: i18n._(msg.content), type });
  };

  return (
    <Wrapper>
      <LearnMore
        label={i18n._(t`Manually invited members cannot be synchronised or delete via en API.`)}
        LearnMoreLink={getInstructionUrl(INSTRUCTIONS.INVITING_MANAGING_MEMBERS)}
      />
      <ShowSpinnerIfLoading loading={loading}>
        <UserExternalHeader>
          <UserExternalHeaderItem>
            <SelectAllWrapper onClick={() => toggleSelectAll(!$isSelectAll.value)}>
              <SelectedAllCheckMark checked={$isSelectAll.value} size={24} />
            </SelectAllWrapper>
          </UserExternalHeaderItem>
          <UserExternalHeaderItem>
            <Trans>Email</Trans>
          </UserExternalHeaderItem>
          <UserExternalHeaderItem>
            <Trans>Name</Trans>
          </UserExternalHeaderItem>
          <UserExternalHeaderItem>
            <Trans>Role</Trans>
          </UserExternalHeaderItem>
          <UserExternalHeaderItem>
            <Trans>Member of team</Trans>
          </UserExternalHeaderItem>
          <UserExternalHeaderItem>
            <Trans>Coach of team</Trans>
          </UserExternalHeaderItem>
          <UserExternalHeaderItem>
            <Trans>Admin</Trans>
          </UserExternalHeaderItem>
        </UserExternalHeader>

        {usersExternal.length <= 0 && (
          <PlaceholderWrap>
            <Placeholder
              title={i18n._(t`No users available`)}
              subTitle={i18n._(
                isIntegrationError
                  ? INTEGRATIONS_CONN_ERROR_MSG
                  : t`It seems that all employees in your HR system have been added to Learned.`,
              )}
              subTitleStyles={{ ...(isIntegrationError && { color: COLORS.ACCENT_ERROR }) }}
              Icon={() => (
                <SvgIcon
                  url={UsersIcon}
                  width="50px"
                  height="50px"
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
            />
          </PlaceholderWrap>
        )}

        {!isEmpty(usersExternalFiltered) && (
          <Scrollbars style={{ height: 500 }}>
            {usersExternal &&
              Object.values(usersExternalFiltered).map((userExternal) =>
                renderUserRow(
                  i18n,
                  userExternal,
                  selectedUsersExternal,
                  setSelectedUsersExternal,
                  teams,
                  availableRoles,
                ),
              )}
          </Scrollbars>
        )}
        <ButtonSection>
          <Button
            label={i18n._(t`Invite`)}
            disabled={isEmpty(selectedUsersExternal)}
            onClick={async () => {
              setLoading(true);
              await Promise.all(
                selectedUsersExternal.map((userExternal) =>
                  createUserFromIntegration({ ...userExternal, integrationId: integration.id }),
                ),
              );
              onModalClose();
              showToastMessage(messages.membersImported, TOAST_TYPES.INFO);
            }}
            loading={loading}
            type="primary"
          />
        </ButtonSection>
      </ShowSpinnerIfLoading>
    </Wrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.locale.lang,
    users: state.users.data,
    companies: state.companies.data,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(UsersIntegrationTab);
