import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import CleanUpModal from '~/components/CleanUpModal';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';

import usersIcon from '~/assets/a-users-icn.svg';

import { INTEGRATIONS, INTEGRATIONS_CONN_ERROR_MSG } from '~/constants';
import { INSTRUCTIONS } from '~/constants/instructions';
import useBoolState from '~/hooks/useBoolState';
import { findTeamsToDeleteFromCompanyIntegrations } from '~/services/integrations';
import { COLORS, COLOR_PALETTE } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';

function CleanUpTeamsModal({ i18n, onClose, onSubmit, teams }) {
  const [integrations, setIntegrations] = useState([]);
  const [isDeleteAllForIntegration, setIsDeleteAllForIntegration] = useState('');
  const [isIntegrationError, setIsIntegrationError] = useState(false);
  const $loading = useBoolState(true);
  const itemsPath = 'result'; // path where to get items

  useEffect(() => {
    const fetchData = async () => {
      let integrationsWithDeletedTeams;
      try {
        integrationsWithDeletedTeams = await findTeamsToDeleteFromCompanyIntegrations();
      } catch (e) {
        // Do nothing
      }

      // If it is undefined then the fetching of integration data failed
      if (!integrationsWithDeletedTeams) {
        setIsIntegrationError(true);
        $loading.off();
        return;
      }

      if (!isEmpty(integrationsWithDeletedTeams)) {
        setIntegrations(integrationsWithDeletedTeams);

        // Check if any integration is informing that all their teams should be cleaned up
        const companyExternalTeams = Object.values(teams).filter(
          ({ externalSource }) => externalSource,
        );

        integrationsWithDeletedTeams.forEach((integration) => {
          const companyIntegrationTeamsLength = companyExternalTeams.filter(
            ({ externalSource }) => externalSource === integration.id,
          ).length;

          if (
            integration.result?.length &&
            integration.result.length === companyIntegrationTeamsLength
          ) {
            const integrationSystem = Object.values(INTEGRATIONS).find(
              ({ key }) => key === integration.externalSoftware,
            );

            // Set warning field as this is possibly an integration problem
            setIsDeleteAllForIntegration(integrationSystem?.name(i18n));
          }
        });
      }

      $loading.off();
    };

    fetchData();

    // eslint-disable-next-line
  }, []);

  return (
    <CleanUpModal
      title={i18n._(t`Cleanup teams`)}
      integrations={integrations}
      loading={$loading.value}
      onClose={onClose}
      onSubmit={() => {
        if (!isEmpty(integrations)) {
          const teamsToDelete = [];
          integrations.forEach((i) => teamsToDelete.push(...(i.result || [])));
          return onSubmit(teamsToDelete);
        }
      }}
      placeholder={
        <Placeholder
          Icon={() => (
            <SvgIcon
              url={usersIcon}
              width={'50px'}
              height={'50px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
          title={i18n._(t`No API available`)}
          subTitle={i18n._(
            isIntegrationError
              ? INTEGRATIONS_CONN_ERROR_MSG
              : t`Integrate Learned with your HR systems. Contact your customer success manager.`,
          )}
          subTitleStyles={{ ...(isIntegrationError && { color: COLORS.ACCENT_ERROR }) }}
        />
      }
      warningMessage={i18n._(
        t`Warning: Always check if the teams you are about to delete are no longer being used in Learned. Deleting teams cannot be undone.`,
      )}
      confirmMessage={
        isDeleteAllForIntegration
          ? i18n._(
              t`Warning: please note that the integration with ${isDeleteAllForIntegration} has provided 0 results. This is most likely incorrect and a system error in the integration. If you continue with the synchronisation you will DELETE ALL your imported teams. This action CANNOT be undone. Click ‘Cancel’ to return.`,
            )
          : i18n._(
              t`Are you sure you want to clean-up this teams from Learned? Deleting teams cannot be undone!`,
            )
      }
      integrationExplanation={i18n._(
        t`These team changes have been detected. Click cleanup to apply the changes.`,
      )}
      integrationDescriptionCol={i18n._(t`Delete role`)}
      integrationPlaceholder={
        <Placeholder
          Icon={() => (
            <SvgIcon
              url={usersIcon}
              width={'50px'}
              height={'50px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
          title={i18n._(t`No teams to clean up`)}
        />
      }
      getIntegrationCols={(_integration) => [
        {
          title: i18n._(t`Team`),
          width: '150px',
        },
        {
          title: i18n._(t`Type`),
          width: '120px',
        },
        {
          title: i18n._(t`Description`),
        },
      ]}
      getIntegrationRowData={(item) => [item.name, i18n._(t`Status`), i18n._(t`Delete team`)]}
      isSubmitDisabled={integrations.every((i) => isEmpty(i.result))}
      infoMessage={i18n._(
        t`Teams can only be cleaned-up if they have been imported via an API and can only be cleaned-up with that specific API`,
      )}
      infoUrl={getInstructionUrl(INSTRUCTIONS.CLEANUP_TEAMS)}
      pathToItems={itemsPath}
    />
  );
}

CleanUpTeamsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withI18n()(CleanUpTeamsModal);
