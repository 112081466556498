// table grid ie. the heatmap table
import React, { ChangeEvent, useState, useContext, useEffect, MouseEventHandler } from 'react';

import { REPORT_CHART_TYPES, ROLES } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Loader } from '~/components/Buttons/components/Loader';
import { ICONS, Icon } from '~/components/Icon';
import { TableGrid } from '~/components/TableGrid';
import { checkEmptyData } from '~/components/TableGrid/utils';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import {
  EmptyCell,
  NormalCell,
  NormalCellCtr,
  NotAvailableCtr,
  SearchFieldWrapper,
  SearchCtr,
  FilterBtnCtr,
  FilterCtr,
  HeatmapCtr,
  BodyCtr,
  NoDataTextContainer,
} from './CustomStyles';

import { ColumnPosition, IColumnTable } from '~/@types/table';
import useDebounce from '~/hooks/useDebounce';
import { useOutsideClick } from '~/hooks/useOutsideClick';
import { usePagination } from '~/hooks/usePagination';
import useSearchState from '~/hooks/useSearchState';
import { TLucaModalData, TSortingOrder, getEngagementDetails } from '~/services/reports';
import { COLORS } from '~/styles';
import { getDateForTimeFrame, getSelectedKeys, sanitizeDimensions } from '~/utils/reports';

import { isCorrectDimensionSelected, isFirst } from '../../common';
import { Cell } from '../../Components/Cell';
import ReFetchBtn from '../../Components/RefetchBtn';
import { MENU_SIZE, PAGINATION_PRIMARY_OPTIONS } from '../../options';
import { TEngagementData, TDataStruct, TData, EOptions, TOptions } from '../../types';
import { EngagementReportContext } from '../EngagementContext';
import { HeaderIncluded } from '../Header';
import { InformationModal } from '../InformationModal';
import { Luca } from '../Luca';

export type TFetchDataProp = {
  sortBy?: string;
  skip?: number;
  limit?: number;
  dt?: TDataStruct;
};

type TProps = { optionChangeHandler: (key: EOptions, value?: string | TOptions[] | null) => void };

const EngagementCustomPage = ({ optionChangeHandler }: TProps) => {
  const $search = useSearchState();
  const { i18n } = useLingui();
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [engagementData, setEngagementData] = useState<TEngagementData[]>([]);
  const [columns, setColumns] = useState<IColumnTable<any>[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const debouncedSearch = useDebounce($search.value, 1000);
  const [isHavingSecondary, setIsHavingSecondary] = useState(false);
  const [search, setSearch] = useState('');
  const [noDataMessage, setNoDataMessage] = useState('');

  const [informationModalData, setInformationModalData] = useState<TLucaModalData | null>(null);

  const { viewAs, shouldShowLuca, options, reportType, dimensions, filters, isWaiting } =
    useContext(EngagementReportContext);
  const { pagination, changePagination } = usePagination(PAGINATION_PRIMARY_OPTIONS[0].id);

  const shouldBenchmarkDisabled =
    dimensions.primary === 'theme' &&
    (dimensions.secondary === 'secondary_none' ||
      dimensions.secondary === 'primary_none' ||
      !dimensions.secondary) &&
    (dimensions.measure === 'month' ||
      dimensions.measure === 'quarter' ||
      dimensions.measure === 'year');

  const onItemClick = {
    column: 'primaryDimension',
    onClick: (d: TEngagementData) => {
      setInformationModalData({
        filters: {
          themeId: d.id,
        },
        name: d.name || '',
        value:
          d.cells.find((c) => c.measure === (viewAs === ROLES.USER ? 'you' : 'average'))?.value ||
          0,
      });
      setShowInformationModal(true);
    },
  };

  const getData = async () => {
    if (dimensions.primary === 'primary_none') {
      setNoDataMessage(i18n._(t`Please select a primary dimension`));
    } else if (dimensions.measure === 'measure_none' || !dimensions.measure) {
      setNoDataMessage(i18n._(t`Please select a measurement type`));
    } else {
      setNoDataMessage(i18n._(t`There is no data available yet. Please try again later.`));
    }
    if (!isCorrectDimensionSelected(dimensions.primary, dimensions.secondary, dimensions.measure)) {
      setEngagementData([]);
      setColumns([]);
      return;
    }
    setIsLoading(true);
    setTotalCount(0);
    const sortedArray = sortBy.split('__') || [];
    const payload = {
      viewAs,
      reportType,
      chartType: REPORT_CHART_TYPES.BY_TWO_DIMENSION,
      primaryDimension: sanitizeDimensions(dimensions.primary),
      secondaryDimension: sanitizeDimensions(dimensions.secondary),
      measure: sanitizeDimensions(dimensions.measure),
      dateRange: getDateForTimeFrame(filters.monthSelected),
      filters: {
        themes: getSelectedKeys(filters.themesOptionSelected),
        teams: getSelectedKeys(filters.teamsOptionSelected),
        surveys: getSelectedKeys(filters.surveysOptionSelected),
        jobs: getSelectedKeys(filters.jobsSelected),
        jobGroups: getSelectedKeys(filters.jobsGroupsSelected),
        genders: getSelectedKeys(filters.gendersSelected),
        ageGroups: getSelectedKeys(filters.ageGroupSelected),
        educationLevels: getSelectedKeys(filters.educationLevelsSelected),
        ...(debouncedSearch &&
          debouncedSearch.trim().length > 0 && { search: debouncedSearch || '' }),
      },
      sorting: {
        orderBy: sortedArray.length === 2 ? sortedArray[0] : '',
        order: sortedArray.length === 2 ? (sortedArray[1] as TSortingOrder) : ('' as TSortingOrder),
      },
      options: {
        includeCompanyAverage: options.includeCompanyAverage,
        includeBenchmark: shouldBenchmarkDisabled && options.includeBenchmark,
        isHeatmapColored: options.isHeatmapColored,
        ...(viewAs === ROLES.USER && { includeTeamAverage: options.includeTeamAverage }),
      },
      pagination,
    };
    try {
      const heatmap = await getEngagementDetails(payload);
      const heatmapData = heatmap.data as TData;
      const hasSecondaryColumn = heatmapData.columns?.filter(
        (item) => item.id === 'secondaryDimension',
      );
      const engagementRows = heatmapData.rows.map((item, i, arr) => ({
        ...item,
        showPrimary: hasSecondaryColumn.length === 0 ? true : isFirst(arr, i),
      }));
      setTotalCount(heatmapData.total || 0);
      setEngagementData(engagementRows);
      setIsHavingSecondary(hasSecondaryColumn.length > 0);
      const heatmapColumns = heatmapData.columns.map((item) => {
        if (item.id === 'primaryDimension' || item.id === 'secondaryDimension') {
          return {
            id: item.id,
            name: `${item.name}`,
            accessor: `${item.id}`,
            renderCell: (
              cell: TEngagementData,
              onClick: MouseEventHandler<HTMLDivElement> | undefined,
            ) => {
              if (item.id === 'primaryDimension' && !cell.showPrimary) {
                return null;
              }
              const secondaryColumnName =
                cell.secondaryName && cell.secondaryName.trim().length > 0
                  ? cell.secondaryName
                  : '';
              const displayName = item.id === 'primaryDimension' ? cell.name : secondaryColumnName;
              return (
                <NormalCellCtr
                  className="cell"
                  clickable={!!onClick}
                  onClick={() => {
                    /* @ts-ignore */
                    onClick ? onClick(cell) : {};
                  }}
                >
                  <Tooltip tooltip={displayName} size={TOOLTIP_SIZES.BIG}>
                    <NormalCell
                      mWidth={
                        isHavingSecondary
                          ? `${MENU_SIZE.LEFT_HALF_WIDTH}px`
                          : `${MENU_SIZE.LEFT_FULL_WIDTH}px`
                      }
                    >
                      {displayName}
                    </NormalCell>
                  </Tooltip>
                </NormalCellCtr>
              );
            },
            isFixed: true,
            centerAlign: true,
            position: ColumnPosition.LEFT,
            maxWidth: item.id === 'primaryDimension' ? '200px' : '100px',
            minWidth: '40px',
            padding: '7px',
            showHeaderTooltip: true,
            sortBy: {
              asc: {
                key: `${item.id}__asc`,
                label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
              },
              desc: {
                key: `${item.id}__desc`,
                label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
              },
            },
          };
        }
        return {
          id: item.id,
          name: `${item.name}`,
          accessor: `${item.id}`,
          renderCell: (row: TEngagementData) => {
            const cellValue = row.cells.find((c) => c.measure === item.id);
            if (!cellValue?.value) {
              if (item.id === 'benchmark') {
                return (
                  <Tooltip tooltip={i18n._(t`Not enough data available, please check back later`)}>
                    <EmptyCell>
                      <NotAvailableCtr>{i18n._(t`N/A`)}</NotAvailableCtr>
                    </EmptyCell>
                  </Tooltip>
                );
              }
              return (
                <Tooltip tooltip={i18n._(t`Reporting threshold has not been reached`)}>
                  <EmptyCell>
                    <Icon className="incognito" icon={ICONS.INCOGNITO} fill={COLORS.PLACEHOLDERS} />
                  </EmptyCell>
                </Tooltip>
              );
            }
            return (
              <Cell
                value={cellValue?.value || 0}
                noColor={!options.isHeatmapColored}
                onClick={() => {
                  onItemClick.onClick(row);
                }}
              >
                {cellValue?.value}%
              </Cell>
            );
          },
          isFixed: item.id === 'average' || item.id === 'benchmark',
          position: ColumnPosition.RIGHT,
          maxWidth: '20px',
          minWidth: '20px',
          padding: '7px',
          centerAlign: true,
          showHeaderTooltip: true,
          ...(!dimensions.secondary && {
            sortBy: {
              asc: {
                key: `${item.id}__desc`,
                label: (i18n: I18n) => i18n._(t`High to low`),
              },
              desc: {
                key: `${item.id}__asc`,
                label: (i18n: I18n) => i18n._(t`Low to high`),
              },
            },
          }),
        };
      });
      setColumns(heatmapColumns);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isWaiting) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isWaiting,
    options.includeBenchmark,
    options.includeCompanyAverage,
    options.includeTeamAverage,
    filters.ageGroupSelected,
    filters.educationLevelsSelected,
    filters.gendersSelected,
    filters.jobsGroupsSelected,
    filters.jobsSelected,
    filters.monthSelected,
    filters.surveysOptionSelected,
    filters.teamsOptionSelected,
    filters.themesOptionSelected,
    dimensions.measure,
    dimensions.primary,
    dimensions.secondary,
    pagination.index,
    options.isHeatmapColored,
    debouncedSearch,
    sortBy,
  ]);

  useEffect(() => {
    optionChangeHandler(EOptions.reset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const baseRef = useOutsideClick<HTMLDivElement>(() => {
    setShowInformationModal(false);
  });

  const getRightMinWidth = () => {
    if (options.includeBenchmark && options.includeCompanyAverage) {
      return `${MENU_SIZE.RIGHT_FULL_WIDTH}px`;
    }
    if (options.includeCompanyAverage) {
      return `${MENU_SIZE.ONLY_AVERAGE}px`;
    }
    if (options.includeBenchmark) {
      return `${MENU_SIZE.ONLY_BENCHMARK}px`;
    }
    return '0px';
  };

  const isLoadingState = isWaiting || isLoading;

  if (!isLoading && engagementData.length === 0) {
    return (
      <HeaderIncluded isLoading={isLoading}>
        <HeatmapCtr>
          <BodyCtr>
            <NoDataTextContainer>{noDataMessage}</NoDataTextContainer>
            <ReFetchBtn optionChangeHandler={() => getData()} />
          </BodyCtr>
        </HeatmapCtr>
      </HeaderIncluded>
    );
  }

  const onCellClick = (row: TEngagementData, column: any) => {
    setInformationModalData({
      // TODO: LR-6013 dimensions and measure based filtering
      filters: {
        themeId: row.id,
        ...(dimensions.measure === 'team' ? { teamId: column.measure } : {}),
      },
      name: row.name || '',
      value: row.cells.find((c) => c.measure === column.accessor)?.value || 0,
    });
    setShowInformationModal(true);
  };

  const mainTablePlaceholder = checkEmptyData({
    data: engagementData,
    column: 'cells',
    field: 'measure',
    skipColumns: [],
    reportingThreshold: 0,
    i18n,
  });

  return (
    <HeaderIncluded isLoading={isLoading}>
      {isLoadingState ? (
        <BodyCtr>
          <Loader />
        </BodyCtr>
      ) : (
        <>
          <HeatmapCtr>
            <FilterCtr>
              <FilterBtnCtr>
                <SearchCtr>
                  <SearchFieldWrapper
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      $search.set(e.currentTarget.value);
                    }}
                    value={$search.value}
                    placeholder={i18n._(t`Search...`)}
                  />
                </SearchCtr>
              </FilterBtnCtr>
            </FilterCtr>
            <TableGrid
              data={engagementData}
              columns={columns}
              onColClick={onItemClick}
              onCellClick={onCellClick}
              isLeftColumnsStriped
              isScrollbarVisible
              showSortBy={true}
              leftMinWidth={
                isHavingSecondary
                  ? `${MENU_SIZE.LEFT_HALF_WIDTH * 2 + 10}px`
                  : `${MENU_SIZE.LEFT_FULL_WIDTH + 10}px`
              }
              rightMinWidth={getRightMinWidth()}
              isLoading={isLoading}
              paginationProps={{
                pagination,
                changePagination,
                totalCount,
              }}
              placeholderProps={{
                noResultText: i18n._(t`No surveys found`),
                emptyStateText: i18n._(t`No surveys yet… Let’s create one!`),
                mainTable: mainTablePlaceholder,
              }}
              filtersProps={{
                filters: {
                  search,
                  setSearch: (value) => setSearch(value),
                },
                isToggleHideFilterVisible: false,
                isFiltered: false,
              }}
              showTopArea={false}
              sortBy={''}
              setSortBy={(value) => {
                setSortBy(value);
              }}
              paginationIndexAlternatives={PAGINATION_PRIMARY_OPTIONS.map((item) => ({
                id: item.id,
                label: item.label(i18n),
              }))}
            />
          </HeatmapCtr>
          {shouldShowLuca && <Luca />}
          {showInformationModal && informationModalData && (
            <InformationModal
              data={informationModalData}
              onClose={setShowInformationModal}
              baseRef={baseRef}
            />
          )}
        </>
      )}
    </HeaderIncluded>
  );
};

export default EngagementCustomPage;
