/**
 @deprecated Use Locals in package/constants (if possible)
  We would need to replace Locals_OLD with Locals in future
 */
export enum LOCALS_SHORT {
  en = 'en',
  nl = 'nl',
}
export const DEFAULT_LOCALE = LOCALS_SHORT.en;

export enum Locals {
  en_GB = 'en_GB',
  nl_NL = 'nl_NL',
  de_DE = 'de_DE',
}

// TODO
export enum Locals_all {
  nl_NL = 'nl_NL',
  en_GB = 'en_GB',
  de_DE = 'de_DE',
  bg_BG = 'bg_BG',
  hr_HR = 'hr_HR',
  cs_CZ = 'cs_CZ',
  da_DK = 'da_DK',
  fi_FI = 'fi_FI',
  fr_FR = 'fr_FR',
  el_GR = 'el_GR',
  hu_HU = 'hu_HU',
  it_IT = 'it_IT',
  lv_LV = 'lv_LV',
  lt_LT = 'lt_LT',
  nb_NO = 'nb_NO',
  pl_PL = 'pl_PL',
  pt_PT = 'pt_PT',
  ro_RO = 'ro_RO',
  ru_RU = 'ru_RU',
  sr_RS = 'sr_RS',
  sk_SK = 'sk_SK',
  es_ES = 'es_ES',
  sv_SE = 'sv_SE',
  tr_TR = 'tr_TR',
  uk_UA = 'uk_UA',
}
