import styled from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 45px 85px;
  box-sizing: border-box;
`;

const Header = styled.div<{ gradient: string }>`
  height: 80px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-image: ${({ gradient }) => gradient};
  padding: 0 32px;
  box-sizing: border-box;
  justify-content: space-between;
  button {
    background-color: transparent;
    color: ${COLORS.WHITE};
    border: none;
    font-weight: 600;
    padding: 3px 40px 3px 13px;
    display: flex;
    align-items: center;
  }
  button > div:nth-of-type(2) {
    background: ${COLORS.WHITE};
  }
`;

const TeamHeader = styled.div`
  color: ${COLORS.WHITE};
  font-size: 22px;
`;

const DropDownContainer = styled.div`
  display: flex;
  gap: 32px;
`;

const FilterContainer = styled.div`
  & > div {
    background: transparent;
    border: transparent;
    font-size: 22px;
    color: ${COLORS.WHITE};
    font-weight: 400;
    min-width: 250px;
    gap: 4px;
    align-items: center;

    & > svg {
      color: ${COLORS.WHITE};
    }
  }
`;

const Separator = styled.div`
  width: 1px;
  height: auto;
  background: ${COLORS.WHITE};
`;

const AllTeams = styled.div`
  color: ${COLORS.WHITE};
  font-size: 12px;
  display: flex;
  align-items: center;
  flex: none;
  gap: 8px;
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  box-sizing: border-box;
  padding: 32px 0;
  align-items: stretch;
`;

const LoaderContainer = styled.div`
  position: relative;
  min-height: calc(100vh - 260px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TileLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const EmptyMessageHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 200px;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;

const EmptyMessageHeader = styled.div`
  font-weight: 600;
  font-size: 22px;
  color: ${COLORS.ICONS_PRIMARY};
  max-width: 500px;
  text-align: center;
`;

const EmptyMessageSubTitle = styled.div`
  color: ${COLORS.SUBTEXT};
  font-size: 14px;
  max-width: 500px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const EmptyTeamHolder = styled.div`
  color: ${COLORS.WHITE};
  font-size: 22px;
  font-weight: 600;
`;

const Navigator = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export {
  Navigator,
  EmptyTeamHolder,
  Wrapper,
  Header,
  CardWrapper,
  AllTeams,
  DropDownContainer,
  FilterContainer,
  Separator,
  LoaderContainer,
  TileLoader,
  EmptyMessageHeader,
  EmptyMessageHolder,
  EmptyMessageSubTitle,
  TeamHeader,
};
