import { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_STATUS,
  REVIEW_TYPES,
} from '@learned/constants';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { ICONS } from '~/components/Icon';
import { transformToISOString } from '~/pages/Reviews/utils';

import useBoolState from '~/hooks/useBoolState';
import { useLanguageState } from '~/hooks/useLanguageState';
import { getUser } from '~/selectors/baseGetters';
import { deleteReviewById, fetchReviewById, updateReviewById } from '~/services/reviews';
import { getUserReviews } from '~/services/userReviews';
import { turnArrayIntoMultiLang, turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type { IReviewIndividualForm } from '../types';
import type { IReview, IUserReview } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface UseReviewProps {
  formMethods: UseFormReturn<IReviewIndividualForm>;
  reviewId: IReview['id'];
}

export const useReview = ({ formMethods, reviewId }: UseReviewProps) => {
  const { setValue, watch } = formMethods;
  const languageState = useLanguageState(true);

  const [item, setItem] = useState<IReview>();
  const user = useSelector(getUser);

  const [isAllowToDelete, setIsAllowToDelete] = useState(false);
  const $isReviewLoading = useBoolState(true);

  useEffect(() => {
    setIsAllowToDelete(item && (user.isAdmin || item?.createdBy === user.id));
  }, [item, user]);

  const fetchReview = async () => {
    const result = await fetchReviewById(reviewId);
    const review: IReview = result.data[API_RETURN_FIELDS.REVIEW];
    setItem(review);
    return review;
  };

  const fetchUserReview = async () => {
    const result = await getUserReviews({ filters: { review: [reviewId] }, options: {} });
    const userReview: IUserReview = result.data[API_RETURN_FIELDS.USER_REVIEWS]?.[0];
    return userReview;
  };

  const setFormValues = async () => {
    const review = await fetchReview();
    const userReview = await fetchUserReview();
    setValue('notifications', review.notifications);
    setValue('name', turnMultiLangIntoArray(review.name, languageState.companyLanguages));
    setValue('reviewTemplate', review.reviewTemplate);
    setValue('privacy', review.privacy);
    setValue('settings', {
      ...review.settings,
      startDate: new Date(review.settings.startDate),
      endDate: review.settings.endDate ? new Date(review.settings.endDate) : null,
    });
    setValue('status', review.status);
    setValue('userReviewId', userReview?.id);
    setValue('fetchedReview', review);
    $isReviewLoading.off();
  };

  const saveReview = async (status?: REVIEW_STATUS) => {
    const evaluators = watch('evaluators').map(
      (evaluator: { value: REVIEW_QUESTION_EVALUATORS; icon: ICONS; title: string }) =>
        evaluator.value,
    );
    const review = {
      name: turnArrayIntoMultiLang(watch('name')),
      notifications: watch('notifications'),
      reviewTemplate: watch('reviewTemplate') || null,
      privacy: watch('privacy'),
      settings: {
        ...watch('settings'),
        startDate: transformToISOString(watch('settings.startDate')),
        endDate: transformToISOString(watch('settings.endDate')),
      },
      status: status ? status : watch('status'),
      description: {},
      tasks: {},
      type: REVIEW_TYPES.SELF,
      reviewInvitationTemplate: null,
    };

    if (evaluators.includes(REVIEW_QUESTION_EVALUATORS.PEER)) {
      // @ts-ignore
      review.tasks.reviewPeerEvaluate = {
        startDate: transformToISOString(watch('tasks.reviewPeerEvaluate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewPeerEvaluate.endDate')),
      };
      // @ts-ignore
      review.tasks.reviewPeerNominate = {
        startDate: transformToISOString(watch('tasks.reviewPeerNominate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewPeerNominate.endDate')),
        description: turnArrayIntoMultiLang(watch('tasks.reviewPeerNominate.description') || []),
      };
    }

    if (evaluators.includes(REVIEW_QUESTION_EVALUATORS.COACH)) {
      // @ts-ignore
      review.tasks.reviewCoachEvaluate = {
        startDate: transformToISOString(watch('tasks.reviewCoachEvaluate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewCoachEvaluate.endDate')),
      };
    }

    if (evaluators.includes(REVIEW_QUESTION_EVALUATORS.EMPLOYEE)) {
      // @ts-ignore
      review.tasks.reviewSelfEvaluate = {
        startDate: transformToISOString(watch('tasks.reviewSelfEvaluate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewSelfEvaluate.endDate')),
      };
    }

    const employee = watch('employees')?.[0];

    const editUserReviews = [];

    if (
      !isEmpty(employee?.coaches) &&
      !isEmpty(employee?.guests) &&
      !isEmpty(employee?.careerPlans)
    ) {
      editUserReviews.push({
        id: watch('userReviewId'),
        coaches: employee.coaches,
        guests: employee.guests,
        careerPlans: employee.careerPlans?.map((careerPlan) => careerPlan.id) || [],
      });
    }

    return await updateReviewById(reviewId, {
      // @ts-ignore
      review,
      editUserReviews,
    });
  };

  const deleteReview = async () => {
    if (item && isAllowToDelete) {
      await deleteReviewById(item.id);
    }
  };

  useEffect(() => {
    setFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId]);

  return {
    deleteReview,
    saveReview,
    isAllowToDelete,
    isReviewLoading: $isReviewLoading.value,
  };
};
