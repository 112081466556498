import React from 'react';

import { REVIEW_STATUS } from '@learned/constants';
import { useForm } from 'react-hook-form';

import { ReviewCycleForm } from './ReviewCycleForm';
import { resolver } from './ReviewCycleForm/validations';

import { useLanguageState } from '~/hooks/useLanguageState';

import type { IReviewCycleForm } from './ReviewCycleForm/types';

const ReviewCycleCreate = () => {
  const languageState = useLanguageState();

  const formMethods = useForm<IReviewCycleForm>({
    mode: 'all',
    resolver,
    defaultValues: {
      employees: [],
      evaluators: [],
      reviewQuestionTypes: [],
      skills: [],
      isExpandMode: false,
      status: REVIEW_STATUS.DRAFT,
      userReviews: [],
      settings: {
        isCoachesAskedToScheduleReview: false,
        isCalibrate: false,
        isDigitalSign: false,
        isAutoArchive: true,
      },
      notifications: {
        isOn7DaysBeforeTaskDeadline: true,
        isOnTaskStartDate: true,
        isOnTaskDeadline: true,
      },
      privacy: {
        isAllEmployeeCoachesCanSeeReview: false,
      },
      tasks: {
        reviewPeerNominate: {
          description: languageState.languages.map(({ locale }) => ({ locale, value: '' })),
        },
        isPreviouslyAutoGenerateEnabled: false,
        isAutoGenerate: true,
      },
      description: languageState.languages.map(({ locale }) => ({ locale, value: '' })),
    },
  });

  return <ReviewCycleForm formMethods={formMethods} languageState={languageState} />;
};

export { ReviewCycleCreate };
