import React, { useState, useEffect } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import map from 'lodash/map';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import Hint from '~/components/Hint';
import Modal from '~/components/Modal';
import Select from '~/components/Select';
import SwitchYesNo from '~/components/SwitchYesNo';
import { TextField } from '~/components/Text';
import Divider from '~/components/UI/Divider';

import AfasConnectionInfo from './ConnectionInfo/AfasConnectionInfo';
import AzureConnectionInfo from './ConnectionInfo/AzureConnectionInfo';
import NmbrsConnectionInfo from './ConnectionInfo/NmbrsConnectionInfo';
import SdworxConnectionInfo from './ConnectionInfo/SdworxConnectionInfo';

import {
  INTEGRATIONS,
  DISABLED_DELETE_INTEGRATIONS,
  DISABLED_TEAMS_INTEGRATIONS,
  DISABLED_ROLES_INTEGRATIONS,
} from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { createIntegrationSettings, testIntegrationSettings } from '~/services/integrationSettings';
import { COLOR_PALETTE } from '~/styles';

const MainContent = styled.div``;
const TypeSelection = styled.div``;
const TypeLabel = styled.div`
  margin: 8px;
`;
const TypeSelectionWrapper = styled.div`
  margin: 8px;
`;
const SettingLabel = styled.div``;
const SettingBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin: 4px;
  margin-left: 8px;
  margin-top: 8px;
`;

const IntegrationSettingsSection = styled.div`
  margin-top: 16px;
  padding: 8px;
  border-radius: 6px;
  background-color: #f6f8fc;
`;

const SettingTextField = styled(TextField)`
  width: 100%;
  height: 48px;
`;
const ButtonSection = styled.div`
  display: flex;
`;

const IntegrationSettingsHeader = styled.div`
  font-size: 1.15em;
  font-weight: 600;
  margin: 8px;
  margin-bottom: 16px;
`;

const TestStateBoxInvalid = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin: 4px;
  color: red;
`;

const TestStateBoxValid = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin: 4px;
  color: green;
`;

const ModulesWrapper = styled.div`
  background-color: ${COLOR_PALETTE.WHITE};
  padding: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 6px;
`;

const ModulesHeader = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr 2fr;
`;

const ModulesHeaderItem = styled.div`
  font-size: 0.8em;
  font-weight: 300;
`;

const ToggleOption = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr 2fr;
  margin: 16px;
`;

const ToggleLabel = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
`;

const ToggleSetting = styled.div``;

function AddIntegrationModal({ onClose, i18n }) {
  const loadingTest = useBoolState();
  const loadingAdd = useBoolState();
  const [selectedType, setSelectedType] = useState(INTEGRATIONS.AZURE_AD.key);
  const [integrationSettings, setIntegrationSettings] = useState({
    integrationModules: {
      importUsers: { enabled: true, automatic: false },
      syncUsers: { enabled: true, automatic: true },
      deleteUsers: { enabled: true, automatic: false },
      importTeams: { enabled: true, automatic: false },
      syncTeams: { enabled: true, automatic: false },
      deleteTeams: { enabled: true, automatic: false },
      importRoles: { enabled: true, automatic: false },
      syncRoles: { enabled: true, automatic: false },
      deleteRoles: { enabled: true, automatic: false },
    },
    connectionInfo: {},
  });
  const [testState, setTestState] = useState({ tested: false, isValid: false });

  useEffect(() => {
    const rolesEnabled = !DISABLED_ROLES_INTEGRATIONS.includes(selectedType);
    const teamsEnabled = !DISABLED_TEAMS_INTEGRATIONS.includes(selectedType);
    const deleteUsersEnabled = !DISABLED_DELETE_INTEGRATIONS.includes(selectedType);

    setIntegrationSettings({
      integrationModules: {
        importUsers: { enabled: true, automatic: false },
        syncUsers: { enabled: true, automatic: true },
        deleteUsers: { enabled: deleteUsersEnabled, automatic: false },
        importTeams: { enabled: teamsEnabled, automatic: false },
        syncTeams: { enabled: teamsEnabled, automatic: false },
        deleteTeams: { enabled: teamsEnabled, automatic: false },
        importRoles: { enabled: rolesEnabled, automatic: false },
        syncRoles: { enabled: rolesEnabled, automatic: false },
        deleteRoles: { enabled: rolesEnabled, automatic: false },
      },
      connectionInfo: {},
    });
  }, [selectedType]);

  return (
    <Modal
      title={i18n._(t`Add new integration`)}
      onClose={onClose}
      footerLeft={<Button type="primary-border" label={i18n._(t`Cancel`)} onClick={onClose} />}
      footerRight={
        <ButtonSection>
          {testState.tested && testState.isValid && (
            <TestStateBoxValid>
              {i18n._(
                t`Connection to ${Object.values(INTEGRATIONS)
                  .find((x) => x.key === selectedType)
                  .name(i18n)} successful!`,
              )}
            </TestStateBoxValid>
          )}
          {testState.tested && !testState.isValid && (
            <TestStateBoxInvalid>
              {i18n._(
                t`Could not connect to ${Object.values(INTEGRATIONS)
                  .find((x) => x.key === selectedType)
                  .name(i18n)}!`,
              )}
            </TestStateBoxInvalid>
          )}
          <Button
            type="primary"
            width="auto"
            styles={{ minWidth: '67px', margin: '4px' }}
            label={i18n._(t`Test integration settings`)}
            loading={loadingTest.value || loadingAdd.value}
            onClick={async () => {
              loadingTest.on();
              const result = await testIntegrationSettings({
                externalSoftware: selectedType,
                ...integrationSettings,
              });
              setTestState({ tested: true, isValid: result.isValid });
              loadingTest.off();
            }}
          />
          <Button
            type="primary"
            width="auto"
            styles={{ minWidth: '67px', margin: '4px' }}
            label={i18n._(t`Add integration`)}
            loading={loadingAdd.value}
            onClick={async () => {
              loadingTest.on();
              loadingAdd.on();
              const result = await testIntegrationSettings({
                externalSoftware: selectedType,
                ...integrationSettings,
              });
              setTestState({ tested: true, isValid: result.isValid });
              if (result.isValid) {
                if (!integrationSettings.name || integrationSettings.name === '') {
                  integrationSettings.name = i18n._(t`Integration`);
                }
                await createIntegrationSettings({
                  externalSoftware: selectedType,
                  ...integrationSettings,
                });
                onClose();
              } else {
                alert(i18n._(t`The integration settings could not be verified...`));
              }
              loadingAdd.off();
              loadingTest.off();
            }}
          />
        </ButtonSection>
      }
    >
      <MainContent>
        <TypeSelection>
          <TypeLabel>
            <Trans>Select the system to integrate with</Trans>
          </TypeLabel>
          <TypeSelectionWrapper>
            <Select
              options={map(INTEGRATIONS, (integration) => ({
                value: integration.key,
                name: integration.name(i18n),
              }))}
              onChange={(e) => setSelectedType(e)}
              value={selectedType}
              height="36px"
            />
          </TypeSelectionWrapper>
        </TypeSelection>
        <IntegrationSettingsSection>
          <IntegrationSettingsHeader>
            {Object.values(INTEGRATIONS)
              .find((x) => x.key === selectedType)
              .name(i18n)}{' '}
            <Trans>Connection Information</Trans>
          </IntegrationSettingsHeader>
          <SettingBlock>
            <SettingLabel>
              <Trans>Name</Trans>
              <Hint>
                <Trans>
                  Type any name here. Choose something recognizable to easily refer back to this
                  integration at a later time.
                </Trans>
              </Hint>
            </SettingLabel>
            <SettingTextField
              placeholder={i18n._(t`Type any name for this integration...`)}
              onChange={(e) => {
                setTestState({ tested: false, isValid: false });
                setIntegrationSettings({ ...integrationSettings, name: e.target.value });
              }}
            />
          </SettingBlock>
          <SettingBlock>
            <SettingLabel>
              <Trans>Filter</Trans>
              <Hint>
                <Trans>
                  The value of this field is used to filter out email addresses of users in your
                  external system. For example, type your domain name (ie. @learned.io) to only
                  include users with email addresses that contain this domain name. This field is
                  optional.
                </Trans>
              </Hint>
            </SettingLabel>
            <SettingTextField
              placeholder={i18n._(t`(Optional) Enter domain name filter for user emails...`)}
              onChange={(e) => {
                setTestState({ tested: false, isValid: false });
                setIntegrationSettings({ ...integrationSettings, domainFilter: e.target.value });
              }}
            />
          </SettingBlock>
          {selectedType === INTEGRATIONS.AZURE_AD.key && (
            <AzureConnectionInfo
              testHook={[testState, setTestState]}
              settingsHook={[integrationSettings, setIntegrationSettings]}
              updateMode={false}
            />
          )}
          {selectedType === INTEGRATIONS.NMBRS.key && (
            <NmbrsConnectionInfo
              testHook={[testState, setTestState]}
              settingsHook={[integrationSettings, setIntegrationSettings]}
              updateMode={false}
            />
          )}
          {selectedType === INTEGRATIONS.AFAS.key && (
            <AfasConnectionInfo
              testHook={[testState, setTestState]}
              settingsHook={[integrationSettings, setIntegrationSettings]}
              updateMode={false}
            />
          )}
          {selectedType === INTEGRATIONS.SDWORX.key && (
            <SdworxConnectionInfo
              testHook={[testState, setTestState]}
              settingsHook={[integrationSettings, setIntegrationSettings]}
              updateMode={false}
            />
          )}
          <SettingBlock>
            <SettingLabel>
              <Trans>Modules</Trans>
            </SettingLabel>
            <ModulesWrapper>
              <ModulesHeader>
                <ModulesHeaderItem>
                  <Trans>Module</Trans>
                </ModulesHeaderItem>
                <ModulesHeaderItem>
                  <Trans>Enabled</Trans>
                  <Hint>
                    <Trans>
                      When an integration module is enabled, extra buttons appear on the Company
                      settings, Members page to synchronize or delete users.
                    </Trans>
                  </Hint>
                </ModulesHeaderItem>
                <ModulesHeaderItem>
                  <Trans>Automatic</Trans>
                  <Hint>
                    <Trans>
                      When automatic mode for an integration module is enabled, our servers will run
                      this integration every day after midnight and update the user list in Learned
                      to match those in your external system. If automatic mode is disabled, manual
                      action is required to synchronize or delete the users.
                    </Trans>
                  </Hint>
                </ModulesHeaderItem>
              </ModulesHeader>
              <ToggleOption>
                <ToggleLabel>
                  <Trans>Invite module</Trans>
                </ToggleLabel>
                <ToggleSetting>
                  <SwitchYesNo
                    width={64}
                    value={integrationSettings.integrationModules.importUsers.enabled}
                    onChange={() => {
                      setIntegrationSettings({
                        ...integrationSettings,
                        integrationModules: {
                          ...integrationSettings.integrationModules,
                          importUsers: {
                            ...integrationSettings.integrationModules.importUsers,
                            enabled: !integrationSettings.integrationModules.importUsers.enabled,
                            automatic: integrationSettings.integrationModules.importUsers.enabled
                              ? false
                              : integrationSettings.integrationModules.importUsers.automatic,
                          },
                        },
                      });
                    }}
                  />
                </ToggleSetting>
                <ToggleSetting>
                  <SwitchYesNo
                    disabled={!integrationSettings.integrationModules.importUsers.enabled}
                    width={64}
                    value={integrationSettings.integrationModules.importUsers.automatic}
                    onChange={() => {
                      setIntegrationSettings({
                        ...integrationSettings,
                        integrationModules: {
                          ...integrationSettings.integrationModules,
                          importUsers: {
                            ...integrationSettings.integrationModules.importUsers,
                            automatic:
                              !integrationSettings.integrationModules.importUsers.automatic,
                          },
                        },
                      });
                    }}
                  />
                </ToggleSetting>
              </ToggleOption>
              <ToggleOption>
                <ToggleLabel>
                  <Trans>Synchronize Users</Trans>
                </ToggleLabel>
                <ToggleSetting>
                  <SwitchYesNo
                    width={64}
                    value={integrationSettings.integrationModules.syncUsers.enabled}
                    onChange={() => {
                      setIntegrationSettings({
                        ...integrationSettings,
                        integrationModules: {
                          ...integrationSettings.integrationModules,
                          syncUsers: {
                            ...integrationSettings.integrationModules.syncUsers,
                            enabled: !integrationSettings.integrationModules.syncUsers.enabled,
                            automatic: integrationSettings.integrationModules.syncUsers.enabled
                              ? false
                              : integrationSettings.integrationModules.syncUsers.automatic,
                          },
                        },
                      });
                    }}
                  />
                </ToggleSetting>
                <ToggleSetting>
                  <SwitchYesNo
                    disabled={!integrationSettings.integrationModules.syncUsers.enabled}
                    width={64}
                    value={integrationSettings.integrationModules.syncUsers.automatic}
                    onChange={() => {
                      setIntegrationSettings({
                        ...integrationSettings,
                        integrationModules: {
                          ...integrationSettings.integrationModules,
                          syncUsers: {
                            ...integrationSettings.integrationModules.syncUsers,
                            automatic: !integrationSettings.integrationModules.syncUsers.automatic,
                          },
                        },
                      });
                    }}
                  />
                </ToggleSetting>
              </ToggleOption>

              {!DISABLED_DELETE_INTEGRATIONS.includes(selectedType) && (
                <ToggleOption>
                  <ToggleLabel>
                    <Trans>Delete user accounts</Trans>
                  </ToggleLabel>
                  <ToggleSetting>
                    <SwitchYesNo
                      width={64}
                      value={integrationSettings.integrationModules.deleteUsers.enabled}
                      onChange={() => {
                        setIntegrationSettings({
                          ...integrationSettings,
                          integrationModules: {
                            ...integrationSettings.integrationModules,
                            deleteUsers: {
                              ...integrationSettings.integrationModules.deleteUsers,
                              enabled: !integrationSettings.integrationModules.deleteUsers.enabled,
                              automatic: integrationSettings.integrationModules.deleteUsers.enabled
                                ? false
                                : integrationSettings.integrationModules.deleteUsers.automatic,
                            },
                          },
                        });
                      }}
                    />
                  </ToggleSetting>
                  <ToggleSetting>
                    <SwitchYesNo
                      disabled={!integrationSettings.integrationModules.deleteUsers.enabled}
                      width={64}
                      value={integrationSettings.integrationModules.deleteUsers.automatic}
                      onChange={() => {
                        setIntegrationSettings({
                          ...integrationSettings,
                          integrationModules: {
                            ...integrationSettings.integrationModules,
                            deleteUsers: {
                              ...integrationSettings.integrationModules.deleteUsers,
                              automatic:
                                !integrationSettings.integrationModules.deleteUsers.automatic,
                            },
                          },
                        });
                      }}
                    />
                  </ToggleSetting>
                </ToggleOption>
              )}
              {!DISABLED_TEAMS_INTEGRATIONS.includes(selectedType) && (
                <>
                  <Divider />
                  <ToggleOption>
                    <ToggleLabel>
                      <Trans>Import Teams</Trans>
                    </ToggleLabel>
                    <ToggleSetting>
                      <SwitchYesNo
                        width={64}
                        value={integrationSettings.integrationModules.importTeams.enabled}
                        onChange={() => {
                          setIntegrationSettings({
                            ...integrationSettings,
                            integrationModules: {
                              ...integrationSettings.integrationModules,
                              importTeams: {
                                ...integrationSettings.integrationModules.importTeams,
                                enabled:
                                  !integrationSettings.integrationModules.importTeams.enabled,
                                automatic: integrationSettings.integrationModules.importTeams
                                  .enabled
                                  ? false
                                  : integrationSettings.integrationModules.importTeams.automatic,
                              },
                            },
                          });
                        }}
                      />
                    </ToggleSetting>
                    <ToggleSetting>
                      <SwitchYesNo
                        disabled={!integrationSettings.integrationModules.importTeams.enabled}
                        width={64}
                        value={integrationSettings.integrationModules.importTeams.automatic}
                        onChange={() => {
                          setIntegrationSettings({
                            ...integrationSettings,
                            integrationModules: {
                              ...integrationSettings.integrationModules,
                              importTeams: {
                                ...integrationSettings.integrationModules.importTeams,
                                automatic:
                                  !integrationSettings.integrationModules.importTeams.automatic,
                              },
                            },
                          });
                        }}
                      />
                    </ToggleSetting>
                  </ToggleOption>
                  <ToggleOption>
                    <ToggleLabel>
                      <Trans>Synchronize Teams</Trans>
                    </ToggleLabel>
                    <ToggleSetting>
                      <SwitchYesNo
                        width={64}
                        value={integrationSettings.integrationModules.syncTeams.enabled}
                        onChange={() => {
                          setIntegrationSettings({
                            ...integrationSettings,
                            integrationModules: {
                              ...integrationSettings.integrationModules,
                              syncTeams: {
                                ...integrationSettings.integrationModules.syncTeams,
                                enabled: !integrationSettings.integrationModules.syncTeams.enabled,
                                automatic: integrationSettings.integrationModules.syncTeams.enabled
                                  ? false
                                  : integrationSettings.integrationModules.syncTeams.automatic,
                              },
                            },
                          });
                        }}
                      />
                    </ToggleSetting>
                    <ToggleSetting>
                      <SwitchYesNo
                        disabled={!integrationSettings.integrationModules.syncTeams.enabled}
                        width={64}
                        value={integrationSettings.integrationModules.syncTeams.automatic}
                        onChange={() => {
                          setIntegrationSettings({
                            ...integrationSettings,
                            integrationModules: {
                              ...integrationSettings.integrationModules,
                              syncTeams: {
                                ...integrationSettings.integrationModules.syncTeams,
                                automatic:
                                  !integrationSettings.integrationModules.syncTeams.automatic,
                              },
                            },
                          });
                        }}
                      />
                    </ToggleSetting>
                  </ToggleOption>
                  <ToggleOption>
                    <ToggleLabel>
                      <Trans>Delete Teams</Trans>
                    </ToggleLabel>
                    <ToggleSetting>
                      <SwitchYesNo
                        width={64}
                        value={integrationSettings.integrationModules.deleteTeams.enabled}
                        onChange={() => {
                          setIntegrationSettings({
                            ...integrationSettings,
                            integrationModules: {
                              ...integrationSettings.integrationModules,
                              deleteTeams: {
                                ...integrationSettings.integrationModules.deleteTeams,
                                enabled:
                                  !integrationSettings.integrationModules.deleteTeams.enabled,
                                automatic: integrationSettings.integrationModules.deleteTeams
                                  .enabled
                                  ? false
                                  : integrationSettings.integrationModules.deleteTeams.automatic,
                              },
                            },
                          });
                        }}
                      />
                    </ToggleSetting>
                    <ToggleSetting>
                      <SwitchYesNo
                        disabled={!integrationSettings.integrationModules.deleteTeams.enabled}
                        width={64}
                        value={integrationSettings.integrationModules.deleteTeams.automatic}
                        onChange={() => {
                          setIntegrationSettings({
                            ...integrationSettings,
                            integrationModules: {
                              ...integrationSettings.integrationModules,
                              deleteTeams: {
                                ...integrationSettings.integrationModules.deleteTeams,
                                automatic:
                                  !integrationSettings.integrationModules.deleteTeams.automatic,
                              },
                            },
                          });
                        }}
                      />
                    </ToggleSetting>
                  </ToggleOption>
                </>
              )}
              {!DISABLED_ROLES_INTEGRATIONS.includes(selectedType) && (
                <>
                  <Divider />
                  <ToggleOption>
                    <ToggleLabel>
                      <Trans>Import Roles</Trans>
                    </ToggleLabel>
                    <ToggleSetting>
                      <SwitchYesNo
                        width={64}
                        value={integrationSettings.integrationModules.importRoles.enabled}
                        onChange={() => {
                          setIntegrationSettings({
                            ...integrationSettings,
                            integrationModules: {
                              ...integrationSettings.integrationModules,
                              importRoles: {
                                ...integrationSettings.integrationModules.importRoles,
                                enabled:
                                  !integrationSettings.integrationModules.importRoles.enabled,
                                automatic: integrationSettings.integrationModules.importRoles
                                  .enabled
                                  ? false
                                  : integrationSettings.integrationModules.importRoles.automatic,
                              },
                            },
                          });
                        }}
                      />
                    </ToggleSetting>
                    <ToggleSetting>
                      <SwitchYesNo
                        disabled={!integrationSettings.integrationModules.importRoles.enabled}
                        width={64}
                        value={integrationSettings.integrationModules.importRoles.automatic}
                        onChange={() => {
                          setIntegrationSettings({
                            ...integrationSettings,
                            integrationModules: {
                              ...integrationSettings.integrationModules,
                              importRoles: {
                                ...integrationSettings.integrationModules.importRoles,
                                automatic:
                                  !integrationSettings.integrationModules.importRoles.automatic,
                              },
                            },
                          });
                        }}
                      />
                    </ToggleSetting>
                  </ToggleOption>
                  <ToggleOption>
                    <ToggleLabel>
                      <Trans>Synchronize Roles</Trans>
                    </ToggleLabel>
                    <ToggleSetting>
                      <SwitchYesNo
                        width={64}
                        value={integrationSettings.integrationModules.syncRoles.enabled}
                        onChange={() => {
                          setIntegrationSettings({
                            ...integrationSettings,
                            integrationModules: {
                              ...integrationSettings.integrationModules,
                              syncRoles: {
                                ...integrationSettings.integrationModules.syncRoles,
                                enabled: !integrationSettings.integrationModules.syncRoles.enabled,
                                automatic: integrationSettings.integrationModules.syncRoles.enabled
                                  ? false
                                  : integrationSettings.integrationModules.syncRoles.automatic,
                              },
                            },
                          });
                        }}
                      />
                    </ToggleSetting>
                    <ToggleSetting>
                      <SwitchYesNo
                        disabled={!integrationSettings.integrationModules.syncRoles.enabled}
                        width={64}
                        value={integrationSettings.integrationModules.syncRoles.automatic}
                        onChange={() => {
                          setIntegrationSettings({
                            ...integrationSettings,
                            integrationModules: {
                              ...integrationSettings.integrationModules,
                              syncRoles: {
                                ...integrationSettings.integrationModules.syncRoles,
                                automatic:
                                  !integrationSettings.integrationModules.syncRoles.automatic,
                              },
                            },
                          });
                        }}
                      />
                    </ToggleSetting>
                  </ToggleOption>
                  <ToggleOption>
                    <ToggleLabel>
                      <Trans>Delete Roles</Trans>
                    </ToggleLabel>
                    <ToggleSetting>
                      <SwitchYesNo
                        width={64}
                        value={integrationSettings.integrationModules.deleteRoles.enabled}
                        onChange={() => {
                          setIntegrationSettings({
                            ...integrationSettings,
                            integrationModules: {
                              ...integrationSettings.integrationModules,
                              deleteRoles: {
                                ...integrationSettings.integrationModules.deleteRoles,
                                enabled:
                                  !integrationSettings.integrationModules.deleteRoles.enabled,
                                automatic: integrationSettings.integrationModules.deleteRoles
                                  .enabled
                                  ? false
                                  : integrationSettings.integrationModules.deleteRoles.automatic,
                              },
                            },
                          });
                        }}
                      />
                    </ToggleSetting>
                    <ToggleSetting>
                      <SwitchYesNo
                        disabled={!integrationSettings.integrationModules.deleteRoles.enabled}
                        width={64}
                        value={integrationSettings.integrationModules.deleteRoles.automatic}
                        onChange={() => {
                          setIntegrationSettings({
                            ...integrationSettings,
                            integrationModules: {
                              ...integrationSettings.integrationModules,
                              deleteRoles: {
                                ...integrationSettings.integrationModules.deleteRoles,
                                automatic:
                                  !integrationSettings.integrationModules.deleteRoles.automatic,
                              },
                            },
                          });
                        }}
                      />
                    </ToggleSetting>
                  </ToggleOption>
                </>
              )}
            </ModulesWrapper>
          </SettingBlock>
        </IntegrationSettingsSection>
      </MainContent>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    users: state.users.data,
    teams: state.teams.data,
  };
};

export default withI18n()(connect(mapStateToProps)(AddIntegrationModal));
