import React from 'react';

import { t } from '@lingui/macro';
import find from 'lodash/find';

import type { IColumnTable } from '~/@types/table';

import type { IMultiLangString } from '@learned/types';
import type { I18n } from '@lingui/core';

export const JOB_KPIS_COLUMNS: IColumnTable<IMultiLangString>[] = [
  {
    name: (i18n: I18n) => i18n._(t`KPI`),
    accessor: 'name',
    maxWidth: '100%',
    renderCell: (item) => {
      return <span>{item.en_GB || find(item)}</span>;
    },
  },
];
