import { t } from '@lingui/macro';

import { ICONS, ICON_SIZES } from '~/components/Icon';

import type { I18n } from '@lingui/core';

export enum SIDEBAR_ITEM_TYPES {
  CAREER = 'career',
  CONVERSATION = 'conversation',
  NEXT_STEPS = 'next_steps',
  TEAMS = 'teams',
  COACH = 'coach',
}

export const SIDEBAR_ITEMS = {
  COACH: {
    key: SIDEBAR_ITEM_TYPES.COACH,
    title: (i18n: I18n) => i18n._(t`My Coach`),
  },
  CONVERSATION: {
    key: SIDEBAR_ITEM_TYPES.CONVERSATION,
    title: (i18n: I18n) => i18n._(t`Next conversation`),
    placeholder: (i18n: I18n) => i18n._(t`None planned`),
  },
  CAREER: {
    key: SIDEBAR_ITEM_TYPES.CAREER,
    title: (i18n: I18n) => i18n._(t`Current Role`),
    icon: ICONS.CAREER,
    iconSize: ICON_SIZES.SMALL,
    iconColors: '#ffd2bb, #ffaf95',
    placeholder: (i18n: I18n) => i18n._(t`None`),
  },
  NEXT_STEPS: {
    key: SIDEBAR_ITEM_TYPES.NEXT_STEPS,
    title: (i18n: I18n) => i18n._(t`Next steps`),
    icon: ICONS.COLLAPSE_MENU,
    iconSize: ICON_SIZES.SMALL,
    iconColors: '#88a8ef, #7c8fb9',
    placeholder: (i18n: I18n) => i18n._(t`All done!`),
  },
  TEAMS: {
    key: SIDEBAR_ITEM_TYPES.TEAMS,
    title: (i18n: I18n) => i18n._(t`My team(s)`),
    icon: ICONS.TEAM,
    iconSize: ICON_SIZES.SMALL,
    iconColors: '#ffbaba, #e88686',
  },
};
