import React, { useEffect, useState } from 'react';

import { CAREER_PLAN_STATUSES } from '@learned/constants';
import filter from 'lodash/filter';
import size from 'lodash/size';
import moment from 'moment';

import { JOB_PROFILE_STATUSES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getCareerPlans } from '~/services/careerPlans';
import { getJobProfiles } from '~/services/jobProfiles';
import { getLikes } from '~/services/likes';

import { SIDEBAR_ITEM_TYPES } from '../../constants';
import { SidebarRow } from '../SidebarRow';

function mapLikes(likes) {
  const result = {};
  Object.values(likes).forEach((like) => {
    result[like.jobProfile] = like.value;
  });
  return result;
}

const CareerCard = () => {
  const $loading = useBoolState(true);
  const [roles, setRoles] = useState([]);
  const getMultiLangString = useMultiLangString();

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      const [careerPlans, likes, jobProfiles] = await Promise.all([
        getCareerPlans({ status: CAREER_PLAN_STATUSES.CURRENT }, { populate: ['jobProfile'] }),
        getLikes(),
        getJobProfiles({ status: JOB_PROFILE_STATUSES.ACTIVE.key }),
      ]);

      const roles = filter(careerPlans, (role) => {
        return (
          role.jobProfile &&
          role.jobProfile.status !== JOB_PROFILE_STATUSES.INACTIVE.key &&
          role.primary &&
          (!role?.endDate || moment(role.endDate).isAfter(moment()))
        );
      }).sort((a, b) => getMultiLangString(a?.name).localeCompare(getMultiLangString(b?.name)));

      const mapedLikes = mapLikes(likes);
      const savedRoles = filter(jobProfiles, (jobProfile) => {
        return (
          mapedLikes[jobProfile.id] &&
          mapedLikes[jobProfile.id] === 1 &&
          !roles.find((r) => r.jobProfile.id === jobProfile.id)
        );
      }).sort((a, b) => a.name.localeCompare(b.name));

      const allRoles = roles.concat(savedRoles);

      if (isMounted) {
        $loading.off();
        setRoles(allRoles);
      }
    };

    fetchData();

    return () => (isMounted = false);
    // eslint-disable-next-line
  }, []);

  const firstRoleName = getMultiLangString(roles[0]?.name || '');
  const totalRoles = size(roles);

  return (
    <SidebarRow
      type={SIDEBAR_ITEM_TYPES.CAREER}
      onClick={() =>
        routes.JOB_PROFILE.go(
          {},
          {
            roleId: roles[0].jobProfile.id,
            isBackPath: true,
          },
        )
      }
      isShowPlaceholder={totalRoles === 0}
      name={firstRoleName}
      nameCount={null}
      isLoading={$loading.value}
    />
  );
};

export default React.memo(CareerCard);
