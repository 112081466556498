import React from 'react';
import type { Dispatch } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { SearchSelect } from '~/components/SearchSelect';

import { StepFooter } from './components/StepFooter';
import { Form, Title, Subtitle, JobProfilesLevels, JobProfilesByLevel } from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import { useJobProfiles } from '../SkillForm/hooks';

import type { IGeneralForm } from './types';
import type { ISkillCategory } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

type StepJobProfilesProps = {
  setCurrentSection: Dispatch<number>;
  formMethods: UseFormReturn<IGeneralForm>;
  skillCategory?: ISkillCategory;
};

function StepJobProfiles({ setCurrentSection, formMethods, skillCategory }: StepJobProfilesProps) {
  const { i18n } = useLingui();
  const { jobProfiles } = useJobProfiles();
  const getMultiLangString = useMultiLangString();

  const { handleSubmit, setValue, watch } = formMethods;
  const selectedJobProfiles = watch('jobProfiles');

  const onSubmit = () => setCurrentSection(3);

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmit)}>
      <Title>
        <Trans>Jobs that require this skill</Trans>
      </Title>
      <Subtitle>
        <Trans>
          Here, you can associate specific jobs within your company with this skill at each level.
          Once selected, the skill and skill level will be visible in the job requirements and can
          be considered during reviews.
        </Trans>
      </Subtitle>

      {!skillCategory && (
        <Trans>No category selected, go back to step 1 and select a category first</Trans>
      )}

      {skillCategory && (
        <JobProfilesLevels>
          {skillCategory.skillLevels.map((skillLevel, index) => (
            <JobProfilesByLevel key={index}>
              <h2>{getMultiLangString(skillLevel)}</h2>
              <SearchSelect
                selectedItems={selectedJobProfiles[index]}
                onChange={(items) => {
                  setValue('jobProfiles', { ...selectedJobProfiles, [index]: items });
                }}
                items={jobProfiles}
                stringifyItem={(job) => getMultiLangString(job.name)}
                placeholder={i18n._(t`Job name`)}
              />
            </JobProfilesByLevel>
          ))}
        </JobProfilesLevels>
      )}

      <StepFooter hideSeparator onPrev={() => setCurrentSection(1)} />
    </Form>
  );
}

export { StepJobProfiles };
