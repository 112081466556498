import React from 'react';

import { t } from '@lingui/macro';

import type { IColumnTable } from '~/@types/table';

import type { IMultiLangString } from '@learned/types';
import type { I18n } from '@lingui/core';

export const createJobKPIsColumns = ({
  getMultiLangString,
}: {
  getMultiLangString: (name: Record<string, string> | string) => string;
}): { columns: IColumnTable<IMultiLangString>[] } => {
  return {
    columns: [
      {
        name: (i18n: I18n) => i18n._(t`KPI`),
        accessor: 'name',
        maxWidth: '100%',
        renderCell: (item) => {
          return <span>{getMultiLangString(item)}</span>;
        },
      },
    ],
  };
};
