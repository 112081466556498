import styled, { css } from 'styled-components';

import { Button } from '~/components/Buttons';

import { COLORS } from '~/styles';

export const Container = styled.div<{ isDraft: boolean }>`
  display: flex;
  gap: 151px;

  ${(props) =>
    props.isDraft
      ? css`
          margin-top: 32px;
        `
      : css`
          margin-top: 75px;
        `}
  justify-content: center;
  align-items: center;
`;

export const Tabs = styled.div`
  width: 750px;
  padding-left: 38px;
  padding-top: 27px;
  padding-bottom: 57px;
  padding-right: 29px;

  border-radius: 10px;
  border-top-left-radius: 0;
  background-color: ${COLORS.WHITE};
`;

export const Start = styled.span`
  display: flex;
  justify-content: flex-start;
`;

export const Center = styled.span`
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: -0.22px;
`;

export const End = styled.span`
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
`;

export const DraftBar = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.ACTIVITY_GREY};
  padding: 9px 32px;
`;

export const Card = styled.div``;

export const ActionItemBlock = styled.div<{ minWidth?: string }>`
  display: flex;
  column-gap: 10px;
  align-items: center;
  padding: 0 10px;
  height: 20px;
  border-left: solid 1.2px ${COLORS.BORDERS};
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  margin-right: 20px;
`;

export const ExpectedResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 4px;
`;

export const ExpectedResult = styled.div`
  min-height: 48px;
  padding: 13px 19px 15px 17px;
  display: flex;
  align-items: center;
  background-color: ${COLORS.BG_LIST};
  border-radius: 6px;
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
  box-sizing: border-box;
`;
