import styled from 'styled-components';

import { SearchField } from '~/components/Text';

import { COLORS } from '~/styles';

export const StyledSearchFieldWrapper = styled.div`
  position: relative;
`;

export const StyledSearchField = styled(SearchField)`
  height: 32px;
  margin-right: 12px;
`;

export const Label = styled.label`
  color: ${COLORS.SUBTEXT};
  padding-top: 22px;
  font-size: 14px;
  letter-spacing: -0.16px;
`;

export const SuggestedSkillsWrapper = styled.div`
  position: absolute;
  top: 32px;
  left: 20px;
  z-index: 1000;
  border-radius: 6px;
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
  border: solid 1px ${COLORS.BORDERS};
  background-color: ${COLORS.WHITE};
  max-height: 200px;
  min-width: 226px;
  overflow-y: auto;

  & .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 16px;
    width: 100%;
    height: 40px;
    color: ${COLORS.TEXT_MAIN};
    background-color: ${COLORS.WHITE};
    box-sizing: border-box;
    font-size: 12px;
    gap: 10px;

    & .icon {
      visibility: hidden;
    }

    &:hover {
      color: ${COLORS.COMPANY};
      cursor: pointer;
      background-color: ${COLORS.BG_LIST};

      & .icon {
        visibility: visible;
      }
    }
  }

  & .notFound {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 16px;
    width: 100%;
    height: 40px;
    color: ${COLORS.TEXT_MAIN};
    background-color: ${COLORS.WHITE};
    box-sizing: border-box;
    font-size: 12px;
    gap: 10px;
    user-select: none;
  }
`;

export const RecommendedSkillsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow-y: auto;
  max-height: 200px;
  margin-top: 10px;

  & .item {
    padding: 8px 14px;
    color: ${COLORS.TEXT_MAIN};
    border-radius: 100px;
    font-size: 14px;

    &:hover {
      color: ${COLORS.COMPANY};
      cursor: pointer;
      background-color: ${COLORS.BG_LIST};
    }
  }
`;

export const Title = styled.span`
  font-size: 26px;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

export const Subtitle = styled.span`
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.SUBTEXT};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  border-bottom: solid 1px ${COLORS.BORDERS};
  padding-bottom: 7px;
  margin-bottom: 20px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
`;
