import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, ProductName, ProductStatus } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { CompanyLanguage } from '~/components/CompanyLanguage';
import OverviewHeading from '~/components/OverviewHeading';
import BaseLayout from '~/layouts/BaseLayout';
import { SecuritySettings } from '~/pages/CompanySecuritySettings/components/SecuritySettings';
import { SSOSettings } from '~/pages/CompanySecuritySettings/components/SSOSettings';
import { BrandingSection } from '~/pages/CompanySettings/sections/Branding';

import { ActiveProducts } from './components/ActiveProducts';
import { CoachSettings } from './components/CoachSettings';
import { CompanyData } from './components/CompanyData';
import { DeleteCompany } from './components/DeleteCompany';
import { NotificationSettings } from './components/NotificationSettings';
import { PrimaryColorActions } from './components/PrimaryColorActions';
import { UploadLogo } from './components/UploadLogo';

import { INSTRUCTIONS } from '~/constants/instructions';
import {
  getAppTheme,
  getCompanies,
  getCurrentCompanyId,
  getCurrentProducts,
  getUser,
} from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import {
  deleteCompanyAllData,
  deleteCompanyAllDataSuperAdmin,
  getCompany,
  removeCompanyLogo,
  updateCompany,
  updateCompanyLogo,
  updateCompanyLogoEmail,
  updateDefaultSSOMethod,
  updateForceMFA,
} from '~/services/companies';
import * as appThemeActions from '~/store/appTheme/actions';
import { finishGetCompany, setIndustry } from '~/store/companies/actions';
import getInstructionUrl from '~/utils/getInstructionUrl';

import type { ICompany, IIndustry } from '@learned/types';

export interface UpdateData {
  [key: string]: any;
}

interface Product {
  name: ProductName;
  status: ProductStatus;
  settings: {};
  lastStatusChanged: string;
}

const BRIDGE_PRODUCT_PRODUCT_NAME_TO_LABEL = {
  [ProductName.LEARNING_AND_ONBOARDING]: 'Onboarding & Learn',
  [ProductName.JOB_MATRIX]: 'Job matrix',
};

const CompanySettings = () => {
  const dispatch = useDispatch();
  const { i18n } = useLingui();
  const user = useSelector(getUser);
  const companies = useSelector(getCompanies);
  const isOwner = user.isOwner;
  const isSuperAdmin = user.isSuperAdmin;
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const appTheme = useSelector(getAppTheme);
  const currentCompany = useSelector(getCurrentCompany);
  const products: Record<string, Product> = useSelector(getCurrentProducts);

  const dateDiff = (statusChangedOn: string) => {
    const current = moment();
    const trialEnding = moment(statusChangedOn).add(14, 'days');

    return trialEnding.diff(current, 'days');
  };

  const productsOrder = [
    ProductName.JOB_MATRIX,
    ProductName.PERFORMANCE,
    ProductName.CAREER,
    ProductName.ENGAGEMENT,
    ProductName.LEARNING_AND_ONBOARDING,
    ProductName.INTEGRATIONS,
  ];
  const productsOrdered = sortBy(Object.keys(products), (key) =>
    productsOrder.indexOf(key as unknown as ProductName),
  ) as unknown as ProductName[];

  const allActiveProducts = productsOrdered
    .map((key) => {
      const product = products[key];
      return {
        key,
        name:
          BRIDGE_PRODUCT_PRODUCT_NAME_TO_LABEL[
            key as unknown as ProductName.LEARNING_AND_ONBOARDING | ProductName.JOB_MATRIX
          ] || key,
        status: product.status,
        lastStatusChanged: product.lastStatusChanged,
        settings: product.settings || '',
        daysToEnd: dateDiff(product.lastStatusChanged),
      };
    })
    .filter((i) => i.status === ProductStatus.ACTIVE || i.status === ProductStatus.TRIAL_ACTIVE)
    .filter(
      (i) =>
        ![ProductName.LEARNING_AND_ONBOARDING].includes(i.key) ||
        currentCompany.addons[i.key] === true,
    );

  const [company, setCompany] = useState<ICompany>(
    currentCompany || {
      name: '',
      logoUrl: null,
      color: null,
    },
  );

  const [update, setUpdate] = useState<UpdateData>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      setLoading(true);

      getCompany(currentCompanyId).then((companyData) => {
        if (isMounted) {
          setCompany(companyData);
          setLoading(false);
        }
      });
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, [currentCompanyId]);

  useEffect(() => {
    saveCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setCompany({ ...company, [name]: value });

    if (!isEmpty(value)) {
      setUpdate({ ...update, [name]: value });
    }
  };

  const handleIndustryChange = (selectedIndustry: IIndustry) => {
    setCompany({ ...company, industry: selectedIndustry.id });
    dispatch(setIndustry(selectedIndustry.id));
    setUpdate({ ...update, industry: selectedIndustry.id });
  };

  const onUpdateForceMFA = async (force_mfa: boolean) => {
    // If the value hasn't changed, do nothing
    if (force_mfa === (company as ICompany)?.force_mfa) {
      return;
    }
    // @ts-ignore
    dispatch(finishGetCompany(await updateForceMFA(force_mfa)));
    setCompany({ ...company, force_mfa });
  };

  const onUpdateDefaultSSOMethod = async (defaultSSOMethod: string) => {
    setCompany({ ...company, defaultSSOMethod });
    try {
      await updateDefaultSSOMethod(defaultSSOMethod);
    } catch (e) {
      // do nothing
    }
  };

  const saveCompany = async (e?: React.FormEvent) => {
    if (isEmpty(update)) {
      return;
    }
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    const updatedCompany = await updateCompany(company.id, update);
    if (updatedCompany.color !== appTheme.color || updatedCompany.logoUrl !== appTheme.url) {
      dispatch(appThemeActions.setTheme(updatedCompany));
    }
    setCompany(updatedCompany);
    setUpdate({});
    setLoading(false);
  };

  const onChange = async (key: string, value: any) => {
    const update = {
      [key]: value,
    };

    setLoading(true);
    const updatedCompany = await updateCompany(company.id, update);

    if (updatedCompany) {
      // update company color in redux-store
      // update company avatar in redux-store
      if (updatedCompany.color !== appTheme.color || updatedCompany.logoUrl !== appTheme.url) {
        dispatch(appThemeActions.setTheme(updatedCompany));
      }

      setCompany(updatedCompany);
      setUpdate({});
    }

    setLoading(false);
  };

  const onUploadChange = async (file: File | null) => {
    if (file) {
      const updatedCompany = await updateCompanyLogo(company.id, file);
      dispatch(appThemeActions.setTheme(updatedCompany));
      setCompany(updatedCompany);
    }
  };

  const onUploadFile = async (key: string, file: File | null) => {
    if (!key) {
      return;
    }

    switch (key) {
      case 'logoEmailUrl': {
        const response = await updateCompanyLogoEmail(company.id, file);
        const updatedCompany = response?.data?.[API_RETURN_FIELDS.COMPANY];
        if (updatedCompany) {
          setCompany(updatedCompany);
        }
        break;
      }
      default:
        break;
    }
  };

  const handleColorChange = (color: any) => {
    setUpdate({ ...update, color: color.hex });
    setCompany({ ...company, color: color.hex });
  };

  const deleteLogo = async () => {
    const updatedCompany = await removeCompanyLogo(company.id);
    dispatch(appThemeActions.setTheme(updatedCompany));
    setCompany(updatedCompany);
  };

  const deleteCompany = async () => {
    setLoading(true);
    if (isSuperAdmin) {
      await deleteCompanyAllDataSuperAdmin(currentCompanyId);
    } else {
      await deleteCompanyAllData(currentCompanyId);
    }
    setLoading(false);
    location.reload();
  };

  const uploadLogoActions = () => {
    return (
      <UploadLogo
        company={company as ICompany}
        deleteLogo={deleteLogo}
        onUploadChange={onUploadChange}
      />
    );
  };

  const updatePrimaryColorActions = () => {
    return (
      <PrimaryColorActions
        isDisabled={!update.color}
        company={company as ICompany}
        handleColorChange={handleColorChange}
        saveCompany={saveCompany}
      />
    );
  };

  return (
    <>
      {/* @ts-ignore */}
      <OverviewHeading
        title={i18n._(t`Company settings`)}
        description={i18n._(t`Customise your company name, company color and/or logo`)}
        instructions={i18n._(t`How customising Learned works`)}
        instructionsUrl={getInstructionUrl(INSTRUCTIONS.DESIGN_IDENTIY)}
      />
      <BaseLayout>
        {company.id && (
          <>
            <CompanyLanguage />
            <CompanyData
              companyName={company.name}
              handleChange={handleChange}
              saveCompany={saveCompany}
              handleIndustryChange={handleIndustryChange}
              companyIndustry={company.industry}
            />
            <BrandingSection
              company={company}
              onChange={onChange}
              onUploadFile={onUploadFile}
              uploadLogoActions={uploadLogoActions}
              updatePrimaryColorActions={updatePrimaryColorActions}
            />
            {currentCompanyId && <CoachSettings companyId={currentCompanyId} />}
            <ActiveProducts products={allActiveProducts} />
            {currentCompanyId && <NotificationSettings companyId={currentCompanyId} />}
            <SecuritySettings company={company as ICompany} updateForceMFA={onUpdateForceMFA} />
            <SSOSettings
              company={company as ICompany}
              updateDefaultSSOMethod={onUpdateDefaultSSOMethod}
            />
            <DeleteCompany
              companyName={companies[currentCompanyId].name}
              isAllowedToDelete={isOwner || isSuperAdmin}
              loading={loading}
              deleteCompany={deleteCompany}
            />
          </>
        )}
      </BaseLayout>
    </>
  );
};

export { CompanySettings };
