import React from 'react';

import styled from 'styled-components';

import { TotalSection } from './TotalSection';

const Wrapper = styled.div``;

const ParticipationTab = () => {
  return (
    <Wrapper>
      <TotalSection />
    </Wrapper>
  );
};

export { ParticipationTab };
