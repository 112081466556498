import React from 'react';
import type { Dispatch } from 'react';

import { Trans } from '@lingui/macro';
import { DragDropContext } from 'react-beautiful-dnd';

import { FocusAreas } from './components/FocusAreas';
import { StepFooter } from './components/StepFooter';
import { Form, Title, Subtitle, Levels } from './design';

import type { IBaseLanguageStateReturn } from '~/hooks/useLanguageState';

import type { IGeneralForm } from './types';
import type { ISkillCategory } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

type StepFocusAreasProps = {
  setCurrentSection: Dispatch<number>;
  formMethods: UseFormReturn<IGeneralForm>;
  skillCategory?: ISkillCategory;
  languageState: IBaseLanguageStateReturn;
};

function StepFocusAreas({
  setCurrentSection,
  skillCategory,
  languageState,
  formMethods,
}: StepFocusAreasProps) {
  const { handleSubmit, getValues, setValue } = formMethods;

  const onSubmit = () => setCurrentSection(2);

  const moveItem = ({
    sourceId,
    targetId,
    sourceIndex,
    targetIndex,
  }: {
    sourceId: string;
    targetId?: string;
    sourceIndex: number;
    targetIndex?: number;
  }) => {
    if (targetId === undefined || targetIndex === undefined) {
      return;
    }

    const sourceFocusAreaIndex = parseInt(sourceId, 10);
    const targetFocusAreaIndex = parseInt(targetId, 10);
    if (sourceId === targetId) {
      const fieldsCopy = getValues(`focusAreas.${sourceFocusAreaIndex}.values`);
      const [removed] = fieldsCopy.splice(sourceIndex, 1);
      fieldsCopy.splice(targetIndex, 0, removed);
      setValue(`focusAreas.${sourceFocusAreaIndex}.values`, fieldsCopy);
    } else {
      const sourceFields = getValues(`focusAreas.${sourceFocusAreaIndex}.values`);
      const targetFields = getValues(`focusAreas.${targetFocusAreaIndex}.values`);
      const [removed] = sourceFields.splice(sourceIndex, 1);
      targetFields.splice(targetIndex, 0, removed);
      setValue(`focusAreas.${sourceFocusAreaIndex}.values`, sourceFields);
      setValue(`focusAreas.${targetFocusAreaIndex}.values`, targetFields);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmit)}>
      <Title>
        <Trans>Levels & focus areas</Trans>
      </Title>
      <Subtitle>
        <Trans>
          A description of what characteristics an employee needs to meet the level of this skill.
          Add or remove levels if necessary. Click and drag item to change position.
        </Trans>
      </Subtitle>

      {!skillCategory && (
        <Trans>No category selected, go back to step 1 and select a category first</Trans>
      )}

      {skillCategory && (
        <Levels>
          <DragDropContext
            onDragEnd={(result) => {
              const sourceId = result.source.droppableId;
              const targetId = result.destination?.droppableId;

              const sourceIndex = result.source.index;
              const targetIndex = result.destination?.index;
              moveItem({ sourceId, targetId, sourceIndex, targetIndex });
            }}
          >
            {skillCategory.skillLevels.map((levelName, index) => (
              <FocusAreas
                formMethods={formMethods}
                levelName={levelName}
                key={index}
                index={index}
                languageState={languageState}
              />
            ))}
          </DragDropContext>
        </Levels>
      )}

      <StepFooter hideSeparator onPrev={() => setCurrentSection(0)} />
    </Form>
  );
}

export { StepFocusAreas };
