import React from 'react';

import { REVIEW_QUESTION_EVALUATORS } from '@learned/constants';
import { I18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';
import { UseFormReturn } from 'react-hook-form';

import { ICONS } from '~/components/Icon';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { SectionHeaderWithIcon } from './SectionHeaderWithIcon';
import { SubHeader } from './SubHeader';
import { SummaryField } from './SummaryField';

import { ExplanationText } from '../../TimelineSection/design';
import { SectionGrid, SectionTitle } from '../design';

import type { IReviewSelfForm, TasksType } from '../../../types';

interface SettingsSummaryProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewSelfForm>;
}

type TaskItem = {
  title: (i18n: I18n) => string;
  icon: ICONS;
  key: TasksType;
};

const TASKS: Array<TaskItem> = [
  {
    key: 'reviewPeerNominate',
    icon: ICONS.NOMINATE_PEER,
    title: (i18n: I18n) => i18n._(t`Nominate peers for input`),
  },
  {
    key: 'reviewSelfEvaluate',
    icon: ICONS.EMPLOYEE,
    title: (i18n: I18n) => i18n._(t`Self review`),
  },
  {
    key: 'reviewPeerEvaluate',
    icon: ICONS.PEER,
    title: (i18n: I18n) => i18n._(t`Peer review`),
  },
  {
    key: 'reviewCoachEvaluate',
    icon: ICONS.COACH,
    title: (i18n: I18n) => i18n._(t`Coach review`),
  },
];

const SettingsSummary = ({ formMethods, sectionState }: SettingsSummaryProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;

  const navigateToSection = () => sectionState.setCurrentSection(2);

  const getOnOffStatus = (value: boolean) => {
    return value ? i18n._(t`ON`) : i18n._(t`OFF`);
  };

  const getFormattedDate = (date: Date | null) => {
    const datePattern = 'DD-MM-YYYY';
    return date ? moment(date).format(datePattern) : i18n._(t``);
  };

  const getTaskSection = (task: TaskItem) => (
    <>
      <SectionHeaderWithIcon
        title={task.title(i18n)}
        icon={task.icon}
        marginTop="19px"
        marginBottom="14px"
      />
      <SectionGrid>
        <SummaryField
          label={i18n._(t`Start date`)}
          value={getFormattedDate(watch(`tasks.${task.key}.startDate`))}
        />
        <SummaryField
          label={i18n._(t`Deadline`)}
          value={getFormattedDate(watch(`tasks.${task.key}.endDate`))}
        />
      </SectionGrid>
    </>
  );

  const evaluators = watch('evaluators').map(
    (evaluator: { value: REVIEW_QUESTION_EVALUATORS; icon: ICONS; title: string }) =>
      evaluator.value,
  );

  return (
    <>
      <SubHeader title={i18n._(t`Settings`)} onEdit={navigateToSection} />
      <>
        <SectionTitle marginBottom="16px">
          <Trans>General</Trans>
        </SectionTitle>
        <SectionGrid>
          <SummaryField
            value={getOnOffStatus(watch('settings.isDigitalSign'))}
            label={i18n._(t`Sign digitally`)}
          />
          <SummaryField
            value={getOnOffStatus(watch('settings.isCoachesAskedToScheduleReview'))}
            label={i18n._(t`Coaches schedule review`)}
          />
        </SectionGrid>
      </>
      <>
        <SectionTitle marginBottom="16px" marginTop="32px">
          <Trans>Task notifications</Trans>
        </SectionTitle>
        <SectionGrid>
          <SummaryField
            value={getOnOffStatus(watch('notifications.isOnTaskStartDate'))}
            label={i18n._(t`On start date`)}
          />
          <SummaryField
            value={getOnOffStatus(watch('notifications.isOn7DaysBeforeTaskDeadline'))}
            label={i18n._(t`7 days before deadline`)}
          />
          <SummaryField
            value={getOnOffStatus(watch('notifications.isOnTaskDeadline'))}
            label={i18n._(t`On the day of the deadline`)}
          />
        </SectionGrid>
      </>
      <>
        <SectionTitle marginBottom="16px" marginTop="32px">
          <Trans>Privacy</Trans>
        </SectionTitle>
        <ExplanationText>
          {!watch('privacy.isAllEmployeeCoachesCanSeeReview')
            ? i18n._(t`Employees, guests and coaches selected for coach review can see the review.`)
            : i18n._(
                t`Employees, guests and all current and future coaches of the employee can view the review.`,
              )}
        </ExplanationText>
      </>
      <>
        <SectionTitle marginBottom="16px" marginTop="32px">
          <Trans>Timeline</Trans>
        </SectionTitle>
        <SectionGrid>
          <SummaryField
            label={i18n._(t`Start date`)}
            value={getFormattedDate(watch('settings.startDate'))}
          />
          <SummaryField
            label={i18n._(t`Deadline`)}
            value={getFormattedDate(watch('settings.endDate'))}
          />
        </SectionGrid>

        {TASKS.map((item) => {
          switch (true) {
            case item.key === 'reviewPeerNominate' &&
              evaluators.includes(REVIEW_QUESTION_EVALUATORS.PEER):
              return getTaskSection(item);
            case item.key === 'reviewSelfEvaluate' &&
              evaluators.includes(REVIEW_QUESTION_EVALUATORS.EMPLOYEE):
              return getTaskSection(item);
            case item.key === 'reviewPeerEvaluate' &&
              evaluators.includes(REVIEW_QUESTION_EVALUATORS.PEER):
              return getTaskSection(item);
            case item.key === 'reviewCoachEvaluate' &&
              evaluators.includes(REVIEW_QUESTION_EVALUATORS.COACH):
              return getTaskSection(item);
          }
        })}
      </>
    </>
  );
};

export { SettingsSummary };
