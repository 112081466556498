import styled from 'styled-components';

import { COLORS } from '~/styles';

const MainContainer = styled.div<{ margin: string }>`
  background: ${COLORS.BG_LIST};
  color: ${COLORS.TEXT_MAIN};
  margin: ${({ margin }) => margin};
  padding: 24px;
  box-sizing: border-box;
  font-size: 14px;
`;

const LucaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px 0;
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Notice = styled.div<{ maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
  text-align: center;
`;

const Summary = styled.div<{ isScrollable: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  color: ${COLORS.TEXT_HOVER};
  padding: ${({ isScrollable }) => (isScrollable ? '8px 16px' : '0')};
  position: ${({ isScrollable }) => (isScrollable ? 'sticky' : 'relative')};
  background: ${COLORS.BG_LIST};
  top: 0;
  left: 0;
`;

const SummaryDetails = styled.div<{ isScrollable: boolean }>`
  overflow-y: ${({ isScrollable }) => (isScrollable ? 'auto' : 'visible')};
  padding: ${({ isScrollable }) => (isScrollable ? '0 16px 32px 16px' : '0')};
  flex: auto;
`;

const SummaryActions = styled.div<{ isScrollable: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ isScrollable }) => (isScrollable ? '8px 16px' : '0')};
  background: ${COLORS.BG_LIST};
  position: relative;
  isolation: isolate;
  &::before {
    display: ${({ isScrollable }) => (isScrollable ? 'block' : 'none')};
    content: '';
    z-index: -1;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(251, 251, 251, 1) 50%
    );
    width: 100%;
    height: 20px;
    position: absolute;
    left: 0;
    top: -10px;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex: auto;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
`;

const LegalDisclaimer = styled.div`
  padding: 0px 16px 40px;
  font-size: 10px;
  color: ${COLORS.SUBTEXT};
  cursor: pointer;
`;

const LucaModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: stretch;
  z-index: 100;
  font-size: 14px;
`;

const ModalContent = styled.div`
  background: ${COLORS.WHITE};
  border-radius: 6px;
  box-sizing: border-box;
  max-width: 1724px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  margin: 80px 70px;
`;

const Header = styled.div`
  padding: 16px 24px 16px 32px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.TEXT_MAIN};
`;

const Title = styled.div`
  font-size: 24px;
  color: ${COLORS.TEXT_HOVER};
`;

const HeaderInnerWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ModalTitleRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ModalTitleRowActions = styled.div`
  display: flex;
  gap: 16px;
`;

const CloseIcon = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LucaDescriptionContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: auto;
  overflow: hidden;
`;

const DescriptionWrapper = styled.div`
  flex: auto;
  background: ${COLORS.WHITE};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: auto;
  overflow: auto;
`;

const SummaryWrapper = styled.div`
  background: ${COLORS.BG_LIST};
  box-sizing: border-box;
  display: flex;
`;

const CollapsedContainer = styled.div<{ minWidth?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};
`;

const CollapsedText = styled.div`
  writing-mode: vertical-rl;
  text-orientation: mixed;
  user-select: none;
`;

const SummaryActionContainer = styled.div<{ isOpen: boolean }>`
  color: ${COLORS.COMPANY};
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: ${({ isOpen }) => (isOpen ? 'flex-start' : 'center')};
  padding: ${({ isOpen }) => (isOpen ? '16px 24px' : '16px')};
  box-sizing: border-box;
  cursor: pointer;
  gap: 16px;
  font-size: 10px;
  flex: none;
  span {
    color: ${COLORS.SUBTEXT};
  }
`;

const CollapsedBody = styled.div<{ isScrollable: boolean }>`
  box-sizing: border-box;
  max-width: 380px;
  flex: auto;
  padding: ${({ isScrollable }) => (isScrollable ? '0px' : '16px')};
  overflow-y: ${({ isScrollable }) => (isScrollable ? 'auto' : 'visible')};
`;

const Spacer = styled.div`
  height: 16px;
`;

const SpaceDivider = styled.div`
  height: 10px;
`;

const LoadingText = styled.div<{ isScrollable: boolean }>`
  color: ${COLORS.COMPANY};
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 2s steps(50, end) forwards;
  animation-iteration-count: infinite;
  width: 0ch;
  padding: ${({ isScrollable }) => (isScrollable ? '0 16px' : '0')};
  @keyframes typing {
    0% {
      width: 0ch;
    }
    50% {
      width: 16ch;
    }
    100% {
      width: 0ch;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  padding: 0 32px 16px 32px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
`;

const PaginationContainer = styled.div`
  width: 100%;
`;

const LucaDescription = styled.div<{ isScrollable: boolean }>`
  box-sizing: border-box;
  padding: ${({ isScrollable }) => (isScrollable ? '16px 16px 16px 16px' : '0')};
`;

const UserName = styled.div`
  font-weight: 500;
  margin-bottom: 8px;
`;

export {
  Spacer,
  PaginationContainer,
  UserName,
  LucaDescription,
  Footer,
  Title,
  HeaderInnerWrapper,
  SpaceDivider,
  MainContainer,
  Notice,
  Summary,
  LucaContainer,
  SummaryContainer,
  SummaryDetails,
  SummaryActions,
  ActionContainer,
  LegalDisclaimer,
  LucaModalContainer,
  ModalContent,
  ModalTitleRow,
  ModalTitleRowActions,
  Header,
  LucaDescriptionContainer,
  DescriptionWrapper,
  SummaryWrapper,
  CollapsedContainer,
  CollapsedText,
  SummaryActionContainer,
  CollapsedBody,
  CloseIcon,
  LoadingText,
};
