import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import RocketIcon from '~/components/Icons/Rocket';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { GiveReview } from '~/pages/ReviewGiveFeedback/components/GiveReview';
import { useReviewTask } from '~/pages/ReviewGiveFeedback/hooks';

import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import { useQueryURL } from '~/hooks/useQueryURL';
import { getCompanyOutside } from '~/services/companies';

function OutsideReview() {
  const { i18n } = useLingui();
  const { values: queryParams } = useQueryURL({ keys: ['taskId'] });
  const reviewTaskId = queryParams.taskId;
  const introTitle = i18n._(t`Provide input`);

  const { reviewTask, userReview, isLoading } = useReviewTask({ reviewTaskId });

  const [companyLogo] = useAsyncFetch(
    {
      initialState: undefined,
      fetch: async () => {
        if (!reviewTask?.company) {
          return undefined;
        }

        const { companyLogo } = await getCompanyOutside(reviewTask?.company);
        return companyLogo;
      },
    },
    [reviewTask],
  );

  return (
    <ShowSpinnerIfLoading loading={isLoading}>
      {reviewTask && userReview && (
        <GiveReview
          reviewTask={reviewTask}
          userReview={userReview}
          introTitle={introTitle}
          companyLogo={companyLogo}
        />
      )}
      {(!reviewTask || !userReview) && (
        <Placeholder title={i18n._(t`No review task`)} Icon={RocketIcon} />
      )}
    </ShowSpinnerIfLoading>
  );
}

export { OutsideReview };
