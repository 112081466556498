import { useEffect, useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { useSelector } from 'react-redux';

import { useLanguageState } from '~/hooks/useLanguageState';
import { getUser } from '~/selectors/baseGetters';
import { deleteReviewById, fetchReviewById } from '~/services/reviews';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import { IReviewDashboardCycleForm } from '../types';

import type { IReview } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface UseReviewProps {
  formMethods: UseFormReturn<IReviewDashboardCycleForm>;
  reviewId: IReview['id'];
}

export const useReview = ({ formMethods, reviewId }: UseReviewProps) => {
  const { setValue } = formMethods;
  const languageState = useLanguageState(true);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(getUser);

  const [item, setItem] = useState<IReview>();

  const [isAllowToDelete, setIsAllowToDelete] = useState(false);

  useEffect(() => {
    setIsAllowToDelete(item && (user.isAdmin || (user.isCoach && item?.createdBy === user.id)));
  }, [item, user]);

  const fetchReview = async () => {
    const result = await fetchReviewById(reviewId);
    const review: IReview = result.data[API_RETURN_FIELDS.REVIEW];
    setItem(review);
    return review;
  };

  const setFormValues = async () => {
    setIsLoading(true);
    const review = await fetchReview();
    setValue('notifications', review.notifications);
    setValue('name', turnMultiLangIntoArray(review.name, languageState.companyLanguages));
    setValue('reviewTemplate', review.reviewTemplate);
    setValue('privacy', review.privacy);
    setValue('settings', review.settings);
    setValue('status', review.status);
    setValue(
      'description',
      turnMultiLangIntoArray(review.description, languageState.companyLanguages),
    );
    setIsLoading(false);
  };

  const deleteReview = async () => {
    if (isAllowToDelete && item) {
      await deleteReviewById(item.id);
    }
  };

  useEffect(() => {
    setFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId]);

  return {
    deleteReview,
    isAllowToDelete,
    isLoading,
  };
};
