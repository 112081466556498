import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import { ContextMenu, ContextOption } from '~/components/ContextMenu';
import { ICON_SIZES, ICONS } from '~/components/Icon';

import { CheckBox } from './components/CheckBox';
import { FixedTable } from './components/FixedTable';
import { Header } from './components/Header';
import { RadioButton } from './components/RadioButton';
import { SelectedHeader } from './components/SelectedHeader';
import {
  TD,
  TR,
  TableWrapper,
  IconMenuWrapper,
  IsVisible,
  StyledTop,
  SelectorWrapper,
  StatusLabel,
  StyledIcon,
  TableContainer,
  MainTable,
} from './design';
import { isHiddenFilter } from './utils';

import { ColumnPosition, IColumnTable } from '~/@types/table';
import { COLORS } from '~/styles';

import PaginationBar from '../PaginationBar';
import { TablePlaceholder } from '../TablePlaceholder';

import type { IMultiSelect, ITableListProps } from './types';

const STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  UPCOMING: 'upcoming',
  ACTIVE: 'active',
  COMPLETED: 'completed',
  ARCHIVED: 'archived',
};

const Wrapper = styled.div`
  position: relative;
  background-color: ${COLORS.WHITE};
  border-radius: 0 10px 10px 10px;
  padding: 24px 32px;
`;

const StyledPaginationBar = styled(PaginationBar)`
  padding: 0;
  height: 16px;
  margin-top: 20px;
`;

const TableList = ({
  columns,
  data,
  onRowClick,
  sortProps: { sortBy, setSortBy } = {},
  multiSelectProps: {
    isMultiSelectVisible = false, // for multi and single select
    multiSelect,
    isSelectedCountVisible = true, // for single select should be false
    isSelectAllVisible = true, // for single select should be false
    isSingleSelect = false,
  } = {},
  menuProps: { isMenuVisible, createMenuItems } = { isMenuVisible: false },
  // TODO: Deprecate this as only one table use this prop
  isDraftStatusVisible,
  onColClick,
  actionButton,
  secondaryButton,
  thirdButton,
  isLoading,
  paginationProps,
  placeholderProps: { noResultText, emptyStateText, emptyButtonText, onClickEmptyButton } = {},
  filtersProps,
  className,
  isAllDone = false,
  isHideHeader = false,
  isHideTop = false,
  topClassName,
  noForceUpdate = false,
  isScrollbarVisible,
  leftMinWidth,
  isLeftColumnsStriped,
}: ITableListProps) => {
  const { i18n } = useLingui();
  const [hovered, setHovered] = useState(-1);
  const handleSortBy = (key?: string) => {
    setSortBy && setSortBy(key || '');
  };

  const sortProps = {
    sortBy,
    handleSortBy,
  };

  useEffect(() => {
    if (
      !noForceUpdate &&
      paginationProps &&
      data &&
      isEmpty(data) &&
      paginationProps?.pagination?.index > 1
    ) {
      let index = paginationProps?.pagination?.index || 1;
      const newPagination = {
        ...paginationProps?.pagination,
        index: --index,
        skip: paginationProps?.pagination.skip - paginationProps?.pagination.limit,
      };

      paginationProps.changePagination(newPagination);
      paginationProps.onPaginationChange?.(newPagination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationProps?.changePagination, data, paginationProps?.pagination]);

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    if (paginationProps?.pagination) {
      const newPagination = {
        ...paginationProps?.pagination,
        limit,
      };

      paginationProps?.changePagination?.(newPagination);
      paginationProps?.onPaginationChange?.(newPagination);
    }
  };

  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    if (paginationProps?.pagination) {
      const newPagination = {
        ...paginationProps?.pagination,
        skip,
        index,
      };

      paginationProps?.changePagination(newPagination);
      paginationProps?.onPaginationChange?.(newPagination);
    }
  };

  const onResetSearch = (): void => {
    filtersProps?.resetFilters ? filtersProps.resetFilters() : filtersProps?.filters.setSearch('');
  };

  const getStatusLabel = (status?: string) => {
    let labelProps;
    let text: string;
    let icon: ICONS | undefined;

    switch (status) {
      case STATUS.PUBLISHED:
        labelProps = {
          color: COLORS.STATUS_PUBLISHED,
          backgroundColor: COLORS.STATUS_PUBLISHED,
          backgroundByPercentage: 8,
        };
        text = i18n._(t`Published`);
        break;
      case STATUS.UPCOMING:
        labelProps = {
          color: COLORS.STATUS_UPCOMING,
          backgroundColor: COLORS.STATUS_UPCOMING,
          backgroundByPercentage: 14,
        };
        text = i18n._(t`Upcoming`);
        break;
      case STATUS.ACTIVE:
        labelProps = {
          color: COLORS.STATUS_ACTIVE,
          backgroundColor: COLORS.STATUS_ACTIVE,
          backgroundByPercentage: 14,
        };
        text = i18n._(t`Active`);
        break;
      case STATUS.COMPLETED:
        labelProps = {
          color: COLORS.STATUS_COMPLETED,
          backgroundColor: COLORS.STATUS_COMPLETED,
          backgroundByPercentage: 8,
        };
        text = i18n._(t`Completed`);
        icon = ICONS.CHECKMARK;
        break;
      case STATUS.ARCHIVED:
        labelProps = {
          color: COLORS.STATUS_ARCHIVED,
          backgroundColor: COLORS.ANY_OTHER_PRODUCTS,
        };
        text = i18n._(t`Archived`);
        break;
      case STATUS.DRAFT:
        labelProps = {
          color: COLORS.STATUS_DRAFT,
          backgroundColor: COLORS.STATUS_DRAFT,
          backgroundByPercentage: 30,
        };
        text = i18n._(t`Draft`);
        break;
      default:
        return null;
    }

    return (
      <StatusLabel {...labelProps}>
        {icon && <StyledIcon icon={icon} size={ICON_SIZES.SMALL} color={labelProps.color} />}
        {text}
      </StatusLabel>
    );
  };

  const isNotFixedFilter = (column: IColumnTable) => !column.isFixed;

  const isThereAnyLeftFixedColumn = !!columns
    .filter(isHiddenFilter)
    .filter((column) => column.isFixed && column.position === ColumnPosition.LEFT)?.length;

  const isAnyRowSelected =
    // @ts-ignore
    !!isMultiSelectVisible && multiSelect?.checkedCount > 0 && isSelectedCountVisible;

  return (
    <Wrapper className={className}>
      {!isHideTop && filtersProps && (
        <StyledTop
          filters={filtersProps.filters}
          actionButton={actionButton}
          secondaryButton={secondaryButton}
          thirdButton={thirdButton}
          filterComponents={filtersProps.filterComponents}
          isToggleHideFilterVisible={filtersProps.isToggleHideFilterVisible}
          resetFilters={filtersProps.resetFilters}
          className={topClassName}
        />
      )}
      {isMultiSelectVisible &&
        (multiSelect as IMultiSelect)?.checkedCount > 0 &&
        isSelectedCountVisible && <SelectedHeader multiSelect={multiSelect as IMultiSelect} />}
      <TableContainer padding={!isAnyRowSelected ? '10px 0 0' : '0'}>
        <FixedTable
          columns={columns
            .filter(isHiddenFilter)
            .filter((column) => column.isFixed && column.position === ColumnPosition.LEFT)}
          data={data}
          isStriped={isLeftColumnsStriped}
          minWidth={leftMinWidth}
          sortBy={sortBy}
          setSortBy={setSortBy}
          onColClick={onColClick}
          onRowClick={onRowClick}
          multiSelect={multiSelect}
          isMultiSelectVisible={isMultiSelectVisible && isThereAnyLeftFixedColumn}
          isSingleSelect={isSingleSelect}
        />
        <MainTable isScrollbarVisible={isScrollbarVisible}>
          <TableWrapper
            isScrollbarVisible={isScrollbarVisible}
            $isPointer={Boolean(onRowClick)}
            isLeftCornerRounded={!isThereAnyLeftFixedColumn}
          >
            {!isHideHeader && !isAnyRowSelected && (
              <Header
                multiSelect={multiSelect}
                isMultiSelectVisible={isMultiSelectVisible && !isThereAnyLeftFixedColumn}
                columns={columns.filter(isHiddenFilter).filter(isNotFixedFilter)}
                sortProps={sortProps}
                isMenuVisible={isMenuVisible}
                isSelectedCountVisible={isSelectedCountVisible}
                isSelectAllVisible={isSelectAllVisible}
                isStatusVisible={isDraftStatusVisible}
              />
            )}
            {!isEmpty(data) && (
              <tbody>
                {data.map((item, i) => {
                  const isItemChecked = isMultiSelectVisible && multiSelect?.isItemChecked(item);
                  return (
                    <TR
                      onMouseEnter={() => setHovered(i)}
                      onMouseLeave={() => setHovered(-1)}
                      key={item?.id}
                      onClick={() => onRowClick && onRowClick(item)}
                      role="link"
                      // @ts-ignore
                      isSelected={isMultiSelectVisible && isItemChecked}
                    >
                      {isMultiSelectVisible && !isThereAnyLeftFixedColumn && (
                        <TD className="checkbox" key={`td-${i}`} $isPointer padding="0 0 0 25px">
                          <SelectorWrapper>
                            {isSingleSelect ? (
                              <RadioButton
                                isSelected={Boolean(isItemChecked)}
                                isHovered={hovered === i}
                                onChange={(e: { stopPropagation: () => void }) => {
                                  e.stopPropagation();
                                  multiSelect?.onSelectItem(item);
                                }}
                              />
                            ) : (
                              <CheckBox
                                isSelected={Boolean(isItemChecked)}
                                isHovered={hovered === i}
                                onChange={(e: { stopPropagation: () => void }) => {
                                  e.stopPropagation();
                                  multiSelect?.onSelectItem(item);
                                }}
                              />
                            )}
                          </SelectorWrapper>
                        </TD>
                      )}
                      {columns
                        .filter(isHiddenFilter)
                        .filter(isNotFixedFilter)
                        .map((column) => {
                          const isCallWithClick =
                            !!onColClick && onColClick.column === column.accessor;
                          return column.renderCell ? (
                            <TD
                              key={`td-${column.accessor}`}
                              maxWidth={column.maxWidth}
                              padding={column.padding}
                              isSelected={Boolean(isItemChecked)}
                              isHovered={hovered === i}
                            >
                              {column.renderCell(
                                item,
                                isCallWithClick ? onColClick?.onClick : undefined,
                                hovered === i,
                                Boolean(isItemChecked),
                              )}
                            </TD>
                          ) : (
                            <TD
                              key={`td-${column.accessor}`}
                              minWidth={column.minWidth}
                              maxWidth={column.maxWidth}
                              isSelected={Boolean(isItemChecked)}
                              isHovered={hovered === i}
                            >
                              {/* @ts-ignore */}
                              {item?.[column.accessor]}
                            </TD>
                          );
                        })}
                      {isDraftStatusVisible && <TD>{getStatusLabel(item?.status)}</TD>}
                      {isMenuVisible && (
                        <TD minWidth="32px" padding="0">
                          <IconMenuWrapper>
                            {isMenuVisible &&
                              createMenuItems &&
                              !isEmpty(createMenuItems(item)) && (
                                <IsVisible isVisible={hovered === i}>
                                  <ContextMenu>
                                    {createMenuItems(item).map((option, i) => (
                                      <ContextOption
                                        key={i}
                                        action={option.action}
                                        icon={option.icon}
                                        isWarning={option.isWarning}
                                        isDisabled={option.isDisabled}
                                        tooltip={option.tooltip}
                                      >
                                        {option.label}
                                      </ContextOption>
                                    ))}
                                  </ContextMenu>
                                </IsVisible>
                              )}
                          </IconMenuWrapper>
                        </TD>
                      )}
                    </TR>
                  );
                })}
              </tbody>
            )}
          </TableWrapper>
        </MainTable>
      </TableContainer>
      {isEmpty(data) && (
        <TablePlaceholder
          isLoading={isLoading}
          isFiltered={filtersProps?.isFiltered}
          noResultText={noResultText}
          emptyStateText={emptyStateText}
          isAllDone={isAllDone}
          onResetSearch={onResetSearch}
          emptyButtonText={emptyButtonText}
          onClickEmptyButton={onClickEmptyButton}
        />
      )}
      {paginationProps && (
        <StyledPaginationBar
          pagination={paginationProps.pagination}
          changePagination={onPageChangeClick}
          changePageSize={handleChangeItemsPerPage}
          count={paginationProps.totalCount}
          noShadow
          noBorder
          noTopBorder
          showCount
          itemLabel={paginationProps.paginationItemLabel}
        />
      )}
    </Wrapper>
  );
};

export { TableList, STATUS };
