import React, { useCallback, useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  SKILL_TEMPLATE_SORT_OPTIONS,
  SKILL_TEMPLATE_STATUS,
} from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';

import { FilterIndustries } from '~/components/AutocompleteFilters/Industries';
import { FilterSurveyStatus } from '~/components/AutocompleteFilters/SkillTemplates';
import { ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TableList } from '~/components/TableList';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { Wrapper } from '~/pages/SuperAdminDashboard/SuperAdminSkillMatrix/SuperAdminPendingSkills.design';
import { SKILL_TEMPLATES_COLUMNS } from '~/pages/SuperAdminDashboard/SuperAdminSkillMatrix/SuperAdminSkillTemplates.columns';

import { FilterForQualityTitle, FilterForQualityWrapper, StyledSwitch } from './design';

import routes from '~/constants/routes';
import { SUPERADMIN_SKILL_TEMPLATE_EDIT } from '~/constants/routes/superadmin';
import type { ISkillTemplateStatus } from '~/constants/skillTemplate';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { useMultiSelectState } from '~/hooks/useMultiSelectState';
import { usePagination } from '~/hooks/usePagination';
import {
  downloadSkillTemplatesCSVSuperAdmin,
  listSkillTemplatesSuperAdmin,
  updateSkillTemplatesSuperAdmin,
  updateSkillTemplateSuperAdmin,
} from '~/services/skillTemplates';
import { isNotFalse } from '~/utils/typePredicates';

import type { IIndustry, ISkillTemplate } from '@learned/types';

function SuperAdminSkillTemplates({
  categoryId,
  isCoreValues,
}: {
  categoryId: string;
  isCoreValues: boolean;
}) {
  const { addToast } = useToasts();
  const { i18n } = useLingui();

  const { pagination, changePagination } = usePagination(20);
  const [search, setSearch] = useState('');
  const [statuses, setStatuses] = useState<ISkillTemplateStatus[]>([]);
  const [industries, setIndustries] = useState<IIndustry[]>([]);
  const [total, setTotal] = useState<number>();
  const [sortBy, setSortBy] = useState<SKILL_TEMPLATE_SORT_OPTIONS>(
    SKILL_TEMPLATE_SORT_OPTIONS.NAME_A_Z,
  );
  const debouncedSearch = useDebounce(search, 300);
  const [skillTemplates, setSkillTemplates] = useState<ISkillTemplate[]>([]);
  const {
    selectedItems: selectedSkillTemplates,
    onSelectItem,
    isItemChecked,
    onCheckAll,
    resetSelectedItems,
  } = useMultiSelectState(skillTemplates);
  const [loading, setLoading] = useState(false);
  const $isCheckForQuality = useBoolState(false);

  const listSkillTemplates = useCallback(
    async () =>
      listSkillTemplatesSuperAdmin(
        {
          search: debouncedSearch,
          categoryId,
          ...(!isEmpty(statuses) && { statuses: statuses.map((status) => status.key) }),
          ...(!isEmpty(industries) && { industries: industries.map((industry) => industry.id) }),
          isQualityFilter: $isCheckForQuality.value,
          isCoreValues,
        },
        {
          skip: pagination.skip,
          limit: pagination.limit,
          sortBy,
        },
      ),
    [
      pagination,
      sortBy,
      categoryId,
      debouncedSearch,
      statuses,
      industries,
      $isCheckForQuality.value,
      isCoreValues,
    ],
  );

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    async function fetch() {
      const result = await listSkillTemplates();
      if (isMounted && result?.code === 200) {
        setSkillTemplates(result?.data?.[API_RETURN_FIELDS.SKILL_TEMPLATES]);
        setTotal(result?.data?.[API_RETURN_FIELDS.TOTAL]);
        setLoading(false);
      }
    }

    fetch();

    return () => void (isMounted = false);
  }, [listSkillTemplates]);

  const openCreateSkillTemplatePage = () => {
    routes.SUPERADMIN_SKILL_TEMPLATE_CREATE.go({ role: 'super-admin' }, { isBackPath: true });
  };

  const exportSkillTemplates = async () => {
    addToast({
      title: i18n._(t`Exporting CSV`),
      subtitle: i18n._(
        t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
      ),
      type: TOAST_TYPES.INFO,
    });

    await downloadSkillTemplatesCSVSuperAdmin(
      {
        search: debouncedSearch,
        categoryId,
        ...(!isEmpty(statuses) && { statuses: statuses.map((status) => status.key) }),
        ...(!isEmpty(industries) && { industries: industries.map((industry) => industry.id) }),
      },
      {
        sortBy,
      },
    );
  };

  const refetchSkillTemplates = async () => {
    const result = await listSkillTemplates();
    if (result?.code === 200) {
      setSkillTemplates(result?.data?.[API_RETURN_FIELDS.SKILL_TEMPLATES]);
      setTotal(result?.data?.[API_RETURN_FIELDS.TOTAL]);
    }
  };

  const changeStatus = async (
    item: ISkillTemplate,
    { status, alertString }: { status: SKILL_TEMPLATE_STATUS; alertString?: string },
  ) => {
    if (
      await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        description: alertString,
      })
    ) {
      const response = await updateSkillTemplateSuperAdmin(item.id, { status });
      if (response?.code === 200) {
        await refetchSkillTemplates();
      }
    }
  };

  const archiveSelectedItems = async () => {
    const alertString = i18n._(
      t`Are you sure you want to archive ${selectedSkillTemplates.length} skill templates?`,
    );
    if (
      await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        description: alertString,
      })
    ) {
      const response = await updateSkillTemplatesSuperAdmin(selectedSkillTemplates, {
        status: SKILL_TEMPLATE_STATUS.ARCHIVED,
      });
      if (response?.code === 200) {
        resetSelectedItems();
        await refetchSkillTemplates();
      }
    }
  };

  const FilterForQuality = () => {
    return (
      <FilterForQualityWrapper>
        <StyledSwitch checked={$isCheckForQuality.value} onChange={$isCheckForQuality.toggle} />
        <FilterForQualityTitle>
          <Trans>Check for quality</Trans>
        </FilterForQualityTitle>
      </FilterForQualityWrapper>
    );
  };

  return (
    <Wrapper>
      <TableList
        isLoading={loading}
        data={skillTemplates}
        onRowClick={(item) => {
          routes.SUPERADMIN_SKILL_TEMPLATE_VIEW.go(
            { role: 'super-admin' },
            {
              skillTemplateId: item.id,
              isBackPath: true,
            },
          );
        }}
        columns={SKILL_TEMPLATES_COLUMNS}
        filtersProps={{
          isToggleHideFilterVisible: true,
          isFiltered: true,
          filters: { search, setSearch },
          filterComponents: (
            <>
              <FilterSurveyStatus selectedItems={statuses} onChange={setStatuses} />
              <FilterIndustries selectedItems={industries} onChange={setIndustries} />
              <FilterForQuality />
            </>
          ),
        }}
        paginationProps={{
          pagination,
          changePagination,
          totalCount: total,
          paginationItemLabel: i18n._('Skills'),
        }}
        sortProps={{ sortBy, setSortBy }}
        actionButton={{ label: <Trans>Add Skill</Trans>, onClick: openCreateSkillTemplatePage }}
        secondaryButton={{
          icon: ICONS.EXPORT,
          variant: ButtonVariant.SECONDARY,
          label: <Trans>Export</Trans>,
          onClick: exportSkillTemplates,
        }}
        multiSelectProps={{
          isMultiSelectVisible: true,
          multiSelect: {
            checkedCount: selectedSkillTemplates.length,
            isAllChecked: every(
              skillTemplates.map((skillTemplate) =>
                selectedSkillTemplates.includes(skillTemplate.id),
              ),
            ),
            onSelectItem,
            isItemChecked,
            onCheckAll,
            onArchive: archiveSelectedItems,
          },
        }}
        menuProps={{
          isMenuVisible: true,
          createMenuItems: (item) =>
            [
              item.status === SKILL_TEMPLATE_STATUS.DRAFT && {
                icon: ICONS.CHECKMARK,
                action: () =>
                  changeStatus(item, {
                    status: SKILL_TEMPLATE_STATUS.PUBLISHED,
                    alertString: i18n._(t`Are you sure you want to publish this skill template?`),
                  }),
                label: <Trans>Publish</Trans>,
              },
              item.status !== SKILL_TEMPLATE_STATUS.ARCHIVED && {
                icon: ICONS.EDIT_PENCIL,
                action: () =>
                  SUPERADMIN_SKILL_TEMPLATE_EDIT.go(
                    { role: 'super-admin' },
                    { skillTemplateId: item?.id, isBackPath: true },
                  ),
                label: <Trans>Edit</Trans>,
              },
              item.status === SKILL_TEMPLATE_STATUS.PUBLISHED && {
                icon: ICONS.EDIT_PENCIL,
                action: () =>
                  changeStatus(item, {
                    status: SKILL_TEMPLATE_STATUS.DRAFT,
                    alertString: i18n._(
                      t`Are you sure you want to set this skill template to draft?`,
                    ),
                  }),
                label: <Trans>Set to draft</Trans>,
              },
              item.status === SKILL_TEMPLATE_STATUS.ARCHIVED && {
                icon: ICONS.EDIT_PENCIL,
                action: () =>
                  changeStatus(item, {
                    status: SKILL_TEMPLATE_STATUS.DRAFT,
                    alertString: i18n._(
                      t`Are you sure you want to set this skill template to draft?`,
                    ),
                  }),
                label: <Trans>Unarchive</Trans>,
              },
              item.status !== SKILL_TEMPLATE_STATUS.ARCHIVED && {
                icon: ICONS.ARCHIVE,
                action: () =>
                  changeStatus(item, {
                    status: SKILL_TEMPLATE_STATUS.ARCHIVED,
                    alertString: i18n._(t`Are you sure you want to archive this skill template?`),
                  }),
                label: <Trans>Archive</Trans>,
              },
            ].filter(isNotFalse),
        }}
      />
    </Wrapper>
  );
}

export { SuperAdminSkillTemplates };
