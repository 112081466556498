import React, { Fragment, useContext } from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Tippy from '@tippyjs/react';

import { Loader } from '~/components/Buttons/components/Loader';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import {
  BenchMarkContainer,
  ChangeContainer,
  DescriptionContainer,
  Label,
  LucaHeaderTable,
  LucaOuterTable,
  LucaTable,
  FirstContainer,
  FirstText,
  FirstTitle,
  PercentageContainer,
  TableHeaderItem,
  TableItem,
  TableItemValue,
  LoadingContainer,
} from './LucaDescription.design';

import { COLORS } from '~/styles';
import { toFixed } from '~/utils/math';
import { stripHtml } from '~/utils/stripHtml';

import { EngagementReportContext } from '../EngagementContext';

const COLUMN_COUNT = 4;

type TProps = {
  ratings?: { date: string; pName: string; rating: number; comment: string }[];
  value: number | null;
  team?: number | null;
  benchmark?: number | null;
  deviation?: number | null;
  timeFrame: string;
  themeID?: string;
  isLoading: boolean;
};

const evaluateCharLength = (text?: string) => !!(text && text.length < 24);

const LucaDescription = ({
  ratings,
  value,
  team,
  benchmark,
  deviation,
  timeFrame,
  themeID,
  isLoading,
}: TProps): JSX.Element => {
  const { i18n } = useLingui();
  const titles = [i18n._('Date'), i18n._('Survey name'), i18n._('Rating'), i18n._('Comment')];

  const { primaryRowSelected, themesOptions, viewAs, reportType } =
    useContext(EngagementReportContext);
  const dimensionName = primaryRowSelected?.key || '';

  const definition = stripHtml(
    themesOptions.find(({ key }) => key === themeID)?.description?.(i18n) || '',
  );

  const getDetails = () => {
    if (viewAs !== ROLES.USER) {
      return null;
    }
    return (
      <>
        {!!team && (
          <ChangeContainer>
            {i18n._(t`Team`)} {toFixed(team || 0, 2)}%
          </ChangeContainer>
        )}
        {!!benchmark && (
          <BenchMarkContainer>
            {i18n._(t`Company`)} {toFixed(benchmark || 0, 2)}%
          </BenchMarkContainer>
        )}
      </>
    );
  };

  const getLabelText = themesOptions.find(({ key }) => key === themeID)?.description?.(i18n) || '';

  return (
    <DescriptionContainer>
      <FirstContainer>
        <FirstTitle>
          <PercentageContainer>{toFixed(value || 0, 2)}%</PercentageContainer>
          {getDetails()}
          {deviation && (
            <Tooltip
              disabled={!deviation}
              tooltip={`The deviation is the difference between the engagement from the start to todays engagement for this ${dimensionName}.`}
              size={TOOLTIP_SIZES.BIG}
            >
              <Label
                color={deviation < 0 ? COLORS.ACCENT_ERROR : COLORS.ACCENT_SUCCESS}
                backgroundColor={deviation < 0 ? COLORS.ERROR_LIGHT : COLORS.SUCCESS_LIGHT}
              >
                {toFixed(deviation || 0, 2)}% {i18n._(t`compared to`)} [{timeFrame}]
              </Label>
            </Tooltip>
          )}
        </FirstTitle>
        {getLabelText.trim().length > 0 && (
          <Tippy content={definition} disabled={!ratings}>
            <FirstText>{stripHtml(getLabelText)}</FirstText>
          </Tippy>
        )}
      </FirstContainer>
      {ratings && ratings.length > 0 && (
        <LucaOuterTable columnCount={COLUMN_COUNT} reportType={reportType}>
          <LucaHeaderTable columnCount={COLUMN_COUNT} rowCount={1}>
            {titles.map((title) => (
              <TableHeaderItem key={title}>{title}</TableHeaderItem>
            ))}
          </LucaHeaderTable>
          <LucaTable columnCount={COLUMN_COUNT} rowCount={ratings.length} isLoading={isLoading}>
            {ratings.map((row, rowIndex) => {
              const rowPosition = rowIndex % 2 === 0 ? true : false;
              return (
                <Fragment key={`${rowIndex}-date`}>
                  <TableItem isPositionedOdd={rowPosition}>{row.date}</TableItem>
                  <TableItem isPositionedOdd={rowPosition}>
                    <Tippy
                      content={row.pName}
                      offset={[18, 10]}
                      disabled={evaluateCharLength(row.pName)}
                    >
                      <TableItemValue>{row.pName}</TableItemValue>
                    </Tippy>
                  </TableItem>
                  <TableItem isPositionedOdd={rowPosition}>{row.rating}</TableItem>
                  <TableItem isPositionedOdd={rowPosition}>
                    <Tippy
                      content={row.comment}
                      offset={[18, 10]}
                      disabled={evaluateCharLength(row.comment)}
                    >
                      <TableItemValue>{row.comment}</TableItemValue>
                    </Tippy>
                  </TableItem>
                </Fragment>
              );
            })}
          </LucaTable>
          {isLoading && (
            <LoadingContainer>
              <Loader />
            </LoadingContainer>
          )}
        </LucaOuterTable>
      )}
    </DescriptionContainer>
  );
};

export { LucaDescription };
