import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CleanUpModal from '~/components/CleanUpModal';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';
import AvatarCard from '~/components/UI/AvatarCard';

import usersIcon from '~/assets/a-users-icn.svg';

import { INTEGRATIONS, INTEGRATIONS_CONN_ERROR_MSG } from '~/constants';
import { INSTRUCTIONS } from '~/constants/instructions';
import useBoolState from '~/hooks/useBoolState';
import { getCompanyConnections, getUsers } from '~/selectors/baseGetters';
import { findUsersToDeleteFromCompanyIntegrations } from '~/services/integrations';
import { COLORS, COLOR_PALETTE } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';
import getUserFullName from '~/utils/getUserFullName';

function CleanUpMembersModal({ i18n, onClose, onSubmit }) {
  const [integrations, setIntegrations] = useState([]);
  const [isDeleteAllForIntegration, setIsDeleteAllForIntegration] = useState('');
  const [isIntegrationError, setIsIntegrationError] = useState(false);
  const $loading = useBoolState(true);
  const itemsPath = 'result'; // path where to get items
  const users = useSelector(getUsers);
  const companyConnections = useSelector(getCompanyConnections);

  useEffect(() => {
    const fetchData = async () => {
      let integrationsWithDeletedMembers;
      try {
        integrationsWithDeletedMembers = await findUsersToDeleteFromCompanyIntegrations();
      } catch (e) {
        // Do nothing
      }

      // If it is undefined then the fetching of integration data failed
      if (!integrationsWithDeletedMembers) {
        setIsIntegrationError(true);
        $loading.off();
        return;
      }

      if (!isEmpty(integrationsWithDeletedMembers)) {
        // Get all external user connections
        const companyExternalUserConnections = Object.values(companyConnections).filter(
          ({ integration }) => integration,
        );

        // Add user name
        integrationsWithDeletedMembers.forEach((integration) => {
          if (!isEmpty(integration.result)) {
            integration.result.map((item, index) => {
              const member = users[item.user];
              if (!isEmpty(member)) {
                integration.result[index].name = getUserFullName(member);
              }
            });
          }

          // Check if any integration is informing that all their members should be cleaned up
          const companyIntegrationConnectionsLength = companyExternalUserConnections.filter(
            (connection) => connection.integration === integration.id,
          ).length;

          if (
            integration.result?.length &&
            integration.result.length === companyIntegrationConnectionsLength
          ) {
            const integrationSystem = Object.values(INTEGRATIONS).find(
              ({ key }) => key === integration.externalSoftware,
            );

            // Set warning field as this is possibly an integration problem
            setIsDeleteAllForIntegration(integrationSystem?.name(i18n));
          }
        });

        setIntegrations(integrationsWithDeletedMembers);
      }

      $loading.off();
    };

    fetchData();

    // eslint-disable-next-line
  }, []);

  return (
    <CleanUpModal
      title={i18n._(t`Cleanup members`)}
      integrations={integrations}
      loading={$loading.value}
      onClose={onClose}
      onSubmit={async () => {
        $loading.on();
        if (!isEmpty(integrations)) {
          const MembersToDelete = [];
          integrations.forEach((i) => MembersToDelete.push(...(i.result || [])));
          await onSubmit(MembersToDelete);
        }
        $loading.off();
      }}
      placeholder={
        <Placeholder
          Icon={() => (
            <SvgIcon
              url={usersIcon}
              width={'50px'}
              height={'50px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
          title={i18n._(t`No API available`)}
          subTitle={i18n._(
            isIntegrationError
              ? INTEGRATIONS_CONN_ERROR_MSG
              : t`Integrate Learned with your HR systems. Contact your customer success manager.`,
          )}
          subTitleStyles={{ ...(isIntegrationError && { color: COLORS.ACCENT_ERROR }) }}
        />
      }
      warningMessage={i18n._(
        t`Warning: Always check if the members you are about to delete are correct. Deleting members cannot be undone.`,
      )}
      confirmMessage={
        isDeleteAllForIntegration
          ? i18n._(
              t`Warning: please note that the integration with ${isDeleteAllForIntegration} has provided 0 results. This is most likely incorrect and a system error in the integration. If you continue with the synchronisation you will DELETE ALL your imported members. This action CANNOT be undone. Click ‘Cancel’ to return.`,
            )
          : i18n._(
              t`Are you sure you want to clean-up this members from Learned? Deleting members cannot be undone!`,
            )
      }
      integrationExplanation={i18n._(
        t`These changes have been detected. Click cleanup to apply the changes.`,
      )}
      integrationDescriptionCol={i18n._(t`Delete user`)}
      integrationPlaceholder={
        <Placeholder
          Icon={() => (
            <SvgIcon
              url={usersIcon}
              width={'50px'}
              height={'50px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
          title={i18n._(t`No members to clean up`)}
        />
      }
      getIntegrationCols={(_integration) => [
        {
          title: i18n._(t`Member`),
          width: '240px',
        },
        {
          title: i18n._(t`Type`),
          width: '120px',
        },
        {
          title: i18n._(t`Description`),
        },
      ]}
      getIntegrationRowData={(item) => [
        <AvatarCard key={0} type={AvatarCard.TYPES.SMALL} userId={item.user} />,
        i18n._(t`Status`),
        i18n._(t`Delete user`),
      ]}
      isSubmitDisabled={integrations.every((i) => isEmpty(i.result))}
      infoMessage={i18n._(
        t`Members can only be cleaned-up if they have been imported via an API and can only be cleaned-up with that specific API.`,
      )}
      infoUrl={getInstructionUrl(INSTRUCTIONS.CLEANUP_MEMBERS)}
      pathToItems={itemsPath}
    />
  );
}

CleanUpMembersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withI18n()(CleanUpMembersModal);
