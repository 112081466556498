import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { AxiosResponse } from 'axios';
import { isObject } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Button } from '~/components/Buttons/Button';
import { Loader } from '~/components/Buttons/components/Loader';
import DashboardHeader from '~/components/DashboardHeader';
import { ICONS, Icon } from '~/components/Icon';
import { LastSaved } from '~/components/LastSaved';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import PaginationBar from '~/components/PaginationBar';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import JobTable from './components/JobTable';
import { MULTI_LANG, MODEL_TYPE, MODAL_TYPE, VIEW_TYPE } from './constants';
import CommonJobModal from './EditJobModals/CommonJobModal';
import {
  ActionsWrap,
  ActionItemBlock,
  MainContainer,
  FilterWrapper,
  Wrapper,
  SearchContainer,
  SearchFieldWrapper,
  WrapperOuter,
  ModalContainer,
  LoadingContainer,
  TopLayer,
  PaginationContainer,
} from './JobMatrix.design';
import JobOptionMenu from './JobOptionMenu';
import {
  addJobFamily,
  addJobGroup,
  addJobLevel,
  deleteColumn,
  deleteJobGroup,
  deleteJobLevel,
  getDataPaginated,
  sortItOut,
  sortUnassignedJobs,
  sortJobLevels,
  TItemToMove,
  updateJobColors,
} from './utils';

import { JOB_PROFILE_STATUSES } from '~/constants';
import { LastSavedStatus } from '~/constants/lastSaved';
import routes from '~/constants/routes';
import useDebounce from '~/hooks/useDebounce';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { usePagination } from '~/hooks/usePagination';
import {
  getJobLevelGroups,
  getJobFamilies,
  getJobProfiles,
  getCareerPlans,
} from '~/selectors/baseGetters';
import getAllUsers from '~/selectors/getAllUsers';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import {
  createJobFamily,
  deleteJobFamilies,
  updateJobFamily,
  getJobFamilies as getJobFamilyService,
} from '~/services/jobFamilies';
import {
  deleteJobLevelGroups,
  updateJobLevelGroup,
  createJobLevelGroup,
  getJobLevelGroups as getJobLevelGroupService,
} from '~/services/jobLevelGroups';
import { getJobProfiles as getJobProfileService, updateJobProfile_ } from '~/services/jobProfiles';
import { getCareerPlans as getCareerPlansAction } from '~/store/careerPlans/actions';
import { setJobFamilies } from '~/store/jobFamilies/actions';
import { setJobLevelGroups } from '~/store/jobLevelGroups/actions';
import { setJobProfiles } from '~/store/jobProfiles/actions';
import { COLORS } from '~/styles';

import type {
  TCareerGroup,
  TCell,
  TFamilyData,
  TMeta,
  TOtherData,
  TSelected,
  TTableData,
} from './types';
import type {
  ICareerPlan,
  IJobFamily,
  IJobLevelGroup,
  IJobProfile,
  IMultiLangString,
  IUser,
} from '@learned/types';

type TProps = { type: VIEW_TYPE; onExpandClick?: (status: boolean) => void; expanded?: boolean };

function JobMatrixCommon({ type, onExpandClick, expanded }: TProps): JSX.Element {
  const [jobData, setJobData] = useState<TTableData[]>([]);
  const [allJobGroupData, setAllJobGroupData] = useState<TTableData[]>([]);
  const [unAssignedJobs, setUnAssignedJobs] = useState<TCell[]>([]);
  const [columnData, setColumnData] = useState<TFamilyData[]>([]);
  const [openedItems, setOpenedItems] = useState<number[]>([]);
  const [colIndex, setColIndex] = useState(0);
  const [rowIndex, setRowIndex] = useState(0);
  const [searchVal, setSearchVal] = useState('');
  const debouncedSearch = useDebounce(searchVal, 500);
  const { i18n } = useLingui();
  const [modalStatus, setModalStatus] = useState<MODEL_TYPE>(MODEL_TYPE.NONE);
  const [otherData, setOtherData] = useState<TOtherData | null>(null);
  const [colorArr, setColorArr] = useState<{ value: string; isSelected: boolean }[]>([]);
  const [noColorUpdate, setNoColorUpdate] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [disableLoader, setDisableLoader] = useState(false);
  const [ascending, setAscending] = useState(false);
  const { addToast } = useToasts();
  const [isLastJob, setIsLastJob] = useState(false);
  const [openedModal, setOpenedModal] = useState<MODAL_TYPE | null>(null);
  const [opened, setOpened] = useState(false);
  const [levelTo, setLevelTo] = useState(0);
  const jobLevelGroups = useSelector(getJobLevelGroups) as IJobLevelGroup[];
  const jobFamilies = useSelector(getJobFamilies) as IJobFamily[];
  const jobProfiles = useSelector(getJobProfiles) as IJobProfile[];
  const dispatch = useDispatch();
  const [initiated, setInitiated] = useState(false);
  const users = useSelector(getAllUsers) as { [key: string]: IUser };
  const careerPlans = useSelector(getCareerPlans) as { [key: string]: ICareerPlan };
  const company = useSelector(getCurrentCompany);
  const getMultiLangString = useMultiLangString();
  const [filteredJobProfiles, setFilteredJobProfiles] = useState(jobProfiles);
  const [filteredJobFamilies, setFilteredJobFamilies] = useState(jobFamilies);
  const [filteredJobLevelGroups, setFilteredJobLevelGroups] = useState(jobLevelGroups);
  const jobProfilesArray = useMemo(
    () => Object.values(filteredJobProfiles || {}),
    [filteredJobProfiles],
  );

  const [lastSaved, setLastSaved] = useState({
    time: new Date(),
    status: LastSavedStatus.SUCCESS,
    errorMessage: '',
  });
  const { pagination, changePagination } = usePagination(5);
  const skipFrom = pagination?.skip || 0;
  const unPaginatedRowNumber = rowIndex + skipFrom;
  const [totalCount, setTotalCount] = useState(0);

  const customPaginationList = useMemo(
    () => [
      { id: 5, label: i18n._(t`5 per page`) },
      { id: 10, label: i18n._(t`10 per page`) },
      { id: 25, label: i18n._(t`25 per page`) },
      { id: 50, label: i18n._(t`50 per page`) },
      { id: 100, label: i18n._(t`100 per page`) },
    ],
    [i18n],
  );

  const updateJobGroupData = (updatedJobGroup: TTableData[], changeTotal = false) => {
    const paginatedData = getDataPaginated(pagination, updatedJobGroup);
    setJobData(paginatedData);
    if (changeTotal) {
      setTotalCount(updatedJobGroup.length);
    }
    setAllJobGroupData(updatedJobGroup);
  };

  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    if (pagination) {
      const newPagination = {
        ...pagination,
        skip,
        index,
      };
      changePagination(newPagination);
      const paginatedJobData = allJobGroupData.slice(skip, skip + pagination.limit);
      setJobData(paginatedJobData);
    }
  };

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    if (pagination) {
      const newPagination = {
        ...pagination,
        limit,
      };
      changePagination(newPagination);
      setJobData(allJobGroupData.slice(0, limit));
    }
  };

  const onLastSavedChange = () => {
    setLastSaved({
      time: new Date(),
      status: LastSavedStatus.SUCCESS,
      errorMessage: '',
    });
  };

  const [selectedValues, setSelectedValues] = useState<TSelected>({
    selectedInputs: null,
    selectedName: '',
  });

  const filteredJobGroup = useMemo(() => {
    return allJobGroupData.length > unPaginatedRowNumber
      ? allJobGroupData[unPaginatedRowNumber]
      : null;
  }, [allJobGroupData, unPaginatedRowNumber]);

  const filteredJobFamily = useMemo(() => {
    return columnData.length > colIndex + 2 ? columnData[colIndex + 2] : null;
  }, [columnData, colIndex]);

  const existingColors = useMemo(() => {
    return [...[COLORS.CAREER], ...allJobGroupData.map((row) => row.color)];
  }, [allJobGroupData]);

  const uniqueColors = useMemo(
    () => existingColors.filter((color, index) => existingColors.indexOf(color) === index) || [],
    [existingColors],
  ) as string[];

  const showErrorToast = (subtitle: string) => {
    addToast({
      title: i18n._(t`Something went wrong! Try again later`),
      subtitle,
      type: TOAST_TYPES.ERROR,
    });
  };

  const onTableItemClick = (
    selected: MODEL_TYPE,
    meta?: { title?: IMultiLangString; values?: IMultiLangString },
  ) => {
    setModalStatus(selected);
    if (meta) {
      setSelectedValues((val) => ({
        ...val,
        ...(meta.values && { selectedInputs: meta?.values }),
        ...(meta.title && { selectedName: meta?.title?.en_GB || '' }),
      }));
    }
  };

  const onJobFamilyDelete = async (index: number) => {
    try {
      setDisableLoader(true);
      await deleteJobFamilies([columnData[index + 2].id]);
      const [newJobs, newColumnNames, newUnAssigned, unAssignedForApi] = deleteColumn(
        allJobGroupData,
        columnData,
        unAssignedJobs,
        index,
      );

      updateJobGroupData(newJobs);
      setColumnData(newColumnNames);
      await Promise.all(
        unAssignedForApi.map((job) => {
          const newJob = {
            jobFamily: null,
            jobLevelGroup: null,
            unAssignJobs: true,
          };

          if (job?.id) {
            return updateJobProfile_(job?.id, newJob);
          }
        }),
      );
      setUnAssignedJobs(sortItOut(newUnAssigned) as TCell[]);
      setDisableLoader(false);
      onLastSavedChange();
    } catch (error) {
      showErrorToast(i18n._(t`Error while deleting job family`));
      setDisableLoader(false);
    } finally {
      onTableItemClick(MODEL_TYPE.NONE);
      refreshJobProfileData(true);
    }
  };

  const onJobGroupAdd = async (values: IMultiLangString, color: string) => {
    setDisableLoader(true);
    try {
      const getNewOrder = () => {
        const jobOrder = allJobGroupData[unPaginatedRowNumber].order || 0;
        if (ascending) {
          return isLastJob ? jobOrder + 1 : jobOrder;
        }
        return isLastJob ? jobOrder : jobOrder + 1;
      };
      const index = allJobGroupData.length === 0 ? 0 : getNewOrder();
      const newlyCreatedGroup = await createJobLevelGroup({
        name: values,
        color,
        levelsFrom: 0,
        levelsTo: 0,
        order: index,
        company: company.id,
      });
      const jobGroupApiData = newlyCreatedGroup.data.jobLevelGroups;

      const updatedJobGroup = addJobGroup(
        allJobGroupData,
        jobGroupApiData.order,
        jobGroupApiData,
        columnData,
        ascending,
      );
      await Promise.all(
        updatedJobGroup.map((element) =>
          updateJobLevelGroup({
            ...element,
            color: element.color || '',
            order: element.order || 0,
          }),
        ),
      );
      updateJobGroupData(updatedJobGroup, true);
      setOpenedItems([]);
      setDisableLoader(false);
      onLastSavedChange();
    } catch (error) {
      showErrorToast(i18n._(t`Error while adding job group`));
      setDisableLoader(false);
    } finally {
      refreshJobProfileData(true);
    }
  };

  const onJobGroupDelete = async (index: number) => {
    const updatedIndex = index + skipFrom;
    try {
      setDisableLoader(true);
      const idToBeRemoved = ascending
        ? allJobGroupData[updatedIndex].id
        : allJobGroupData[allJobGroupData.length - 1 - updatedIndex].id;
      if (idToBeRemoved) {
        await deleteJobLevelGroups([idToBeRemoved]);
      } else {
        return;
      }
      const [newJobs, unAssignedForSorting, unAssignedForApi] = deleteJobGroup(
        allJobGroupData,
        unAssignedJobs,
        index,
        ascending,
      );
      await Promise.all(
        unAssignedForApi.map((job) => {
          const newJob = {
            jobFamily: null,
            jobLevelGroup: null,
            unAssignJobs: true,
          };

          if (job?.id) {
            return updateJobProfile_(job?.id, newJob);
          }
        }),
      );
      await Promise.all(
        newJobs.map((element) =>
          updateJobLevelGroup({
            ...element,
            color: element.color || '',
            order: element.order || 0,
          }),
        ),
      );
      updateJobGroupData(newJobs, true);
      setUnAssignedJobs(sortItOut(unAssignedForSorting) as TCell[]);
      setOpenedItems([]);
      setDisableLoader(false);
      onLastSavedChange();
    } catch (error) {
      showErrorToast(i18n._(t`Error while deleting job group`));
      setDisableLoader(false);
    } finally {
      refreshJobProfileData(true);
    }
  };

  const onJobLevelDelete = async (row: number, level: number) => {
    const updatedRow = row + skipFrom;
    try {
      const [newJobs, unassignedArr, newUnassigned, levelGroupUpdated] = deleteJobLevel(
        allJobGroupData,
        unAssignedJobs,
        updatedRow,
        level,
        ascending,
      );
      setDisableLoader(true);
      await Promise.all([
        ...levelGroupUpdated.map((job) => {
          const newJob = {
            jobLevelGroup: job.jobLevelGroup,
          };

          if (job?.id) {
            return updateJobProfile_(job?.id, newJob);
          }
        }),
        ...newUnassigned.map((job) => {
          const newJob = {
            jobFamily: null,
            jobLevelGroup: null,
            unAssignJobs: true,
          };

          if (job?.id) {
            return updateJobProfile_(job?.id, newJob);
          }
        }),
      ]);

      await updateJobLevelGroup({
        ...newJobs[updatedRow],
        color: newJobs[updatedRow].color || '',
        order: newJobs[updatedRow].order || 0,
      });
      updateJobGroupData(newJobs);
      setUnAssignedJobs(sortItOut(unassignedArr) as TCell[]);
      setDisableLoader(false);
      onLastSavedChange();
    } catch (error) {
      setDisableLoader(false);
      showErrorToast(i18n._(t`Error while deleting job levels`));
    } finally {
      refreshJobProfileData(true);
    }
  };

  const onOrderChange = () => {
    const isAscending = !ascending;
    setAscending(isAscending);
    setOpenedItems((prev) => prev.map((item) => allJobGroupData.length - 1 - item));
    const sortedJobs = sortJobLevels(allJobGroupData || [], isAscending);
    updateJobGroupData(sortedJobs);
  };

  const onDeleteConfirmation = async () => {
    const confirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete?`),
      description: i18n._(t`Are you sure you want to delete the job group? This cannot be undone.`),
    });
    if (confirmed) {
      onJobGroupDelete && onJobGroupDelete(unPaginatedRowNumber);
      onTableItemClick(MODEL_TYPE.NONE);
    }
  };

  const onJobLevelAdd = async (row: number, level: number) => {
    const updateRow = row + skipFrom;
    try {
      setDisableLoader(true);
      const selectedJobGroup = allJobGroupData[updateRow];
      const fixedLevelNumber = ascending ? level : selectedJobGroup.count - level;

      const filtered = (selectedJobGroup?.levels || [])
        ?.filter((_, i) => i >= fixedLevelNumber)
        .map((a) => a.columns)
        .flat()
        .map((a) => a.values)
        .flat();

      await Promise.all(
        filtered.map((job) => {
          const newJob = {
            jobLevelGroup: {
              id: selectedJobGroup.id,
              level: (job?.jobLevelGroup?.level || 0) + 1,
            },
          };
          if (job?.id) {
            return updateJobProfile_(job?.id, newJob);
          }
        }),
      );
      await updateJobLevelGroup({
        id: selectedJobGroup.id,
        name: selectedJobGroup.name,
        levelsFrom: selectedJobGroup.levelsFrom,
        levelsTo: selectedJobGroup.count + 1,
        color: selectedJobGroup.color || '',
        order: selectedJobGroup.order || 0,
      });
      const updatedJobGroups = addJobLevel(
        allJobGroupData,
        updateRow,
        fixedLevelNumber,
        columnData.length - 2,
        columnData,
      );
      updateJobGroupData(updatedJobGroups);
      setDisableLoader(false);
      onLastSavedChange();
    } catch (error) {
      showErrorToast(i18n._(t`Error while updating job levels`));
      setDisableLoader(false);
    } finally {
      refreshJobProfileData(true);
    }
  };

  const onDragEndAction = async (
    groupId: string,
    job: TItemToMove,
    levelNumber: number,
    jobFamilyId: string | null,
    unassign: boolean,
  ) => {
    try {
      setDisableLoader(true);
      const newJob = {
        jobLevelGroup:
          groupId === 'unassigned'
            ? null
            : {
                id: groupId,
                level: levelNumber,
              },
        jobFamily: jobFamilyId,
        unAssignJobs: unassign,
      };
      if (job?.id) {
        await updateJobProfile_(job?.id, newJob);
      }
      onLastSavedChange();
    } catch (error) {
      showErrorToast(i18n._(t`Error while updating job profile`));
    } finally {
      refreshJobProfileData(true);
      setDisableLoader(false);
    }
  };

  const onItemToggle = (index: number) => {
    setOpenedItems((prevOpened) => {
      if (prevOpened.includes(index)) {
        return prevOpened.filter((i) => i !== index);
      } else {
        return [...prevOpened, index];
      }
    });
  };

  const onDeleteJobFamily = async () => {
    const confirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete?`),
      description: i18n._(
        t`Are you sure you want to delete the job family? This cannot be undone.`,
      ),
    });
    if (confirmed) {
      onJobFamilyDelete && onJobFamilyDelete(colIndex);
    }
  };

  const jobActionHandler = async (type: MODEL_TYPE, meta?: TMeta) => {
    if (type === MODEL_TYPE.ADD_JOB_FAMILY) {
      if (!meta?.values) {
        return;
      }
      try {
        setDisableLoader(true);
        const newJobFamily = await createJobFamily({ name: meta?.values });
        const newColData = [...columnData];
        newColData.splice(colIndex + 2, 0, newJobFamily.data.jobFamily);
        const updatedJobGroups = addJobFamily(
          allJobGroupData,
          colIndex,
          newJobFamily.data.jobFamily.id,
        );
        updateJobGroupData(updatedJobGroups);
        setColumnData(newColData);
        setDisableLoader(false);
        onLastSavedChange();
      } catch (error) {
        showErrorToast(i18n._(t`Error while updating job family`));
        setDisableLoader(false);
      } finally {
        refreshJobProfileData(true);
      }
    } else if (type === MODEL_TYPE.EDIT_JOB_FAMILY) {
      if (meta?.id && meta?.values) {
        try {
          setDisableLoader(true);
          await updateJobFamily(meta?.id, {
            name: meta?.values,
          });
          setDisableLoader(false);
          onLastSavedChange();
        } catch (error) {
          showErrorToast(i18n._(t`Error while updating job levels`));
          setDisableLoader(false);
        } finally {
          refreshJobProfileData(true);
        }
      }
      setColumnData((prev) => {
        const newColData = prev.map((a) =>
          a.id === meta?.id && meta?.values ? { ...a, name: meta?.values } : a,
        );
        return newColData;
      });
    } else if (type === MODEL_TYPE.EDIT_JOB_GROUP) {
      try {
        setDisableLoader(true);
        const updatedJobGroups = updateJobColors(allJobGroupData, unPaginatedRowNumber, meta);
        await updateJobLevelGroup({
          ...updatedJobGroups[unPaginatedRowNumber],
          color: updatedJobGroups[unPaginatedRowNumber].color || '',
          order: updatedJobGroups[unPaginatedRowNumber].order || 0,
        });
        updateJobGroupData(updatedJobGroups);
        setDisableLoader(false);
        onLastSavedChange();
      } catch (error) {
        showErrorToast(i18n._(t`Error while updating job group`));
        setDisableLoader(false);
      } finally {
        refreshJobProfileData(true);
      }
    }
  };

  const getModalData = () => {
    switch (modalStatus) {
      case MODEL_TYPE.ADD_JOB_GROUP:
        return (
          <CommonJobModal
            title={i18n._(t`Add job group`)}
            inputText={i18n._(t`Job group name`)}
            onCancel={() => onTableItemClick(MODEL_TYPE.NONE)}
            isColorAvailable
            type={modalStatus}
            colorArr={colorArr}
            setNoColorUpdate={setNoColorUpdate}
            setColorArr={setColorArr}
            onJobGroupAdd={onJobGroupAdd}
          />
        );
      case MODEL_TYPE.EDIT_JOB_GROUP:
        return (
          <CommonJobModal
            title={i18n._(t`Edit job group`)}
            inputText={i18n._(t`Job group name`)}
            onCancel={() => onTableItemClick(MODEL_TYPE.NONE)}
            selectedValues={selectedValues.selectedInputs}
            isColorAvailable
            type={modalStatus}
            colorArr={colorArr}
            setNoColorUpdate={setNoColorUpdate}
            setColorArr={setColorArr}
            otherData={otherData}
            jobActionHandler={jobActionHandler}
            onDelete={onDeleteConfirmation}
          />
        );
      case MODEL_TYPE.ADD_JOB_FAMILY:
        return (
          <CommonJobModal
            title={i18n._(t`Add job family`)}
            inputText={i18n._(t`Job family name`)}
            closeBtnAvailable
            onCancel={() => onTableItemClick(MODEL_TYPE.NONE)}
            type={modalStatus}
            jobActionHandler={jobActionHandler}
          />
        );
      case MODEL_TYPE.EDIT_JOB_FAMILY:
        return (
          <CommonJobModal
            title={i18n._(t`Edit job family`)}
            inputText={i18n._(t`Job family name`)}
            onCancel={() => onTableItemClick(MODEL_TYPE.NONE)}
            selectedValues={selectedValues.selectedInputs}
            jobActionHandler={jobActionHandler}
            type={modalStatus}
            otherData={otherData}
            onDelete={onDeleteJobFamily}
          />
        );

      case MODEL_TYPE.ADD_JOB_LEVEL:
        return (
          <CommonJobModal
            title={i18n._(t`Add job level`)}
            inputText={i18n._(t`Level Name`)}
            onCancel={() => onTableItemClick(MODEL_TYPE.NONE)}
            type={modalStatus}
          />
        );

      default:
        return null;
    }
  };

  const initiateJobProfileData = (updatedJobProfileData: IJobProfile[] = []) => {
    const newJobFamilies = sortItOut(
      (filteredJobFamilies || [])
        ?.map((jobFamily) => ({
          name: jobFamily.name,
          id: jobFamily.id,
          company: jobFamily.company,
          meta: jobFamily.meta,
        }))
        .filter((a) => a.company === company.id) as TFamilyData[],
    );

    const jobProfilesIds = jobProfilesArray.map((profile) => profile.id);

    const filteredCareerPlans = Object.values(careerPlans || {})
      .map((career) => ({
        jobProfile: career?.jobProfile,
        id: career?.id,
        createdFor: career?.createdFor,
      }))
      .filter((career) => jobProfilesIds.includes(career.jobProfile));

    const groupedCareerPlans = filteredCareerPlans.reduce((groups: TCareerGroup, career) => {
      const key = career.jobProfile;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(career);
      return groups;
    }, {});

    const newJobProfiles = (
      updatedJobProfileData.length > 0 ? updatedJobProfileData : jobProfilesArray || []
    )
      ?.map((profile) => {
        return {
          ...profile,
          members: getMembers(profile.id, groupedCareerPlans),
        } as unknown as TCell;
      })
      .filter((a) => a.company === company.id);

    let alreadyAssignedJobs: string[] = [];

    const getValues = (id: string, level: number) => {
      const newLevels = newJobFamilies.map((family) => {
        const filteredValues = newJobProfiles.filter(
          (a) =>
            a.jobLevelGroup?.id === id &&
            a.jobFamily === family.id &&
            a.jobLevelGroup?.level === level,
        );
        const filteredIdArr = filteredValues.map((a) => a.id);
        alreadyAssignedJobs = [...alreadyAssignedJobs, ...filteredIdArr];
        return { id: uuidv4(), values: filteredValues, familyId: family.id };
      });
      return newLevels;
    };

    const getMaxLevelCount = (id: string, levelCount: number) => {
      const numberOfLevels: number[] = newJobProfiles
        .filter((a) => a.jobLevelGroup?.id === id)
        .map((a) => a.jobLevelGroup?.level || 1);
      // If jobProfile has more levels than the group, we need to show all levels
      const isMaxLevelCount =
        Math.max(...numberOfLevels) > levelCount ? Math.max(...numberOfLevels) : levelCount;
      // In case if there are no levels, we need to show at least one level
      return levelCount === 0 ? 1 : isMaxLevelCount;
    };

    const getColumns = (id: string, levelCount: number) => {
      return Array.from({ length: getMaxLevelCount(id, levelCount + 1) }, (_, i) => ({
        columns: getValues(id, i),
      }));
    };

    const getFirstColumnName = (columnName: string) => {
      return {
        [MULTI_LANG.en_GB]: columnName,
        [MULTI_LANG.nl_NL]: columnName,
        [MULTI_LANG.de_DE]: columnName,
      };
    };

    const newJobGroup = (filteredJobLevelGroups || [])
      .map((jobGroup) => {
        if (company.id !== jobGroup.company) {
          return null;
        }
        return {
          id: jobGroup.id,
          name: jobGroup.name,
          company: jobGroup.company,
          meta: {
            createdDate: jobGroup.meta?.createdDate,
            lastModifiedDate: jobGroup.meta?.lastModifiedDate,
          },
          levelsFrom: 0,
          levelsTo: jobGroup.levelsTo,
          color: jobGroup.color,
          order: jobGroup.order,
          count: getMaxLevelCount(jobGroup.id, jobGroup.levelsTo + 1 || 0),
          levels: getColumns(jobGroup.id, jobGroup.levelsTo || 0),
        };
      })
      .filter((group) => group !== null) as TTableData[];

    const sortedJobs = sortJobLevels(newJobGroup || [], ascending);
    const existingJobFamilies = newJobFamilies.map((a) => a.id);

    const finalJobProfiles = newJobProfiles.filter(
      (a) =>
        (!alreadyAssignedJobs.includes(a.id) && !a.jobFamily && !a.jobLevelGroup) ||
        !existingJobFamilies.includes(a.jobFamily || '') ||
        (existingJobFamilies.includes(a.jobFamily || '') && !a.jobLevelGroup),
    );

    const paginatedData = getDataPaginated(pagination, sortedJobs);
    setTotalCount(sortedJobs.length);
    setAllJobGroupData(sortedJobs);
    setJobData(paginatedData);
    setOpenedItems([]);
    setUnAssignedJobs(
      sortUnassignedJobs(
        finalJobProfiles,
        getMultiLangString,
        company?.primaryLang?.locale,
      ) as TCell[],
    );
    setColumnData([
      {
        id: 'firstCol',
        name: getFirstColumnName(i18n._(t`Group`)),
      },
      {
        id: 'secondCol',
        name: getFirstColumnName(i18n._(t`Level`)),
      },
      ...newJobFamilies,
    ] as TFamilyData[]);
  };

  const getMembers = (members: string, groupedCareerPlans: TCareerGroup) => {
    try {
      const filtered = groupedCareerPlans[members] || [];
      const filteredWithAvatar = filtered.map((career) => ({
        id: career?.createdFor,
        avatarUrl: users[career?.createdFor]?.avatarUrl || '',
      }));
      return filteredWithAvatar;
    } catch {
      return [];
    }
  };

  const refreshJobProfileData = async (disabled: boolean, search = '') => {
    try {
      if (disabled) {
        setDisableLoader(true);
      } else {
        setTableLoader(true);
      }

      if (!initiated) {
        dispatch(getCareerPlansAction());
      }

      const [jobProfilesApiData, jobLevelGroups, jobFamilies] = await Promise.all([
        getJobProfileService(
          { status: JOB_PROFILE_STATUSES.ACTIVE.key, ...(search.trim().length > 0 && { search }) },
          {
            projection: {
              name: 1,
              jobFamily: 1,
              jobLevelGroup: 1,
              meta: 1,
              status: 1,
              externalId: 1,
              externalSource: 1,
              company: 1,
            },
          },
        ) as Promise<{ [key: string]: IJobProfile }[]>,
        getJobLevelGroupService() as Promise<
          AxiosResponse<{ jobLevelGroups: { [key: string]: IJobLevelGroup } }>
        >,
        getJobFamilyService() as Promise<
          AxiosResponse<{ jobFamilies: { [key: string]: IJobFamily } }>
        >,
      ]);

      const jobProfileData = Object.values(jobProfilesApiData || {}) as unknown as IJobProfile[];
      setFilteredJobProfiles(jobProfileData);
      const jobLevelGroupsData = Object.values(jobLevelGroups?.data?.jobLevelGroups || {});
      setFilteredJobLevelGroups(jobLevelGroupsData);
      const jobFamiliesData = Object.values(jobFamilies?.data?.jobFamilies || {});
      setFilteredJobFamilies(jobFamiliesData);
    } catch {
      showErrorToast(i18n._(t`Error while fetching data`));
    } finally {
      if (disabled) {
        setDisableLoader(false);
      } else {
        setTableLoader(false);
      }
    }
  };

  useEffect(() => {
    if (type === VIEW_TYPE.JOB_EDIT && company.id) {
      if (!isObject(filteredJobProfiles)) {
        refreshJobProfileData(false);
      } else if (Object.keys(filteredJobProfiles || {}).length === 0 && !initiated) {
        refreshJobProfileData(false);
      } else {
        initiateJobProfileData();
      }
    } else if (type === VIEW_TYPE.JOB_VIEW && company.id && isObject(filteredJobProfiles)) {
      initiateJobProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredJobLevelGroups,
    filteredJobFamilies,
    filteredJobProfiles,
    company.id,
    careerPlans,
    users,
  ]);

  useEffect(() => {
    if (debouncedSearch.trim().length > 0) {
      refreshJobProfileData(false, debouncedSearch);
    } else if (initiated) {
      refreshJobProfileData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  useEffect(() => {
    if (noColorUpdate) {
      return;
    }
    setColorArr(
      uniqueColors.map((color) => ({
        value: color,
        isSelected: false,
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueColors, noColorUpdate]);

  useEffect(() => {
    setInitiated(true);
  }, []);

  const onEditModeClicked = async () => {
    if (searchVal.trim().length > 0) {
      await dispatch(setJobFamilies([]));
      await dispatch(setJobLevelGroups([]));
      await dispatch(setJobProfiles([]));
    }
    if (type === VIEW_TYPE.JOB_VIEW) {
      routes.JOB_MATRIX_EDIT.go();
    } else {
      routes.JOB_PROFILES_ADMIN.go();
    }
  };

  const isAnythingLoading = disableLoader;

  if (type === VIEW_TYPE.JOB_VIEW) {
    return (
      <Wrapper expanded isDisabled={false}>
        <FilterWrapper>
          <SearchContainer>
            <SearchFieldWrapper
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setSearchVal(e.currentTarget.value);
              }}
              value={searchVal}
              placeholder={i18n._(t`Search...`)}
            />
          </SearchContainer>
          <Button
            icon={ICONS.EDIT_PENCIL}
            disabled={false}
            label={i18n._(t`Edit mode`)}
            variant={ButtonVariant.PRIMARY}
            onClick={onEditModeClicked}
            size={ButtonSize.MEDIUM}
          />
          <Icon
            icon={expanded ? ICONS.COLLAPSE_WINDOW : ICONS.EXPAND}
            color={COLORS.PLACEHOLDERS}
            className="skill-button"
            onClick={() => {
              onExpandClick && onExpandClick(!expanded);
            }}
          />
        </FilterWrapper>

        {tableLoader ? (
          <LoadingContainer>
            <Loader />
          </LoadingContainer>
        ) : (
          <>
            <JobTable
              setUnAssignedJobs={setUnAssignedJobs}
              jobData={jobData}
              setJobData={setJobData}
              unAssignedJobs={unAssignedJobs}
              columnData={columnData}
              openedItems={openedItems}
              onItemToggle={onItemToggle}
              isEditMode={false}
              onOrderChange={onOrderChange}
              ascending={ascending}
              pagination={pagination}
              allJobGroupData={allJobGroupData}
            />
            <PaginationContainer>
              <PaginationBar
                pagination={pagination}
                changePagination={onPageChangeClick}
                changePageSize={handleChangeItemsPerPage}
                count={totalCount}
                noShadow
                noBorder
                noTopBorder
                showCount
                itemLabel={i18n._(t`Job Groups`)}
                customPaginationList={customPaginationList}
              />
            </PaginationContainer>
          </>
        )}
      </Wrapper>
    );
  }

  return (
    <>
      <MainContainer>
        <DashboardHeader
          isBoxShadow
          className="dashboard-header"
          title={i18n._(t`Edit mode: Job House`)}
          onBack={onEditModeClicked}
          actions={
            <ActionsWrap>
              <ActionItemBlock>
                <LastSaved
                  time={lastSaved.time}
                  status={lastSaved.status}
                  errorMessage={lastSaved.errorMessage}
                />
              </ActionItemBlock>
            </ActionsWrap>
          }
        />
        <WrapperOuter>
          <Wrapper expanded isDisabled={isAnythingLoading}>
            <FilterWrapper>
              <SearchContainer>
                <SearchFieldWrapper
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchVal(e.currentTarget.value);
                  }}
                  value={searchVal}
                  placeholder={i18n._(t`Search...`)}
                />
              </SearchContainer>
              <JobOptionMenu
                setOpened={setOpened}
                opened={opened}
                setOpenedModal={setOpenedModal}
                openedModal={openedModal}
                jobLevelGroupId={filteredJobGroup?.id || null}
                jobFamilyId={filteredJobFamily?.id || null}
                levelTo={levelTo || 0}
              />
            </FilterWrapper>
            {tableLoader ? (
              <LoadingContainer>
                <Loader />
              </LoadingContainer>
            ) : (
              <>
                <JobTable
                  setUnAssignedJobs={setUnAssignedJobs}
                  jobData={jobData}
                  setJobData={setJobData}
                  unAssignedJobs={unAssignedJobs}
                  columnData={columnData}
                  openedItems={openedItems}
                  onItemToggle={onItemToggle}
                  isEditMode
                  onTableItemClick={onTableItemClick}
                  setColIndex={setColIndex}
                  setRowIndex={setRowIndex}
                  otherData={otherData}
                  otherDataChange={(metaData) => {
                    setOtherData((a) => ({ ...a, ...metaData }));
                  }}
                  onOrderChange={onOrderChange}
                  ascending={ascending}
                  onDragEndAction={onDragEndAction}
                  onJobLevelAdd={onJobLevelAdd}
                  onJobLevelDelete={onJobLevelDelete}
                  onJobFamilyDelete={onJobFamilyDelete}
                  onJobGroupDelete={onJobGroupDelete}
                  setIsLastJob={setIsLastJob}
                  setOpenedModal={setOpenedModal}
                  setLevelTo={setLevelTo}
                  pagination={pagination}
                  allJobGroupData={allJobGroupData}
                />
                <PaginationContainer>
                  <PaginationBar
                    pagination={pagination}
                    changePagination={onPageChangeClick}
                    changePageSize={handleChangeItemsPerPage}
                    count={totalCount}
                    noShadow
                    noBorder
                    noTopBorder
                    showCount
                    itemLabel={i18n._(t`Job Groups`)}
                    customPaginationList={customPaginationList}
                  />
                </PaginationContainer>
              </>
            )}
            {isAnythingLoading && (
              <TopLayer>
                <Loader />
              </TopLayer>
            )}
          </Wrapper>
        </WrapperOuter>
      </MainContainer>
      {modalStatus !== MODEL_TYPE.NONE && <ModalContainer>{getModalData()}</ModalContainer>}
    </>
  );
}

export default JobMatrixCommon;
