import React from 'react';

import { Trans } from '@lingui/macro';

import { Button, ButtonVariant } from '~/components/Buttons';
import { Icon, ICONS } from '~/components/Icon';

import { ButtonLabel, FooterContainer } from './Footer.design';

function Footer({ onBack, onNext }: { onBack?: () => void; onNext: () => void }) {
  return (
    <FooterContainer>
      {onBack && (
        <Button
          onClick={onBack}
          variant={ButtonVariant.SECONDARY}
          label={
            <ButtonLabel>
              <Icon icon={ICONS.BACK} transform="rotate(90)" />
              <Trans>Back</Trans>
            </ButtonLabel>
          }
        />
      )}
      <Button
        onClick={onNext}
        variant={ButtonVariant.PRIMARY}
        label={
          <ButtonLabel>
            <Trans>Next</Trans>
            <Icon icon={ICONS.BACK} transform="rotate(-90)" />
          </ButtonLabel>
        }
      />
    </FooterContainer>
  );
}

export { Footer };
