import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { Input } from '~/components/Input';

import { Action } from './Action';
import { NoOptions, Option, Options, OptionsContainer, SearchInput } from './design';

import { useOutsideClick } from '~/hooks/useOutsideClick';

import type { BaseSelectSearchProps } from './types';

function BaseSelectSearch<T>({
  items,
  search,
  setSearch,
  onSearchChange,
  stringifyItem,
  children,
  hasSelectedItem,
  handleNewItemSelected,
  isHideOptionsOnSelect,
  showRightIcon,
  placeholder,
  CustomOptionComponent,
  noResultsPlaceholder,
  className,
  actions,
}: BaseSelectSearchProps<T>) {
  const { i18n } = useLingui();
  const [showOptions, setShowOptions] = useState(false);
  const baseRef = useOutsideClick<HTMLDivElement>(() => {
    setShowOptions(false);
  });

  useEffect(() => {
    onSearchChange?.(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const filteredItems = !onSearchChange
    ? items.filter((item) => stringifyItem(item).toLowerCase().includes(search.toLowerCase()))
    : items;

  return (
    <SearchInput ref={baseRef} className={className}>
      <Input
        height="38px"
        width="100%"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        leftIcon={!showOptions && search === '' && !hasSelectedItem && <Icon icon={ICONS.SEARCH} />}
        placeholder={placeholder ?? i18n._(t`Search`)}
        onFocus={() => setShowOptions(true)}
        onAbort={() => setShowOptions(false)}
        rightIcon={
          showRightIcon &&
          search !== '' && <Icon icon={ICONS.CLOSE_2} onClick={() => setSearch('')} />
        }
      />
      {children}
      {showOptions && (
        <OptionsContainer>
          <Options>
            {filteredItems.map((item, i) =>
              CustomOptionComponent ? (
                <CustomOptionComponent
                  key={i}
                  item={item}
                  handleNewItemSelected={() => {
                    isHideOptionsOnSelect && setShowOptions(false);
                    handleNewItemSelected(item);
                  }}
                />
              ) : (
                <Option
                  onClick={() => {
                    isHideOptionsOnSelect && setShowOptions(false);
                    handleNewItemSelected(item);
                  }}
                  key={i}
                >
                  {stringifyItem(item)}
                  <Icon icon={ICONS.ADD_PLUS} size={ICON_SIZES.SMALL} />
                </Option>
              ),
            )}
            {filteredItems.length === 0 && (
              <NoOptions>
                {noResultsPlaceholder ? noResultsPlaceholder : <Trans>No results found</Trans>}
              </NoOptions>
            )}
            {actions &&
              actions?.length > 0 &&
              actions.map((action) => (
                <Action
                  key={action.name}
                  action={action}
                  hideDropdown={() => setShowOptions(false)}
                />
              ))}
          </Options>
        </OptionsContainer>
      )}
    </SearchInput>
  );
}

export { BaseSelectSearch };
