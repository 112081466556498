import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import moment from 'moment';
import styled from 'styled-components';

import IconButton from '~/components/IconButton';
import SvgIcon from '~/components/SvgIcon';
import { TableRow, TableCol } from '~/components/UI/TableCard';
import { Header3 } from '~/components/UI/Typographics/headers';

import EditIcon from '~/assets/edit.svg';
import DeleteIcon from '~/assets/mdi-trash.svg';

import { INTEGRATIONS } from '~/constants';
import { COLOR_PALETTE } from '~/styles';

const ActivityTitle = styled(Header3)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const TableText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const ActionSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  justify-items: center;
`;

const IntegrationRow = ({ integration, i18n, onUpdate, onDelete }) => {
  const integrationSoftwareName = Object.values(INTEGRATIONS)
    .find((x) => x.key === integration.externalSoftware)
    .name(i18n);

  return (
    <TableRow key={integration.id} onClick={() => {}} pointer>
      <TableCol pointer>
        <ActivityTitle>{integration.name}</ActivityTitle>
      </TableCol>
      <TableCol pointer>
        <TableText>{integrationSoftwareName}</TableText>
      </TableCol>
      <TableCol pointer>
        <TableText>{moment.utc(integration.meta.lastModifiedDate).format('DD-MM-YYYY')}</TableText>
      </TableCol>
      <TableCol>
        <ActionSection>
          <IconButton
            size={24}
            onClick={() => {
              if (onUpdate) {
                onUpdate();
              }
            }}
            noBorder
            tooltip={i18n._(t`Edit the integration`)}
          >
            <SvgIcon
              width="24px"
              height="24px"
              url={EditIcon}
              alt={i18n._(t`Edit`)}
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
              isDefaultColor
            />
          </IconButton>
          <IconButton
            size={24}
            style={{ marginLeft: '12px' }}
            onClick={() => {
              if (onDelete) {
                onDelete(integration);
              }
            }}
            noBorder
            tooltip={i18n._(t`Delete the integration`)}
          >
            <SvgIcon
              width="24px"
              height="24px"
              url={DeleteIcon}
              alt={i18n._(t`Delete`)}
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
              isDefaultColor
            />
          </IconButton>
        </ActionSection>
      </TableCol>
    </TableRow>
  );
};

export default withI18n()(IntegrationRow);
