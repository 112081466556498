import React from 'react';

import { transparentize } from 'polished';
import styled from 'styled-components';

import CircularProgress from '~/components/CircularProgress';

import { COLORS } from '~/styles';

const Card = styled.div<{ $isMinimizedView: boolean }>`
  padding: 0 20px;
  box-sizing: border-box;
  display: ${(props) => (props.$isMinimizedView ? 'flex' : 'block')};
  align-items: center;
  justify-content: ${(props) => (props.$isMinimizedView ? 'space-between' : 'center')};
  max-width: 484px;
  min-width: 312px;
  height: ${(props) => (props.$isMinimizedView ? '48px' : '229px')};
  border-radius: 6px;
  background-color: ${COLORS.WHITE};
`;

const CircularProgressWrapper = styled.div<{ $isMinimizedView: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.$isMinimizedView ? 'space-between' : 'center')};
  align-items: center;
`;

const TitleWrapper = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
`;

const MinimizedProgress = styled.div`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${COLORS.SUBTEXT};
  margin-left: 12px;
`;

export interface CircularProgressCardProps {
  progress: number;
  title?: string;
  isMinimizedView?: boolean;
  color?: string;
}

const CircularProgressCard = ({
  progress,
  title,
  isMinimizedView = false,
  color = COLORS.SUBTEXT,
}: CircularProgressCardProps) => {
  const circularProgressStyles = {
    strokeWidth: 20,
    textSize: '16px',
    textColor: COLORS.SUBTEXT,
  };

  return (
    <Card $isMinimizedView={isMinimizedView}>
      <TitleWrapper>{title && <Title>{title}</Title>}</TitleWrapper>
      <CircularProgressWrapper $isMinimizedView={isMinimizedView}>
        <CircularProgress
          size={isMinimizedView ? 32 : 150}
          isHideText={isMinimizedView}
          progress={progress}
          pathColor={color}
          trailColor={transparentize(0.8, color)}
          {...circularProgressStyles}
        />
        {isMinimizedView && <MinimizedProgress>{progress}%</MinimizedProgress>}
      </CircularProgressWrapper>
    </Card>
  );
};

export { CircularProgressCard };
