import React, { ReactNode, useEffect, useState } from 'react';

import {
  USER_REVIEW_STATUS,
  USER_REVIEW_REQUEST_TYPE,
  USER_REVIEW_SORT_OPTIONS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Dropdown } from '~/components/Dropdown';
import { ICONS, ICON_SIZES } from '~/components/Icon';
import PaginationBar from '~/components/PaginationBar';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TablePlaceholder } from '~/components/TablePlaceholder';
import { DateOption } from '~/pages/Conversations/components/CalendarDropdown';
import { TableHeader } from '~/pages/Conversations/components/TableHeader';

import { COLUMNS } from './columns';
import { StyledTableReviews } from './design';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import { IMultiSelectOption, USER_REVIEW_STATUS_OPTIONS } from '~/constants/userReviews';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import { checkModuleCoachCreateReview, getSelectedRole, getUser } from '~/selectors/baseGetters';
import { getUserReviewsByUser } from '~/services/publicProfile';
import { getConversationPath } from '~/utils/userConversationsUtils';

import {
  Wrapper,
  FilterMargin,
  StyledButton,
  FiltersWrapper,
  StyledAutocompleteMembers,
  FilterWrapper,
} from '../../design';

import type { IUser, IUserReview } from '@learned/types';

export interface IType {
  id: string;
  key: string;
  name: string;
  translated: (i18n: any) => ReactNode;
}

type IFilter = {
  isShowFilters: boolean;
  search: string;
  sortBy: USER_REVIEW_SORT_OPTIONS;
  selectedDateOption: DateOption | undefined;
  selectedCoaches: IUser[];
  statuses: IMultiSelectOption[];
};

const initialFilters: IFilter = {
  isShowFilters: false,
  search: '',
  statuses: USER_REVIEW_STATUS_OPTIONS.filter(
    (option) =>
      option.key !== USER_REVIEW_STATUS.DRAFT && option.key !== USER_REVIEW_STATUS.ARCHIVED,
  ),
  sortBy: USER_REVIEW_SORT_OPTIONS.START_DATE_NEW_OLD,
  selectedDateOption: undefined,
  selectedCoaches: [],
};

const LS_KEY = 'LS_DEVELOPMENT_PERSONAL_USER_PUBLIC';

type TConversations = {
  userTempId?: string;
};
const Reviews = ({ userTempId }: TConversations) => {
  const { i18n } = useLingui();
  const params = useParams();
  const userId = get(params, 'userId') || userTempId; // userID that coach or admin look on

  const localStorageData = localStorage.getItem(LS_KEY);
  const isLocalStorageData = !isEmpty(localStorageData);
  const parsedLocalStorageData = isLocalStorageData ? JSON.parse(localStorageData as string) : null;

  const history = useHistory();
  const user = useSelector(getUser);
  const selectedRole = useSelector(getSelectedRole);
  const isModuleCoachCreateConversation = useSelector(checkModuleCoachCreateReview);
  const [items, setItems] = useState<IUserReview[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const { pagination, changePagination, resetPagination } = usePagination(3);
  const [currentFilters, setCurrentFilters] = useState<IFilter>(
    isLocalStorageData ? parsedLocalStorageData : initialFilters,
  );

  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300); // isShowFilters does not affect on reFetch
  const $loading = useBoolState(false);

  const fetchData = async () => {
    $loading.on();

    const {
      data: { userReviews: items, total },
    } = await getUserReviewsByUser(userId || user.id, {
      filters: {
        search: currentFilters.search,
        status: currentFilters.statuses.map(
          ({ key }: IMultiSelectOption) => key as USER_REVIEW_STATUS,
        ),
        timeframe:
          currentFilters.selectedDateOption?.fromDate && currentFilters.selectedDateOption?.toDate
            ? [currentFilters.selectedDateOption.fromDate, currentFilters.selectedDateOption.toDate]
            : undefined,
      },
      options: {
        skip: pagination.skip,
        limit: pagination.limit,
        sortBy: currentFilters.sortBy,
      },
      type: USER_REVIEW_REQUEST_TYPE.PERSONAL,
    });

    setItems(Object.values(items));
    setTotalCount(total);
    $loading.off();
  };

  // first render
  useEffect(() => {
    if (isLocalStorageData) {
      // set pagination from localstorage
      if (parsedLocalStorageData.pagination) {
        changePagination(parsedLocalStorageData.pagination);
      }
    }
    // eslint-disable-next-line
  }, [params]);

  // change filters fetch
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [pagination.limit, pagination.skip, JSON.stringify(debCurrentFilters), userId]);

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    const newPagination = {
      ...pagination,
      limit,
    };
    onPaginationChange(newPagination);
  };

  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    const newPagination = {
      ...pagination,
      skip,
      index,
    };
    onPaginationChange(newPagination);
  };

  const actionButton =
    (selectedRole === ROLES.COACH && isModuleCoachCreateConversation) ||
    selectedRole === ROLES.ADMIN
      ? {
          label: t`Create`,
          onClick: () => {
            routes.REVIEW_CREATE.go(
              {},
              {
                isBackPath: true,
                query: { users: [userId] }, // pre-select user that coach/admin work with
              },
            );
          },
        }
      : undefined;

  const onCurrentFiltersChange = (newFilters: IFilter) => {
    setCurrentFilters(newFilters);

    // store in localstorage
    localStorage.setItem(LS_KEY, JSON.stringify(newFilters));
  };

  const onPaginationChange = (newPagination: typeof pagination) => {
    changePagination(newPagination);

    // store in localstorage
    localStorage.setItem(LS_KEY, JSON.stringify({ ...currentFilters, pagination: newPagination }));
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,
    setSearch: (value: string) => {
      const newFilters = { ...currentFilters, search: value };
      onCurrentFiltersChange(newFilters);
      resetPagination();
    },

    // @ts-ignore
    onChangeFilter: (key, value) => {
      const newFilters = { ...currentFilters, [key]: value };
      onCurrentFiltersChange(newFilters);

      // do not reset pagination for keys
      if (!['isShowFilters'].includes(key)) {
        resetPagination();
      }
    },
    resetFilters: () => onCurrentFiltersChange(initialFilters),
    selectedDateOption: currentFilters.selectedDateOption,
    selectedCoaches: currentFilters.selectedCoaches,
    statuses: currentFilters.statuses,
  };

  const onItemClick = {
    column: 'name',
    onClick: (conversation: any) => {
      const path = getConversationPath({
        conversation,
        selectedRole,
        userId,
        user,
        teams: undefined,
      });
      history.push(path as string);
    },
  };

  return (
    <>
      <Wrapper>
        <TableHeader
          filters={filters}
          actionButton={actionButton}
          actionButtonIcon={ICONS.ADD_PLUS}
          headerTitle={i18n._(t`Reviews`)}
        >
          {!isEmpty(items) && (
            <FilterMargin>
              <StyledButton
                icon={filters.isShowFilters ? ICONS.HIDE : ICONS.SHOW}
                label={filters.isShowFilters ? i18n._(t`Hide filters`) : i18n._(t`Show filters`)}
                variant={ButtonVariant.TEXT_PRIMARY}
                size={ButtonSize.MEDIUM}
                onClick={() => filters.onChangeFilter('isShowFilters', !filters.isShowFilters)}
              />
            </FilterMargin>
          )}
        </TableHeader>

        {filters.isShowFilters && (
          <FiltersWrapper>
            {selectedRole === ROLES.USER && (
              <StyledAutocompleteMembers
                checkedList={currentFilters.selectedCoaches}
                onChange={(value: IType[]) => filters.onChangeFilter('selectedCoaches', value)}
              />
            )}
            <Dropdown
              placeholder={i18n._(t`Status`)}
              selectedItems={filters.statuses || []}
              items={USER_REVIEW_STATUS_OPTIONS.filter(
                (option) => option.key !== USER_REVIEW_STATUS.DRAFT,
              )}
              onChange={(selectedItems?: IMultiSelectOption[]) => {
                filters.onChangeFilter('statuses', selectedItems || []);
              }}
              stringifyItem={(item) => item.translated(i18n)}
              isSingleSelect={false}
              skipSort={true}
            />
            <FilterWrapper>
              <Button
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.MEDIUM}
                label={i18n._(t`Reset all filters`)}
                onClick={filters.resetFilters}
                icon={ICONS.CLOSE}
                iconSize={ICON_SIZES.SMALL}
              />
            </FilterWrapper>
          </FiltersWrapper>
        )}

        <ShowSpinnerIfLoading loading={$loading.value}>
          <StyledTableReviews
            data={items}
            columns={!isEmpty(items) ? COLUMNS : []}
            sortBy={currentFilters.sortBy}
            setSortBy={(sortBy: USER_REVIEW_SORT_OPTIONS) =>
              onCurrentFiltersChange({ ...currentFilters, sortBy })
            }
            onColClick={onItemClick}
            hideHeaders={false}
          />

          {isEmpty(items) && (
            <TablePlaceholder
              isLoading={false}
              isFiltered={!!currentFilters.search.length}
              noResultText={i18n._(t`No reviews found`)}
              emptyStateText={i18n._(t`No reviews yet… Let's create one!`)}
              emptyButtonText={actionButton?.label}
              onClickEmptyButton={() => actionButton?.onClick()}
            />
          )}

          {pagination && (
            <PaginationBar
              pagination={pagination}
              changePagination={onPageChangeClick}
              changePageSize={handleChangeItemsPerPage}
              count={totalCount}
              noShadow
              noBorder
              noTopBorder
              showCount
              itemLabel={i18n._(t`Reviews`)}
            />
          )}
        </ShowSpinnerIfLoading>
      </Wrapper>
    </>
  );
};

export { Reviews };
