import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';
import { TabSlider } from '~/components/TabSlider';
import { ITabSliderItem } from '~/components/TabSlider/types';
import Divider from '~/components/UI/Divider';
import InfoBox from '~/components/UI/InfoBox';
import { InviteExternalPeers } from '~/pages/Reviews/Modals/NominatePeers/InviteExternalPeers';
import { InvitePeers } from '~/pages/Reviews/Modals/NominatePeers/InvitePeers';
import { QuestionsForPeers } from '~/pages/Reviews/Modals/NominatePeers/QuestionsForPeers';

import {
  Footer,
  Container,
  Header,
  ActionContainer,
  Title,
  SubTitle,
  TitleContainer,
} from './design';
import { InvitationMessage } from './InvitationMessage';

import { COLORS } from '~/styles';

type NominatePeersProps = {
  closeModal?: () => void;
  taskId?: string;
};

const NominatePeers = ({ closeModal }: NominatePeersProps) => {
  const { i18n } = useLingui();

  const [peers] = useState([]);
  const [externalPeers] = useState([]);
  const [inviteMessage] = useState('');

  const tabs: ITabSliderItem[] = [
    {
      label: `${i18n._(t`Invite peers `)} (${peers.length})`,
      key: 'one',
      isDefault: true,
      content: <InvitePeers />,
      // eslint-disable-next-line no-console
      onChange: () => console.log(''), // TODO: On Change
    },
    {
      label: `${i18n._(t`Invite external peers `)} (${externalPeers.length})`,
      key: 'two',
      isDefault: false,
      content: <InviteExternalPeers />,
      // eslint-disable-next-line no-console
      onChange: () => console.log(''), // TODO: On Change
    },
  ];

  return (
    <Modal
      showDivider={false}
      centerModal
      contentStyles={{ padding: '24px 32px', overflow: 'visible' }}
      borderRadius={6}
      hideFooter
      isHideHeader
    >
      <Header>
        <TitleContainer>
          <Title>{i18n._(t`Ask peers for input`)}</Title>
          <SubTitle>{`${i18n._(t`For`)} ${'[review name]'}`}</SubTitle>
        </TitleContainer>

        <ActionContainer>
          <Button size={ButtonSize.MEDIUM} onClick={closeModal} variant={ButtonVariant.CLOSE} />
        </ActionContainer>
      </Header>

      <Container>
        <InfoBox
          value={
            '[here comes the instruction that the admin/coach left in the create review cycle for nominate peers task] [here comes the instruction that the admin/coach left in the create review cycle for nominate peers task]'
          } // TODO: Replace with data from task
        />
        <TabSlider
          items={tabs}
          tabTitleAlignment={'left'}
          noPadding={true}
          paddingWrapper={'24px 0'}
          tabPadding={'6px 24px'}
        />

        <InvitationMessage value={inviteMessage} />
        <QuestionsForPeers questions={['test01']} />
      </Container>

      <Divider color={COLORS.BORDERS} />

      <Footer>
        <Button
          label={i18n._(t`Cancel`)}
          type="button"
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={closeModal}
        />
        <Button
          label={i18n._(t`Send`)}
          onClick={closeModal}
          type="button"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
        />
      </Footer>
    </Modal>
  );
};

export { NominatePeers };
