import React from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useParams } from 'react-router';

import { HeaderFocusMode } from '~/components/Headers/HeaderFocusMode';
import { HeaderIconButtons } from '~/components/Headers/HeaderFocusMode/types';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { useUserReview } from './hooks/useUserReview';

import { useFromQuery } from '~/hooks/useFromQuery';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import type { IReviewDashboardUserForm } from './types';
import type { UseFormReturn } from 'react-hook-form';

interface ReviewCycleFormProps {
  formMethods: UseFormReturn<IReviewDashboardUserForm>;
  languageState: ILanguageStateReturn;
}

const ReviewDashboardUserForm = ({ formMethods, languageState }: ReviewCycleFormProps) => {
  const { i18n } = useLingui();
  const params: Record<string, string | undefined> = useParams();
  const query = useFromQuery({ includeHash: true });
  const userReviewId = params.userReviewId as string;

  const getMultiLangString = useMultiLangString();
  const { watch } = formMethods;
  const { addToast } = useToasts();

  const { deleteUserReview, isAllowToDelete } = useUserReview({ formMethods, userReviewId });

  const nameMultiLang = turnArrayIntoMultiLang(watch('name'));

  const goToReviews = () => {
    query.goBack();
  };

  const onDelete = async () => {
    const confirmResult = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete review?`),
      description: i18n._(
        t`Are you sure you want to delete this review? This action cannot be undone.`,
      ),
    });

    if (confirmResult) {
      await deleteUserReview();
      addToast({
        title: i18n._(t`Review deleted`),
        type: TOAST_TYPES.SUCCESS,
      });
      goToReviews();
    }
  };

  const iconButtons: HeaderIconButtons[] = [
    ...(isAllowToDelete
      ? [
          {
            icon: ICONS.DELETE_BIN,
            onClick: async () => {
              await onDelete();
            },
            tooltip: i18n._(t`Delete`),
          } as HeaderIconButtons,
        ]
      : []),
  ];

  return (
    <>
      <HeaderFocusMode
        title={i18n._(t`User Dashboard review: ${getMultiLangString(nameMultiLang)}`)}
        goBack={() => {
          goToReviews();
        }}
        languageState={languageState}
        iconButtons={iconButtons}
      />
    </>
  );
};

export { ReviewDashboardUserForm };
