import React from 'react';

import { ModalDimensionsRow } from './DimensionsStrip.design';

import { TTimeFrame } from '../../types';

interface IDimensionDisplayProps {
  firstDimension: string;
  secondDimension?: string;
  measure?: string;
  timeFrame: TTimeFrame;
}

const DimensionsStrip: React.FC<IDimensionDisplayProps> = ({
  firstDimension,
  secondDimension,
  measure,
  timeFrame,
}) => {
  const MAX_DIMENSION_LENGTH = 15;
  const dimensions = [firstDimension, secondDimension].filter(Boolean);
  return (
    <ModalDimensionsRow>
      {dimensions.map((dimension, i) => {
        const separator =
          i + 1 < dimensions.length ? (
            <span> &nbsp;&gt;&nbsp; </span>
          ) : (
            <span> &nbsp;/&nbsp; </span>
          );
        if (dimension) {
          return (
            <>
              <span key={dimension}>
                {dimension.length > MAX_DIMENSION_LENGTH
                  ? `${dimension.slice(0, MAX_DIMENSION_LENGTH)}...`
                  : dimension}
              </span>
              {separator}
            </>
          );
        }
        return null;
      })}
      {measure || (
        <>
          [{timeFrame.start} - {timeFrame.end}]
        </>
      )}
    </ModalDimensionsRow>
  );
};

export default DimensionsStrip;
