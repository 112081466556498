import React, { useContext, useEffect, useRef, useState } from 'react';

import { LUCA_INSIGHT_STATUS, REPORT_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { LucaSummaryUI } from './LucaSummaryUI';

import useBoolState from '~/hooks/useBoolState';
import * as reportsService from '~/services/reports';

import { EngagementReportContext } from '../EngagementContext';

type TProps = { id: string; type?: string };

const initialInsight = {
  id: null,
  status: LUCA_INSIGHT_STATUS.PENDING,
  responses: { content1: '', content2: '', content3: '', content4: '' },
};
const LucaSummary = ({ id, type = 'REPORT' }: TProps): JSX.Element => {
  const { i18n } = useLingui();
  const { viewAs } = useContext(EngagementReportContext);
  const refreshInterval = useRef<number | undefined>();
  const $isLoading = useBoolState(false);
  const [insight, setInsight] = useState({ ...initialInsight });
  const { addToast } = useToasts();

  useEffect(() => {
    if (refreshInterval.current) {
      clearInterval(refreshInterval.current);
    }

    if (insight.status !== LUCA_INSIGHT_STATUS.SUCCEEDED && id.trim().length > 0) {
      refreshInterval.current = window.setInterval(async () => {
        try {
          const { data } = await reportsService.getAIInsight(
            REPORT_TYPES.ENGAGEMENT,
            viewAs,
            `ENGAGEMENT_${type}_${id}`,
          );
          if (
            data.status === LUCA_INSIGHT_STATUS.READY ||
            data.status === LUCA_INSIGHT_STATUS.FAILED ||
            data.status === LUCA_INSIGHT_STATUS.NO_DATA
          ) {
            setInsight({
              id: data.id,
              status: LUCA_INSIGHT_STATUS.SUCCEEDED,
              responses: { ...data.responses },
            });
          }
        } catch {
          setInsight({
            ...initialInsight,
            status: LUCA_INSIGHT_STATUS.FAILED,
            responses: { ...initialInsight.responses },
          });
        }
      }, 5000);
    }

    return () => {
      clearInterval(refreshInterval.current);
    };
  }, [insight.status, id, type, viewAs]);

  const onRefresh = async () => {
    if (insight.id) {
      try {
        $isLoading.on();
        const response = await reportsService.deleteAIInsight(
          REPORT_TYPES.ENGAGEMENT,
          viewAs,
          insight.id,
          `ENGAGEMENT_REPORT_${id}`,
        );
        if (response.status === 'fail') {
          addToast({
            title: i18n._(t`Try again later`),
            subtitle: i18n._(t`You can only refresh the data once every 6 hours`),
            type: TOAST_TYPES.ERROR,
          });
        }
        if (refreshInterval.current) {
          clearInterval(refreshInterval.current);
        }
      } catch {
        addToast({
          title: i18n._(t`Something went wrong!`),
          type: TOAST_TYPES.ERROR,
        });
      } finally {
        $isLoading.off();
        setInsight({ ...initialInsight });
      }
    }
  };

  const isSucceeded = insight.status === LUCA_INSIGHT_STATUS.SUCCEEDED;

  return (
    <LucaSummaryUI
      isSucceeded={isSucceeded}
      onRefresh={onRefresh}
      insightResponse={insight.status}
      content={insight.responses.content1}
      showActionButton
      isScrollable={false}
    />
  );
};

export { LucaSummary };
