import React, { useEffect } from 'react';

import { CONFIRMATION_MODAL_TYPE, REVIEW_STATUS, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';

import { HeaderFocusMode } from '~/components/Headers/HeaderFocusMode';
import { HeaderIconButtons } from '~/components/Headers/HeaderFocusMode/types';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TabSlider } from '~/components/TabSlider';
import type { ITabSliderItem } from '~/components/TabSlider/types';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { useReview } from './hooks/useReview';

import routes from '~/constants/routes';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { checkModuleReviews } from '~/selectors/baseGetters';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import { AnswersTab } from '../tabs/Answers';
import { CalibrationTab } from '../tabs/Calibration';
import { LogsTab } from '../tabs/Logs';
import { ParticipationTab } from '../tabs/Participation';
import { ResultsTab } from '../tabs/Results';
import { SettingsTab } from '../tabs/Settings';

import type { IReviewDashboardCycleForm } from './types';
import type { UseFormReturn } from 'react-hook-form';

const ContentWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

interface ReviewCycleFormProps {
  formMethods: UseFormReturn<IReviewDashboardCycleForm>;
  languageState: ILanguageStateReturn;
}

export const REVIEW_CYCLE_DASHBOARD_TAB_KEYS = {
  PARTICIPATION: 'participation',
  CALIBRATION: 'calibration',
  RESULTS: 'results',
  ANSWERS: 'answers',
  SETTINGS: 'settings',
  LOGS: 'logs',
};

const ReviewDashboardForm = ({ formMethods, languageState }: ReviewCycleFormProps) => {
  const { i18n } = useLingui();
  const history = useHistory();
  const params: Record<string, string | undefined> = useParams();
  const reviewId = params.reviewId as string;
  const isModulePerformanceEnabled = useSelector(checkModuleReviews);
  const getMultiLangString = useMultiLangString();
  const { watch } = formMethods;

  const { addToast } = useToasts();

  const { isLoading, deleteReview, isAllowToDelete } = useReview({ formMethods, reviewId });

  const nameMultiLang = turnArrayIntoMultiLang(watch('name'));

  useEffect(() => {
    if (!isModulePerformanceEnabled) {
      history.push(routes.HOME);
    }
  }, [isModulePerformanceEnabled, history]);

  const goToReviewCycles = () =>
    history.push(
      routes.REVIEWS.build(
        // @ts-ignore
        { role: ROLES.USER },
        { hash: 'review-cycles' },
      ),
    );

  const onDelete = async () => {
    const confirmResult = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete review cycle?`),
      description:
        formMethods.getValues('status') === REVIEW_STATUS.DRAFT
          ? i18n._(
              t`Are you sure you want to delete this review cycle? This action cannot be undone.`,
            )
          : i18n._(
              t`Are you sure you want to delete this review cycle? All reviews within this cycle will be deleted. This action cannot be undone.`,
            ),
    });

    if (confirmResult) {
      await deleteReview();
      addToast({
        title: i18n._(t`Review cycle deleted`),
        type: TOAST_TYPES.SUCCESS,
      });
      goToReviewCycles();
    }
  };

  const iconButtons: HeaderIconButtons[] = [
    ...(isAllowToDelete
      ? [
          {
            icon: ICONS.DELETE_BIN,
            onClick: async () => {
              await onDelete();
            },
            tooltip: i18n._(t`Delete`),
          } as HeaderIconButtons,
        ]
      : []),
  ];

  const TABS: ITabSliderItem[] = [
    {
      key: REVIEW_CYCLE_DASHBOARD_TAB_KEYS.PARTICIPATION,
      label: t`Participation`,
      content: <ParticipationTab />,
    },
    {
      key: REVIEW_CYCLE_DASHBOARD_TAB_KEYS.CALIBRATION,
      label: t`Calibration`,
      content: <CalibrationTab />,
    },
    {
      key: REVIEW_CYCLE_DASHBOARD_TAB_KEYS.RESULTS,
      label: t`Results`,
      content: <ResultsTab />,
    },
    {
      key: REVIEW_CYCLE_DASHBOARD_TAB_KEYS.ANSWERS,
      label: t`Answers`,
      content: <AnswersTab />,
    },
    {
      key: REVIEW_CYCLE_DASHBOARD_TAB_KEYS.SETTINGS,
      label: t`Settings`,
      content: <SettingsTab />,
    },
    {
      key: REVIEW_CYCLE_DASHBOARD_TAB_KEYS.LOGS,
      label: t`Activity log`,
      content: <LogsTab />,
    },
  ];

  return (
    <>
      <HeaderFocusMode
        title={i18n._(t`Dashboard review: ${getMultiLangString(nameMultiLang)}`)}
        goBack={() => {
          history.push(
            routes.REVIEWS.build(
              // @ts-ignore
              { role: ROLES.USER },
              { hash: 'review-cycles' },
            ),
          );
        }}
        languageState={languageState}
        iconButtons={iconButtons}
      />
      <ContentWrapper>
        <ShowSpinnerIfLoading loading={isLoading}>
          <TabSlider items={TABS} />
        </ShowSpinnerIfLoading>
      </ContentWrapper>
    </>
  );
};

export { ReviewDashboardForm };
