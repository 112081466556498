import styled from 'styled-components';

import { COLORS } from '~/styles';

const GraphOuterCtr = styled.div`
  height: 100%;
  width: 100%;
  flex: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const GraphCtr = styled.div`
  position: relative;
  cursor: default;
  flex: auto;
`;

const TooltipCtr = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.BORDERS};
  border-radius: 6px;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
  padding: 15px 17px;
  width: 240px;
  height: auto;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  transition: visibility 0.4s ease-in-out;
  transform: translate(0%, -100%);
  box-sizing: border-box;
  pointer-events: none;
  user-select: none;
`;

const TooltipTitle = styled.div`
  color: ${COLORS.TEXT_HOVER};
  font-weight: 600;
  font-size: 18px;
`;

const TooltipRowValue = styled.div`
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
`;

const TooltipSecondary = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
  box-sizing: border-box;
`;

const OtherContent = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  display: flex;
  margin-top: 8px;
  gap: 10px;
  align-items: center;
`;

const OtherColorBar = styled.div`
  background: currentColor;
  width: 8px;
  align-self: stretch;
  border-radius: 50px;
`;

const OtherContentLeft = styled.div`
  color: currentColor;
`;

const OtherContentRight = styled.div`
  color: ${COLORS.TEXT_HOVER};
`;

const TooltipColorBar = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  width: 8px;
  border-radius: 50px;
  align-self: stretch;
  opacity: 0.8;
  flex: none;
`;

const TooltipColorText = styled.div`
  display: flex;
  flex-direction: column;
`;

const TooltipColValue = styled.div`
  color: ${COLORS.TEXT_HOVER};
  font-size: 24px;
`;

const TooltipComparison = styled.div`
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 4px;
  position: relative;
  color: ${COLORS.CONFIRMATION_MODAL_SUCCESS};
  border: 1px solid currentColor;
`;

const TooltipBackgroundCtr = styled.div`
  background: ${COLORS.CONFIRMATION_MODAL_SUCCESS};
  opacity: 0.2;
  position: absolute;
  inset: 0;
  background: currentColor;
`;

const Progress = styled.div``;

const Tile = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ColoredCircle = styled.div<{ selectedColor: string }>`
  background: ${({ selectedColor }) => selectedColor};
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

const GraphFooter = styled.div`
  font-size: 12px;
  color: ${COLORS.TEXT_HOVER};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  margin-top: 20px;
`;

export {
  GraphFooter,
  ColoredCircle,
  Tile,
  Progress,
  TooltipBackgroundCtr,
  TooltipComparison,
  TooltipColValue,
  TooltipColorText,
  TooltipColorBar,
  OtherContentRight,
  OtherContentLeft,
  OtherColorBar,
  OtherContent,
  TooltipSecondary,
  TooltipRowValue,
  TooltipTitle,
  TooltipCtr,
  GraphCtr,
  GraphOuterCtr,
};
