import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import { UserAvatar } from '~/components/UserAvatar';

import { SubHeader } from './SubHeader';
import { SummaryField } from './SummaryField';

import type { IReviewSelfForm } from '../../../types';
import type { UseFormReturn } from 'react-hook-form';

interface ParticipantsSummaryProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewSelfForm>;
}

const Employee = styled.div`
  padding-top: 4px;
`;

const ParticipantsSummary = ({ formMethods, sectionState }: ParticipantsSummaryProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;

  const employees = watch('employees');

  const navigateToSection = () => sectionState.setCurrentSection(1);

  return (
    <>
      <SubHeader title={i18n._(t`Participants`)} onEdit={navigateToSection} />
      <SummaryField
        value={
          <Employee>
            <UserAvatar user={employees[0]} />
          </Employee>
        }
        label={i18n._(t`Employees`)}
        marginBottom="22px"
      />
    </>
  );
};

export { ParticipantsSummary };
