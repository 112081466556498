import { useEffect } from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';

import type { IReviewSelfForm } from '../types';
import type { UseFormReturn } from 'react-hook-form';

interface UseReviewTasksProps {
  formMethods: UseFormReturn<IReviewSelfForm>;
}

const useReviewTasks = ({ formMethods }: UseReviewTasksProps) => {
  const { watch, setValue, trigger } = formMethods;
  const { i18n } = useLingui();

  const cycleStartDate = watch('settings.startDate');
  const isAutoGenerate = watch('tasks.isAutoGenerate');
  const previouslyEnabled = watch('tasks.isPreviouslyAutoGenerateEnabled');

  const addDaysToDate = (daysToAdd: number) => {
    return moment(cycleStartDate).add(daysToAdd, 'days').toDate();
  };

  const autoGenerateDates = () => {
    if (cycleStartDate) {
      setValue('settings.endDate', addDaysToDate(42));

      setValue('tasks.reviewSelfEvaluate.startDate', cycleStartDate);
      setValue('tasks.reviewPeerEvaluate.startDate', cycleStartDate);
      setValue('tasks.reviewCoachEvaluate.startDate', cycleStartDate);
      setValue('tasks.reviewPeerNominate.startDate', cycleStartDate);

      setValue('tasks.reviewSelfEvaluate.endDate', addDaysToDate(7));
      setValue('tasks.reviewPeerEvaluate.endDate', addDaysToDate(14));
      setValue('tasks.reviewCoachEvaluate.endDate', addDaysToDate(21));
      setValue('tasks.reviewPeerNominate.endDate', addDaysToDate(7));

      trigger('tasks');
      trigger('settings.endDate');
    }
  };

  const resetGeneratedDates = () => {
    if (cycleStartDate) {
      setValue('settings.endDate', null);

      setValue('tasks.reviewSelfEvaluate.startDate', null);
      setValue('tasks.reviewPeerEvaluate.startDate', null);
      setValue('tasks.reviewCoachEvaluate.startDate', null);
      setValue('tasks.reviewPeerNominate.startDate', null);

      setValue('tasks.reviewSelfEvaluate.endDate', null);
      setValue('tasks.reviewPeerEvaluate.endDate', null);
      setValue('tasks.reviewCoachEvaluate.endDate', null);
      setValue('tasks.reviewPeerNominate.endDate', null);

      setValue('tasks.isPreviouslyAutoGenerateEnabled', true);
    }
  };

  const handleReEnableAutoGenerate = async () => {
    const isConfirm = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Change timeline?`),
      description: i18n._(
        t`Are you sure you want to enable the recommended timeline? Any manual changes in the timeline will be overwritten.`,
      ),
    });
    if (isConfirm) {
      autoGenerateDates();
      setValue('tasks.isPreviouslyAutoGenerateEnabled', false);
    } else {
      setValue('tasks.isAutoGenerate', false);
    }
  };

  useEffect(() => {
    if (isAutoGenerate && previouslyEnabled) {
      handleReEnableAutoGenerate();
    }

    if (isAutoGenerate) {
      autoGenerateDates();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoGenerate, cycleStartDate]);

  return {
    autoGenerateDates,
    resetGeneratedDates,
  };
};

export { useReviewTasks };
