import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Modal from '~/components/Modal';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';
import Tabs from '~/components/Tabs';
import { SearchField } from '~/components/Text';

import RolesIntegrationTab from './rolesIntegrationTab';

import usersIcon from '~/assets/a-users-icn.svg';

import useBoolState from '~/hooks/useBoolState';
import { getCompanyIntegrationSettings } from '~/services/integrationSettings';
import { COLOR_PALETTE } from '~/styles';
import getIntegrationName from '~/utils/getIntegrationName';

const TabsWrapper = styled.div`
  width: 100%;
`;

const UsersWrapper = styled.div`
  padding: 0 25px 0 24px;
`;

const SearchFieldWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: 24px;
`;

const inviteModalHeaderStyles = {
  padding: '0 24px',
};

const inviteModalContentStyles = {
  padding: '0',
};

function ImportRolesModal({ i18n, onClose }) {
  const [integrations, setIntegrations] = useState([]);
  const $loading = useBoolState(true);
  const [activeTab, setActiveTab] = useState(null);
  const [tabItems, setTabItems] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const retrieveData = async () => {
      const [integrationsSettings] = await Promise.all([getCompanyIntegrationSettings()]);

      setIntegrations(Object.values(integrationsSettings));

      const enabledIntegrations = Object.values(integrationsSettings || {}).filter(
        (integration) => integration.integrationModules.importRoles.enabled,
      );

      let tabItems = [];
      if (!isEmpty(enabledIntegrations)) {
        // Sort integrations tabs by external software name
        enabledIntegrations.sort((a, b) => (a.externalSoftware > b.externalSoftware ? 1 : -1));

        tabItems = enabledIntegrations.map((integration) => ({
          key: integration.id,
          label: (i18n) => `${integration.name} - ${getIntegrationName(integration, i18n)}`,
        }));

        setTabItems(tabItems);
        setActiveTab(tabItems[0].key);
      }

      $loading.off();
    };

    retrieveData();

    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      title={i18n._(t`Import roles`)}
      onClose={onClose}
      minWidth={1100}
      width={1100}
      hideFooter={true}
      headerStyles={inviteModalHeaderStyles}
      contentStyles={inviteModalContentStyles}
    >
      <ShowSpinnerIfLoading loading={$loading.value}>
        {isEmpty(tabItems) ? (
          <Placeholder
            Icon={() => (
              <SvgIcon
                url={usersIcon}
                width={'50px'}
                height={'50px'}
                isDefaultColor
                defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
              />
            )}
            title={i18n._(t`No API available`)}
            subTitle={i18n._(
              t`Integrate Learned with your HR systems to easily import your roles. Contact your customer success manager.`,
            )}
          />
        ) : (
          <>
            <TabsWrapper>
              <Tabs
                currentItem={activeTab}
                items={tabItems}
                handleChangeTab={setActiveTab}
                isSmall
                actions={
                  <SearchFieldWrapper>
                    <SearchField
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder={i18n._(t`Search`)}
                      style={{ borderRadius: '6px', maxHeight: '32px', fontSize: '14px' }}
                    />
                  </SearchFieldWrapper>
                }
              />
            </TabsWrapper>
            <UsersWrapper>
              <RolesIntegrationTab
                onModalClose={onClose}
                search={search}
                integration={integrations.find((integration) => integration.id === activeTab)}
              />
            </UsersWrapper>
          </>
        )}
      </ShowSpinnerIfLoading>
    </Modal>
  );
}

ImportRolesModal.propTypes = {
  onClose: PropTypes.func,
};

export default withI18n()(ImportRolesModal);
