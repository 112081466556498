import { ROLES, REPORT_CHART_TYPES } from '@learned/constants';
import { I18n } from '@lingui/core';

import type { ITabConfigurationResponse } from '~/services/reports';

export type TViewAs = ROLES.ADMIN | ROLES.COACH | ROLES.USER;

export type TPagination = {
  skip: number;
  limit: number;
  index: number;
};

export type TForm = {
  newName: string;
};

export type TTimeFrame = {
  start: string;
  end: string;
};

export type TTabs = {
  label: (i18n: I18n) => string;
  key: REPORT_CHART_TYPES;
}[];

export type TOptions = {
  key: string;
  title: (trans: I18n) => string;
  description?: (trans: I18n) => string | undefined;
};

export enum EAction {
  getData = 'getData',
  exportCsv = 'exportCsv',
  exportPdf = 'exportPdf',
}

export type TDataStruct = {
  primary: TOptions | null;
  secondary: TOptions | null;
  measure: TOptions | null;
  isHeatmapSelected: boolean;
  themesOptionSelected: TOptions[];
  teamsOptionSelected: TOptions[];
  surveysOptionSelected: TOptions[];
  jobsSelected: TOptions[];
  jobsGroupsSelected: TOptions[];
  gendersSelected: TOptions[];
  ageGroupSelected: TOptions[];
  educationLevelsSelected: TOptions[];
  monthSelected: string;
  includeBenchmark?: boolean;
  includeCompanyAverage?: boolean;
  includeTeamAverage?: boolean;
  totalSortedBy?: string;
  customSortedBy?: string;
  customSearch?: string;
  totalSearch?: string;
  limit?: number;
  skip?: number;
};

export type TRenderedTableItem = {
  title: (item: I18n) => string;
};

export type TTeam = {
  id: string;
  name: string;
};

export type TColumns = { id: string | number; name: string | number };

export type TEngagementData = {
  id: string;
  name?: string;
  secondaryName?: string;
  showPrimary?: boolean;
  cells: {
    measure: string; // columnId
    value: number;
  }[];
};

export type TData = {
  query: string;
  columns: TColumns[];
  rows: TEngagementData[];
  total: number;
};

export enum EOptions {
  primaryDimension = 'primaryDimension',
  secondaryDimension = 'secondaryDimension',
  measure = 'measure',
  // Options
  includeBenchmark = 'includeBenchmark',
  includeCompanyAverage = 'includeCompanyAverage',
  isHeatmapColored = 'isHeatmapColored',
  exportOptions = 'exportOptions',
  includeTeamAverage = 'includeTeamAverage',
  // filters
  timeFrame = 'timeFrame',
  themes = 'themes',
  teams = 'teams',
  surveys = 'surveys',
  reset = 'reset',
  jobs = 'jobs',
  jobGroups = 'jobGroups',
  genders = 'genders',
  ageGroups = 'ageGroups',
  educationLevels = 'educationLevels',
  currentTab = 'currentTab',
  customSortedBy = 'customSortedBy',
  totalSortedBy = 'totalSortedBy',
  totalSearch = 'totalSearch',
  customSearch = 'customSearch',
  limit = 'limit',
  skip = 'skip',
  fetchDataAgain = 'fetchDataAgain',
}

export enum EMenuTypes {
  filter = 'filter',
  rows = 'rows',
  none = 'none',
}

export enum EExportOptions {
  'csv' = 'csv',
  'pdf' = 'pdf',
}

export type TDimensions = {
  primary: TOptions | null;
  secondary: TOptions | null;
  measure: TOptions | null;
};

export type TDropDownValues = string | TOptions[] | null | TOptions;

export type TUpdateFnProps = {
  [key: string]: string | TOptions[] | boolean | TOptions | null | number;
};

export type TEngagementTabs = {
  showMenu: boolean;
  companyAverageSelected: boolean;
  teamAverageSelected: boolean;
  isLoading: boolean;
  industryBenchMarkSelected: boolean;
  isSecondaryColumn: boolean;
  noRetry: boolean;
  showExportMenu: boolean;
  totalCount: number;
  customSortedBy: string;
  customSearch: string;
  totalGraphItems: number;
  totalSearch: string;
  totalSortedBy: string;
  noDataText: string;
  currentTab: REPORT_CHART_TYPES | null;
};

export type TInitDtObj = {
  teamsOptions: TOptions[];
  themesOptions: TOptions[];
  surveysOptions: TOptions[];
  primaryOptions: TOptions[];
  tabConfiguration: ITabConfigurationResponse | null;
  currentTab: REPORT_CHART_TYPES | null;
} | null;
