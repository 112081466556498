import React from 'react';

import { Trans } from '@lingui/macro';

import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { GeneralSettings } from './components/GeneralSettings';
import { NotificationSettings } from './components/NotificationSettings';
import { PrivacySettings } from './components/PrivacySettings';
import { TimelineSection } from './components/TimelineSection';
import { Form, StepTimelineContainer, Title } from './design';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';

import type { IReviewSelfForm } from './types';
import type { UseFormReturn } from 'react-hook-form';

interface StepSettingsProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewSelfForm>;
  languageState: ILanguageStateReturn;
  onPublish: () => void;
  resetGeneratedDates: () => void;
}

const StepSettings = ({
  formMethods,
  sectionState,
  languageState,
  onPublish,
  resetGeneratedDates,
}: StepSettingsProps) => {
  return (
    <StepTimelineContainer>
      <Form>
        <Title>
          <Trans>Settings</Trans>
        </Title>
        <GeneralSettings formMethods={formMethods} />
        <NotificationSettings formMethods={formMethods} />
        <PrivacySettings formMethods={formMethods} />
      </Form>
      <TimelineSection
        formMethods={formMethods}
        sectionState={sectionState}
        languageState={languageState}
        onPublish={onPublish}
        resetGeneratedDates={resetGeneratedDates}
      />
    </StepTimelineContainer>
  );
};

export { StepSettings };
