import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import CheckBox from '~/components/CheckBox';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import Divider from '~/components/UI/Divider';

import ExternalTeam from './externalTeam';

import TeamsIcon from '~/assets/mdi-account-group.svg';

import { INTEGRATIONS_CONN_ERROR_MSG } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { getTeamsFromIntegration } from '~/services/integrations';
import { createTeams } from '~/store/teams/actions';
import { COLOR_PALETTE, COLORS } from '~/styles';

import ShowSpinnerIfLoading from '../ShowSpinnerIfLoading';

const PlaceholderWrap = styled.div`
  margin: 25px auto 100px auto;
`;

const TeamsListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectAllWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SelectedAllCheckMark = styled(CheckBox)`
  align-self: center;
  padding: 8px;
`;

const SelectAllLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: ${COLOR_PALETTE.DARK_GRAY};
  text-transform: uppercase;
`;

const TeamsList = styled.div`
  height: 350px;
  overflow: auto;
`;

const ButtonSection = styled.div`
  display: flex;
  padding: 8px 0px 16px 18px;
  justify-content: flex-end;
`;

const messages = {
  teamsImported: {
    title: t`Success`,
    content: t`The selected teams have been imported`,
  },
};

function TeamsIntegrationTab({ i18n, onModalClose, search, integration, teams }) {
  const $loading = useBoolState(true);
  const $isSelectAll = useBoolState(false);
  const [teamsExternal, setTeamsExternal] = useState([]);
  const [selectedExternalTeams, setSelectedExternalTeams] = useState([]);
  const [isIntegrationError, setIsIntegrationError] = useState([]);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    if (!$loading.value) {
      $loading.on();
    }

    const getData = async () => {
      let teamsExternal;
      try {
        teamsExternal = await getTeamsFromIntegration(integration.id, true);
      } catch (e) {
        // Do nothing
      }

      // If it is undefined then the fetching of integration data failed
      if (!teamsExternal) {
        setIsIntegrationError(true);
        setTeamsExternal([]);
        $loading.off();
        return;
      }

      // Sort teams by name, ascending
      teamsExternal.sort((a, b) => (b.name > a.name ? -1 : 1));

      // Create field in each team to know if its name already exists in local teams
      const teamIds = teams
        .filter((team) => team.externalId)
        .map((team) => `${team.name}_${team.externalId}`);
      teamsExternal.forEach(
        (teamExt) =>
          (teamExt.duplicatedName = teamIds.includes(teamExt.name + '_' + teamExt.externalId)),
      );

      setTeamsExternal(teamsExternal);
      $loading.off();
    };
    getData();

    // eslint-disable-next-line
  }, [integration]);

  const toggleSelectAll = (isChecked) => {
    if (isChecked) {
      // select all
      $isSelectAll.on();

      setSelectedExternalTeams([...teamsExternal.filter((team) => !team.duplicatedName)]);
    } else {
      // deselect all
      $isSelectAll.off();
      setSelectedExternalTeams([]);
    }
  };

  const toggleExternalTeam = (externalTeam) => {
    const selectedId = externalTeam.id;
    let newSelectedList = [...selectedExternalTeams];
    if (selectedExternalTeams.includes(externalTeam)) {
      // remove from selected list
      newSelectedList = selectedExternalTeams.filter((t) => t.id !== selectedId);
    } else {
      // add to selected list
      newSelectedList.push(externalTeam);
    }

    // affect on isSelectAll checkbox
    const teamsExternalLength = teamsExternal.filter((team) => !team.duplicatedName).length;
    if (isEmpty(newSelectedList) || newSelectedList.length !== teamsExternalLength) {
      // deselect all
      $isSelectAll.off();
    } else if (newSelectedList.length === teamsExternalLength) {
      // select all
      $isSelectAll.on();
    }
    setSelectedExternalTeams(newSelectedList);
  };

  const createTeamsFromExternal = async () => {
    if (!isEmpty(selectedExternalTeams)) {
      $loading.on();
      const newTeams = selectedExternalTeams.map((externalTeam) => ({
        name: externalTeam.name,
        externalId: String(externalTeam.id),
        externalSource: integration.id,
      }));
      const createdTeams = await dispatch(createTeams(newTeams));
      onModalClose(createdTeams);
      $loading.off();
      showToastMessage(messages.teamsImported, TOAST_TYPES.INFO);
    }
  };

  // search logic
  let teamsExternalFiltered = [...teamsExternal];
  if (search) {
    teamsExternalFiltered = teamsExternal.filter((item) =>
      (item.name || '').toLowerCase().includes(search.toLowerCase()),
    );
  }

  const showToastMessage = (msg, type) => {
    addToast({
      title: i18n._(msg.title),
      subtitle: i18n._(msg.content),
      type,
    });
  };

  return (
    <ShowSpinnerIfLoading loading={$loading.value}>
      {isEmpty(teamsExternalFiltered) ? (
        <PlaceholderWrap>
          <Placeholder
            title={i18n._(t`No teams available`)}
            subTitle={i18n._(
              isIntegrationError
                ? INTEGRATIONS_CONN_ERROR_MSG
                : t`It seems that all teams in your HR system have been added to Learned.`,
            )}
            subTitleStyles={{ ...(isIntegrationError && { color: COLORS.ACCENT_ERROR }) }}
            Icon={() => (
              <SvgIcon
                url={TeamsIcon}
                width="50px"
                height="50px"
                isDefaultColor
                defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
              />
            )}
          />
        </PlaceholderWrap>
      ) : (
        <>
          <TeamsListHeader>
            <SelectAllWrapper onClick={() => toggleSelectAll(!$isSelectAll.value)}>
              <SelectedAllCheckMark checked={$isSelectAll.value} size={24} />
              <SelectAllLabel>
                <Trans>select all</Trans>
              </SelectAllLabel>
            </SelectAllWrapper>
          </TeamsListHeader>
          <Divider depth={1} />
          <TeamsList>
            {teamsExternalFiltered.map((externalTeam) => (
              <ExternalTeam
                disabled={externalTeam.duplicatedName}
                key={externalTeam.id}
                externalTeam={externalTeam}
                isSelected={selectedExternalTeams.includes(externalTeam)}
                onToggle={() => toggleExternalTeam(externalTeam)}
              />
            ))}
          </TeamsList>
        </>
      )}
      <ButtonSection>
        <Button
          label={
            selectedExternalTeams.length > 1
              ? i18n._(t`Import ${selectedExternalTeams.length} teams`)
              : i18n._(t`Import`)
          }
          disabled={isEmpty(selectedExternalTeams)}
          onClick={createTeamsFromExternal}
          loading={$loading.value}
          type="primary"
        />
      </ButtonSection>
    </ShowSpinnerIfLoading>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.locale.lang,
    users: state.users.data,
    companies: state.companies.data,
    user: state.auth.user,
  };
};

export default withI18n()(connect(mapStateToProps)(TeamsIntegrationTab));
