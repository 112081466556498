import React, { useState, KeyboardEvent, useEffect } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import { useMultiSelectState } from '~/components/Dropdown/hooks';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { Input } from '~/components/Input';
import { NoOptions, OptionsContainer } from '~/components/SearchSelect/design';
import { UserAvatar } from '~/components/UserAvatar';

import { Label, Container, Labels, Options, Option } from './design';

import { useOutsideClick } from '~/hooks/useOutsideClick';

import type { MultiSearchSelectExpandableProps } from '../types';
import type { IUser, WithRequired } from '@learned/types';

function MultiSelectSearchExpandable<T>({
  items,
  onChange,
  stringifyItem,
  selectedItems,
  hashItem,
  showAvatar,
  onSearchChange,
  placeholder,
  disabled,
  minCharsToShowDropdown = 0,
}: WithRequired<MultiSearchSelectExpandableProps<T>, 'stringifyItem'>) {
  const { isItemSelected, handleNewItemSelected } = useMultiSelectState({
    selectedItems,
    onChange,
    hashItem,
  });
  const { i18n } = useLingui();
  const [search, setSearch] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const baseRef = useOutsideClick<HTMLDivElement>(() => {
    setShowOptions(false);
  });

  const filteredItems = items.filter((item) => !isItemSelected(item));

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    // remove the last item from input
    if (e.key === 'Backspace' && isSearchEmpty && !isEmpty(selectedItems)) {
      // @ts-ignore
      handleNewItemSelected(selectedItems.at(-1));
    }

    if (!search) {
      setIsSearchEmpty(true);
    } else {
      setIsSearchEmpty(false);
    }

    if (size(search) >= minCharsToShowDropdown && !showOptions) {
      setShowOptions(true);
    }

    if (size(search) < minCharsToShowDropdown && showOptions) {
      setShowOptions(false);
    }

    // add the first item to the input
    if (e.key === 'Enter' && !isEmpty(filteredItems)) {
      handleNewItemSelected(filteredItems[0]);
      setSearch('');
      setIsSearchEmpty(true);
    }
  };

  useEffect(() => {
    onSearchChange?.(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Container ref={baseRef}>
      <div className="searchIcon">
        <Icon icon={ICONS.SEARCH} />
      </div>
      <Labels>
        {selectedItems.map((item) => (
          <Label key={stringifyItem(item)}>
            <div className="content">
              {showAvatar && <UserAvatar user={item as IUser} options={{ customName: '' }} />}
              <span>{stringifyItem(item)}</span>
            </div>
            {!disabled && (
              <Icon
                icon={ICONS.CLOSE_2}
                size={ICON_SIZES.SMALL}
                onClick={() => handleNewItemSelected(item)}
              />
            )}
          </Label>
        ))}
        <Input
          height="28px"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyUp={onKeyUp}
          placeholder={disabled ? '' : placeholder || i18n._(t`Search`)}
          onFocus={() => {
            if (size(search) >= minCharsToShowDropdown) {
              setShowOptions(true);
            }
          }}
          onAbort={() => setShowOptions(false)}
          className="inputWrapper"
          disabled={disabled}
        />
      </Labels>
      {showOptions && (
        <OptionsContainer>
          <Options>
            {filteredItems.map((item, i) => {
              return (
                <Option
                  onClick={() => {
                    handleNewItemSelected(item);
                    setSearch('');
                  }}
                  key={i}
                >
                  <div className="content">
                    {showAvatar && <UserAvatar user={item as IUser} options={{ customName: '' }} />}
                    <span>{stringifyItem(item)}</span>
                  </div>
                  <Icon icon={ICONS.ADD_PLUS} size={ICON_SIZES.SMALL} />
                </Option>
              );
            })}
            {filteredItems.length === 0 && <NoOptions>No results found</NoOptions>}
          </Options>
        </OptionsContainer>
      )}
    </Container>
  );
}

export { MultiSelectSearchExpandable };
