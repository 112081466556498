export enum REVIEW_TYPES {
  SELF = 'self',
  INDIVIDUAL = 'individual',
  REVIEW_CYCLE = 'review-cycle',
}

export enum SIGNATURE_STATUSES {
  WAITING = 'waiting',
  ACTIVE = 'active',
  SIGNED = 'signed',
}

export enum SIGNATURE_VIEWS {
  USER = 'user',
  COACH = 'coach',
}

export enum REVIEW_SECTION_THEMES {
  DEFAULT = 'default',
  BUSINESS_GOALS = 'businessGoals',
  LEARNING_GOALS = 'learningGoals',
  SKILLS = 'skills',
  CAREER = 'career',
  WELL_BEING = 'wellBeing',
  AMBITIONS = 'ambitions',
}

export enum REVIEW_SECTIONS {
  NONE = 'none',
  PERFORMANCE = 'performance',
  POTENTIAL = 'potential',
  SKILLS = 'skills',
}

export enum REVIEW_STATUS {
  DRAFT = 'draft', // not published
  PUBLISHED = 'published', // as upcoming - start date in future
  ACTIVE = 'active', // start date today or before today
  COMPLETED = 'completed', // All reviews in this cycle are completed
  ARCHIVED = 'archived',
}

export enum REVIEW_LAST_STATUS_METHOD {
  MANUAL = 'manual',
  AUTO = 'auto',
}

export enum REVIEW_CREATED_BY {
  ADMIN = 'admin',
  COACH = 'coach',
  EMPLOYEE = 'employee',
}

export enum REVIEW_SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  START_DATE_NEW_OLD = 'START_DATE_NEW_OLD',
  START_DATE_OLD_NEW = 'START_DATE_OLD_NEW',
  EMPLOYEE_A_Z = 'EMPLOYEE_A_Z',
  EMPLOYEE_Z_A = 'EMPLOYEE_Z_A',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
}

export enum REVIEW_PARTICIPATION_COLUMNS {
  SELF_REVIEW = 'self-review',
  PEER_REVIEW = 'peer-review',
  COACH_REVIEW = 'coach-review',
  NOMINATED_PEERS = 'nominated-peers',
  CONVERSATION_DATE = 'conversation-date',
  CALIBRATION = 'calibration',
  COMPLETED_REVIEW = 'completed-review',
  PEER_REVIEW_PROVIDED = 'peer-review-provided',
  COACH_REVIEW_PROVIDED = 'coach-review-provided',
}

export enum REVIEW_PARTICIPATION_ROW_TYPE {
  TEAM = 'team',
  EMPLOYEE = 'employee',
}

// if no digital sign -> create complete task only for reviews created after 23.08.2022
export const COMPLETE_REVIEW_FEATURE_START = '2022-08-23';
