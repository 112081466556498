import React, { useEffect, useRef, useState } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  ENGAGEMENT_REPORT_CHART_DIMENSIONS,
  REPORT_CHART_TYPES,
  REPORT_TYPES,
  ROLES,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

import AutocompleteFilter from '~/components/AutocompleteFilter';
import { Loader } from '~/components/Buttons/components/Loader';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import SelectDropDown from '~/components/SelectDropDown';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import {
  AllTeams,
  CardWrapper,
  DropDownContainer,
  EmptyMessageHeader,
  EmptyMessageHolder,
  EmptyMessageSubTitle,
  EmptyTeamHolder,
  FilterContainer,
  Header,
  LoaderContainer,
  Separator,
  TileLoader,
  Wrapper,
  Navigator,
  TeamHeader,
} from './components/Dashboard.design';
import { ReportCard } from './components/ReportCard';
import { PRIMARY_OPTIONS_KEYS, LOADER_STATE, PAGE_TYPE } from './types';

import emptyState from '~/assets/empty-state.png';

import routes from '~/constants/routes';
import { getSelectedRole, getTeams } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import {
  deleteFromDashboard,
  getEngagementCharts,
  getTabDetails,
  getDashboard,
  ITabConfigurationResponse,
  TEngagementReportResponse,
  updateOrderOfDashboardReports,
  getEngagementDetails,
  HeatmapResponse,
} from '~/services/reports';
import { PRIMARY_COLOR } from '~/styles';
import { getLaminatedColor } from '~/utils/colorManipulator';
import { getDateForTimeFrame, sanitizeDimensions } from '~/utils/reports';

import { AreaChartContainer } from '../Charts/AreaChartContainer';
import { HorizontalChartContainer } from '../Charts/HorizontalChartContainer';
import { MiniHeatmap } from '../Charts/MiniHeatmap';
import { Team } from '../PassportPage/types';

import type { DashboardItemType, OptionTypes, SavedDataType } from './types';
import type { I18n } from '@lingui/core';

const MAX_ITEM_COUNT = 5;

const PRIMARY_OPTIONS = [
  {
    key: PRIMARY_OPTIONS_KEYS.ORGANIZATION,
    title: (i18n: I18n) => i18n._(t`Organization dashboard`),
  },
  { key: PRIMARY_OPTIONS_KEYS.TEAM, title: (i18n: I18n) => i18n._(t`Team dashboard`) },
];

const CommonReportsDashboard = ({ type, currentTeam }: { type: PAGE_TYPE; currentTeam?: Team }) => {
  const { i18n } = useLingui();
  const [primaryOption, setPrimaryOption] = useState<OptionTypes>(PRIMARY_OPTIONS[0]);

  const [isDashboardLoading, setIsDashboardLoading] = useState<LOADER_STATE>(
    LOADER_STATE.DASHBOARD_LOADING,
  );
  const [tileViews, setTileViews] = useState<(JSX.Element | null)[]>([]);
  const { addToast } = useToasts();
  const currentRole = useSelector(getSelectedRole);
  const teams = useSelector(getTeams);
  const teamsArray: Team[] = Object.values(teams);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [tabDetails, setTabDetails] = useState<ITabConfigurationResponse[]>([]);
  const [savedTabData, setSavedTabData] = useState<(SavedDataType | null)[]>([]);
  const currentCompany = useSelector(getCurrentCompany);
  const companyColor = currentCompany.color || PRIMARY_COLOR;
  const transparentCompanyColor = getLaminatedColor(companyColor);
  const headerGradient = `linear-gradient(86deg, ${companyColor} 48%, ${transparentCompanyColor} 99%)`;
  const primaryOptionRef = useRef({ key: '', teams: null as Team | null });
  primaryOptionRef.current.key = primaryOption.key;
  primaryOptionRef.current.teams = selectedTeam;
  const isDragDisabled = type === PAGE_TYPE.COACH;
  const isAdmin = currentRole === ROLES.ADMIN && type === PAGE_TYPE.ADMIN;
  const VIEW_ROLE = selectedTeam ? ROLES.COACH : (currentRole as ROLES);

  const onPrimaryChange = (value: string) => {
    const selectedOption = PRIMARY_OPTIONS.find((option) => option.key === value);
    if (selectedOption) {
      setPrimaryOption(selectedOption);
      const teamArray: Team[] = Object.values(teams || {});
      if (selectedOption.key === PRIMARY_OPTIONS_KEYS.TEAM) {
        setSelectedTeam(teamArray[0]);
        fetchData(ROLES.COACH, teamArray.length > 0 ? teamArray[0] : null);
      } else {
        setSelectedTeam(null);
        fetchData(ROLES.ADMIN, null);
      }
    }
  };

  const onGoToReportClick = (dashboardItem: DashboardItemType) => {
    routes.REPORTS_ADMIN_CUSTOM_REPORT.go(
      // @ts-ignore
      { role: ROLES.ADMIN },
      {
        reportType: dashboardItem.reportType,
        reportId: dashboardItem.reportId,
        query: { name: 'test' },
      },
    );
  };

  const getStatus = (value: string) => {
    return teamsArray
      .filter((team) => (value.trim().length > 0 ? team.name === value : true))
      .map((team) => ({
        id: team.id,
        key: team.id,
        name: team.name,
        translated: (i18n: I18n) => i18n._(t`Published`),
      }));
  };

  const updateTileViews = (
    cardData: (SavedDataType | null)[],
    updatedConfiguration: ITabConfigurationResponse[],
  ) => {
    const tileViewDetails = cardData.map((card, index) => {
      if (!card) {
        return null;
      }
      if (card.viewType === REPORT_CHART_TYPES.BY_MONTH) {
        const series =
          (card?.chartData as TEngagementReportResponse)?.averageEngagement?.series || [];
        return (
          <ReportCard
            key={`by-month-report-card-${index + 1}`}
            title={`${card.reportType}-${updatedConfiguration[index].name}`}
            onGoToReportClick={() => {
              onGoToReportClick({
                reportId: card.reportId,
                reportType: card.reportType,
              });
            }}
            reportId={card.reportId}
            reportType={card.reportType}
            isAdmin={isAdmin}
            reference={card.id}
            deleteItem={deleteItem}
            isDragDisabled={isDragDisabled}
          >
            <AreaChartContainer
              chartData={series}
              viewAs={VIEW_ROLE}
              includeCompanyAverage={
                !!updatedConfiguration[index].options?.includeCompanyAverage?.value
              }
              includeBenchmark={!!updatedConfiguration[index].options?.includeBenchmark?.value}
              includeTeamAverage={!!updatedConfiguration[index].options?.includeTeamAverage?.value}
            />
          </ReportCard>
        );
      } else if (card.viewType === REPORT_CHART_TYPES.BY_ONE_DIMENSION) {
        const fullData = (card?.chartData as TEngagementReportResponse)?.dimensionAverage || [];
        const limitedData = fullData?.slice(0, MAX_ITEM_COUNT);
        const hiddenItemCount =
          fullData?.length > MAX_ITEM_COUNT ? fullData?.length - MAX_ITEM_COUNT : 0;
        return (
          <ReportCard
            key={`one-dimension-report-card-${index + 1}`}
            title={`${card.reportType}-${updatedConfiguration[index].name}`}
            hiddenItemCount={hiddenItemCount}
            hasExtraSpace={hiddenItemCount === 0}
            onGoToReportClick={() => {
              onGoToReportClick({
                reportId: card.reportId,
                reportType: card.reportType,
              });
            }}
            reportId={card.reportId}
            reportType={card.reportType}
            isAdmin={isAdmin}
            reference={card.id}
            deleteItem={deleteItem}
            isDragDisabled={isDragDisabled}
          >
            <HorizontalChartContainer chartData={limitedData || []} />
          </ReportCard>
        );
      } else if (card.viewType === REPORT_CHART_TYPES.BY_TWO_DIMENSION) {
        const rows = (card.chartData as HeatmapResponse)?.rows || [];
        return (
          <ReportCard
            key={`heatmap-${index + 1}`}
            title={`${card.reportType}-${updatedConfiguration[index].name}`}
            onGoToReportClick={() => {
              onGoToReportClick({
                reportId: card.reportId,
                reportType: card.reportType,
              });
            }}
            reportId={card.reportId}
            reportType={card.reportType}
            reference={card.id}
            isAdmin={isAdmin}
            hiddenItemCount={card.total ? card.total - rows.length : 0}
            deleteItem={deleteItem}
            isDragDisabled={isDragDisabled}
          >
            <MiniHeatmap rows={rows} isCompanyColor={false} />
          </ReportCard>
        );
      } else {
        return null;
      }
    });
    setTileViews(tileViewDetails);
  };

  const deleteItem = async (reportId: string | null, reportType: string | null) => {
    if (reportId === null || reportType === null) {
      return;
    }
    try {
      const viewRole =
        primaryOptionRef.current.key === PRIMARY_OPTIONS_KEYS.TEAM ? ROLES.COACH : ROLES.ADMIN;
      if (
        await confirm({
          type: CONFIRMATION_MODAL_TYPE.DELETE,
          title: i18n._(t`Delete?`),
          description: i18n._(t`Are you sure? This action cannot be undone`),
        })
      ) {
        await deleteFromDashboard({
          reportId,
          reportType,
          role: viewRole,
        });
        addToast({
          title: i18n._(t`Successfully deleted`),
          type: TOAST_TYPES.SUCCESS,
        });
        fetchData(viewRole, (primaryOptionRef.current.teams as Team) || null);
      }
    } catch {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while deleting. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
    }
  };

  const fetchData = async (viewRole: ROLES, selectedTeamItem: Team | null) => {
    try {
      let tabConfigurations: ITabConfigurationResponse[] = [];
      let individualDashboardItems: DashboardItemType[] = [];
      setIsDashboardLoading(LOADER_STATE.DASHBOARD_LOADING);
      const response = await getDashboard(viewRole);
      individualDashboardItems = [...response.data.items];
      const results = await Promise.allSettled(
        individualDashboardItems.map((item) =>
          getTabDetails(item.reportId, item.reportType, viewRole),
        ),
      );

      const configurationArray: ITabConfigurationResponse[] = results.map((result) => {
        if (result.status === 'fulfilled') {
          return result?.value?.data || {};
        } else {
          return {} as ITabConfigurationResponse;
        }
      });
      tabConfigurations = [...configurationArray];
      setTabDetails(configurationArray);
      setIsDashboardLoading(LOADER_STATE.TILE_LOADING);

      setTileViews(
        tabConfigurations.map((_, index) => {
          const individualDashboardItem = individualDashboardItems[index];
          return (
            <ReportCard
              key={`placeholders-${index}`}
              title={`${individualDashboardItem.reportType}-${tabConfigurations[index].name}`}
              onGoToReportClick={() => {
                onGoToReportClick(individualDashboardItem);
              }}
              reportId={individualDashboardItem.reportId}
              reportType={individualDashboardItem.reportType}
              reference={`placeholders-${index}`}
              deleteItem={deleteItem}
              isAdmin={isAdmin}
              isDragDisabled={isDragDisabled}
            >
              <TileLoader>
                <Loader />
              </TileLoader>
            </ReportCard>
          );
        }),
      );
      setIsDashboardLoading(LOADER_STATE.TILE_LOADED);
      const tabData = await Promise.allSettled(
        tabConfigurations.map((item, index) => {
          if (
            item.viewType === REPORT_CHART_TYPES.BY_MONTH ||
            item.viewType === REPORT_CHART_TYPES.BY_ONE_DIMENSION
          ) {
            return getEngagementCharts({
              viewAs: viewRole,
              reportType: individualDashboardItems[index].reportType as REPORT_TYPES,
              chartType: item.viewType,
              ...(item.viewType === REPORT_CHART_TYPES.BY_ONE_DIMENSION &&
                item.primaryDimension?.value && {
                  primaryDimension: sanitizeDimensions(
                    item.primaryDimension?.value,
                  ) as ENGAGEMENT_REPORT_CHART_DIMENSIONS,
                }),
              filters: {
                ageGroups: item.filters.ageGroups?.value || [],
                educationLevels: item.filters.educationLevels?.value || [],
                genders: item.filters.genders?.value || [],
                jobGroups: item.filters.jobGroups?.value || [],
                jobs: item.filters.jobs?.value || [],
                surveys: item.filters.surveys?.value || [],
                teams: selectedTeamItem ? [selectedTeamItem.id] : item.filters.teams?.value,
                themes: item.filters.themes?.value || [],
              },
              options: {
                includeBenchmark: !!item.options?.includeBenchmark?.value,
                includeCompanyAverage: !!item.options?.includeCompanyAverage?.value,
                includeTeamAverage: !!item.options?.includeTeamAverage?.value,
              },
              dateRange: getDateForTimeFrame(item.filters.timeFrame?.value || '12'),
            });
          } else if (item.viewType === REPORT_CHART_TYPES.BY_TWO_DIMENSION) {
            const teamIds = item.filters.teams?.value || [];
            return getEngagementDetails({
              viewAs: viewRole,
              reportType: individualDashboardItems[index].reportType as REPORT_TYPES,
              primaryDimension: sanitizeDimensions(
                item.primaryDimension?.value || '',
              ) as ENGAGEMENT_REPORT_CHART_DIMENSIONS,
              secondaryDimension: sanitizeDimensions(
                item.secondaryDimension?.value || '',
              ) as ENGAGEMENT_REPORT_CHART_DIMENSIONS,
              measure: sanitizeDimensions(
                item.measure?.value || '',
              ) as ENGAGEMENT_REPORT_CHART_DIMENSIONS,
              filters: {
                ageGroups: item.filters.ageGroups?.value || [],
                educationLevels: item.filters.educationLevels?.value || [],
                genders: item.filters.genders?.value || [],
                jobGroups: item.filters.jobGroups?.value || [],
                jobs: item.filters.jobs?.value || [],
                surveys: item.filters.surveys?.value || [],
                teams: selectedTeamItem ? [selectedTeamItem.id] : teamIds,
                themes: item.filters.themes?.value || [],
              },
              options: {
                includeBenchmark: false,
                includeCompanyAverage: true,
                includeTeamAverage: false,
              },
              dateRange: getDateForTimeFrame(item.filters.timeFrame?.value || '12'),
              pagination: {
                limit: MAX_ITEM_COUNT,
                skip: 0,
              },
            });
          }
          return null;
        }),
      );

      const tabDataArray = tabData.map((status, index) => {
        if (status.status === 'rejected') {
          return null;
        }

        return {
          chartData: status.value?.data || null,
          viewType: tabConfigurations[index].viewType,
          reportType: individualDashboardItems[index].reportType,
          reportId: individualDashboardItems[index].reportId,
          id: `${individualDashboardItems[index].reportType}-${individualDashboardItems[index].reportId}`,
          total: status.value?.data?.total,
        };
      });

      setSavedTabData(tabDataArray);
      updateTileViews(tabDataArray, tabConfigurations);
    } catch {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while fetching the data. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
    } finally {
      setIsDashboardLoading(LOADER_STATE.DASHBOARD_LOADED);
    }
  };

  const onDragEndAction = async (result: {
    source: { droppableId: string; index: number };
    destination: { droppableId: string; index: number } | null;
    draggableId: string;
  }) => {
    const newTabData = [...savedTabData];
    const newTabDetails = [...tabDetails];
    const sourceId = result.source?.droppableId.split('droppable-')[1] || '';
    const destinationId = result.destination?.droppableId.split('droppable-')[1] || '';
    const sourceItem = newTabData.filter((card) => card?.id === sourceId);
    const destinationItem = newTabData.filter((card) => card?.id === destinationId);
    const sourceIndex = newTabData.findIndex((card) => card?.id === sourceId);
    const destinationIndex = newTabData.findIndex((card) => card?.id === destinationId);
    const sourceItemDetails = newTabDetails[sourceIndex];
    const destinationItemDetails = newTabDetails[destinationIndex];

    if (destinationItem.length === 0 || sourceItem.length === 0) {
      return;
    }
    newTabData[sourceIndex] = destinationItem[0];
    newTabData[destinationIndex] = sourceItem[0];
    newTabDetails[sourceIndex] = destinationItemDetails;
    newTabDetails[destinationIndex] = sourceItemDetails;
    setSavedTabData(newTabData);
    setTabDetails(newTabDetails);
    updateTileViews(newTabData, newTabDetails);
    try {
      await updateOrderOfDashboardReports(
        newTabData.map((item) => ({
          reportType: item?.reportType || '',
          reportId: item?.reportId || '',
          role: VIEW_ROLE,
        })),
      );
    } catch {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while updating the order. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
    } finally {
      addToast({
        title: i18n._(t`Successfully updated`),
        type: TOAST_TYPES.SUCCESS,
      });
    }
  };

  useEffect(() => {
    if (type === PAGE_TYPE.ADMIN) {
      fetchData(ROLES.ADMIN, null);
    } else {
      fetchData(ROLES.COACH, currentTeam || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEmptyState = () => {
    const hasNoData =
      (isDashboardLoading === LOADER_STATE.DASHBOARD_LOADED ||
        isDashboardLoading === LOADER_STATE.TILE_LOADED) &&
      tileViews.length === 0;

    if (!hasNoData) {
      return null;
    }
    if (primaryOption.key === PRIMARY_OPTIONS_KEYS.TEAM) {
      if (teamsArray.length === 0) {
        return (
          <EmptyMessageHolder>
            <img src={emptyState} alt={i18n._(t`No data found`)} width="216px" />
            {VIEW_ROLE === ROLES.ADMIN && (
              <>
                <EmptyMessageHeader>{i18n._(t`You have not created any teams`)}</EmptyMessageHeader>
                <EmptyMessageSubTitle>
                  {i18n._(t`Create your first team`)}
                  <Navigator onClick={() => routes.MEMBERS.go({ role: ROLES.ADMIN })}>
                    {i18n._(t`here`)}
                  </Navigator>
                </EmptyMessageSubTitle>
              </>
            )}
          </EmptyMessageHolder>
        );
      }
    }
    return (
      <EmptyMessageHolder>
        <img src={emptyState} alt={i18n._(t`No data found`)} width="216px" />
        {VIEW_ROLE === ROLES.ADMIN ? (
          <>
            <EmptyMessageHeader>
              {i18n._(t`No reports have been added to the dashboard`)}
            </EmptyMessageHeader>
            <EmptyMessageSubTitle>
              {i18n._(
                t`Add reports by clicking on "Save view" > "Add to dashboard"  in any report`,
              )}
            </EmptyMessageSubTitle>
          </>
        ) : (
          <>
            <EmptyMessageHeader>
              {i18n._(t`No reports have been added to the dashboard`)}
            </EmptyMessageHeader>
          </>
        )}
      </EmptyMessageHolder>
    );
  };

  return (
    <Wrapper>
      <Header gradient={headerGradient}>
        {type === PAGE_TYPE.ADMIN ? (
          <>
            <DropDownContainer>
              <SelectDropDown
                options={PRIMARY_OPTIONS}
                value={primaryOption?.key}
                onChange={onPrimaryChange}
                keyName="key"
                listMarginTop="8px"
                labelStyle={{
                  fontSize: '22px',
                }}
                optionStyles={{
                  fontSize: '12px',
                }}
                small
                label={i18n._(t`Select`)}
                renderLabel={(item: OptionTypes) => <div>{item.title(i18n)}</div>}
              />
              {primaryOption?.key === PRIMARY_OPTIONS_KEYS.TEAM && teamsArray.length > 0 && (
                <>
                  <Separator />
                  <FilterContainer>
                    <AutocompleteFilter
                      placeholder={
                        <>{selectedTeam ? selectedTeam.name || '' : i18n._(t`Select a team`)}</>
                      }
                      fetch={getStatus}
                      // @ts-ignore
                      labelProperty={(i: { name: string }) => i.name}
                      labelPropertyReserve={'name'}
                      isSingleSelect
                      onChange={(team) => {
                        if (team.length === 0) {
                          setSelectedTeam(null);
                          fetchData(ROLES.COACH, null);
                          return;
                        }
                        setSelectedTeam(team);
                        fetchData(ROLES.COACH, team);
                      }}
                    />
                  </FilterContainer>
                </>
              )}
              {primaryOption?.key === PRIMARY_OPTIONS_KEYS.TEAM && teamsArray.length === 0 && (
                <>
                  <Separator />
                  <EmptyTeamHolder>-</EmptyTeamHolder>
                </>
              )}
            </DropDownContainer>
            {primaryOption?.key === PRIMARY_OPTIONS_KEYS.TEAM && (
              <AllTeams>
                <Icon icon={ICONS.INFO_2} size={ICON_SIZES.MEDIUM} />
                {i18n._(t`Changes effect all teams`)}
              </AllTeams>
            )}
          </>
        ) : (
          <>
            <TeamHeader>{currentTeam?.name}</TeamHeader>
          </>
        )}
      </Header>
      <DragDropContext
        onDragEnd={(result) => {
          const { source, destination, draggableId } = result;
          if (
            !destination ||
            !source?.droppableId ||
            !destination.droppableId ||
            source.droppableId === destination.droppableId
          ) {
            return;
          }
          onDragEndAction({
            source,
            destination,
            draggableId,
          });
        }}
      >
        {(isDashboardLoading === LOADER_STATE.DASHBOARD_LOADED ||
          isDashboardLoading === LOADER_STATE.TILE_LOADING ||
          isDashboardLoading === LOADER_STATE.TILE_LOADED) && (
          <CardWrapper>{tileViews}</CardWrapper>
        )}
      </DragDropContext>
      {isDashboardLoading === LOADER_STATE.DASHBOARD_LOADING && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      {getEmptyState()}
    </Wrapper>
  );
};

export { CommonReportsDashboard };
