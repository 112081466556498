import React from 'react';

import { Container, Text } from './design';

type InfoBoxProps = {
  value: string;
};

const InfoBox = ({ value }: InfoBoxProps) => (
  <Container>
    <Text>{value}</Text>
  </Container>
);

export default InfoBox;
