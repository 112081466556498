import React from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useParams } from 'react-router-dom';

import RocketIcon from '~/components/Icons/Rocket';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import { RoleGuard } from '~/hooks/useRoleGuard';

import { GiveReview } from '../components/GiveReview';
import { useReviewTask } from '../hooks';

function CoachReview() {
  const params: Record<string, string> = useParams();
  const { i18n } = useLingui();
  const reviewTaskId = params.taskId;

  const { reviewTask, userReview, isLoading } = useReviewTask({ reviewTaskId });
  const introTitle = i18n._(t`Provide coach review`);

  return (
    <RoleGuard role={ROLES.COACH}>
      <ShowSpinnerIfLoading loading={isLoading}>
        {reviewTask && userReview && (
          <GiveReview reviewTask={reviewTask} userReview={userReview} introTitle={introTitle} />
        )}
        {(!reviewTask || !userReview) && (
          <Placeholder title={i18n._(t`No review task`)} Icon={RocketIcon} />
        )}
      </ShowSpinnerIfLoading>
    </RoleGuard>
  );
}

export { CoachReview };
