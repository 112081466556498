import React, { useEffect, useRef, useState } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import moment from 'moment';
import { Chart } from 'react-chartjs-2';

import {
  GraphFooter,
  ColoredCircle,
  Tile,
  Progress,
  TooltipBackgroundCtr,
  TooltipComparison,
  TooltipColValue,
  TooltipColorText,
  TooltipColorBar,
  OtherContentRight,
  OtherContentLeft,
  OtherColorBar,
  OtherContent,
  TooltipSecondary,
  TooltipRowValue,
  TooltipTitle,
  TooltipCtr,
  GraphCtr,
  GraphOuterCtr,
} from './AreaGraph.design';

import { COLORS, PRIMARY_COLOR } from '~/styles';
import { getRgba } from '~/utils/colorManipulator';

import { GraphDataType } from '../constants';

import type { ChartData, ChartOptions } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineController, LineElement);

export enum ELabelType {
  FILL = 'fill',
  BORDER = 'border',
}

export interface ILineGraphPoint {
  data: GraphDataType;
  colors: string[];
  label: string;
  type: ELabelType;
  key: string;
}

export interface ILineGraphProps {
  min?: number;
  max?: number;
  height?: number;
  stepSize: number;
  width?: number;
  colorRatio?: number;
  timeData?: ILineGraphPoint[];
  tooltipTitle?: string;
  bgColor?: string | null;
  onPointClick?: (d: any) => void;
}

const createGradient = (
  canvas: HTMLCanvasElement,
  colorRatio: number,
  color1: string,
  color2: string,
) => {
  const ctx = canvas.getContext('2d');
  const gradient = ctx?.createLinearGradient(0, 0, 0, ctx.canvas.height * colorRatio);
  gradient?.addColorStop(0, color1);
  gradient?.addColorStop(1, color2);
  return gradient;
};

function AreaGraph({
  min = 0,
  max = 100,
  height,
  stepSize = 20,
  width,
  colorRatio = 1,
  timeData = [],
  tooltipTitle,
  bgColor = null,
  onPointClick,
}: ILineGraphProps) {
  const filterMainData = timeData?.filter((a) => a.key === 'primary');
  const filteredTeamData = timeData?.filter((a) => a.key === 'team');
  const filteredCompanyData = timeData?.filter((a) => a.key === 'company');
  const filteredBenchmarkData = timeData?.filter((a) => a.key === 'benchmark');
  const primaryData = filterMainData && filterMainData.length > 0 ? filterMainData[0].data : [];
  const primaryDataLabels = primaryData.length > 0 ? primaryData.map((a) => a.key) : [];

  const toolRef = useRef<HTMLDivElement>(null);
  const yRef = useRef<HTMLDivElement>(null);
  const xRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const tRef = useRef<HTMLDivElement>(null);
  const teamRef = useRef<HTMLDivElement>(null);
  const teamOuterRef = useRef<HTMLDivElement>(null);
  const companyRef = useRef<HTMLDivElement>(null);
  const companyOuterRef = useRef<HTMLDivElement>(null);
  const benchmarkRef = useRef<HTMLDivElement>(null);
  const benchmarkOuterRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<ChartJS<'line'>>(null);
  const [chartDataSet, setChartDataSet] = useState<ChartData<'line'>>({ labels: [], datasets: [] });
  const baseColor = bgColor ? bgColor : PRIMARY_COLOR;
  const transparentBaseColor = getRgba(baseColor);

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const GRADIENT = createGradient(chart.canvas, colorRatio, baseColor, transparentBaseColor);

    const dataSets = timeData.map((item, i) => {
      const data = item.data.map((d) => d.value);
      return {
        label: item.key,
        data,
        tension: i === 0 ? 0.2 : 0,
        fill: i === 0,
        backgroundColor: i === 0 ? GRADIENT : COLORS.TRANSPARENT,
        borderWidth: i === 0 ? 0 : 3,
        ...(i !== 0 && { borderDash: [2, 2] }),
        borderColor:
          (timeData && timeData.length > i && timeData[i].colors[0]) || COLORS.TEXT_HOVER,
        pointRadius: 3,
        pointBorderWidth: 1,
        pointBackgroundColor:
          (timeData && timeData.length > i && timeData[i].colors[0]) || COLORS.WHITE,
        pointHoverBorderWidth: 1,
        pointHoverRadius: 6,
        pointHoverBorderColor: 'rgba(0, 0, 0, 0.5)',
        pointHoverBackgroundColor:
          (timeData && timeData.length > i && timeData[i].colors[0]) || COLORS.WHITE,
        order: timeData.length - i,
      };
    });

    if (dataSets?.length) {
      setChartDataSet({
        labels: primaryDataLabels,
        datasets: dataSets,
      } as ChartData<'line'>);
    }

    // eslint-disable-next-line
  }, [chartRef]);

  const options: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          if (
            toolRef?.current &&
            xRef?.current &&
            yRef?.current &&
            progressRef?.current &&
            tRef?.current
          ) {
            if (context.tooltip.opacity === 0) {
              toolRef.current.style.visibility = 'hidden';
              return;
            }
            toolRef.current.style.visibility = 'visible';
            toolRef.current.style.left = `${context.tooltip.x}px`;
            toolRef.current.style.top = `${context.tooltip.y}px`;
            if (context.tooltip.dataPoints && context.tooltip.dataPoints.length > 0) {
              const primaryPoint = context.tooltip.dataPoints.find(
                (dp) => dp.dataset.label === 'primary',
              ) || { formattedValue: '', dataIndex: 0 };
              yRef.current.innerText = `${primaryPoint.formattedValue}%`;
              const selectedIndex = primaryPoint.dataIndex;
              xRef.current.innerText = primaryData[selectedIndex].key;
              const deviation = Number(primaryData[selectedIndex].deviation.toFixed(2));
              progressRef.current.innerText = `${deviation}% compared to the previous`;
              if (deviation === 0) {
                tRef.current.style.display = 'none';
              } else {
                tRef.current.style.display = 'flex';
                if (deviation > 0) {
                  tRef.current.style.color = COLORS.CONFIRMATION_MODAL_SUCCESS;
                } else {
                  tRef.current.style.color = COLORS.CONFIRMATION_MODAL_DELETE;
                }
              }
              if (teamOuterRef && teamRef && teamRef.current && teamOuterRef.current) {
                const tempVal =
                  filteredTeamData.length > 0 ? filteredTeamData[0].data[selectedIndex].value : 0;
                if (tempVal > 0) {
                  teamRef.current.innerText = `${tempVal}%`;
                  teamOuterRef.current.style.display = 'flex';
                } else {
                  teamOuterRef.current.style.display = 'none';
                }
              }
              if (companyRef && companyOuterRef && companyRef.current && companyOuterRef.current) {
                const tempVal =
                  filteredCompanyData.length > 0
                    ? filteredCompanyData[0]?.data[selectedIndex].value
                    : 0;
                if (tempVal > 0) {
                  companyRef.current.innerText = `${tempVal}%`;
                  companyOuterRef.current.style.display = 'flex';
                } else {
                  companyOuterRef.current.style.display = 'none';
                }
              }

              if (
                benchmarkRef &&
                benchmarkOuterRef &&
                benchmarkRef.current &&
                benchmarkOuterRef.current
              ) {
                const tempVal =
                  filteredBenchmarkData.length > 0
                    ? filteredBenchmarkData[0]?.data[selectedIndex].value
                    : 0;
                if (tempVal > 0) {
                  benchmarkRef.current.innerText = `${tempVal}%`;
                } else {
                  benchmarkRef.current.innerText = '-';
                }
              }

              if (primaryPoint.dataIndex && primaryPoint.dataIndex > primaryData.length - 3) {
                toolRef.current.style.transform = 'translate(-50%, -100%)';
              } else {
                toolRef.current.style.transform = 'translate(0%, -100%)';
              }
            }
          }
        },
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
          lineWidth: 0,
        },
        ticks: {
          callback: (value) => {
            const valueIndex = parseInt(value.toString(), 10);
            const label = primaryDataLabels[valueIndex];

            if (label.includes('01')) {
              return moment(label).format('YYYY MMM');
            }
            return moment(label).format('MMM');
          },
        },
      },
      y: {
        beginAtZero: true,
        min,
        suggestedMax: max,
        ticks: {
          stepSize,
        },
        grid: {
          display: true,
          color: COLORS.BORDERS,
        },
        border: {
          display: false,
        },
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    onClick(_event, elements, _chart) {
      const primaryDataIndex = timeData.findIndex((a) => a.key === 'primary');
      const elementData = elements.find((e) => e.datasetIndex === primaryDataIndex);

      if (!elementData) {
        return;
      }

      // find the correct element from the primaryData
      const element = timeData[primaryDataIndex].data[elementData.index];

      // TODO: handle filters with its dedicated ticket. LR-6013
      !!onPointClick && onPointClick({ id: 'some', name: 'some', value: element.value });
    },
  };

  return (
    <GraphOuterCtr>
      <GraphCtr>
        <Chart
          ref={chartRef}
          height={height}
          width={width}
          type="line"
          data={chartDataSet}
          options={options as any}
        />
        <TooltipCtr ref={toolRef}>
          {tooltipTitle && <TooltipTitle>{tooltipTitle}</TooltipTitle>}
          <TooltipRowValue ref={xRef} />

          {filterMainData.length > 0 && (
            <TooltipSecondary>
              <TooltipColorBar color={filterMainData[0]?.colors[0] || COLORS.TEXT_HOVER} />
              <TooltipColorText>
                <TooltipColValue ref={yRef} />
                <TooltipComparison ref={tRef}>
                  <TooltipBackgroundCtr />
                  <Progress ref={progressRef} />
                </TooltipComparison>
              </TooltipColorText>
            </TooltipSecondary>
          )}

          {filteredTeamData.length > 0 && (
            <OtherContent
              ref={teamOuterRef}
              color={filteredTeamData[0]?.colors[0] || COLORS.TEXT_HOVER}
            >
              <OtherColorBar />
              <OtherContentLeft>{filteredTeamData[0].label}</OtherContentLeft>
              <OtherContentRight ref={teamRef} />
            </OtherContent>
          )}
          {filteredCompanyData.length > 0 && (
            <OtherContent
              ref={companyOuterRef}
              color={filteredCompanyData[0]?.colors[0] || COLORS.TEXT_HOVER}
            >
              <OtherColorBar />
              <OtherContentLeft>{filteredCompanyData[0].label}</OtherContentLeft>
              <OtherContentRight ref={companyRef} />
            </OtherContent>
          )}
          {filteredBenchmarkData.length > 0 && (
            <OtherContent
              color={filteredBenchmarkData[0]?.colors[0] || COLORS.TEXT_HOVER}
              ref={benchmarkOuterRef}
            >
              <OtherColorBar />
              <OtherContentLeft>{filteredBenchmarkData[0].label}</OtherContentLeft>
              <OtherContentRight ref={benchmarkRef} />
            </OtherContent>
          )}
        </TooltipCtr>
      </GraphCtr>
      {timeData && (
        <GraphFooter>
          {timeData.map((item, i) => (
            <Tile key={`tiles-${i + 1}`}>
              <ColoredCircle selectedColor={item.colors[0]} />
              {item.label}
            </Tile>
          ))}
        </GraphFooter>
      )}
    </GraphOuterCtr>
  );
}

export { AreaGraph };
