import { useEffect, useState } from 'react';

import { SKILL_TEMPLATE_SORT_OPTIONS } from '@learned/constants';

import { AddSkillModalResource } from './types';

import useDebounce from '~/hooks/useDebounce';
import { getSkills } from '~/services/skills';
import { listSkillTemplatesSuperAdmin } from '~/services/skillTemplates';

import type { ISkill, ISkillTemplate } from '@learned/types';

const useAddSkillModal = ({
  skillCategoryTemplateId,
  skillCategoryId,
  initSource,
  resource,
}: {
  skillCategoryId: string;
  skillCategoryTemplateId?: string;
  initSource?: ISkillTemplate | ISkill;
  resource: AddSkillModalResource;
}) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [isSelectLevelAndFocusAreasVisible, setIsSelectLevelAndFocusAreasVisible] = useState(
    !!initSource,
  );
  const search = useDebounce(searchInputValue, 300);

  const [skillNames, setSkillNames] = useState();
  const [skills, setSkills] = useState([]);
  const [source, setSource] = useState<ISkillTemplate | ISkill | undefined>(initSource);

  useEffect(() => {
    const fetchSkillTemplates = async () => {
      const response = await listSkillTemplatesSuperAdmin(
        { categoryId: skillCategoryId },
        { skip: 0, limit: 20, sortBy: SKILL_TEMPLATE_SORT_OPTIONS.NAME_A_Z },
      );

      setSkills(response.data.skillTemplates);
    };

    const fetchSkills = async () => {
      const response = await getSkills({
        hideDeleted: true,
        limit: 20,
        // @ts-ignore
        categories: [skillCategoryId],
      });

      setSkills(Object.values(response.data.skills));
    };

    if (resource === AddSkillModalResource.SKILL_TEMPLATES) {
      fetchSkillTemplates();
    }

    if (resource === AddSkillModalResource.SKILLS) {
      fetchSkills();
    }
  }, [resource, skillCategoryId]);

  useEffect(() => {
    const fetchSuggestedSkillTemplates = async () => {
      const response = await listSkillTemplatesSuperAdmin(
        { search, categoryId: skillCategoryTemplateId },
        { skip: 0, limit: 20, sortBy: SKILL_TEMPLATE_SORT_OPTIONS.NAME_A_Z },
      );

      setSkillNames(response.data.skillTemplates);
    };

    if (search) {
      fetchSuggestedSkillTemplates();
    }
  }, [search, skillCategoryId, skillCategoryTemplateId]);

  useEffect(() => {
    if (!searchInputValue) {
      setSkillNames(undefined);
    }
  }, [searchInputValue]);

  return {
    skillNames,
    setSearchInputValue,
    searchInputValue,
    skills,
    isSelectLevelAndFocusAreasVisible,
    setIsSelectLevelAndFocusAreasVisible,
    source,
    setSource,
  };
};

export { useAddSkillModal };
