import type { IGetServerRoute, IServerRoute, IUpdateServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

import type { IReview } from '@learned/types';

export function createReview(body: Partial<IReview>) {
  return cloudRequest(serverRoutes.reviews.createReview as IServerRoute, {}, body);
}

export function fetchReviewById(reviewId: IReview['id']) {
  return cloudRequest((serverRoutes.reviews.fetchReviewById as IGetServerRoute)(reviewId), {}, {});
}

export function updateReviewById(reviewId: IReview['id'], body: { review: Partial<IReview> }) {
  return cloudRequest(
    (serverRoutes.reviews.updateReviewById as IUpdateServerRoute)(reviewId),
    {},
    body,
  );
}

export function deleteReviewById(reviewId: IReview['id']) {
  return cloudRequest((serverRoutes.reviews.deleteById as IGetServerRoute)(reviewId), {}, {});
}

export function archiveReviewById(reviewId: IReview['id']) {
  return cloudRequest((serverRoutes.reviews.archiveById as IGetServerRoute)(reviewId), {}, {});
}

export function unarchiveReviewById(reviewId: IReview['id']) {
  return cloudRequest((serverRoutes.reviews.unarchiveById as IGetServerRoute)(reviewId), {}, {});
}

export function fetchReviewLogs(
  reviewId: IReview['id'],
  body: { options: { skip?: number; limit?: number } },
) {
  return cloudRequest((serverRoutes.reviews.getReviewLogs as IGetServerRoute)(reviewId), {}, body);
}
