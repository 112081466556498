import React, { useCallback, useEffect, useState } from 'react';

import { useLingui } from '@lingui/react';

import ChevronIcon from '~/components/Icons/Chevron';
import SvgIcon from '~/components/SvgIcon';
import Tooltip from '~/components/Tooltip';

import dropdownIcon from '~/assets/ic-arrow-drop-down-down.svg';

import type { IColumnTable } from '~/@types/table';
import { useScrollEffect } from '~/hooks/useScrollEffect';
import { COLORS } from '~/styles';

import { THLabel, TH, SortMenu, SortIcons } from '../../design';

interface ITHColumnProps {
  column: IColumnTable;
  sortProps?: {
    sortBy?: string;
    handleSortBy: (col: string | undefined) => void;
  };
  visibleColumn?: string;
  thColumnPadding?: string;
  showSortBy?: boolean;
}

const i18nLabel = (label: string | Function, i18n: any) =>
  typeof label === 'function' ? label(i18n) : label;

const THColumn = ({
  column,
  sortProps,
  visibleColumn,
  thColumnPadding,
  showSortBy = false,
}: ITHColumnProps) => {
  const { i18n } = useLingui();
  const [activeSortByColumn, setActiveSortByColumn] = useState<string | undefined>();
  const { ref, scrollToRef } = useScrollEffect<HTMLDivElement>({ behavior: 'smooth' });

  useEffect(() => {
    if (visibleColumn && visibleColumn === column.accessor) {
      scrollToRef();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSortColumnMenu = useCallback(
    (accessor: IColumnTable['accessor']) => {
      if (activeSortByColumn === accessor) {
        setActiveSortByColumn('');
      } else {
        setActiveSortByColumn(accessor);
      }
    },
    [activeSortByColumn],
  );

  const checkActiveSortColumn = (column: IColumnTable) => {
    return activeSortByColumn === column.accessor;
  };

  const handleMouseDown = useCallback(
    (event: MouseEvent) => {
      // @ts-ignore
      const className = event?.target?.className;
      if ((className?.match && className?.match('item')) || className?.baseVal?.match('icon')) {
        return;
      } else {
        activeSortByColumn && toggleSortColumnMenu('');
      }
    },
    [toggleSortColumnMenu, activeSortByColumn],
  );

  useEffect(() => {
    // @ts-ignore
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      // @ts-ignore
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleMouseDown]);

  return (
    <TH
      // @ts-ignore
      ref={ref}
      key={`th-${column.accessor}`}
      minWidth={column?.minWidth}
      maxWidth={column?.maxWidth}
    >
      <THLabel
        className={
          activeSortByColumn !== undefined
            ? checkActiveSortColumn(column)
              ? 'hover'
              : 'isFade'
            : ''
        }
        $isPointer={Boolean(column.sortBy)}
        onClick={() => column.sortBy && toggleSortColumnMenu(column.accessor)}
        padding={
          thColumnPadding
            ? thColumnPadding
            : column?.padding === 'none'
            ? '7px 18px'
            : column?.padding
        }
        centerAlign={column?.centerAlign}
      >
        {column?.showHeaderTooltip ? (
          <Tooltip
            tooltip={
              typeof column.name === 'function' ? column.name && column.name(i18n) : column.name
            }
          >
            <span>
              {typeof column.name === 'function' ? column.name && column.name(i18n) : column.name}
            </span>
          </Tooltip>
        ) : (
          <span>
            {typeof column.name === 'function' ? column.name && column.name(i18n) : column.name}
          </span>
        )}
        {column.sortBy && (
          <>
            <SortIcons visibility={showSortBy || checkActiveSortColumn(column)}>
              <SvgIcon
                className="rotate"
                width="9px"
                height="7px"
                isDefaultColor={sortProps?.sortBy !== column?.sortBy?.asc.key}
                defaultColor={COLORS.PLACEHOLDERS}
                url={dropdownIcon}
              />
              <SvgIcon
                width="9px"
                height="7px"
                isDefaultColor={sortProps?.sortBy !== column?.sortBy?.desc.key}
                defaultColor={COLORS.PLACEHOLDERS}
                url={dropdownIcon}
              />
            </SortIcons>
          </>
        )}
      </THLabel>
      {column.sortBy && checkActiveSortColumn(column) && (
        <SortMenu>
          <button
            className={`item ${column?.sortBy?.asc?.key === sortProps?.sortBy ? 'selected' : ''}`}
            onClick={() => {
              sortProps?.handleSortBy(column?.sortBy?.asc?.key);
              setActiveSortByColumn('');
            }}
          >
            <ChevronIcon
              size={12}
              className="icon"
              width="12px"
              height="15px"
              color={COLORS.MIDDLE_GRAY}
            />
            {i18nLabel(column.sortBy?.asc.label, i18n)}
          </button>
          <button
            className={`item ${column?.sortBy?.desc?.key === sortProps?.sortBy ? 'selected' : ''}`}
            onClick={() => {
              sortProps?.handleSortBy(column?.sortBy?.desc?.key);
              setActiveSortByColumn('');
            }}
          >
            <ChevronIcon
              size={12}
              className="icon rotate"
              width="12px"
              height="15px"
              color={COLORS.MIDDLE_GRAY}
            />

            {i18nLabel(column.sortBy?.desc.label, i18n)}
          </button>
        </SortMenu>
      )}
    </TH>
  );
};

export { THColumn };
